<template>
  <BaseModal @close="$emit('close')">
    <template v-slot:header>
      <h2>New Admin</h2>
    </template>

    <template v-slot:body>
      <hr style="margin-top: 0" />
      <div class="form">
        <form @submit.prevent="addAdmin">
          <!-- <div class="upload-photo-container">
            <div class="upload-photo-bg">
              <p class="upload-photo-text">
                <span
                  v-if="fileName"
                  style="font-weight: 900; font-size: 18px"
                  >{{ fileName }}</span
                >
                <span v-else>Upload Photo</span>
              </p>
            </div>
            <input
              type="file"
              class="upload-photo-input"
              accept="image/*"
              @change="uploadImage"
            />
          </div> -->
          <fieldset class="form-group">
            <label>Name</label>
            <input type="text" placeholder="Name" v-model="formUserAdd.name" />
          </fieldset>
          <fieldset class="form-group">
            <label>Email</label>
            <input type="text" v-model="formUserAdd.email" />
          </fieldset>
          <fieldset class="form-group">
            <label>Password</label>
            <input type="password" v-model="formUserAdd.password" />
          </fieldset>
          <fieldset class="form-group">
            <label>Confirm Password</label>
            <input type="password" v-model="confirmPassword" />
          </fieldset>
          <p v-if="formUserAdd.password !== confirmPassword" class="red">
            Please, check your password
          </p>
          <fieldset class="form-group">
            <label>Role</label>
            <select v-model="formUserAdd.role">
              <option
                v-for="r in adminRoles"
                :value="r.value"
                :key="r.id"
                :checked="r.title === 'admin'"
              >
                {{ r.title }}
              </option>
            </select>
          </fieldset>
        </form>
      </div>
    </template>

    <template v-slot:footer>
      <div class="btn-wrapper">
        <BaseButton
          className="typical big"
          text="Send Invitation"
          :disabled="isDisabledBtn"
          @click="addAdmin"
        ></BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import BaseModal from "@/components/BaseModal.vue";
import BaseButton from "@/modules/components/base/BaseButton.vue";

export default {
  components: { BaseModal, BaseButton },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();

    const formUserAdd = ref({
      name: "",
      email: "",
      password: "",
      role: "",
      procurement_site_id: route.params.id,
    });
    const confirmPassword = ref("");

    const adminRoles = computed(() => store.getters["admins/adminRoles"]);
    const isDisabledBtn = computed(() => {
      const f = formUserAdd.value;
      return !f.name || !f.email || f.password !== confirmPassword.value;
    });

    const cleanForm = () => {
      formUserAdd.value.name = "";
      formUserAdd.value.email = "";
      formUserAdd.value.password = "";
      confirmPassword.value = "";
    };

    const addAdmin = () => {
      store.dispatch("admins/createAdmin", formUserAdd.value);

      cleanForm();
      emit("close");
    };

    return {
      adminRoles,
      formUserAdd,
      confirmPassword,
      isDisabledBtn,
      addAdmin,
    };
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 26px;
  font-weight: 400;
}
input,
textarea,
select {
  width: 100%;
  margin: 0;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--col-border);
  font-size: 16px;
  font-weight: 400;
  color: var(--col-text-secondary);
}
select {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
label {
  display: block;
  font-size: 16px;
  line-height: 1.2;
  font-family: "OpenSans-Regular";
  color: var(--col-text-secondary);
}
.btn-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
