<template>
  <div class="chat-wrapper">
    <div v-if="dialogIsShown || newChat">
      <ChatDialog :id="id" :user="userData" @show-all-chats="showAllChats" />
    </div>

    <div v-else style="position: relative">
      <h3 class="green">Messages</h3>
      <hr />

      <div v-if="chats && chats.length">
        <div v-if="isLoading" class="chat-loader">
          <h3 class="green">Loading ...</h3>
        </div>

        <div v-for="(chat, index) in chats" :key="index">
          <h4 class="chat-tss-title green">TSS: {{ chat.title }}</h4>
          <div
            v-for="user in chat.users"
            :key="user.id"
            style="cursor: pointer"
            @click="showUserChat(user, chat.title)"
          >
            <div class="chat-container">
              <div class="chat-avatar">
                <img v-if="user.avatar" :src="`${user.avatar}`" alt="user" />
                <span v-else>U</span>
              </div>

              <div class="chat-text">
                <p class="chat-text-area">{{ user.name }}</p>
                <p class="chat-text-area grey">{{ user.email }}</p>
              </div>

              <div v-if="!user.seen" class="chat-status"></div>
            </div>
            <hr style="margin-bottom: 10px" />
          </div>
        </div>
      </div>

      <div v-else>
        <h4 class="grey" style="padding-top: 15px">No messages yet</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs, onUnmounted } from "vue";
import { useStore } from "vuex";
import ChatDialog from "@/components/chat/ChatDialog.vue";

export default {
  components: { ChatDialog },
  props: {
    id: {
      type: Number,
    },
  },
  setup(props) {
    const store = useStore();
    const { id } = toRefs(props);

    const isLoading = computed(() => store.getters["chat/isLoading"]);
    const chat = computed(() => store.getters["chat/activeChat"]);
    const chats = computed(() => store.getters["chat/chats"]);
    const newChat = computed(() => store.getters["chat/newChat"]);

    const dialogIsShown = ref(false);
    const userData = ref({});

    const showUserChat = async (user, tss) => {
      await store.dispatch("chat/getChat", { pid: id.value, uid: user.id });
      userData.value = {
        id: user.id,
        name: user.name,
        tss: tss,
      };
      dialogIsShown.value = true;
    };

    const showAllChats = () => (dialogIsShown.value = false);

    onUnmounted(() => {
      store.commit("chat/START_CHAT", {
        isShown: false,
        user: null,
      });
    });
    return {
      chat,
      chats,
      newChat,
      dialogIsShown,
      isLoading,
      userData,
      showUserChat,
      showAllChats,
    };
  },
};
</script>

<style lang="scss" scoped>
.chat {
  &-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--col-info-bg);
  }

  &-tss-title {
    padding: 15px 0 10px;
  }

  &-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-avatar {
    min-width: 40px;
    height: 40px;
    flex-basis: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--col-info);
    border-radius: 50%;
    color: var(--col-info);
    background-color: var(--col-info-bg);
    overflow: hidden;
  }

  &-text {
    flex-grow: 1;
    padding: 0 5px;

    &-area {
      padding: 0 5px;
      line-height: 1.2;
    }
  }

  &-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--col-info);
  }
}
</style>
