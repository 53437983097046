<template>
  <div class="visibilities">
    <h3 class="green">Visibility:</h3>
    <div class="line">
      <div class="chbx">
        <label
          class="line-heading"
          :class="{ active: project.visibility == 'all' }"
          @click.prevent="saveProcurementSitesVisibility('all')"
        >
          <IconHouse />
          <span>Anyone</span>
          <input
            type="radio"
            name="vis"
            value="all"
            :checked="project.visibility === 'all'"
            v-model="proj.visibility"
          />
        </label>
      </div>
    </div>
    <div class="line">
      <div class="chbx">
        <label
          class="line-heading"
          :class="{ active: project.visibility == 'procurement-sites' }"
          @click="$emit('select')"
        >
          <IconPeople />
          <span>Select TSS</span>
          <input
            type="radio"
            name="vis"
            value="procurement-sites"
            :checked="project.visibility === 'procurement-sites'"
            v-model="proj.visibility"
          />
        </label>
      </div>
    </div>
    <div class="line">
      <div class="chbx">
        <label
          class="line-heading"
          :class="{ active: project.visibility == 'tissue-bank' }"
          @click.prevent="saveProcurementSitesVisibility('tissue-bank')"
        >
          <IconPeople />
          <span>Tissue bank only</span>
          <input
            type="radio"
            name="vis"
            value="tissue-bank"
            :checked="project.visibility === 'tissue-bank'"
            v-model="proj.visibility"
          />
        </label>
      </div>
    </div>

    <div
      v-if="
        project.project_visibility && proj.visibility === 'procurement-sites'
      "
      class="visibilities-list"
    >
      <h4 class="green">Is visible for TSS:</h4>
      <h4
        class="grey"
        v-for="(tss, index) in project.project_visibility"
        :key="tss.id"
      >
        {{ index + 1 }}) {{ tss.title }}
      </h4>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import IconHouse from "@/modules/components/icons/IconHouse.vue";
import IconPeople from "@/modules/components/icons/IconPeople.vue";

export default {
  components: {
    IconHouse,
    IconPeople,
  },
  props: {
    project: {
      type: Object,
    },
  },
  emits: ["select"],
  setup(props) {
    const store = useStore();

    const { project } = toRefs(props);
    const proj = ref({});
    proj.value = project.value;

    const saveProcurementSitesVisibility = (type) => {
      store.dispatch("projects/saveVisibility", {
        id: project.value.id,
        visibility: type,
      });
    };

    const visibility = computed(() => project.value.visibility);

    return {
      proj,
      visibility,
      saveProcurementSitesVisibility,
    };
  },
};
</script>

<style lang="scss" scoped>
.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  color: var(--col-subtext-primary);

  &-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-name {
      padding-left: 10px;
    }

    &:hover,
    &.active {
      background-color: var(--col-info);
      color: var(--col-contrast-text);

      svg {
        fill: var(--col-contrast-text);
      }
    }
  }
}

.visibilities {
  position: relative;

  label,
  input {
    font-size: 16px;
    line-height: 24px;
    font-family: "OpenSans-Regular", sans-serif;
    color: var(--col-text-secondary);
  }
  input {
    margin: 7px 0;
    padding: 6px 12px;
  }

  .chbx {
    margin: 0;
    flex: 1;
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    input {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 16px;
      flex: none;
    }
  }
  .line-heading {
    padding: 2px;
    cursor: pointer;
  }
  svg {
    margin-right: 12px;
  }
  span {
    flex: 1;
  }
  input,
  .ticked,
  .empty {
    display: inline-block;
    width: 24px;
    height: 24px;
    flex: none;
    margin: 7px 0;
    padding: 6px 12px;
    margin-right: 16px;
  }

  &-list {
    max-height: 200px;
    margin: 10px 0;
    padding-left: 10px;
    border-left: 3px solid var(--col-info);
    border-radius: 3px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
<style lang="scss">
.chbx {
  margin: 2rem 0;
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    flex: none;
  }
}
</style>
