<template>
  <BaseModal @close="$emit('close')">
    <template v-slot:header>
      <h2>Manage User</h2>
    </template>

    <template v-slot:body>
      <hr style="margin-top: 0" />
      <div class="form">
        <form @submit.prevent="updateUser">
          <fieldset class="form-group">
            <label>Contact Name</label>
            <input type="text" placeholder="Name" v-model="formUserEdit.name" />
          </fieldset>
          <fieldset class="form-group">
            <label>Role</label>
            <select v-model="formUserEdit.role">
              <option
                v-for="r in userRoles"
                :value="r.value"
                :key="r.id"
                :checked="r.title === 'admin'"
              >
                {{ r.title }}
              </option>
            </select>
          </fieldset>
          <fieldset class="form-group">
            <label>Contact Email</label>
            <input type="text" v-model="formUserEdit.email" />
          </fieldset>
        </form>
      </div>
    </template>

    <template v-slot:footer>
      <div class="btn-wrapper">
        <div class="resend">
          <input
            type="checkbox"
            name="resend"
            id="resend"
            class="resend-input"
          />
          <label for="resend" class="resend-label">Re-send invitation</label>
        </div>
        <BaseButton
          className="typical"
          text="Save Changes"
          @click="editUser"
        ></BaseButton>
      </div>
      <div class="btn-wrapper-qwe">
        <hr class="dashed" style="margin-bottom: 15px" />
        <BaseButton
          className="error"
          text="Remove User"
          @click="deleteUser"
        ></BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import BaseModal from "@/components/BaseModal.vue";
import BaseButton from "@/modules/components/base/BaseButton.vue";

export default {
  components: { BaseModal, BaseButton },
  props: {
    user: {
      type: Object,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const userRoles = computed(() => store.getters["user/userRoles"]);

    const formUserEdit = ref({
      name: "",
      email: "",
      role: "",
      id: "",
    });

    let formUserEditToSend = ref({});

    onMounted(() => {
      formUserEdit.value = Object.assign({}, props.user);
    });

    const cleanForm = () => {
      formUserEdit.value.name = "";
      formUserEdit.value.email = "";
      formUserEdit.value.password = "";
      formUserEdit.value.role = "";
    };

    const compareEditedData = () => {
      const USER = Object.assign({}, props.user);
      Object.keys(formUserEdit.value).map((property) => {
        let p1 = formUserEdit.value[property];
        let p2 = USER[property];
        if (p1 !== p2) {
          formUserEditToSend.value[property] = p1;
        }
      });
      formUserEditToSend.value.id = formUserEdit.value.id;
    };

    const editUser = async () => {
      compareEditedData();
      await store
        .dispatch("user/editUser", formUserEditToSend.value)
        .catch((error) => {
          this.errors = error.response.data.errors;
          console.error("Errors from editUser:", error);
        });
      emit("close");
    };

    const deleteUser = () => {
      store
        .dispatch("user/deleteUser", formUserEdit.value.id)
        .catch((error) => {
          this.errors = error.response.data.errors;
          console.error("Errors from deleteUser:", error);
        });

      cleanForm();
      emit("close");
    };

    return { formUserEdit, userRoles, editUser, deleteUser };
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 26px;
  font-weight: 400;
}
input,
textarea,
select {
  width: 100%;
  margin: 0;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--col-border);
  font-size: 16px;
  font-weight: 400;
  color: var(--col-text-secondary);
}
select {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
label {
  font-size: 16px;
  line-height: 2;
  font-family: "OpenSans-Regular";
  color: var(--col-text-secondary);
}
.btn-wrapper {
  display: flex;
  justify-content: space-between;
}
.resend {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &-input {
    width: 20px;
    margin-right: 10px;
  }

  &-label {
    font-size: 14px;
    font-family: "SFProDisplay-Bold";
    color: var(--col-text-secondary);
  }
}
</style>
