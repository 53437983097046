import { createStore } from "vuex";
import auth from "@/store/modules/auth";
import admins from "@/store/modules/admins";
import active from "@/store/modules/active";
import archived from "@/store/modules/archived";
import feasibility from "@/store/modules/feasibility";
import chat from "@/store/modules/chat";
import globalMessenger from "./modules/globalMessenger";
import projects from "@/store/modules/projects";
import proposals from "@/store/modules/proposals";
import tracking from "@/store/modules/tracking";
import site from "@/store/modules/site";
import user from "@/store/modules/user";
import notifications from "./modules/notifications";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    admins,
    active,
    archived,
    feasibility,
    chat,
    globalMessenger,
    projects,
    proposals,
    tracking,
    site,
    user,
    notifications,
  },
});
