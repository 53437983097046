<template>
  <svg :width="width" :height="height" :fill="iconColor" viewBox="0 0 31 41">
    <path
      d="M30.1384 10.25H21.5282C21.2997 10.25 21.0806 10.16 20.919 9.99969C20.7574 9.83942 20.6667 9.62205 20.6667 9.39539V0.85461C20.6667 0.627954 20.5759 0.41058 20.4143 0.25031C20.2527 0.0900391 20.0336 0 19.8051 0L2.58199 0C1.89764 0.00140691 1.24172 0.271695 0.757814 0.751703C0.273904 1.23171 0.00141835 1.88234 0 2.56117L0 38.4388C0.00141835 39.1177 0.273904 39.7683 0.757814 40.2483C1.24172 40.7283 1.89764 40.9986 2.58199 41H28.418C29.1024 40.9986 29.7583 40.7283 30.2422 40.2483C30.7261 39.7683 30.9986 39.1177 31 38.4388V11.1046C31 10.878 30.9092 10.6606 30.7477 10.5003C30.5861 10.34 30.3669 10.25 30.1384 10.25ZM29.2769 38.4388C29.2769 38.6648 29.1864 38.8815 29.0253 39.0413C28.8643 39.201 28.6458 39.2908 28.418 39.2908H2.58199C2.3542 39.2908 2.13575 39.201 1.97468 39.0413C1.81361 38.8815 1.72312 38.6648 1.72312 38.4388V2.56117C1.72312 2.33522 1.81361 2.11852 1.97468 1.95875C2.13575 1.79898 2.3542 1.70922 2.58199 1.70922H18.9543V9.39539C18.955 10.0751 19.2275 10.7269 19.7121 11.2075C20.1967 11.6882 20.8537 11.9585 21.539 11.9592H29.2876L29.2769 38.4388Z"
    />
    <path
      d="M30.7476 10.5003L20.4143 0.25033C20.3342 0.170966 20.2393 0.10801 20.1347 0.0650587C20.0302 0.022107 19.9181 1.18262e-09 19.805 0C19.6918 -1.18262e-09 19.5798 0.022107 19.4753 0.0650587C19.3707 0.10801 19.2757 0.170966 19.1957 0.25033C19.1157 0.329695 19.0522 0.423914 19.0089 0.527609C18.9656 0.631303 18.9434 0.742443 18.9434 0.854681C18.9434 0.966919 18.9656 1.07806 19.0089 1.18175C19.0522 1.28545 19.1157 1.37967 19.1957 1.45903L29.5237 11.7144C29.6853 11.8746 29.9044 11.9647 30.133 11.9647C30.3615 11.9647 30.5806 11.8746 30.7422 11.7144C30.9038 11.5541 30.9946 11.3367 30.9946 11.11C30.9946 10.8833 30.9038 10.6659 30.7422 10.5057L30.7476 10.5003Z"
    />
    <path
      d="M6.90576 22.3245H9.16568C9.69141 22.3096 10.2126 22.4249 10.6821 22.66C11.0987 22.8831 11.436 23.228 11.6484 23.6477C11.885 24.1401 12 24.6813 11.9839 25.2265C12.0004 25.7774 11.8864 26.3245 11.651 26.8239C11.4413 27.2522 11.104 27.6063 10.6848 27.8382C10.2168 28.0813 9.69378 28.2014 9.16568 28.187H6.90576V22.3245ZM9.01806 27.1726C9.34258 27.1836 9.66415 27.1082 9.9494 26.9543C10.2063 26.8034 10.4076 26.5748 10.5238 26.3021C10.6657 25.9652 10.7335 25.6021 10.7224 25.2371C10.7329 24.8831 10.6641 24.5313 10.5211 24.2068C10.3984 23.9338 10.1917 23.7064 9.93061 23.5572C9.6483 23.4057 9.33085 23.3305 9.01 23.3389H8.14308V27.1726H9.01806Z"
    />
    <path
      d="M13.9326 27.9222C13.5059 27.6765 13.1615 27.3117 12.9422 26.8732C12.7004 26.369 12.5818 25.8154 12.596 25.2572C12.581 24.6995 12.7007 24.1464 12.9449 23.6438C13.1645 23.2054 13.51 22.8414 13.938 22.5975C14.4054 22.3545 14.9253 22.2275 15.4531 22.2275C15.9808 22.2275 16.5007 22.3545 16.9682 22.5975C17.3929 22.8449 17.7361 23.2093 17.9559 23.6465C18.1962 24.1446 18.3148 24.6918 18.3021 25.2439C18.3166 25.8012 18.1979 26.3541 17.9559 26.8573C17.7376 27.2955 17.3941 27.6603 16.9682 27.9062C16.5057 28.1632 15.9816 28.2911 15.4517 28.2763C14.9227 28.294 14.3984 28.1718 13.9326 27.9222ZM16.2972 27.0037C16.5375 26.8269 16.7233 26.5872 16.834 26.3115C16.9712 25.9734 17.037 25.6109 17.0272 25.2465C17.0361 24.882 16.9694 24.5195 16.8313 24.1816C16.7217 23.906 16.5356 23.6669 16.2945 23.4921C16.0428 23.334 15.751 23.25 15.4531 23.25C15.1551 23.25 14.8633 23.334 14.6116 23.4921C14.3648 23.6637 14.1729 23.9022 14.0587 24.1789C13.9196 24.5166 13.8528 24.8793 13.8628 25.2439C13.8527 25.6085 13.9194 25.9712 14.0587 26.3088C14.1719 26.5868 14.3628 26.827 14.609 27.001C14.8618 27.1601 15.155 27.2446 15.4544 27.2446C15.7538 27.2446 16.047 27.1601 16.2999 27.001L16.2972 27.0037Z"
    />
    <path
      d="M20.2317 27.925C19.8099 27.6833 19.4699 27.3229 19.2548 26.8894C19.0119 26.3827 18.8932 25.8262 18.9085 25.2653C18.8932 24.7045 19.0119 24.148 19.2548 23.6413C19.4684 23.2054 19.8086 22.8428 20.2317 22.6003C20.6909 22.3411 21.212 22.2096 21.7401 22.2196C22.1811 22.2087 22.6185 22.2999 23.0177 22.4859C23.3783 22.6601 23.6886 22.922 23.9195 23.2473C24.1479 23.5744 24.2849 23.9556 24.3168 24.3522V24.3921H23.1009L23.0821 24.3335C23.0419 24.1294 22.9566 23.9367 22.8325 23.7691C22.7125 23.6096 22.5549 23.4816 22.3736 23.3964C22.1754 23.3044 21.9589 23.2579 21.7401 23.2606C21.4468 23.2543 21.1587 23.339 20.9162 23.5029C20.6757 23.6761 20.4895 23.9133 20.3794 24.1871C20.2443 24.5257 20.1795 24.8879 20.1888 25.252C20.181 25.616 20.2458 25.9779 20.3794 26.317C20.4875 26.5934 20.6739 26.8329 20.9162 27.0065C21.1575 27.1708 21.4448 27.2556 21.7375 27.2488C21.9558 27.2531 22.1723 27.2085 22.3709 27.1183C22.5565 27.035 22.7173 26.9058 22.8379 26.7429C22.9701 26.5659 23.0617 26.3624 23.1063 26.1466V26.1066H24.3141V26.1519C24.2813 26.5487 24.1434 26.9299 23.9142 27.2568C23.6828 27.582 23.3715 27.8432 23.0097 28.0155C22.6105 28.2016 22.173 28.2927 21.7321 28.2818C21.2089 28.2976 20.6909 28.1745 20.2317 27.925Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 31 },
    height: { type: [Number, String], default: 41 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
