<template>
  <div class="line">
    <div class="line-header">
      <div v-if="proposal.user?.name" class="line-header-user">
        <p class="line-header-user-name">
          <IconGeo icon-color="#fff" width="15" height="13" />
          {{ proposal.user.procurement_site.title }}
        </p>
        <div class="line-header-user-data">
          <div class="line-header-avatar-wrp">
            <img :src="proposal.user.avatar" alt="" />
          </div>

          <p class="line-header-user-name">
            {{ proposal.user.name }}
          </p>

          <button class="btn-icon" @click="enterToChat">
            <IconMessenger icon-color="#fff" width="26" height="24" />
          </button>
        </div>
      </div>
    </div>
    <div class="line-content">
      <div class="form">
        <!-- <div class="form-item">
          <h4 class="heading-4 green">Available</h4>
          <p class="form-item-value">{{ proposal.available || "-" }}</p>
          <p class="form-item-text">Cases</p>
        </div> -->

        <div class="form-item">
          <h4 class="heading-4 green">To collect</h4>
          <p class="form-item-value">{{ proposal.to_collect || "-" }}</p>
          <p class="form-item-text">Cases</p>
        </div>

        <div class="form-item">
          <h4 class="heading-4 green">Accrual Rate</h4>
          <p class="form-item-value">{{ proposal.timeframe || "-" }}</p>
          <p class="form-item-text">&nbsp;</p>
        </div>

        <div class="form-item">
          <h4 class="heading-4 green">Price</h4>
          <p class="form-item-value">{{ proposal.price || "-" }}</p>
          <p class="form-item-text">Per case</p>
        </div>

        <div v-if="isActive" class="form-item">
          <h4 class="heading-4 green">Order</h4>
          <input
            type="number"
            :max="proposal.to_collect"
            class="form-item-input"
            v-model="orderCount"
            :disabled="proposalStatus === 'accepted'"
          />
          <p class="form-item-text">Cases</p>
        </div>

        <div v-if="isActive" class="form-item">
          <h4 class="heading-4 green">Status</h4>
          <div class="line-status-checkbox-wrapper" :class="proposal.status">
            <BaseCheckbox
              :checked="proposalStatus === 'accepted'"
              @setCheckboxValue="acceptProposal"
            />
            <p v-if="proposalStatus === 'accepted'" class="form-item-text">
              <b>Accepted</b>
            </p>
            <p v-else class="yellow">Accept</p>
          </div>
          <!-- <p v-else class="dash info">-</p> -->
        </div>
        <button
          v-if="proposal.comment?.length"
          class="line-hint-toggler"
          @click="toggleHint"
          tabindex="0"
        >
          i
        </button>
        <div v-show="isShownHint" class="line-hint">
          {{ proposal.comment }}
        </div>
        <div
          v-show="isShownHint"
          class="clickable-space"
          @click="isShownHint = false"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import BaseCheckbox from "@/modules/components/base/BaseCheckbox.vue";
import IconGeo from "@/modules/components/icons/IconGeo";
import IconMessenger from "@/modules/components/icons/IconMessenger";

export default {
  components: {
    BaseCheckbox,
    IconGeo,
    IconMessenger,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    proposal: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const { proposal } = toRefs(props);

    const orderCount = ref(0);
    const proposalStatus = ref("pending");
    const isShownHint = ref(false);

    if (!proposal.value.ordered && proposal.value.to_collect) {
      orderCount.value = proposal.value.to_collect;
    } else if (proposal.value.ordered) {
      orderCount.value = proposal.value.ordered;
    }

    if (proposal.value.status) {
      proposalStatus.value = proposal.value.status;
    }

    const acceptProposal = () => {
      let number = 0;

      if (
        orderCount.value &&
        orderCount.value <= +proposal.value.to_collect
      ) {
        number = orderCount.value;
      } else {
        number = +proposal.value.to_collect;
      }

      proposalStatus.value =
        proposalStatus.value === "pending" ? "accepted" : "pending";

      orderCount.value = number;
      let proposalData = {
        id: proposal.value.id,
        ordered: number,
        status: proposalStatus.value,
      };

      store.dispatch("proposals/saveProposal", proposalData);
    };

    const toggleHint = () => {
      isShownHint.value = !isShownHint.value;
    };

    const enterToChat = async () => {
      store.dispatch("chat/getChat", {
        pid: route.params.id,
        uid: proposal.value.user.id,
      });

      let payload = {
        isShown: true,
        user: proposal.value.user,
      };

      store.commit("chat/START_CHAT", payload);
    };

    return {
      route,
      orderCount,
      proposalStatus,
      isShownHint,
      acceptProposal,
      toggleHint,
      enterToChat,
    };
  },
};
</script>

<style lang="scss" scoped>
.line {
  position: relative;
  margin-top: 20px;
  // border: 1px solid var(--col-border);
  border-radius: 8px;
  border: 2px solid var(--col-info);
  background-color: var(--col-bg-primary);

  &-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 3px 5px;
    background-color: var(--col-info);

    &-user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &-data {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &-name {
        font-size: 16px;
        color: var(--col-contrast-text);
      }
    }

    &-avatar-wrp {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
      border: 1px solid var(--col-info-bg);
      overflow: hidden;
    }
  }

  &-content {
    padding: 10px;
  }

  &-quantity-input,
  &-textarea,
  &-track-input {
    border: 1px solid var(--col-border);
    border-radius: 4px;
    color: var(--col-subtext-secondary);
  }

  &-textarea {
    width: 100%;
    padding: 5px;
  }

  &-quantity-input {
    width: 70px;
    margin: 10px;
    text-align: center;
  }

  &-description {
    padding-top: 10px;
    font-size: 14px;

    // &-title {}
    &-text {
      font-size: 22px;
      font-family: "OpenSans-Regular";
    }
  }

  hr {
    margin-bottom: 10px;
  }

  &-conteiner:last-of-type {
    hr {
      display: none;
    }
  }

  &-button-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &-status {
    &-checkbox {
      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        &:not(.accepted) {
          .empty {
            border-color: var(--col-warning);
          }
          .dots {
            font-size: 22px;
          }
        }

        span.green {
          font-weight: 900;
          color: var(--col-info);
        }
      }
    }
  }

  &-hint {
    position: absolute;
    top: 0;
    right: 50px;
    width: 60%;
    height: auto;
    margin: 0;
    padding: 10px 15px;
    border: 2px solid var(--col-info);
    border-radius: 8px;
    background-color: var(--col-contrast-text);
    color: var(--col-text-secondary);

    &-toggler {
      position: absolute;
      right: 15px;
      top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border: none;
      border-radius: 50%;
      background-color: var(--col-info);
      color: var(--col-contrast-text);
      box-shadow: none;
    }
  }
  .clickable-space {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    background-color: transparent;
  }

  &-tracking {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 10px 0;
    padding: 0 15px;
    background-color: #ffffff60;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      min-width: 80px;
      min-height: 100%;
      padding: 5px 0;

      &_number {
        flex-basis: 40%;
      }

      button {
        margin: 10px 0;
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }

  &-track-input {
    margin: 8px 0;
  }
}

.line-add-button {
  display: block;
  width: 100%;
  padding: 4px;
  border: 1px dashed var(--col-primary-light);
  border-radius: 4px;
  text-align: center;
  color: var(--col-primary-dark);
  background-color: var(--col-bg-attention);
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    flex-basis: 15%;
    min-height: 100%;
    padding: 5px 0;
    border-left: 2px solid var(--col-info);

    &:first-child {
      border-left: none;
    }

    h4 {
      font-size: 14px;
      font-family: "SFProDisplay-Medium";
      text-align: center;
    }

    &-text {
      font-size: 12px;
      font-family: "OpenSans-Regular";
      color: var(--col-info);
    }

    &-value {
      font-size: 22px;
      font-weight: 900;
      color: var(--col-text-primary);
    }

    &-input {
      width: 70px;
      text-align: center;
    }

    &.info,
    &.info h4 {
      color: var(--col-info);
    }

    &-count {
      margin: 10px 0;
      font-size: 16px;
      font-weight: 900;
    }

    .dash {
      margin: 10px 0;
      font-weight: 900;

      &.info {
        color: var(--col-info);
      }
    }
  }
  // //  - to css end
}

.btn-icon {
  width: 36px;
  height: 26px;
  margin-left: 20px;
  border: none;
  background-color: transparent;
}
</style>
