<template>
  <div class="breadcrumbs-wrapper">
    <p><router-link to="">Track Shipping</router-link> /</p>
  </div>

  <div class="track-wrapper">
    <div class="track">
      <div
        v-if="shippingTrackList && shippingTrackList.length"
        class="track-container"
      >
        <div style="width: 300px; margin-bottom: 20px">
          <div class="search-form">
            <div class="search-field">
              <input
                class="track-search"
                type="text"
                name="project"
                v-model="shippingCode"
                @keyup.enter.prevent="makeSearch"
              />
            </div>
            <BaseButton class="info" text="Search" @click="makeSearch" />
          </div>
        </div>

        <div class="track-head">
          <div class="track-head-img">
            <img src="@/modules/assets/images/box.png" alt="" />
          </div>
          <div class="track-head-info">
            <div class="track-head-info-left">
              <div>
                <h3 class="track-head-title">
                  {{ shippingTrackList[0].lines[0].prs_title }}
                </h3>
                <p>
                  Date Send:
                  <b style="margin-left: 10px" v-if="shippingTrackList[0]">{{
                    shippingTrackList[0].lines[0].ps_date_shipping
                  }}</b>
                </p>
                <p>
                  Tracking Number:
                  <b style="margin-left: 10px">{{
                    shippingTrackList[0].lines[0].ps_tracking
                  }}</b>
                </p>
              </div>
            </div>
            <div class="track-head-info-right">
              <BaseButton
                className="info big"
                text="Received"
                @click="confirmReceiving"
              ></BaseButton>
            </div>
          </div>
          <!-- <p class="track-head-date"></p> -->
        </div>

        <div class="track-body">
          <div
            class="track-project-list"
            v-for="project in shippingTrackList"
            :key="project.project_number"
          >
            <router-link :to="`/active/${project.id}`">
              <h4 class="green">Project# {{ project.project_number }}</h4>
            </router-link>
            <!-- <div
              class="track-line"
              v-for="track in shippingTrackList"
              :key="track.id"
            > -->
            <ShippingLine
              v-if="project"
              :lines="project.lines"
              :pid="project.id"
            />
            <!-- </div> -->
          </div>
        </div>
      </div>

      <div v-else class="search-container">
        <div class="opacity-icon">
          <IconSearch width="150" height="150" />
        </div>
        <h2 class="green">Track Shipping</h2>
        <p v-if="wrongCode" class="search-alert search-alert_red">
          Your code is wrong. Please enter right code
        </p>
        <p v-else class="search-alert">
          Enter the code and we will show you the options
        </p>
        <div class="search-form">
          <div class="search-field">
            <input
              class="track-search"
              type="text"
              name="project"
              v-model="shippingCode"
              @keyup.enter.prevent="makeSearch"
            />
          </div>
          <BaseButton class="info" text="Search" @click="makeSearch" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import ShippingLine from "../components/ShippingLine.vue";
import BaseButton from "@/modules/components/base/BaseButton";
import IconSearch from "@/modules/components/icons/IconSearch";
import { useRouter } from "vue-router";

// import { formatDate } from "@/modules/helpers/convertDateFormat.js";

export default {
  components: { BaseButton, IconSearch, ShippingLine },
  setup() {
    const store = useStore();
    const router = useRouter();

    const shippingCode = ref("");
    const wrongCode = ref(false);
    const isReceived = ref(false);

    store.commit("tracking/SET_SHIPPING_CODE", "");
    store.commit("tracking/GET_SHIPPING_TRACK", []);
    const storedShippingCode = computed(
      () => store.getters["tracking/shippingCode"]
    );
    if (shippingCode.value.length) {
      shippingCode.value = storedShippingCode.value;
    }
    const shippingTrackList = computed(
      () => store.getters["tracking/shippingTrackList"]
    );
    if (
      shippingTrackList.value.length &&
      shippingTrackList.value[0].lines[0].length &&
      shippingTrackList.value[0].lines[0].ps_shipping_status
    ) {
      isReceived.value =
        shippingTrackList.value[0].lines[0].ps_shipping_status === "arrived";
    }

    const confirmReceiving = () => {
      store.dispatch("tracking/makeShipmentReceive", shippingCode.value);
      isReceived.value = true;

      router.push({ name: "QualityControl" });
    };

    const makeSearch = async () => {
      store.commit("tracking/SET_SHIPPING_CODE", shippingCode.value);
      await store.dispatch("tracking/getShippingInfo", shippingCode.value);
      if (!shippingTrackList.value.length) {
        wrongCode.value = true;
        console.log(`No shipments via ${shippingCode.value} number`);
      }
    };

    watch(shippingCode, () => {
      wrongCode.value = false;
    });

    return {
      shippingCode,
      wrongCode,
      isReceived,
      shippingTrackList,
      makeSearch,
      confirmReceiving,
    };
  },
};
</script>

<style lang="scss" scoped>
.track {
  max-width: 1100px;
  margin: 0 auto;

  &-wrapper {
    min-height: calc(100vh - 190px);
    border-radius: 8px;
    padding: 30px;
    background-color: var(--col-bg-secondary);
  }

  &-search {
    width: 100%;
    max-width: 270px;
    padding: 6px 25px;
    border: 1px solid var(--col-border);
    border-radius: 4px;
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  &-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 24px;
    padding-bottom: 15px;

    &-img {
      // width: 110px;
      // height: 110px;
      width: 170px;
      margin-right: 20px;
      border-radius: 8px;
      // background-color: var(--col-info);
      // opacity: 0.3;
    }

    &-info {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-top: 15px;
      padding-bottom: 25px;
      border-bottom: 2px solid var(--col-info);
      &-right {
        width: 200px;
      }
    }

    &-title {
      padding-bottom: 6px;
      font-size: 20px;
      font-family: "SFProDisplay-Bold";
      color: var(--col-subtext-primary);
    }

    &-date {
      font-size: 14px;
      font-family: "SFProDisplay-Regular";
      color: var(--col-subtext-primary);
      text-align: center;
    }
  }

  &-body {
    width: 100%;
  }

  &-project-list {
    padding-top: 25px;
  }

  &-site {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;

    &-address {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-point {
        font-size: 14px;
        font-family: "SFProDisplay-Bold";
        color: var(--col-text-secondary);
      }
    }

    &-user {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &-photo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        margin-right: 10px;
        flex: none;
        border: 5px solid var(--col-bg-attention);
        border-radius: 50%;
        background-color: var(--col-bg-primary);
      }

      &-name {
        font-size: 12px;
        font-family: "SFProDisplay-Regular";
        font-weight: 500;
        color: var(--col-primary-dark);
      }
    }

    &-prefix {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      border-radius: 4px;
      background-color: var(--col-info);
    }
  }

  &-line {
    &:last-child {
      hr {
        display: none;
      }
    }
  }
}
.search {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 245px);
  }
  &-alert {
    margin: 15px 0;
    &_red {
      color: var(--col-error);
    }
  }
  &-form {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  &-field {
    margin: 2px;
  }
}
.opacity-icon {
  width: 150px;
  margin: 0 auto 20px;
  opacity: 0.15;
}
</style>
