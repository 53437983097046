<template>
  <div :class="!cards || !cards.length ? 'column-empty' : 'column-wrapper'">
    <div class="column">
      <div class="column-bg">
        <h4 class="column-title green">{{ title }}</h4>
        <div class="column-scroll">
          <div
            class="column-card"
            v-for="card in cards"
            :key="card.p_id"
            :style="`border-right: 9px solid ${card.pl_color};`"
          >
            <div class="column-card-head">
              <div class="column-card-code">
                <div class="column-card-code-prefix">
                  <IconGeo icon-color="#fff" height="15" />
                  <span>TSS: {{ card.prs_title }}</span>
                </div>
              </div>
              <div v-if="card.plp_price">
                <h4 class="">Ordered:</h4>
                <h2>
                  <span class="green">{{ card.plp_ordered }}</span>
                  <span class="grey" style="opacity: 0.3"
                    >/{{ card.pl_cases_count }}</span
                  >
                </h2>
              </div>
              <hr style="margin-top: 0" />
            </div>

            <div class="column-card-body">
              <div class="column-card-body-item">
                <h4 class="grey">Indication:</h4>
                <h4 class="green">{{ card.pl_title }}</h4>
              </div>

              <div class="column-card-body-item">
                <h4 class="grey">Order date:</h4>
                <h4 class="grey">{{ card.plp_order_date }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconGeo from "@/modules/components/icons/IconGeo";

export default {
  components: { IconGeo },
  props: {
    cards: {
      type: Object,
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup() {},
};
</script>
