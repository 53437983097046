<template>
  <div class="mt-head">
    TSS: <b>{{ tss.title }}</b>
    <button class="mt-head-btn" @click="showMainChat">
      &#60;
      <b>Back</b>
    </button>
  </div>

  <div class="mt-body">
    <div class="chat-window">
      <div class="chat-scroller" ref="chatContainer">
        <div v-if="dialogWithTss && dialogWithTss.length">
          <div v-for="(msg, i) in dialogWithTss" :key="i">
            <div v-if="Object.keys(msg).length">
              <div v-if="msg.admin_user" class="chat-container chat-admin">
                <div class="chat-text">
                  <div class="chat-text-details">
                    <p
                      v-if="msg.admin_user.name"
                      class="chat-text-details-name grey"
                    >
                      {{ msg.admin_user.name }}
                    </p>
                    <p
                      v-if="msg.created_at"
                      class="chat-text-details-date grey"
                    >
                      {{ getTime(msg.created_at) }}
                    </p>
                  </div>
                  <p v-if="msg.content" class="chat-text-area">
                    {{ msg.content }}
                  </p>
                </div>

                <div class="chat-avatar">
                  <img
                    v-if="msg.admin_user.avatar"
                    :src="`${msg.admin_user.avatar}`"
                    alt=""
                  />
                  <span v-else> A</span>
                </div>
              </div>

              <div v-else-if="msg.user" class="chat-container chat-user">
                <div class="chat-avatar">
                  <img
                    v-if="msg.user.avatar"
                    :src="`${msg.user.avatar}`"
                    alt=""
                  />
                  <span v-else>U</span>
                </div>

                <div class="chat-text">
                  <div class="chat-text-details">
                    <p
                      v-if="msg.user.name"
                      class="chat-text-details-name green"
                    >
                      {{ msg.user.name }}
                    </p>
                    <p class="chat-text-details-date grey">
                      {{ getTime(msg.created_at) }}
                    </p>
                  </div>
                  <p class="chat-text-area">{{ msg.content }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="chat-window-empty-img">
            <img src="@/modules/assets/bg/keyboard.svg" alt="No chatting" />
          </div>
          <h2 class="grey center">Start chatting</h2>
        </div>
      </div>

      <!-- <div v-else>
        <h3 v-if="!!userChatTo && userChatTo.name" class="green">
          Write to {{ userChatTo.name }}:
        </h3>
      </div> -->

      <div class="chat-action">
        <textarea
          v-model="message"
          class="chat-textarea"
          rows="3"
          @keyup.enter.prevent="send"
        />
        <button
          class="chat-btn"
          :class="{ active: message.length }"
          :disabled="!message.length"
          @click="send"
        >
          <IconAirplane icon-color="#ffffff" width="26" height="18" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, toRefs, watch, onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import IconAirplane from "@/modules/components/icons/IconAirplane.vue";

export default {
  components: { IconAirplane },
  props: {
    tss: {
      default: null,
    },
    shouldCloseConnection: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const { tss, shouldCloseConnection } = toRefs(props);
    const chatContainer = ref(null);
    const message = ref("");
    const dialogWithTss = computed(
      () => store.getters["globalMessenger/dialog"]
    );

    const admin = computed(() => store.getters["auth/user"]);

    const clientTss = new WebSocket(
      "wss://admin.cureline-crm.cureline.com/ap/" +
        localStorage.getItem("authToken") +
        "/" +
        tss.value.id
    );

    clientTss.onmessage = function (event) {
      store
        .dispatch("globalMessenger/showMessage", {
          tssId: tss.value.id,
          ...JSON.parse(event.data),
        })
        .then(() => scrollToEnd());
    };

    onMounted(() => scrollToEnd());
    onUpdated(() => scrollToEnd());

    // clientTss.onclose = function (e) {
    //   // console.log(
    //   //   "websocket disconnection: " + e.code + " & " + e.reason + " & " + e.WasClean
    //   // );
    //   console.log(clientTss);
    //   console.log(e);
    // };

    const send = async () => {
      let msg = {
        content: message.value,
        admin_user_id: admin.value.id,

        admin_user: {
          id: admin.value.id,
          avatar: admin.value.avatar,
          name: admin.value.name,
        },
        created_at: new Date().toISOString(),
      };
      let msg2 = {
        content: message.value,
        procurement_sites: [],
      };
      message.value = "";
      msg2.procurement_sites.push(tss.value.id);
      clientTss.send(JSON.stringify(msg));
      await store.dispatch("globalMessenger/sendMessage", msg2);

      // await store.dispatch("globalMessenger/showMessage", msg);
      scrollToEnd();
    };

    const showMainChat = async () => {
      store.commit("globalMessenger/SET_DIALOG_WITH_PS", null);
      await clientTss.close();
      emit("close");
    };

    const getTime = (date) => {
      const formatDate = new Date(date);
      let hours = formatDate.getHours();
      let minutes = formatDate.getMinutes();
      let month = formatDate.getMonth();
      month++;
      let day = formatDate.getDate();

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return `${day}.${month} ${hours}:${minutes}`;
    };

    const scrollToEnd = () => {
      chatContainer.value.scrollTop = chatContainer.value.scrollHeight + 100;
    };

    watch(shouldCloseConnection, (newValue) => {
      if (newValue) {
        clientTss.close();
      }
    });

    return {
      dialogWithTss,
      admin,
      chatContainer,
      message,
      send,
      showMainChat,
      getTime,
      scrollToEnd,
    };
  },
};
</script>

<style lang="scss" scoped>
.mt {
  &-head {
    position: relative;
    padding: 3px 0;
    border-radius: 4px;
    font-size: 20px;
    text-align: center;
    background-color: var(--col-info);
    color: var(--col-contrast-text);

    &-btn {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 70px;
      margin: 3px;
      padding: 2px 10px;
      border: none;
      border-right: 1px solid var(--col-bg-secondary);
      font-size: 14px;
      text-align: left;
      background-color: transparent;
      color: var(--col-contrast-text);
      cursor: pointer;

      &:hover {
        text-shadow: 1px 2px 3px var(--col-info-bg_d);
      }
    }
  }

  &-body {
    padding: 0 30px;
  }
}

.chat {
  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--col-info-bg);
  }

  &-window {
    padding-top: 20px;
    padding-bottom: 10px;

    &-empty-img {
      max-width: 20%;
      min-width: 100px;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }

  &-scroller {
    min-height: 280px;
    height: calc(100vh - 300px);
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 10px;
  }

  &-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-avatar {
    position: relative;
    top: 10px;
    min-width: 36px;
    height: 36px;
    flex-basis: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    color: var(--col-info);
    background-color: var(--col-info-bg);
    overflow: hidden;
  }

  &-text {
    flex-grow: 1;
    max-width: 75%;
    padding: 0 20px 10px;

    &-details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-name {
        font-size: 16px;
        margin: 0 10px;
      }

      &-date {
        font-size: 12px;
        margin: 0 10px;
      }
    }

    &-area {
      padding: 5px 15px;
      border: 1px solid var(--col-info-bg);
      border-radius: 8px;
      font-size: 18px;
      line-height: 24px;
      background-color: var(--col-info-bg);
    }
  }

  &-admin {
    &.chat-container {
      justify-content: flex-end;
    }

    .chat-text {
      flex-grow: 0;
      text-align: right;

      &-area {
        display: inline-block;
        text-align: left;
        background-color: var(--col-contrast-text);
      }
    }
  }

  &-user {
    .chat-text {
      padding-right: 50px;

      display: inline-block;
      position: relative;
      flex-grow: unset;
    }
  }

  &-action {
    position: relative;
    height: 40px;
  }

  &-textarea {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 1px solid #c8c8cc;
    border-radius: 17px;
    padding: 5px 40px 5px 15px;
    line-height: 1.2;
    color: var(--col-primary-dark);
    resize: none;
    overflow: auto;
  }

  &-btn {
    position: absolute;
    right: 4px;
    bottom: 3px;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 34px;
    height: 34px;
    padding: 4px 3px;
    border: none;
    border-radius: 50%;
    background-color: var(--col-info-bg);
    cursor: unset;

    &.active {
      background-color: var(--col-info);
      cursor: pointer;
    }
  }
}
</style>
