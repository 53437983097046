<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.1992 2.13965H12.7617V1.38965C12.7617 1.11352 12.5379 0.889648 12.2617 0.889648C11.9856 0.889648 11.7617 1.11352 11.7617 1.38965V2.13965H4.76172V1.38965C4.76172 1.11352 4.53788 0.889648 4.26172 0.889648C3.98556 0.889648 3.76172 1.11352 3.76172 1.38965V2.13965H2.32422C1.18694 2.13965 0.261719 3.06487 0.261719 4.20215V14.8271C0.261719 15.9644 1.18694 16.8896 2.32422 16.8896H14.1992C15.3365 16.8896 16.2617 15.9644 16.2617 14.8271V4.20215C16.2617 3.06487 15.3365 2.13965 14.1992 2.13965ZM2.32422 3.13965H3.76172V3.63965C3.76172 3.91577 3.98556 4.13965 4.26172 4.13965C4.53788 4.13965 4.76172 3.91577 4.76172 3.63965V3.13965H11.7617V3.63965C11.7617 3.91577 11.9856 4.13965 12.2617 4.13965C12.5379 4.13965 12.7617 3.91577 12.7617 3.63965V3.13965H14.1992C14.7851 3.13965 15.2617 3.61627 15.2617 4.20215V5.38965H1.26172V4.20215C1.26172 3.61627 1.73834 3.13965 2.32422 3.13965ZM14.1992 15.8896H2.32422C1.73834 15.8896 1.26172 15.413 1.26172 14.8271V6.38965H15.2617V14.8271C15.2617 15.413 14.7851 15.8896 14.1992 15.8896Z" />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 17 },
    height: { type: [Number, String], default: 17 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
