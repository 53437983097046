<template>
  <div
    v-if="project && Object.keys(project).length"
    class="feasibility-project-page"
  >
    <div class="feasibility-project-main">
      <div class="breadcrumbs-wrapper">
        <p>
          <router-link to="/archive-list">Archive Project List</router-link>
          /
          <template v-if="project"
            >#{{ project.project_number }} (#{{
              project.feasibility_number
            }})</template
          >
        </p>
      </div>

      <div>
        <ProjectGeneralInfo
          v-if="project"
          :project="project"
          type="Archive Project"
        />

        <div class="project-main-section">
          <div class="field-container">
            <h3 class="green center">{{ project.description_short }}</h3>
            <hr />
          </div>

          <div class="project-main-section_basic-values">
            <div class="field-container">
              <div>
                <IconCalendar icon-color="#17a2b8" width="100" height="100" />
              </div>
              <h3 class="grey">{{ project.timeline }}</h3>
            </div>
            <div class="field-container">
              <div>
                <IconPeople icon-color="#17a2b8" width="100" height="100" />
              </div>
              <h3 class="grey">#Donors: {{ project.donors_number }}</h3>
            </div>
            <div class="field-container">
              <div>
                <IconTrade icon-color="#17a2b8" width="100" height="100" />
              </div>
              <h3 class="grey">$ {{ project.suggested_price }}</h3>
            </div>
          </div>

          <div class="field-container">
            <h3 class="center">
              <span class="grey">Indication: </span>
              <span class="green"> {{ project.indication }}</span>
            </h3>
          </div>
        </div>

        <div class="new-project-lines">
          <h3 class="green"><b> Number of Specimens/Donors</b></h3>

          <div class="lines-wrapper">
            <div v-for="line in project.lines" :key="line.id">
              <StatisticLine
                :line="line"
                :project-id="projectId"
                project-type="archive"
              />
            </div>
          </div>
        </div>

        <div v-if="project?.note?.content" class="field-container yellow-bg">
          <h4 class="heading-4">Notes: Updates Requested</h4>
          <pre>{{ project.note.content }}</pre>
          <hr style="margin: 40px 0 10px" />
        </div>

        <div class="project-secondary-section">
          <div v-if="project.donor_characteristics" class="field-container">
            <h4 class="heading-4">Donor Characteristics</h4>
            <pre>
              {{ project.donor_characteristics }}
            </pre>
          </div>

          <div v-if="project.inclusion_criteria" class="field-container">
            <h4 class="heading-4">Inclusion Criteria</h4>
            <pre>
              {{ project.inclusion_criteria }}
            </pre>
          </div>

          <div v-if="project.exclusion_criteria" class="field-container">
            <h4 class="heading-4">Exclusion Criteria</h4>
            <pre>
              {{ project.exclusion_criteria }}
            </pre>
          </div>

          <div v-if="project.preservation" class="field-container">
            <h4 class="heading-4">Preservation/Procurement</h4>
            <pre>
              {{ project.preservation }}
            </pre>
          </div>

          <div class="field-container">
            <h4 v-if="project.clinical_info" class="heading-4">
              Clinical information
            </h4>
            <pre>
              {{ project.clinical_info }}
            </pre>
          </div>

          <AdditionalFields :is-editable="false" />
        </div>
      </div>
    </div>

    <!--sidebar-->
    <div>
      <div class="sidebar" v-if="project">
        <div v-if="project.public_note" class="field-container">
          <h3 class="green">Public note:</h3>
          <div class="green-border">
            {{ project.public_note }}
          </div>
        </div>

        <div v-if="project.private_note" class="field-container">
          <h3 class="green">Cureline only note:</h3>
          <div class="green-border">
            {{ project.private_note }}
          </div>
        </div>

        <div class="sidebar-admin-data">
          <h6 class="green center">Validated by:</h6>
          <h6 class="green center">or Archived by:</h6>
          <AdminSign />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import AdminSign from "@/components/header/AdminSign.vue";
import ProjectGeneralInfo from "@/components/project/ProjectGeneralInfo.vue";
import StatisticLine from "../../components/project/StatisticLine.vue";
import AdditionalFields from "@/components/project/AdditionalFields.vue";

import IconPeople from "@/modules/components/icons/IconPeople";
import IconCalendar from "@/modules/components/icons/IconCalendar";
import IconTrade from "@/modules/components/icons/IconTrade";

export default {
  components: {
    AdminSign,
    ProjectGeneralInfo,
    StatisticLine,
    AdditionalFields,
    IconPeople,
    IconCalendar,
    IconTrade,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const projectId = computed(() => route.params.id);
    const project = computed(() => store.getters["projects/project"]);
    const lines = computed(() => store.getters["projects/lines"]);
    const additionalFields = computed(
      () => store.getters["projects/additionalFields"]
    );

    onMounted(() => {
      store.dispatch("projects/getProject", { id: projectId.value });
    });

    return {
      project,
      projectId,
      lines,
      additionalFields,
      router,
    };
  },
};
</script>
