<template>
  <div class="heading">
    <div class="logo">
      <router-link :to="{ name: 'ProjectList' }">
        <img src="@/assets/logo.svg" alt="logo" />
      </router-link>
    </div>

    <h2 class="green">{{ title }}</h2>

    <AdminProfile />
  </div>
</template>

<script>
import AdminProfile from "./AdminProfile.vue";

export default {
  components: { AdminProfile },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 57px 7px 52px;
  background-color: var(--col-bg-secondary);

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -2px;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    border-radius: 50%;
    background-color: var(--col-info);
  }
}
.logo {
  height: 55px;
}
</style>
