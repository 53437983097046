<template>
  <hr />

  <div class="af-wrapper">
    <div v-if="isEditable">
      <p class="af-title">Additional Information:</p>

      <div class="af-form" :class="{ highlighted: isHighlighted }">
        <div class="af-title-field-wrapper">
          <label class="af-label">Title:</label>
          <input
            type="text"
            class="af-field"
            v-model="initialAdditionalFieldData.title"
          />
        </div>
        <div>
          <label class="af-label">Description: </label>
          <textarea
            rows="2"
            class="textarea"
            v-model="initialAdditionalFieldData.value"
          />
        </div>
      </div>

      <button class="af-main-button" @click="addTextField">
        + Save additional section
      </button>
    </div>

    <div v-if="additionalFields && additionalFields.length" class="af-result">
      <p class="af-title">Additional Information about the Project:</p>

      <div v-for="(block, index) in additionalFields" :key="index">
        <AdditionalField :block="block" :is-editable="isEditable" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import AdditionalField from "@/components/project/AdditionalField.vue";

export default {
  components: { AdditionalField },
  props: {
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();

    const initialAdditionalFieldData = ref({
      title: "",
      value: "",
      isEditable: false,
    });
    const isHighlighted = ref(false);

    const additionalFields = computed(
      () => store.getters["projects/additionalFields"]
    );
    const project = computed(() => store.getters["projects/project"]);

    const cleanNotification = () => {
      isHighlighted.value = false;
      store.commit("admins/CLEAN_NOTIFICATION");
    };

    const scrollToEnd = () => {
      window.scrollTo({
        top: 4000,
        behavior: "smooth",
      });
    };

    const addTextField = async () => {
      const data = initialAdditionalFieldData.value;
      data.project_id = project.value.id;
      if (!data.title || !data.value) {
        isHighlighted.value = true;

        let payload = {
          isShown: true,
          text: "Please fill in required fields",
          status: "error",
        };
        store.commit("admins/SET_NOTIFICATION", payload);

        setTimeout(() => {
          cleanNotification();
        }, 5000);

        return false;
      }

      await store.dispatch("projects/addTextField", data);

      scrollToEnd();

      initialAdditionalFieldData.value = {
        title: "",
        value: "",
      };
    };

    const editField = () => {};
    const removeField = () => {};

    return {
      isHighlighted,
      additionalFields,
      initialAdditionalFieldData,
      addTextField,
      editField,
      removeField,
    };
  },
};
</script>

<style lang="scss" scoped>
.af {
  &-wrapper {
    margin: 20px 0;
  }

  &-form {
    padding: 5px 1px;

    &.highlighted {
      background-color: var(--col-error-bg);
    }
  }

  &-title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    font-family: "OpenSans-Regular", sans-serif;
    color: var(--col-text-secondary);
  }

  &-title-field-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &-label {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-right: 20px;
    font-size: 14px;
    line-height: 20px;
    font-family: "OpenSans-Regular", sans-serif;
    color: var(--col-text-secondary);
  }

  &-field {
    display: block;
    overflow: hidden;
    resize: both;
    width: 100%;
    padding: 4px 8px;
    border: 1px solid var(--col-border);
    border-radius: 4px;
    font-size: 14px;
    color: var(--col-text-primary);
  }

  &-result {
    margin-top: 30px;
  }

  &-main-button {
    display: block;
    width: 100%;
    margin: 20px 0;
    padding: 4px;
    border: 1px dashed var(--col-primary-light);
    border-radius: 4px;
    text-align: center;
    color: var(--col-primary-dark);
    background-color: var(--col-bg-attention);
  }
}
</style>
