import axios from "@/api/axios";
// import router from "@/router/index";

export default {
  namespaced: true,

  state: {
    loading: false,
    projects: [],
    pagination: {},
    project: null,
    lines: [],
    line: null,
    projectCategories: [],
    categories: [],
    procurement_sites: null,
    additionalFields: [],
    trackingProject: {},
    filteredTrackingProjects: [],
    projectsNumbers: [],
  },

  getters: {
    loading: (state) => state.loading,
    projects: (state) => state.projects,
    pagination: (state) => state.pagination,
    project: (state) => state.project,
    lines: (state) => state.lines,
    projectCategories: (state) => state.projectCategories,
    categories: (state) => state.categories,
    procurementSites: (state) => state.procurement_sites,
    additionalFields: (state) => state.additionalFields,
    trackingProject: (state) => state.trackingProject,
    filteredTrackingProjects: (state) => state.filteredTrackingProjects,
    projectsNumbers: (state) => state.projectsNumbers,
  },

  mutations: {
    GET_PROJECTS(state, payload) {
      state.projects = payload;
    },
    GET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    LOADING_STATE(state, payload) {
      state.loading = payload;

      setTimeout(() => {
        state.loading = false;
      }, 10000);
    },
    PUSH_PROJECT(state, payload) {
      state.projects.data.push(payload);
    },
    GET_PROJECT(state, payload) {
      state.project = payload;
    },
    SET_VISIBILITY(state, payload) {
      state.project.visibility = payload.visibility;
      state.project.project_visibility = payload.project_visibility;
    },
    GET_PROCUREMENT_SITES(state, payload) {
      state.procurement_sites = payload;
    },
    DELETE_PROJECT(state, id) {
      const indexProject = state.projects.findIndex((item) => item.id === id);
      state.projects.splice(indexProject, 1);
      state.project = null;
    },
    FETCH_LINES(state, payload) {
      state.lines = payload;
    },
    SET_LINE(state, payload) {
      state.line = payload;
    },
    PUSH_LINE(state, payload) {
      state.lines.push(payload);
    },
    DELETE_LINE(state, id) {
      const index = state.lines.findIndex((item) => item.id === id);
      state.lines.splice(index, 1);
    },
    EDIT_LINE(state, data) {
      state.lines.map((line, index) => {
        if (line.id === data.id) {
          state.lines[index] = data;
        }
      });
    },
    SET_CATEGORIES(state, data) {
      state.projectCategories = data;
    },
    FETCH_CATEGORIES(state, data) {
      state.categories = [];
      // state.projectCategories = data.allCategories;
      data.allCategories.map((category) => {
        state.categories.push({
          title: category.title,
          isChosen: data.selectedCategories
            .map(({ id }) => id)
            .includes(category.id),
          id: category.id,
        });
      });
    },
    FETCH_ADDITIONAL_FIELDS(state, data) {
      state.additionalFields = data;
    },
    FETCH_ADDITIONAL_FIELD(state, data) {
      state.additionalFields.push(data);
    },
    UPDATE_ADDITIONAL_FIELD(state, data) {
      const index = state.additionalFields.findIndex(
        (field) => field.id === data.id
      );
      state.additionalFields[index] = data;
    },
    DELETE_FIELD(state, id) {
      const index = state.additionalFields.findIndex(
        (field) => field.id === id
      );
      state.additionalFields.splice(index, 1);
    },
    GET_TRACKING_PROJECT(state, data) {
      state.trackingProject = data;
    },
    SET_FILTERED_TRACKING_PROJECTS(state, data) {
      state.filteredTrackingProjects = data;
    },
    SET_PROJECTS_NUMBERS(state, data) {
      state.projectsNumbers = data;
    },
    SAVE_FILE(state, files) {
      if (!state.project.files) {
        state.project.files = [];
      }
      files.map((file) => state.project.files.push(file));
    },
    DELETE_FILE(state, id) {
      const index = state.project.files.findIndex((file) => file.id === id);
      state.project.files.splice(index, 1);
    },
  },

  actions: {
    async getAllProjects({ commit, dispatch }, page) {
      const newPage = page ? `?page=${page}` : "?page=1";
      commit("LOADING_STATE", true);
      await axios
        .get("/client-requests" + newPage)
        .then((response) => {
          if (response.data.status === "Success") {
            commit("GET_PROJECTS", response.data.data.data);
            dispatch("getPagination", response.data.data);
          }
        })
        .catch((err) => {
          console.error("Error from getAllProjects: ", err);
        });
      commit("LOADING_STATE", false);
    },
    getPagination({ commit }, data) {
      let pagination = {};
      for (const key in data) {
        if (key !== "data" && key !== "links") {
          pagination[key] = data[key];
        }
      }
      commit("GET_PAGINATION", pagination);
    },
    async getFilteredProjects({ commit, dispatch }, { params, page }) {
      commit("LOADING_STATE", true);

      const newPage = page ? `&page=${page}` : "";
      let query = [];

      for (const p in params) {
        query.push(`${p}=${params[p]}`);
      }

      const q = query.join().replace(/,/g, "&");

      await axios
        .get(`/search-projects?${q}${newPage}`)
        .then((response) => {
          if (response.data.status === "Success") {
            commit("GET_PROJECTS", response.data.data.data);
            dispatch("getPagination", response.data.data);
          }
        })
        .catch((err) => {
          console.error("Error from getAllProjects: ", err);
        });
      commit("LOADING_STATE", false);
    },
    async getProject({ commit }, { id }) {
      commit("LOADING_STATE", true);
      commit("GET_PROJECT", null);
      commit("FETCH_LINES", null);
      await axios
        .get("/project/" + id)
        .then((response) => {
          commit("GET_PROJECT", response.data.data.project);
          commit("chat/GET_PROJECT_CHATS", response.data.data.projectChats, {
            root: true,
          });
          if (response.data.data.project.lines) {
            commit("FETCH_LINES", response.data.data.project.lines);
          }
          commit("FETCH_CATEGORIES", {
            allCategories: response.data.data.categories,
            selectedCategories: response.data.data.project.categories,
          });
          commit("GET_PROCUREMENT_SITES", response.data.data.procurement_sites);
          commit(
            "FETCH_ADDITIONAL_FIELDS",
            response.data.data.project.additional_fields
          );
        })
        .catch((err) => {
          console.error("Error from getProject: ", err);
        });
      commit("LOADING_STATE", false);
      return true;
    },
    async getCategories({ commit }) {
      await axios
        .get("/category")
        .then((response) => {
          if (response.data.status === "Success") {
            commit("SET_CATEGORIES", response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error getCategories: ", error);
        });
    },
    async createProject({ commit }) {
      commit("GET_PROJECT", null);
      await axios
        .post("/project")
        .then((response) => {
          commit("GET_PROJECT", response.data.data.project);
          commit("FETCH_LINES", response.data.data.project.lines);
          commit("FETCH_CATEGORIES", {
            allCategories: response.data.data.categories,
            selectedCategories: response.data.data.project.categories,
          });
          commit("GET_PROCUREMENT_SITES", response.data.data.procurement_sites);
          commit(
            "FETCH_ADDITIONAL_FIELDS",
            response.data.data.project.additional_fields
          );
        })
        .catch((error) => {
          console.error("Error createProject: ", error);
        });
    },
    async saveVisibility({ commit }, data) {
      commit("LOADING_STATE", true);

      commit("SET_VISIBILITY", data);

      let project = {
        visibility: data.visibility,
      };

      if (data.project_visibility) {
        project.project_visibility = data.project_visibility.map((el) => el.id);
      }
      await axios.put(`/project/${data.id}`, project).catch((err) => {
        console.error(err);
      });

      commit("LOADING_STATE", false);
    },
    async saveProject({ commit }, data) {
      commit("LOADING_STATE", true);

      await axios.put(`/project/${data.id}`, data).catch((err) => {
        console.error(err);
      });
      commit("LOADING_STATE", false);
      return true;
    },
    async saveFile({ commit }, files) {
      commit("LOADING_STATE", true);
      await axios
        .post("/project-file", files, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.status === "Success") {
            let payload = {
              isShown: true,
              text: "Files are saved",
              status: "success", // "error", "success"
            };
            commit("admins/SET_NOTIFICATION", payload, { root: true });
            commit("SAVE_FILE", res.data.data);

            setTimeout(() => {
              commit("admins/CLEAN_NOTIFICATION", {}, { root: true });
            }, 3000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      commit("LOADING_STATE", false);
    },
    async deleteFile({ commit }, id) {
      commit("LOADING_STATE", true);

      await axios
        .delete(`/project-file/${id}`)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("DELETE_FILE", id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      commit("LOADING_STATE", false);
    },
    async deleteProject({ commit }, id) {
      await axios
        .delete("/project/" + id)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("DELETE_PROJECT", id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return true;
    },
    async addNewLine({ commit }, project_id) {
      commit("LOADING_STATE", true);
      await axios
        .post("/project-line", project_id)
        .then((response) => {
          // commit("SET_LINE", project_id);
          commit("PUSH_LINE", response.data.data);
        })
        .catch((error) => {
          console.error("errors from addNewLine:", error);
        });
      commit("LOADING_STATE", false);
    },
    async editLine({ commit }, data) {
      commit("LOADING_STATE", true);
      await axios
        .put("/project-line/" + data.id, data)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("EDIT_LINE", data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      commit("LOADING_STATE", false);
    },
    async removeLine({ commit }, id) {
      await axios
        .delete(`/project-line/${id}`)
        .then((res) => {
          if (res.data.data === 1) {
            commit("DELETE_LINE", id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async addTextField({ commit }, data) {
      await axios
        .post("/project-additional-field", data)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("FETCH_ADDITIONAL_FIELD", res.data.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async updateTextField({ commit }, data) {
      await axios
        .put(`/project-additional-field/${data.id}`, data)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("UPDATE_ADDITIONAL_FIELD", res.data.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async deleteTextField({ commit }, id) {
      await axios
        .delete(`/project-additional-field/${id}`)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("DELETE_FIELD", id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async loadTrackingProject({ commit }) {
      commit("LOADING_STATE", true);
      commit("GET_TRACKING_PROJECT", null);

      await axios.get("project-tracking").then((res) => {
        commit("GET_TRACKING_PROJECT", res.data.data);
        commit("GET_PROCUREMENT_SITES", res.data.data.procurement_sites);
        commit("SET_FILTERED_TRACKING_PROJECTS", res.data.data.allProjects);
      });

      commit("LOADING_STATE", false);
    },
    async getTrackingProject({ commit }, id) {
      commit("LOADING_STATE", true);
      commit("GET_TRACKING_PROJECT", null);

      await axios.get(`project-tracking-info?projectId=${id}`).then((res) => {
        if (res.data && res.data.data) {
          commit("GET_TRACKING_PROJECT", res.data.data);
        }
      });

      commit("LOADING_STATE", false);
    },
    async getFilteredTrackingProject({ commit }, params) {
      commit("LOADING_STATE", true);

      let query = [];

      for (const p in params) {
        query.push(`${p}=${params[p]}`);
      }

      const q = query.join().replace(/,/g, "&");

      await axios
        .get(`/project-tracking-filtered-list?${q}`)
        .then((response) => {
          if (response.data && response.data.status === "Success") {
            let projects = response.data?.data || [];
            commit("SET_FILTERED_TRACKING_PROJECTS", projects);
          }
        })
        .catch((err) => {
          console.error("Error from getFilteredTrackingProject: ", err);
        });

      commit("LOADING_STATE", false);
    },
  },
};
