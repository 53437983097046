<template>
  <div class="notifications__wrapper" v-if="notifications.length">
    <div class="notifications__list">
      <div
        class="notifications__item"
        v-for="(note, index) in notifications"
        :key="index"
        :class="[note.type]"
      >
        <div class="notifications__icon-wrapper" :class="[note.type]">
          <div v-if="note.type == 'error'" class="notifications__item-icon">
            <IconClose 
              width="20"
              height="20"
            />
          </div>
          <div v-else class="notifications__item-icon">
            <IconChecked
              width="40"
              height="40"
            />
          </div>
        </div>
        <div class="notifications__item-title">{{ note.title }}</div>
        <div class="notifications__item-text">{{ note.text }}</div>
        <button class="notifications__item-close" @click="close(index)">
          <IconClose width="8" height="8"/>
        </button>
      </div>
    </div>
    <BaseButton
      text="Close all"
      class-name="indifferent big"
      @click="closeAll"
    ></BaseButton>
  </div>
</template>

<script>
import { computed, watch } from "vue";
import {useStore} from 'vuex';
import BaseButton from "@/modules/components/base/BaseButton.vue";
import IconClose from "../icons/IconClose.vue";
import IconChecked from "../icons/IconChecked.vue";

export default {
  components: { BaseButton, IconClose, IconChecked },
  setup() {
    const store = useStore();
    const authenticated = computed(() => store.getters["auth/authenticated"]);
    const notifications = computed(() => store.getters["notifications/notifications"]);
    
    setInterval(() => {
      store.dispatch("notifications/readNotificationsCookies", notifications);
    }, 5000)

    watch(authenticated, (newValue) => {
      if (newValue) {
        closeAll();
      }
    });

    const closeAll = () => {
      store.dispatch("notifications/setNotifications", [])
    };

    const close = (index) => {
      store.dispatch("notifications/removeNotification", index)
    };

    return { notifications, closeAll, close };
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  &__wrapper {
    position: fixed;
    right: 5px;
    top: 15px;
    z-index: 9999;
    max-height: 275px;
    padding-right: 30px;
    overflow-y: auto;
  }

  &__list {
    width: 337px;
    margin-bottom: 8px;
  }

  &__item {
    position: relative;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 8px 20px 15px 80px;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
    background: #fff;

    &.error {
      border-color: #eb5757;
    }

    &-title {
      font-weight: bold;
      margin-bottom: 4px;
    }

    &-text {
      line-height: 18px;
    }

    &-close {
      position: absolute;
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      top: 3px;
      right: 10px;
    }

    &-icon {
      width: 45px;
      height: 45px;
      top: 12px;
      position: absolute;
      left: 12px;
      border-radius: 50%;
      text-align: center;
      padding-top: 1px;

      svg {
        fill: #fff;
      }
    }
  }

  &__close {
    color: #fff;
    background: #5F84FE;
    display: block;
    text-align: center;
    border-radius: 4px;
    margin-right: 10px;
    padding: 9px 0;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 325px;
  }

  &__icon-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 100%;
    background: #17A2B8;
    border-radius: 4px 0 0 4px;

    &.error {
      background: #DC3545;

      .notifications__item-icon {  
        padding-top: 9px;
        border: 4px solid #fff;
      }
    }
  }
}
</style>