<template>
  <div
    v-if="isEditing"
    class="af-container af-container_form"
    :class="{ highlighted: isHighlighted }"
  >
    <div class="af-container-fields">
      <div class="af-container-fields-header">
        <input
          v-if="isEditing"
          class="af-field"
          type="text"
          v-model="titleField"
        />
        <div class="af-container-action">
          <button class="af-container-btn_edit" @click="editField">
            <IconChecked width="20" height="20" icon-color="#fff" />
          </button>
          <button class="af-container-btn_delete" @click="removeField">
            <IconTrash width="20" height="20" icon-color="#fff" />
          </button>
        </div>
      </div>

      <textarea class="af-textarea" v-model="valueField" />
    </div>
  </div>

  <div v-else class="af-container">
    <div class="af-container-text">
      <h3 class="green">{{ block.title }}</h3>
      <p class="grey">{{ block.value }}</p>
    </div>

    <div v-if="isEditable" class="af-container-action_23">
      <button class="af-container-btn_edit" @click="isEditing = true">
        <IconPencil width="20" height="20" />
      </button>
      <button class="af-container-btn_delete" @click="removeField">
        <IconTrash width="20" height="20" />
      </button>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from "@vue/reactivity";
import { useStore } from "vuex";
import { onMounted } from "@vue/runtime-core";

// import IconClose from "@/modules/components/icons/IconClose";
import IconPencil from "@/modules/components/icons/IconPencil";
import IconChecked from "@/modules/components/icons/IconChecked";
import IconTrash from "@/modules/components/icons/IconTrash";

export default {
  components: { IconTrash, IconPencil, IconChecked },
  props: {
    block: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { block } = toRefs(props);

    const titleField = ref("");
    const valueField = ref("");
    const isEditing = ref(false);
    const isHighlighted = ref(false);

    const cleanNotification = () => {
      isHighlighted.value = false;
      store.commit("admins/CLEAN_NOTIFICATION");
    };

    const editField = async () => {
      cleanNotification();
      if (!titleField.value || !valueField.value) {
        isHighlighted.value = true;

        let payload = {
          isShown: true,
          text: "Please fill in required fields",
          status: "error",
        };
        store.commit("admins/SET_NOTIFICATION", payload);

        setTimeout(() => {
          cleanNotification();
        }, 5000);

        return false;
      }

      const data = {
        id: block.value.id,
        value: valueField.value,
        title: titleField.value,
      };

      await store.dispatch("projects/updateTextField", data);

      isEditing.value = false;
    };
    const removeField = () => {
      store.dispatch("projects/deleteTextField", block.value.id);
    };

    onMounted(() => {
      titleField.value = block.value.title;
      valueField.value = block.value.value;
    });

    return {
      titleField,
      valueField,
      isEditing,
      isHighlighted,
      editField,
      removeField,
    };
  },
};
</script>

<style lang="scss" scoped>
.af {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 20px;
    padding: 5px 10px;
    border-left: 3px solid var(--col-info);
    background-color: var(--col-info-bg);

    &_form {
      display: block;
      background-color: var(--col-bg-attention);

      &.highlighted {
        background-color: var(--col-error-bg);
        border-color: var(--col-error);
      }

      .af-container-btn_edit,
      .af-container-btn_delete {
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: var(--col-info);
      }
      .af-container-btn_delete {
        background-color: var(--col-error);
      }
    }

    &-fields {
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-text {
      flex: 1;
      padding-right: 20px;
    }

    &-action {
      display: flex;
      justify-content: flex-end;
    }

    &-btn {
      &_edit,
      &_delete {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        border: none;
        border-radius: 50%;
        padding: 5px;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  &-field,
  &-textarea {
    overflow: hidden;
    padding: 4px 8px;
    border: 1px solid var(--col-border);
    border-radius: 4px;
    font-size: 14px;
    color: var(--col-text-primary);
  }
  &-textarea {
    display: block;
    width: 100%;
    margin-top: 5px;
    resize: both;
  }
}
</style>
