import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {
    isLoading: false,
    chatList: [],
    chats: [],
    chatNextPage: null,
    newChat: false,
    userChatTo: null,
  },

  getters: {
    isLoading: (state) => state.isLoading,
    chatList: (state) => state.chatList,
    chats: (state) => state.chats,
    chatNextPage: (state) => state.chatNextPage,
    newChat: (state) => state.newChat,
    userChatTo: (state) => state.userChatTo,
  },

  mutations: {
    SET_CHAT_LOADER(state, payload) {
      state.isLoading = payload;
    },
    CLEAN_CHAT(state) {
      state.chatList = [];
      state.chatNextPage = null;
    },
    GET_CHAT_LIST(state, payload) {
      if (payload) {
        state.chatList = [...payload, ...state.chatList];
      }
    },
    GET_PROJECT_CHATS(state, payload) {
      state.chats = payload;
    },
    GET_CHAT_NEXT_PAGE(state, payload) {
      state.chatNextPage = payload ? payload.split("page=")[1] : null;
    },
    PUSH_MESSAGE(state, payload) {
      state.chatList.push(payload);
    },
    START_CHAT(state, payload) {
      state.newChat = payload.isShown;
      state.userChatTo = payload.user;
    },
  },

  actions: {
    async getChat({ commit }, { pid, uid, page = null }) {
      commit("SET_CHAT_LOADER", true);
      commit("CLEAN_CHAT");

      const newPage = page ? `?page=${page}` : "";
      let result;

      await axios
        .get(`/admin-chat/${pid}/${uid}${newPage}`)
        .then((response) => {
          if (response.data.status === "Success") {
            commit("GET_CHAT_LIST", response.data.data.data);
            commit("GET_CHAT_NEXT_PAGE", response.data.data.next_page_url);
            //   dispatch("getPagination", response.data.data);
            result = response.data.data.data;
          }
        })
        .catch((err) => {
          console.error("Error from getChat: ", err);
        });

      commit("SET_CHAT_LOADER", false);

      return result;
    },

    async showMessage({ commit }, data) {
      commit("PUSH_MESSAGE", data);
    },

    async sendMessage(state, data) {
      await axios.put("project-message", data).catch((err) => {
        console.error("Error from sendMessage: ", err);
      });
    },
  },
};
