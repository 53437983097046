import axios from "@/api/axios";
import Cookies from "@/modules/helpers/cookies";

export default {
  namespaced: true,

  state: {
    sites: [],
    site: false,
  },

  getters: {
    sites: (state) => state.sites,
    site: (state) => state.site,
    users: (state) => state.site.users,
  },

  mutations: {
    GET_SITES(state, payload) {
      state.sites = payload;
    },
    PUSH_SITE(state, payload) {
      state.sites.push(payload);
    },
    SET_SITE(state, payload) {
      state.site = payload;
    },
    SET_PS_IMAGE(state, payload) {
      state.site = payload;
    },
    DELETE_SITE(state, id) {
      let index = state.sites.data.findIndex((item) => item.id === id);
      state.sites.splice(index, 1);
    },
  },

  actions: {
    async loadSites({ commit }) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .get("/procurement-site")
        .then((res) => {
          commit("GET_SITES", res.data.data);
        })
        .catch((err) => {
          console.error("Error from sites: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async loadSite({ commit }, { id }) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .get("/procurement-site/" + id)
        .then((res) => {
          commit("SET_SITE", res.data.data);
        })
        .catch((err) => {
          console.error("Error from site: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async createSite({ commit }, site) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .post("/procurement-site", site, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data && res.data.status === "Success") {
            commit("PUSH_SITE", res.data.data);
            commit("SET_SITE", res.data.data);
          }
          if (res.response && res.response.status >= 400) {
            Cookies.setCookie(
              "cureline_notification",
              JSON.stringify([
                {
                  title: res.response.data.message,
                  text: JSON.stringify(res.response.data.errors),
                  type: "error",
                },
              ]),
              1
            );
          }
        })
        .catch((err) => {
          console.error("Error createSite:: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async editSite({ commit }, site) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .post(`/procurement-site/${site.get("id")}`, site, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.status === "Success") {
            commit("SET_SITE", res.data.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async deleteSite({ commit }, site) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .delete("/procurement-site/" + site.id)
        .then(() => {
          commit("DELETE_SITE", site);
          /*if (res.data.status === "Success") {

          }*/
        })
        .catch((err) => {
          console.error(err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
  },
};
