<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @mousedown.self="$emit('close')">
        <div class="modal-container" :class="size">
          <div class="modal-header">
            <slot name="header"></slot>
            <button
              class="modal-default-button"
              @click="$emit('close')"
            ></button>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  &-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    padding: 45px 55px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;

    &.big {
      max-width: 80vw;
      width: 1200px;
    }
  }

  &-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  &-body {
    margin: 5px 0;
  }

  &-footer {
    padding-top: 15px;
  }

  &-default-button {
    position: absolute;
    top: 30px;
    right: 55px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 2px;
    outline: none !important;
    background: none;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: 0.17s all;

    &:before,
    &:after {
      content: "";
      position: absolute;
      background: var(--col-primary-light);
    }

    &:before {
      left: 42%;
      top: 0;
      width: 3px;
      height: 100%;
    }

    &:after {
      top: 42%;
      left: 0;
      height: 3px;
      width: 100%;
    }
  }

  &-enter {
    opacity: 0;
  }

  &-leave-active {
    opacity: 0;
  }

  &-enter &-container,
  &-leave-active &-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
</style>
