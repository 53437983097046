<template>
  <div class="breadcrumbs-wrapper">
    <p><router-link to=""> Archived Projects</router-link> /</p>
  </div>

  <div class="main-body">
    <div class="filters-wrapper">
      <div>
        <div class="filters_search">
          <label>Search:<input v-model="searchTerm" /></label>
        </div>
        <div class="filters_icon"><IconFilter height="18" /></div>
        <div
          class="filters_select"
          v-if="categoryOptions && categoryOptions.length"
        >
          <label>Select Category: </label>
          <select v-model="selectedCategory">
            <option value="">All projects</option>
            <option
              v-for="category in categoryOptions"
              :value="category.id"
              :key="category.id"
            >
              {{ category.title }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="!!archivedProjects && archivedProjects.length">
      <Table
        project-type="archive"
        :columns="table.columns"
        :rows="table.rows"
        :pagination="pagination"
        :sortable="table.sortable"
        @do-sort="doSort"
        @paginate="paginate"
      ></Table>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Table from "@/components/table/table.vue";
import IconFilter from "@/modules/components/icons/IconFilter.vue";

export default {
  components: { Table, IconFilter },
  setup() {
    const store = useStore();
    const router = useRouter();

    store.dispatch("archived/getAllArchivedProjects");
    const archivedProjects = computed(
      () => store.getters["archived/archiveProjectList"]
    );
    const params = ref({
      t: "arc", // r, f, a, arc, reop
      page: 1,
      // s: "bus",
      // so: "created_at",
      // sw: "desc",
      // c: 2, //category.id
    });
    const categoryOptions = computed(
      () => store.getters["projects/projectCategories"]
    );
    const pagination = computed(() => store.getters["projects/pagination"]);
    const selectedCategory = ref("");
    const searchTerm = ref("");

    const table = reactive({
      isLoading: false,
      isReSearch: true,
      columns: [
        {
          label: "Project#",
          field: "project_number",
          width: "10%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Client",
          field: "client_company",
          width: "15%",
          sortable: true,
        },
        {
          label: "Request Title",
          field: "description_short",
          width: "18%",
          sortable: false,
        },
        {
          label: "Creation Date",
          field: "created_at",
          width: "12%",
          sortable: true,
        },
        {
          label: "Timeline",
          field: "timeline",
          width: "10%",
          sortable: false,
        },
        {
          label: "Indication",
          field: "indication",
          width: "15%",
          sortable: false,
        },
        {
          label: "Pathology",
          field: "pathology",
          width: "20%",
          sortable: false,
        },
      ],
      rows: [],
      totalRecordCount: 2,
      sortable: {
        order: "id",
        sort: "asc",
      },
    });

    table.rows = computed(() => {
      if (!archivedProjects.value) {
        return [];
      }
      return archivedProjects.value.map((project) => {
        let p = {};
        p.client_company = project.client_company ? project.client_company : "";
        p.project_number = project.project_number;
        p.description_short = project.description_short
          ? project.description_short
          : "";
        let creationDate = project.created_at;
        // creationDate;
        p.created_at = creationDate.slice(0, 10);
        p.timeline = project.timeline;
        p.id = project.id;
        const pathologies = project.categories.map((c) => c.title);
        p.pathology = pathologies.join(", ");
        p.indication = project.indication ? project.indication : "";
        return p;
      });
    });

    const getFilteredProjects = () => {
      store.dispatch("archived/getFilteredProjects", { params: params.value });
    };

    const doSort = (order, sort) => {
      params.value.sw = sort;
      params.value.so = order;
      getFilteredProjects();
    };

    const paginate = (to) => {
      if (params.value.c || params.value.s) {
        params.value = { ...params.value, ...{ page: to } };
        getFilteredProjects();
      } else {
        store.dispatch("archived/getAllArchivedProjects", to);
      }
    };

    watch(searchTerm, (newTerm) => {
      params.value.s = newTerm;
      if (!newTerm.length) {
        delete params.value.s;
      } else {
        params.value.page = 1;
      }
      getFilteredProjects();
    });

    watch(selectedCategory, (newCategory) => {
      params.value.c = newCategory;
      if (!newCategory) {
        delete params.value.c;
      } else {
        params.value.page = 1;
      }
      getFilteredProjects();
    });

    const goToProjectDetails = (id) => {
      router.push({
        name: "ProjectArchive",
        params: { id: `${id}` },
      });
    };

    return {
      archivedProjects,
      table,
      categoryOptions,
      pagination,
      selectedCategory,
      searchTerm,
      doSort,
      goToProjectDetails,
      paginate,
    };
  },
};
</script>
