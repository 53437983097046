<template>
  <AddAdminModal
    v-if="showAddAdminModal"
    @close="showAddAdminModal = false"
  ></AddAdminModal>

  <div class="breadcrumbs-wrapper">
    <p><router-link to="">Curline Users</router-link> /</p>
  </div>

  <div class="sites-container">
    <div class="sites-wrapper">
      <div class="adding-btn-wrapper">
        <button class="adding-btn" @click="showAddAdminModal = true">
          <IconAdd />
          <p class="adding-btn-title">Add a new Admin</p>
        </button>
      </div>

      <template v-if="adminList">
        <div class="site-preview" v-for="admin in adminList" :key="admin.id">
          <div class="site-avatar">
            <img v-if="admin.avatar" :src="`${admin.avatar}`" alt="" />
            <img
              v-else
              src="https://api.cureline-crm.phnapp.com/storage/avatar/default.png"
              alt=""
            />
          </div>
          <div class="site-preview-info">
            <div class="site-preview-info-inner">
              <h4 v-if="admin.name" class="site-title center">
                {{ admin.name }}
              </h4>
              <p class="site-description center">
                Role: {{ ucFirst(admin.role) }}
              </p>
            </div>
            <BaseButton
              className="info big"
              text="More"
              @click="viewAdminDetails(admin.id)"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import textFormat from "@/modules/helpers/textFormat.js";
import AddAdminModal from "@/components/admin/AddAdminModal.vue";
import BaseButton from "@/modules/components/base/BaseButton.vue";
import IconAdd from "@/modules/components/icons/IconAdd";

export default {
  components: {
    AddAdminModal,
    BaseButton,
    IconAdd,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const showAddAdminModal = ref(false);
    store.dispatch("admins/getAdminList");
    const adminList = computed(() => store.getters["admins/adminList"]);
    const ucFirst = (txt) => textFormat.ucFirst(txt);

    const viewAdminDetails = async (id) => {
      router.push({ name: "AdminDetails", params: { id } });
    };

    return { adminList, showAddAdminModal, ucFirst, viewAdminDetails };
  },
};
</script>

<style lang="scss" scoped>
.sites-container {
  min-height: calc(100vh - 185px);
}

.sites-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin: 0 -15px;
}

.site {
  &-preview {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    min-width: 200px;
    margin: 0 15px 46px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background: #ffffff;

    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      padding: 16px;

      &-inner {
        flex: 1;
      }
    }

    &-link {
      display: block;
      width: 100%;
      margin-top: 15px;
      color: #ffffff;
      font-size: 16px;
      padding: 3px 15px;
      background: #17a2b8;

      &:hover {
        background: #17889a;
      }
    }
  }

  &-avatar {
    overflow: hidden;
    max-height: 110px;
    margin-top: 20px; // user photo
    max-height: 90px; // user photo

    img {
      height: 100%;
      margin: 0 auto;
    }
  }

  &-title {
    font-size: 16px;
    font-family: "OpenSans-Semibold";
    color: var(--col-text-secondary);
  }

  &-description {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-family: "OpenSans-Regular";
    color: var(--col-text-secondary);
  }
}
.adding-btn-wrapper {
  max-width: 200px;
  min-width: 200px;
}

.btn-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  margin: 0 15px 46px;
  padding: 2rem;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #ffffff;

  svg {
    display: inline-block;
    margin-bottom: 15px;
  }

  &:hover {
    background-color: var(--col-bg-primary);
  }
}
</style>
