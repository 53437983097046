import axios from "@/api/axios";
import setToken from "@/modules/helpers/persistanceStorage";
import { useRouter } from "vue-router";

const router = useRouter();
export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    token: null,
  },

  getters: {
    authenticated: (state) => state.authenticated,
    user: (state) => state.user,
    dashboard: (state) => state.dashboard,
  },

  mutations: {
    SET_AUTHENTICATED(state, payload) {
      state.authenticated = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_DASHBOARD(state, payload) {
      state.dashboard = payload;
    },
  },

  actions: {
    async signIn({ commit, dispatch }, credentials) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios.post("/auth/login/admin", credentials).then((res) => {
        setToken(res.data.data.token);
        localStorage.setItem("authToken", res.data.data.token);
      });
      commit("projects/LOADING_STATE", false, { root: true });
      dispatch("me");
      return true;
    },

    async signOut({ commit }) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios.post("/auth/logout").then(() => {
        localStorage.setItem("authToken", "");
        localStorage.removeItem("authToken");
        localStorage.clear();
        sessionStorage.clear();
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", null);
      });
      commit("projects/LOADING_STATE", false, { root: true });
      return true;
    },

    async updateUserAccount({ commit }, data) {
      commit("projects/LOADING_STATE", true, { root: true });
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };

      return await axios
        .post("/admin/" + data.id, data.formData, config)
        .then((response) => {
          commit("SET_USER", response.data.data);
          commit("projects/LOADING_STATE", false, { root: true });
          return response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async me({ commit, dispatch }) {
      if (!localStorage.getItem("authToken")) {
        return null;
      }
      return await axios
        .get("/me")
        .then((response) => {
          commit("SET_AUTHENTICATED", true);
          commit("SET_USER", response.data);
          dispatch("dashboard");
          dispatch("projects/getCategories", null, {
            root: true,
          });
        })
        .catch((error) => {
          commit("SET_AUTHENTICATED", false);
          commit("SET_USER", error);
          commit("SET_USER", null);
          localStorage.removeItem("authToken");

          console.error("error from auth state:", error);
          router.push({ path: "/login" });

          return Promise.reject(
            error && error.response && error.response.status
          );
        });
    },

    async dashboard({commit}) {
      if (!localStorage.getItem("authToken")) {
        return null;
      }
      return await axios
        .get("/dashboard")
        .then((response) => {
          commit("SET_DASHBOARD", response.data.data);
          return response.data.data
        })
        .catch((error) => {
          console.error("error from auth state:", error);
          router.push({ path: "/login" });

          return Promise.reject(
            error && error.response && error.response.status
          );
        });
    },
  },
};
// super.admin@cureline.com
