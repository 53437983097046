<template>
  <div class="line-content pointer" @click="switchToProposalDetails(line.id)">
    <div class="line-content-head">
      <div class="line-content-head-item">
        <p class="line-content-head-title">Line indication: {{ line.title }}</p>
        <p>HBS description: {{ line.description }}</p>
      </div>

      <!-- <div class="line-content-head-item">
        <p>Available</p>
        <p>{{ availablePersent || 0 }}</p>
      </div> -->

      <div class="line-content-head-item">
        <p>Collectable</p>
        <p>{{ collectablePersent || 0 }}</p>
      </div>
    </div>

    <div class="line-content-body">
      <div class="line-content-body-item">
        <p>Up to</p>
        <h3 class="green">{{ line.cases_count }}</h3>
        <p>cases</p>
      </div>

      <!-- <div class="line-content-body-item">
        <p>Available</p>
        <h3 class="green">
          {{ availableCount }}
        </h3>
        <p>Cases</p>
      </div> -->

      <div class="line-content-body-item">
        <p>Collectable</p>
        <h3 class="green">
          {{ collectableCount }}
        </h3>
        <p>Cases</p>
      </div>

      <div class="line-content-body-item">
        <p>Sites</p>
        <h3 class="green">
          {{ sitesCount }}
        </h3>
        <p>Sites</p>
      </div>
    </div>
  </div>
  <hr style="margin: 10px 0" />
</template>

<script>
import { computed, toRefs } from "@vue/reactivity";
import { useRouter } from "vue-router";

export default {
  props: {
    line: {
      type: Object,
    },
    projectId: {
      type: String,
    },
    projectType: {
      type: String,
    },
  },
  setup(props) {
    const router = useRouter();
    const { projectId, line } = toRefs(props);

    // const availablePersent = computed(() => {
    //   if (line.value.cases_count && availableCount.value) {
    //     return `${(
    //       (availableCount.value / line.value.cases_count) *
    //       100
    //     ).toFixed(2)}%`;
    //   } else {
    //     return 0;
    //   }
    // });
    const collectablePersent = computed(() => {
      if (line.value.cases_count && collectableCount.value) {
        return `${(
          (collectableCount.value / line.value.cases_count) *
          100
        ).toFixed(2)}%`;
      } else {
        return 0;
      }
    });
    // const availableCount = computed(
    //   () =>
    //     line.value.proposals.reduce((sum, p) => {
    //       return p.available + sum;
    //     }, 0) || 0
    // );
    const collectableCount = computed(
      () =>
        line.value.proposals.reduce((sum, p) => {
          if (p.to_collect) {
            return p.to_collect + sum;
          }
        }, 0) || 0
    );
    const sitesCount = computed(
      () =>
        line.value.proposals.reduce((sum, p) => {
          if (p.user) {
            return 1 + sum;
          }
        }, 0) || 0
    );

    const switchToProposalDetails = (lineId) => {
      let type = props.projectType;
      let p = "";
      let name = "";

      switch (type) {
        case "feasibility":
          p = "pending-feasibility-details";
          name = "ProjectFeasibilityDetails";
          break;
        case "project":
          p = "active-details";
          name = "ProjectActiveDetails";
          break;
      }

      router.push({
        name,
        path: `${p}/${projectId.value}#line-${lineId}`,
        params: { id: projectId.value },
      });
      // router.push({
      //   name: `ProjectFeasibilityDetails`,
      //   path: `/pending-feasibility-details/${projectId.value}#line-${lineId}`,
      //   params: { id: projectId.value },
      // });
    };

    return {
      // availablePersent,
      collectablePersent,
      // availableCount,
      collectableCount,
      sitesCount,
      switchToProposalDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
.line {
  &-content {
    margin: 10px 0;
    border: 1px solid var(--col-border);
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      border-bottom: 1px solid var(--col-border);

      &-item {
        padding: 10px;
        border-left: 1px solid var(--col-border);
        text-align: center;
        &:first-child {
          flex-basis: 60%;
          border: none;
          text-align-last: left;
        }
        &:nth-child(2),
        &:nth-child(3) {
          flex-grow: 1;
          background-color: var(--col-hr);
          font-weight: 900;
          text-shadow: 1px 1px ghostwhite;
          p:first-child {
            color: var(--col-warning);
          }
        }
      }
      &-number {
        font-weight: 900;
      }
    }
    &-body {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      &-item {
        flex-grow: 1;
        padding: 10px;
        border-left: 1px solid var(--col-border);
        text-align: center;
        &:first-child {
          border: none;
        }
        p {
          &:first-child {
            font-weight: 900;
          }
        }
      }
    }
  }
}
</style>
