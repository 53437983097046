<template>
  <div
    v-if="isShown"
    class="notification-wrapper"
    :class="[status]"
    @click="closeNotification"
  >
    <div class="notification-type" :class="[status]">
      <div class="notification-icon-wrp" :class="[status]">
        <IconChecked
          v-if="status === 'success'"
          icon-color="#fff"
          width="50"
          height="50"
        />
        <IconClose
          v-else-if="status === 'error'"
          icon-color="#fff"
          width="20"
          height="20"
        />
      </div>
    </div>

    <div class="notification" :class="[status]">
      <h4 class="notification-heading">{{ ucFirst(status) }}!</h4>

      <p class="notification-text">{{ text }}</p>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import textFormat from "@/modules/helpers/textFormat.js";
import IconChecked from "@/modules/components/icons/IconChecked";
import IconClose from "@/modules/components/icons/IconClose";

export default {
  components: {
    IconChecked,
    IconClose,
  },
  setup() {
    const store = useStore();
    const isShown = computed(() => store.getters["admins/isNotificationShown"]);
    const text = computed(() => store.getters["admins/notificationText"]);
    const status = computed(() => store.getters["admins/notificationStatus"]);

    const closeNotification = () => {
      store.commit("admins/CLEAN_NOTIFICATION");
    };
    const ucFirst = (txt) => textFormat.ucFirst(txt);

    return {
      isShown,
      text,
      status,
      closeNotification,
      ucFirst,
    };
  },
  // created: function () {
  //   let self = this;

  //   window.addEventListener("click", function (e) {
  //     if (!self.$el.contains(e.target)) {
  //       self.close();
  //     }
  //   });
  // },
};
</script>

<style lang="scss" scoped>
.notification {
  &-wrapper {
    position: fixed;
    z-index: 99;
    top: 90px;
    right: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 400px;
    border-radius: 4px;
    overflow: hidden;

    &.success {
      box-shadow: #b3ecf7 0px 5px 15px;
    }
    &.error {
      box-shadow: var(--col-error-bg) 0px 5px 15px;
    }
  }

  flex: 1;
  padding: 10px 12px;
  background-color: #fffffff7;
  color: var(--col-primary);

  &-type {
    flex-basis: 70px;
    min-height: 70px;

    &.success {
      background-color: var(--col-info);
    }
    &.error {
      background-color: var(--col-error);
    }
  }

  &-icon-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 15px auto 0;

    &.error {
      border: 3px solid #fff;
      border-radius: 50%;
    }
  }

  &-heading {
  }

  &-text {
    // color: var(--col-info);
  }
}
</style>
