<template>
  <div class="wrapper">
    <div
      class="line-conteiner"
      v-for="item in lineList"
      :key="item.id + 'p.item'"
    >
      <ProjectLine
        :item="item"
        :get-line-info="getLineInfo"
        @remove-line="$emit('remove-line', data)"
      />
      <!-- @edit-line="editLine" -->
      <!--      @editLine="$emit('editLine', item.title, item.id)"-->
      <!--      @saveProperty="saveProperty"-->
      <!--      @click="$emit('remove-item', item.id)"-->
      <!--      @remove-item="removeItem(item.id)"-->
      <hr class="dashed" style="margin: 10px 0" />
    </div>

    <!--    <button class="line-add-button" @click="addLine">+ Add a line</button>-->
    <button class="line-add-button" @click="addLine">+ Add a line</button>
  </div>
</template>

<script>
import { useStore } from "vuex";
// import { toRefs } from "vue";
import ProjectLine from "./ProjectLine.vue";

export default {
  props: {
    lineList: {
      type: Array,
      required: true,
    },
    getLineInfo: {
      type: Number,
      default: 1,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  components: { ProjectLine },
  setup(props) {
    const store = useStore();
    // const { getLineInfo } = toRefs(props);

    const addLine = async () => {
      await store.dispatch("projects/addNewLine", {
        project_id: props.projectId,
      });
    };
    const editLine = () => {
      console.log("editLine");
    };

    return { addLine, editLine };
  },
};
</script>

<style lang="scss" scoped>
.line-add-button {
  display: block;
  width: 100%;
  padding: 4px;
  border: 1px dashed var(--col-primary-light);
  border-radius: 4px;
  text-align: center;
  color: var(--col-primary-dark);
  background-color: var(--col-bg-attention);
}
</style>
