<template>
  <div class="home text-center">
    <template v-if="!authenticated">
      <div class="container mx-auto">
        <HomeWelcome msg="Welcome to admin panel!" />
        <p>go to the login page:</p>
        <router-link :to="{ name: 'Login' }" class="login-link"
          >Login</router-link
        >
      </div>
    </template>
    <template v-else>
      <HomeWelcome msg="Hello admin!" />
    </template>
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import HomeWelcome from "@/components/HomeWelcome.vue";

export default {
  name: "Home",
  components: {
    HomeWelcome,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const authenticated = computed(() => store.getters["auth/authenticated"]);
    const user = computed(() => store.getters["auth/user"]);

    watch(authenticated, (newValue) => {
      if (newValue) {
        router.push({ name: "ProjectList" });
      }
    });

    return { authenticated, user };
  },
};
</script>

<style>
.icon {
  display: inline-block;
  cursor: pointer;
  width: 50px;
  height: 50px;
}
.icon svg {
  width: 100%;
  height: 100%;
}
.icon:hover svg path {
  fill: yellow;
}
.login-link {
  font-size: 16px;
  color: var(--col-info);
}
</style>
