<template>
  <div>
    <Loader v-if="isLoading" />
    <BaseNotification />
    <Notification />
    <TheHeading v-if="isAuthOnly" :title="title" />

    <div :class="{ 'main-wrapper': isAuthOnly }">
      <Menu v-if="isAuthOnly" />
      <div :class="{ main: isAuthOnly }">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Loader from "@/modules/components/base/Loader.vue";
import BaseNotification from "@/components/base/BaseNotification.vue";
import Notification from "@/modules/components/base/Notification.vue";
import TheHeading from "@/components/header/TheHeading";
import Menu from "@/components/Menu";

export default {
  components: { Loader, BaseNotification, TheHeading, Menu, Notification },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isAuthOnly = computed(() => route.meta.authOnly);
    let routeName = computed(() => route.name);
    let title = computed(() =>
      routeName.value
        ? routeName.value.replace(/([A-Z][a-z]+)/g, " $1 ").trim()
        : ""
    );

    const isLoading = computed(() => store.getters["projects/loading"]);

    onMounted(async () => {
      await store.dispatch("auth/me").catch((errorCode) => {
        if (errorCode === 401 && router.currentRoute.value.meta.authOnly) {
          router.replace({ name: "Home" });
        }
      });
    });

    return { isAuthOnly, isLoading, title };
  },
};
</script>

<style lang="scss">
@import "./index.scss";

#nav {
  &.router-link-exact-active {
    color: #42b983;
  }
}

.main-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  min-height: calc(100vh - 90px);
  overflow-x: auto;
}

.main {
  position: relative;
  // overflow-x: auto;
  flex: 1;
  padding: 72px 30px 5px;
}
</style>
