<template>
  <div class="file-field-wrapper">
    <label for="file-loader" class="file-field-view">
      <div><IconCloud icon-color="#17a2b8" /></div>
      <div class="file-field-view-title">
        <p><b>Drop your files here, or</b></p>
        <p class="file-field-view-link">Upload file</p>
      </div>
    </label>
    <input
      type="file"
      id="file-loader"
      class="file-field"
      multiple
      accept=".txt, .doc, .xls, .xlsx,.pdf"
      @change="uploadFiles"
    />
  </div>

  <div
    v-if="project && project.files && project.files.length"
    class="file-field-wrapper"
  >
    <div class="file-field-container_bordered">
      <div
        v-for="(file, index) in project.files"
        class="file-field-container"
        :key="index"
      >
        <div class="file-field-bordered">
          <a :href="file.file_url" target="_blank">
            <div class="file-field-body">
              <div style="width: 31px">
                <component :is="`Icon${file.extension}`" icon-color="#17a2b8" />
              </div>
              <span class="file-field-name"
                >{{ file.original_file_name }}
              </span>
            </div>
          </a>
        </div>
        <button class="file-field-remove" @click.prevent="removeFile(file.id)">
          <IconGarbage />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import IconCloud from "@/modules/components/icons/filesTypes/IconCloud.vue";
import IconGarbage from "@/modules/components/icons/IconGarbage.vue";
import Icondoc from "@/modules/components/icons/filesTypes/IconDoc.vue";
import IconNone from "@/modules/components/icons/filesTypes/IconNone.vue";
import Iconpdf from "@/modules/components/icons/filesTypes/IconPdf.vue";
import Iconpng from "@/modules/components/icons/filesTypes/IconPng.vue";
import Icontxt from "@/modules/components/icons/filesTypes/IconTxt.vue";
import Iconzip from "@/modules/components/icons/filesTypes/IconZip.vue";

export default {
  components: {
    IconCloud,
    IconGarbage,
    Icondoc,
    IconNone,
    Iconpdf,
    Iconpng,
    Icontxt,
    Iconzip,
  },
  setup() {
    const store = useStore();
    const project = computed(() => store.getters["projects/project"]);

    let newFormData = new FormData();

    const uploadFiles = (ev) => {
      let files = ev.target.files;
      for (let i = 0; i < files.length; i++) {
        newFormData.append(`files[${i}]`, files[i]);
      }

      newFormData.append("project_id", project.value.id);
      store.dispatch("projects/saveFile", newFormData);
    };

    const removeFile = (id) => {
      store.dispatch("projects/deleteFile", id);
    };

    return { project, uploadFiles, removeFile };
  },
};
</script>
