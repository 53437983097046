import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {
    users: [],
    user: false,
    userRoles: [
      { id: 1, value: "admin", title: "Admin" },
      { id: 2, value: "staff", title: "Staff" },
    ],
  },
  getters: {
    users: (state) => {
      return state.users;
    },
    userRoles: (state) => state.userRoles,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    FETCH_USERS(state, payload) {
      state.users = payload;
    },
    PUSH_USER(state, payload) {
      state.users.push(payload);
    },
    EDIT_USER(state, data) {
      state.users.map((user, index) => {
        if (user.id === data.id) {
          state.users[index] = { ...state.users[index], ...data };
        }
      });
    },
    DELETE_USER(state, id) {
      const index = state.users.findIndex((item) => item.id === id);
      state.users.splice(index, 1);
    },
  },
  actions: {
    async createUser({ commit }, data) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios.post("/user", data).then((response) => {
        commit("SET_USER", response.data);
        commit("PUSH_USER", response.data.data);
      });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async fetchUsers({ commit }, param) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .get("/procurement-site/" + param.id)
        .then((res) => {
          commit("FETCH_USERS", res.data.data.users);
        })
        .catch((err) => {
          console.error(err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async editUser({ commit }, data) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .post("/user/" + data.id, data)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("EDIT_USER", data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async deleteUser({ commit }, id) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .delete("/user/" + id)
        .then((res) => {
          if (res.data.data === 1) {
            commit("DELETE_USER", id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
  },
};
