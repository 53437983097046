<template>
  <svg 
    :width="width"
    :height="height"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4535:24723)">
      <path
        d="M7.91426e-05 10.6204C-0.0933788 6.34096 3.04291 1.77016 8.09354 0.787239C10.7633 0.269539 13.2555 0.795854 15.5328 2.30431C15.9674 2.59253 16.0912 3.05619 15.8443 3.43996C15.5974 3.82373 15.1356 3.90989 14.6886 3.6154C13.2171 2.6239 11.4746 2.11901 9.70413 2.17117C5.77422 2.25419 2.24618 5.39955 1.70802 9.30305C1.08497 13.8143 3.81083 17.8283 8.2127 18.8339C10.2189 19.2929 12.1403 18.9905 13.9588 18.0233C14.0959 17.9504 14.2275 17.8666 14.3677 17.8001C14.7734 17.609 15.2026 17.7405 15.4058 18.1134C15.6169 18.4971 15.5094 18.9749 15.0826 19.1785C14.2571 19.5701 13.4323 20.0095 12.5585 20.246C11.2896 20.5946 9.96424 20.6852 8.66008 20.5125C7.35592 20.3397 6.09913 19.9071 4.96326 19.2399C3.8274 18.5727 2.83529 17.6844 2.04499 16.6269C1.25468 15.5694 0.682075 14.364 0.360671 13.0812C0.162073 12.2808 0.11768 11.4428 7.91426e-05 10.6204Z"
        :fill="iconColor"/>
      <path
        d="M10.0761 14.4297C9.52267 14.423 8.99349 14.2003 8.60024 13.8086C7.30221 12.5737 6.00627 11.3381 4.71239 10.1017C4.29884 9.71007 4.2451 9.25346 4.56987 8.90337C4.90086 8.54153 5.36114 8.56267 5.7965 8.97621C7.03794 10.1568 8.27703 11.3391 9.51379 12.5234C10.0146 13.0011 10.1625 12.987 10.5948 12.445C13.1794 9.20777 15.7656 5.97443 18.3534 2.745C18.809 2.17561 19.2646 2.05656 19.6594 2.39177C20.0418 2.71681 20.0091 3.17498 19.5683 3.72323C16.966 6.97667 14.3653 10.2314 11.7661 13.4875C11.3121 14.0608 10.7747 14.432 10.0761 14.4297Z"
        :fill="iconColor"/>
      <path
        d="M20.0017 10.5922C19.9301 12.5503 19.3997 14.3415 18.36 15.9831C18.0484 16.4804 17.6014 16.6269 17.1917 16.3668C16.8023 16.1209 16.7244 15.6526 17.036 15.1685C18.0479 13.6092 18.5098 11.7532 18.3475 9.89832C18.3057 9.47215 18.2394 9.04877 18.1489 8.63031C18.0375 8.06483 18.233 7.65286 18.6886 7.54791C19.1808 7.43435 19.5664 7.70455 19.6668 8.28413C19.7945 9.05402 19.8888 9.82235 20.0017 10.5922Z"
        :fill="iconColor"/>
    </g>
    <defs>
      <clipPath id="clip0_4535:24723">
        <rect width="20" height="20" fill="white" transform="translate(0 0.59375)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 20 },
    height: { type: [Number, String], default: 21 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
