<template>
  <BaseModal @close="$emit('close')">
    <template v-slot:header>
      <p>Are you sure you want to archive this project?</p>
    </template>

    <template v-slot:footer>
      <div class="btn-wrapper">
        <BaseButton
          className="additional-bordered big"
          text="Cancel"
          @click="$emit('close')"
        ></BaseButton>
        <BaseButton
          className="typical big"
          text="Archive"
          @click="makeArchive"
        ></BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { useStore } from "vuex";
import BaseModal from "@/components/BaseModal.vue";
import BaseButton from "@/modules/components/base/BaseButton.vue";
import { useRouter } from "vue-router";

export default {
  components: { BaseModal, BaseButton },
  props: {
    projectId: {
      type: Number,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const makeArchive = async () => {
      await store.dispatch("archived/makeArchive", props.projectId);

      router.push({ name: "ArchiveList" });

      emit("close");
    };

    return { makeArchive };
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: var(--col-error);
}
.btn-wrapper {
  display: flex;
  justify-content: space-between;
  & > .btn {
    margin: 2px;
  }
}
</style>
