<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 101 101"
    xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <path d="M98.2877 32.036C94.8609 28.6091 89.285 28.6093 85.857 32.0368C84.8027 33.0921 78.0713 39.829 77.043 40.8583V17.6413C77.043 15.2937 76.1287 13.0866 74.4686 11.4267L66.1824 3.14014C64.5225 1.47998 62.3152 0.565918 59.9676 0.565918H9.66016C4.81387 0.565918 0.871094 4.50869 0.871094 9.35498V91.7769C0.871094 96.6231 4.81387 100.566 9.66016 100.566H68.2539C73.1002 100.566 77.043 96.6231 77.043 91.7769V65.7282L98.2875 44.4657C101.722 41.031 101.723 35.4712 98.2877 32.036ZM59.465 6.42529C60.0186 6.42529 61.0852 6.32939 62.0393 7.2833L70.3256 15.5698C71.2553 16.4997 71.1838 17.5187 71.1838 18.144H59.465V6.42529ZM71.1836 91.7769C71.1836 93.3923 69.8695 94.7065 68.2539 94.7065H9.66016C8.04473 94.7065 6.73047 93.3923 6.73047 91.7769V9.35498C6.73047 7.73955 8.04473 6.42529 9.66016 6.42529H53.6055V21.0737C53.6055 22.6917 54.9172 24.0034 56.5352 24.0034H71.1836V46.7228C71.1836 46.7228 62.5402 55.3735 62.5398 55.3737L58.3986 59.5151C58.0771 59.8366 57.8346 60.2288 57.6908 60.6603L53.5475 73.0899C53.1965 74.1427 53.4705 75.3034 54.2553 76.088C55.041 76.8737 56.2021 77.1464 57.2533 76.7958L69.683 72.6526C70.1144 72.5089 70.5066 72.2665 70.8281 71.9448L71.1836 71.5894V91.7769ZM64.6133 61.5868L68.7564 65.73L67.1738 67.3126L60.9592 69.3841L63.0307 63.1692L64.6133 61.5868ZM72.8988 61.5858L68.7557 57.4427C70.9619 55.2347 80.7605 45.4276 82.8387 43.3478L86.9818 47.4909L72.8988 61.5858ZM94.1436 40.3233L91.1234 43.346L86.9803 39.2028L90.0014 36.1792C91.1443 35.0368 93.0025 35.037 94.1447 36.1792C95.2867 37.3218 95.2928 39.1739 94.1436 40.3233Z" :fill="iconColor"/>
      <path d="M56.5352 29.8628H15.5195C13.9016 29.8628 12.5898 31.1745 12.5898 32.7925C12.5898 34.4104 13.9016 35.7222 15.5195 35.7222H56.5352C58.1531 35.7222 59.4648 34.4104 59.4648 32.7925C59.4648 31.1745 58.1531 29.8628 56.5352 29.8628Z" :fill="iconColor"/>
      <path d="M44.8164 41.5815H15.5195C13.9016 41.5815 12.5898 42.8933 12.5898 44.5112C12.5898 46.1292 13.9016 47.4409 15.5195 47.4409H44.8164C46.4344 47.4409 47.7461 46.1292 47.7461 44.5112C47.7461 42.8933 46.4344 41.5815 44.8164 41.5815Z" :fill="iconColor"/>
      <path d="M44.8164 53.3003H15.5195C13.9016 53.3003 12.5898 54.612 12.5898 56.23C12.5898 57.8479 13.9016 59.1597 15.5195 59.1597H44.8164C46.4344 59.1597 47.7461 57.8479 47.7461 56.23C47.7461 54.612 46.4344 53.3003 44.8164 53.3003Z" :fill="iconColor"/>
      <path d="M44.8164 65.019H15.5195C13.9016 65.019 12.5898 66.3308 12.5898 67.9487C12.5898 69.5667 13.9016 70.8784 15.5195 70.8784H44.8164C46.4344 70.8784 47.7461 69.5667 47.7461 67.9487C47.7461 66.3308 46.4344 65.019 44.8164 65.019Z" :fill="iconColor"/>
      <path d="M56.5352 82.9878H38.957C37.3391 82.9878 36.0273 84.2995 36.0273 85.9175C36.0273 87.5354 37.3391 88.8472 38.957 88.8472H56.5352C58.1531 88.8472 59.4648 87.5354 59.4648 85.9175C59.4648 84.2995 58.1531 82.9878 56.5352 82.9878Z" :fill="iconColor"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 101 },
    height: { type: [Number, String], default: 101 },
    iconColor: { type: String, default: "#fff" },
  },
};
</script>
