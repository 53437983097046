<template>
  <div v-if="line" class="line-conteiner" :id="`line-${line.id}`">
    <div class="line-description">
      <!-- <h4 class="line-description-title">{{ line.id }}</h4> -->
      <p class="line-description-text">{{ line.title }}</p>
    </div>

    <div class="form-title">
      <h4 class="info green">Cases {{ line.cases_count || "_" }} (Up to)</h4>
    </div>

    <div>
      <div>{{ line.description || "" }}</div>
    </div>

    <div v-for="proposal in line.proposals" :key="proposal.id">
      <Proposal :is-active="isActive" :proposal="proposal" />
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from "vue";
import Proposal from "./Proposal.vue";

export default {
  components: {
    Proposal,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    line: {
      type: Object,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  // emits: ["accept-proposal"],
  setup(props) {
    const { line } = toRefs(props);
    const proposalsFormDetails = ref([]);
    proposalsFormDetails.value = line.value.proposals.map((p) => {
      let proposalDetails = {
        ...p,
        isShownHint: false,
        orderCount: 0,
      };
      if (!p.ordered && p.to_collect) {
        proposalDetails.orderCount = p.to_collect;
      } else if (p.ordered) {
        proposalDetails.orderCount = p.ordered;
      }
      return proposalDetails;
    });

    // const proposalStatus = ref([]);

    // line.value.proposals.map((p, i) => {
    //   if (p.id && p.status) {
    //     proposalStatus.value[i] = { id: p.id, status: p.status };
    //   }
    // });
    // proposalStatus.value = line
    /* const proposalsFormDetails = computed(() => {
      return line.value.proposals.map((p) => {
        let proposalDetails = {
          ...p,
          isShownHint: false,
          orderCount: 0,
        };
        if (p.available) {
          proposalDetails.orderCount = p.available
        }
        return proposalDetails;
      });
    }); */
    const trackingList = ref([]);

    // const toggleHint = (id) => {
    //   let proposal = proposalsFormDetails.value.find((p) => p.id === id);
    //   proposal.isShownHint = !proposal.isShownHint;
    // };

    // const accepteProposal = async (proposal, index) => {
    //   let number = 0;
    //   let pStatus = "pending";
    //   if (
    //     proposal.orderCount &&
    //     proposal.orderCount <= proposal.available + +proposal.to_collect
    //   ) {
    //     number = proposal.orderCount;
    //   } else {
    //     number = proposal.available + +proposal.to_collect;
    //   }
    //   if (line.value.proposals[index].status === "pending") {
    //     pStatus = "accepted";
    //   }
    //   proposalsFormDetails.value[index].orderCount = number;
    //   let proposalData = {
    //     id: proposal.id,
    //     ordered: number,
    //     status: pStatus,
    //   };
    //   proposalStatus.value[index] = pStatus;
    //   await store.dispatch("proposals/saveProposal", proposalData);
    // };

    return {
      proposalsFormDetails,
      trackingList,
    };
  },
};
</script>

<style lang="scss" scoped>
.line {
  position: relative;
  margin-top: 20px;
  // border: 1px solid var(--col-border);
  border-radius: 8px;
  border: 2px solid var(--col-info);
  background-color: var(--col-bg-primary);

  &-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 3px 5px;
    background-color: var(--col-info);

    &-user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &-data {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &-name {
        font-size: 12px;
        color: var(--col-contrast-text);
      }
    }

    &-avatar-wrp {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }

  &-content {
    padding: 10px;
  }

  &-quantity-input,
  &-textarea,
  &-track-input {
    border: 1px solid var(--col-border);
    border-radius: 4px;
    color: var(--col-subtext-secondary);
  }

  &-textarea {
    width: 100%;
    padding: 5px;
  }

  &-quantity-input {
    width: 70px;
    margin: 10px;
    text-align: center;
  }

  &-description {
    padding-top: 10px;
    font-size: 14px;

    // &-title {}
    &-text {
      font-size: 22px;
      font-family: "OpenSans-Regular";
    }
  }

  hr {
    margin-bottom: 10px;
  }

  &-conteiner:last-of-type {
    hr {
      display: none;
    }
  }

  &-button-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &-status {
    &-checkbox {
      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        &:not(.accepted) {
          .empty {
            border-color: var(--col-warning);
          }
          .dots {
            font-size: 22px;
          }
        }

        span.green {
          font-weight: 900;
          color: var(--col-info);
        }
      }
    }
  }

  &-hint {
    position: absolute;
    top: 0;
    right: 50px;
    width: 60%;
    height: auto;
    margin: 0;
    padding: 10px 15px;
    border: 2px solid var(--col-info);
    border-radius: 8px;
    background-color: var(--col-contrast-text);
    color: var(--col-text-secondary);

    &-toggler {
      position: absolute;
      right: 15px;
      top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border: none;
      border-radius: 50%;
      background-color: var(--col-info);
      color: var(--col-contrast-text);
      box-shadow: none;
    }
  }
  .clickable-space {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    background-color: transparent;
  }

  &-tracking {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 10px 0;
    padding: 0 15px;
    background-color: #ffffff60;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      min-width: 80px;
      min-height: 100%;
      padding: 5px 0;

      &_number {
        flex-basis: 40%;
      }

      button {
        margin: 10px 0;
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }

  &-track-input {
    margin: 8px 0;
  }
}

.line-add-button {
  display: block;
  width: 100%;
  padding: 4px;
  border: 1px dashed var(--col-primary-light);
  border-radius: 4px;
  text-align: center;
  color: var(--col-primary-dark);
  background-color: var(--col-bg-attention);
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    flex-basis: 15%;
    min-height: 100%;
    padding: 5px 0;
    border-left: 2px solid var(--col-info);

    &:first-child {
      border-left: none;
    }

    h4 {
      font-size: 14px;
      font-family: "SFProDisplay-Medium";
      text-align: center;
    }

    &-text {
      font-size: 12px;
      font-family: "OpenSans-Regular";
      color: var(--col-info);
    }

    &-value {
      font-size: 22px;
      font-weight: 900;
      color: var(--col-text-primary);
    }

    &-input {
      width: 70px;
      text-align: center;
    }

    &.info,
    &.info h4 {
      color: var(--col-info);
    }

    &-count {
      margin: 10px 0;
      font-size: 16px;
      font-weight: 900;
    }

    .dash {
      margin: 10px 0;
      font-weight: 900;

      &.info {
        color: var(--col-info);
      }
    }
  }
  // //  - to css end
}
</style>

<style lang="scss">
.line {
  &-comment {
    .textarea {
      background-color: var(--col-bg-secondary);
    }
  }
  &-status-checkbox-wrapper {
    .empty {
      border-color: var(--col-warning);
    }
    .yellow {
      color: var(--col-warning);
    }
  }
}
</style>
