<template>
  <div :class="!cards || !cards.length ? 'column-empty' : 'column-wrapper'">
    <div class="column">
      <div class="column-bg">
        <h4 class="column-title green">{{ title }}</h4>
        <div class="column-scroll">
          <div
            class="column-card"
            v-for="card in cards"
            :key="card.p_id"
            :style="`border-right: 9px solid ${card.pl_color};`"
          >
            <div class="column-card-head">
              <div class="column-card-code">
                <div class="column-card-code-prefix">
                  <IconGeo icon-color="#fff" height="15" />
                  <span>TSS: {{ card.prs_title }}</span>
                </div>
                <!-- :style="`background-color: ${card.pl_color}`" -->
              </div>
              <div v-if="card.plp_price">
                <h4 class="">Received:</h4>
                <h2>
                  <span class="green">{{ card.ps_cases_count }}</span>
                </h2>
              </div>
              <hr style="margin-top: 0" />
              <!-- <div v-if="card.ps_date_shipping" class="column-card-date">
              {{ card.ps_date_shipping }}
            </div> -->
            </div>

            <div class="column-card-body">
              <div class="column-card-body-item">
                <h4 class="grey">Indication:</h4>
                <h4 class="green">{{ card.pl_title }}</h4>
              </div>

              <!-- <div class="column-card-body-item">
              <h4 class="grey">Check:</h4>
              <h4 class="green">{{ card.pl_cases_count }}</h4>
            </div> -->

              <div class="column-card-body-item short">
                <h4 class="green text-center">
                  <IconInfo height="12" width="12" icon-color="#17A2B8" />
                </h4>
                <h4 class="grey">
                  <i>{{ card.ps_tracking }}</i>
                </h4>
              </div>

              <div
                v-if="card.ps_date_created_at"
                class="column-card-body-item short"
              >
                <h4 class="green text-center">
                  <IconDelivering height="12" width="18" />
                </h4>
                <h4 class="grey">
                  <span>{{ card.ps_date_created_at.slice(0, 10) }}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconGeo from "@/modules/components/icons/IconGeo";
import IconInfo from "@/modules/components/icons/IconInfo";
// import IconShipping from "@/modules/components/icons/IconShipping";
import IconDelivering from "@/modules/components/icons/IconDelivering";

export default {
  components: { IconGeo, IconInfo, IconDelivering },
  props: {
    cards: {
      type: Object,
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup() {},
};
</script>
