<template>
  <div class="feasibility-project-page">
    <div class="feasibility-project-main">
      <div class="breadcrumbs-wrapper">
        <p class="grey">Edit Admin Profile</p>
        <!-- <p>
          <router-link to="/procurement-site-list"
            >Edit Admin Profile</router-link
          >
          / {{ admin.id }}
        </p> -->
      </div>

      <div v-if="admin && Object.keys(admin).length" class="admin-page">
        <div class="admin">
          <div class="admin-header">
            <div class="admin-header-avatar">
              <img :src="`${admin.avatar}`" alt="" />
            </div>
            <div class="admin-header-data">
              <h2 v-if="admin.name" class="admin-header-title">
                {{ admin.name }}
              </h2>

              <p class="admin-header-text">
                <span class="admin-header-text-key">Email: </span>
                <b class="admin-header-text-value">
                  {{ admin.email || "-" }}
                </b>
              </p>

              <p class="admin-header-text">
                <span class="admin-header-text-key">Phone: </span>
                <b class="admin-header-text-value">
                  {{ admin.phone || "-" }}
                </b>
              </p>

              <p class="admin-header-text">
                <span class="admin-header-text-key">Curline user from: </span>
                <span class="admin-header-text-value">
                  {{ admin.created_at.slice(0, 10) }}
                </span>
              </p>
            </div>
            <!-- ability to make active/inactive status for any admin:
          <div class="admin-header-activity">
            <div class="admin-header-toggler">
              <h4 class="admin-header-toggler-title">Active</h4>
              <div>
                <input
                  type="checkbox"
                  class="toggle-button"
                  v-model="isActive"
                  @change="toggleUserActivity"
                />
              </div>
            </div>
          </div> -->
          </div>

          <hr class="green" />

          <div class="admin-note">
            <h3>User Information</h3>

            <p>
              <label class="admin-label" for="admin-name"
                >Enter new Name:</label
              >
              <input
                type="text"
                class="textarea"
                name="name"
                id="user-name"
                v-model="adminName"
              />
            </p>

            <p>
              <label class="admin-label" for="admin-email">Email:</label>
              <input
                type="text"
                class="textarea"
                name="email"
                id="user-email"
                v-model="adminEmail"
              />
            </p>

            <div class="admin-roles-wrapper">
              <p class="admin-label">Choose Role:</p>
              <div class="admin-roles">
                <div
                  v-for="role in adminRoles"
                  class="admin-role"
                  :key="role.id"
                >
                  <BaseButton
                    :className="setClassName(role.value)"
                    :text="role.title"
                    :disabled="false"
                    @click="setRole(role.value)"
                  ></BaseButton>
                </div>
              </div>
            </div>
          </div>

          <hr class="green" />
          <!-- user -->

          <div v-if="user.role === 'superadmin'" class="admin-btn-wrp">
            <div class="admin-btn">
              <BaseButton
                class="error big"
                text="Remove Admin"
                :disabled="false"
                @click="removeAdmin"
              ></BaseButton>
            </div>

            <div class="admin-btn">
              <BaseButton
                class="info big"
                text="Save Changes"
                :disabled="false"
                @click="saveChanges"
              ></BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import BaseButton from "@/modules/components/base/BaseButton.vue";

export default {
  components: { BaseButton },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const user = computed(() => store.getters["auth/user"]);
    const admin = computed(() => store.getters["admins/admin"]);

    const adminRoles = computed(() => store.getters["admins/adminRoles"]);

    const adminName = ref("");
    const adminEmail = ref("");
    const adminRole = ref("");

    const getAdminData = async () => {
      await store.dispatch("admins/getAdmin", { id: route.params.id });
      // admin.value = adminData.value;
      adminName.value = admin.value.name;
      adminEmail.value = admin.value.email;
      adminRole.value = admin.value.role;
    };
    getAdminData();

    const isActive = ref(true);

    // :className="[
    //   'big',
    //   role.value == adminRole ? 'info' : 'info_empty',
    // ]"
    const setClassName = (role) => {
      const btnClass = role == adminRole.value ? "info" : "info_empty";
      return `big ${btnClass}`;
    };

    const setRole = (role) => {
      adminRole.value = role;
    };

    const toggleUserActivity = () => {
      // let company = site.value;
      //   let status = isActive.value ? 1 : 0;
      //   store.dispatch("site/editSite", { is_active: status, id: site.value.id });
    };

    const saveChanges = async () => {
      let data = {};
      if (adminName.value !== admin.value.name) {
        data.name = adminName.value;
      }
      if (adminEmail.value !== admin.value.email) {
        data.email = adminEmail.value;
      }
      if (adminRole.value !== admin.value.role) {
        data.role = adminRole.value;
      }

      await store.dispatch("admins/editAdmin", { id: admin.value.id, data });
    };

    const removeAdmin = async () => {
      await store.dispatch("admins/removeAdmin", admin.value.id);
      router.push("/settings");
    };

    return {
      user,
      admin,
      adminName,
      adminEmail,
      adminRole,
      adminRoles,
      isActive,
      setClassName,
      setRole,
      toggleUserActivity,
      saveChanges,
      removeAdmin,
    };
  },
};
</script>

<style lang="scss" scoped>
.admin {
  flex-basis: 100%;
  min-height: calc(100vh - 190px);
  // background-color: var(--col-bg-secondary);

  &-page {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
  }

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 23px 0;

    &-avatar {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      border: 5px solid var(--col-bg-attention);
    }

    &-data {
      padding-left: 40px;
    }

    &-title {
      margin-bottom: 20px;
      flex-basis: 1;
      font-size: 32px;
      line-height: 38px;
      font-weight: 400;
      font-family: "SFProDisplay-Regular";
      color: var(--checkbox-border);
    }

    &-text {
      color: var(--col-secondary);
      &-value {
        padding-left: 10px;
      }
    }

    &-activity {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &-toggler {
      flex-basis: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-title {
        padding-right: 30px;
        font-size: 16px;
        font-family: "OpenSans-Regular";
        color: var(--col-success);
      }
    }

    &-edit-btn {
      display: flex;
      margin-left: 20px;
      padding-left: 10px;
      border: none;
      background-color: transparent;
      transform: rotate(-90deg);
    }
  }

  &-content {
    &-title {
      font-size: 24px;
      color: var(--col-primary);
      font-family: "OpenSans-Semibold";
    }

    &-card {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 30px;
    }
  }

  &-note {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 37px 0 60px;
  }

  &-label {
    display: block;
    padding: 7px 0;
    font-family: "OpenSans-Regular";
    font-size: 16px;
    line-height: 24px;
  }
  .textarea {
    margin-left: 0;
  }

  &-roles {
    display: flex;
    justify-content: flex-start;
  }

  &-role {
    margin-right: 10px;
    width: 200px;
    // flex-basis: 300px;
  }

  &-sidebar {
    width: 200px;
    margin-left: 15px;
    background-color: var(--col-bg-secondary);
  }

  h3 {
    //styleName: Navigation;
    font-family: "OpenSans-Bold";
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    color: var(--col-primary);
  }

  &-btn {
    width: 200px;
    margin: 5px 10px;

    &-wrp {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 20px 0;
    }
  }
}

.toggle-button {
  position: relative;
  width: 60px;
  height: 35px;
  border: 4px solid var(--col-success);
  border-radius: 25px;
  outline: none;
  background: var(--col-bg-secondary);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);

  &::after {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--col-success);
    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  &:checked {
    background-color: var(--col-success);

    &::after {
      transform: translateX(calc(100% - 3px));
      background-color: var(--col-bg-secondary);
    }
  }
}
</style>
