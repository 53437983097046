<template>
  <svg :width="width" :height="height" :fill="iconColor" viewBox="0 0 11 8">
    <path
      d="M8.4402 4.92871C7.74514 4.92871 7.17969 5.49416 7.17969 6.18922C7.17969 6.88428 7.74514 7.44973 8.4402 7.44973C9.13537 7.44973 9.7007 6.88428 9.7007 6.18922C9.7007 5.49416 9.13525 4.92871 8.4402 4.92871ZM8.4402 6.81947C8.09262 6.81947 7.80994 6.5368 7.80994 6.18922C7.80994 5.84164 8.09262 5.55896 8.4402 5.55896C8.78777 5.55896 9.07045 5.84164 9.07045 6.18922C9.07045 6.53682 8.78777 6.81947 8.4402 6.81947Z"
    />
    <path
      d="M4.1316 4.92871C3.43654 4.92871 2.87109 5.49416 2.87109 6.18922C2.87109 6.88428 3.43654 7.44973 4.1316 7.44973C4.82666 7.44973 5.39211 6.88428 5.39211 6.18922C5.39211 5.49416 4.82666 4.92871 4.1316 4.92871ZM4.1316 6.81947C3.78402 6.81947 3.50135 6.5368 3.50135 6.18922C3.50135 5.84164 3.78402 5.55896 4.1316 5.55896C4.47908 5.55896 4.76185 5.84164 4.76185 6.18922C4.76185 6.53682 4.47918 6.81947 4.1316 6.81947Z"
    />
    <path
      d="M9.28895 1.321C9.23537 1.21459 9.12645 1.14746 9.00732 1.14746H7.34766V1.77772H8.81299L9.67108 3.48443L10.2343 3.20123L9.28895 1.321Z"
    />
    <path d="M7.49383 5.88477H5.10938V6.51502H7.49383V5.88477Z" />
    <path
      d="M3.18879 5.88477H2.09637C1.9223 5.88477 1.78125 6.02584 1.78125 6.19988C1.78125 6.37394 1.92232 6.515 2.09637 6.515H3.18881C3.36287 6.515 3.50393 6.37393 3.50393 6.19988C3.50393 6.02582 3.36285 5.88477 3.18879 5.88477Z"
    />
    <path
      d="M10.8222 3.95777L10.2024 3.15945C10.1428 3.08256 10.0509 3.0376 9.95352 3.0376H7.66359V0.831719C7.66359 0.657656 7.52252 0.516602 7.34848 0.516602H2.09637C1.9223 0.516602 1.78125 0.657676 1.78125 0.831719C1.78125 1.00576 1.92232 1.14684 2.09637 1.14684H7.03334V3.35271C7.03334 3.52678 7.17441 3.66783 7.34846 3.66783H9.7992L10.2581 4.259V5.88422H9.38627C9.21221 5.88422 9.07115 6.02529 9.07115 6.19934C9.07115 6.3734 9.21223 6.51445 9.38627 6.51445H10.5732C10.7473 6.51445 10.8884 6.37338 10.8884 6.19934V4.15105C10.8884 4.08109 10.8651 4.01303 10.8222 3.95777Z"
    />
    <path
      d="M3.16705 4.28809H1.71746C1.5434 4.28809 1.40234 4.42916 1.40234 4.6032C1.40234 4.77727 1.54342 4.91832 1.71746 4.91832H3.16703C3.34109 4.91832 3.48215 4.77725 3.48215 4.6032C3.48217 4.42916 3.34109 4.28809 3.16705 4.28809Z"
    />
    <path
      d="M3.89092 3.04785H1.20184C1.02779 3.04785 0.886719 3.18893 0.886719 3.36299C0.886719 3.53705 1.02779 3.67811 1.20184 3.67811H3.89092C4.06498 3.67811 4.20604 3.53703 4.20604 3.36299C4.20604 3.18895 4.06498 3.04785 3.89092 3.04785Z"
    />
    <path
      d="M4.40654 1.80859H1.71746C1.5434 1.80859 1.40234 1.94967 1.40234 2.12371C1.40234 2.29777 1.54342 2.43883 1.71746 2.43883H4.40654C4.58061 2.43883 4.72166 2.29775 4.72166 2.12371C4.72168 1.94967 4.58061 1.80859 4.40654 1.80859Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 11 },
    height: { type: [Number, String], default: 8 },
    iconColor: { type: String, default: "#17A2B8" },
  },
};
</script>
