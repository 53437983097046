<template>
  <div
    v-if="project && Object.keys(project).length && projectId"
    class="feasibility-project-page"
  >
    <div class="feasibility-project-main">
      <div class="breadcrumbs-wrapper">
        <p>
          <router-link
            :to="{
              name: 'FeasibilityList',
            }"
            >Pending Feasibility List</router-link
          >
          /
          <router-link
            :to="{
              name: 'ProjectFeasibility',
              params: { id: projectId },
            }"
            >Pending Feasibility</router-link
          >
          /
          <template v-if="project">#{{ project.project_number }}</template>
        </p>
      </div>

      <div
        v-if="
          lines && lines.length && lines.find((line) => line.proposals.length)
        "
      >
        <div v-for="line in lines" :key="line.id">
          <div v-if="!!line.proposals && line.proposals.length">
            <Line
              :line="line"
              :project-id="projectId"
              :base-data="projectBaseInfo"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <NoProposal
          v-if="project && project.feasibility_number"
          :number-type="project.feasibility_number"
        />
      </div>
    </div>

    <!--sidebar-->
    <div>
      <div class="sidebar">
        <div v-if="project.public_note" class="field-container">
          <h3 class="green">Public note:</h3>
          <div class="green-border">
            {{ project.public_note }}
          </div>
        </div>

        <div v-if="project.private_note" class="field-container">
          <h3 class="green">Cureline only note:</h3>
          <div class="green-border">
            {{ project.private_note }}
          </div>
        </div>

        <hr
          v-if="project.public_note || project.private_note"
          class="dashed"
          style="margin-bottom: 10px"
        />

        <div class="sidebar-admin-data">
          <h6 class="green center">Validated by:</h6>
          <AdminSign />
        </div>
      </div>

      <div class="sidebar">
        <ChatList :id="project.id" :new-chat="newChat" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import Line from "@/components/project/Line.vue";
import AdminSign from "@/components/header/AdminSign.vue";
import NoProposal from "@/modules/components/NoProposal.vue";
import ChatList from "@/components/chat/ChatList.vue";

export default {
  components: { Line, AdminSign, NoProposal, ChatList },
  setup() {
    const store = useStore();
    const route = useRoute();

    const projectId = computed(() => route.params.id);
    const project = computed(() => store.getters["projects/project"]);
    const lines = computed(() => store.getters["projects/lines"]);

    const projectBaseInfo = computed(() => {
      return {
        status: project.value.status,
        created_at: project.value.created_at,
        study_name: project.value.study_name,
        client_company: project.value.client_company,
        contact_name: project.value.contact_name,
        contact_phone: project.value.contact_phone,
        description_short: project.value.description_short,
      };
    });

    onMounted(() => {
      store.dispatch("projects/getProject", { id: projectId.value });
    });

    return {
      projectId,
      project,
      lines,
      projectBaseInfo,
    };
  },
};
</script>
