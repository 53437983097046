<template>
  <div class="chat-wrapper">
    <div
      v-if="isLoading"
      class="chat-loader"
      :style="`min-height: ${chat && chat.length ? 530 : 115}px`"
    >
      <h3 class="green">Loading ...</h3>
    </div>

    <div v-else class="chat">
      <BaseButton
        v-if="chats && chats.length"
        text="<  Back"
        className="info big"
        @click="showAllChats"
      />

      <h4 class="green center" style="margin-top: 10px">
        -
        <span v-if="Object.keys(user).length && user.tss">
          TSS: {{ user.tss }}
        </span>
        <span
          v-else-if="
            Object.keys(userChatTo).length && userChatTo.procurement_site
          "
          >TSS: {{ userChatTo.procurement_site.title }}</span
        >
        -
      </h4>

      <div class="chat-window">
        <div
          v-if="chat && chat.length"
          class="chat-scroller"
          ref="chatContainer"
          v-on:scroll="scrollTop"
        >
          <div v-for="(msg, i) in chat" :key="i">
            <div v-if="Object.keys(msg).length">
              <div v-if="msg.admin_user" class="chat-container chat-admin">
                <div class="chat-text">
                  <div class="chat-text-details">
                    <p
                      v-if="msg.admin_user.name"
                      class="chat-text-details-name grey"
                    >
                      {{ msg.admin_user.name }}
                    </p>
                    <p
                      v-if="msg.created_at"
                      class="chat-text-details-date grey"
                    >
                      {{ getTime(msg.created_at) }}
                    </p>
                  </div>
                  <p v-if="msg.content" class="chat-text-area">
                    {{ msg.content }}
                  </p>
                </div>

                <div class="chat-avatar">
                  <img
                    v-if="msg.admin_user.avatar"
                    :src="`${msg.admin_user.avatar}`"
                    alt=""
                  />
                  <span v-else> A</span>
                </div>
              </div>

              <div v-else-if="msg.user" class="chat-container chat-user">
                <div class="chat-avatar">
                  <img
                    v-if="msg.user.avatar"
                    :src="`${msg.user.avatar}`"
                    alt=""
                  />
                  <span v-else>U</span>
                </div>

                <div class="chat-text">
                  <div class="chat-text-details">
                    <p
                      v-if="msg.user.name"
                      class="chat-text-details-name green"
                    >
                      {{ msg.user.name }}
                    </p>
                    <p class="chat-text-details-date grey">
                      {{ getTime(msg.created_at) }}
                    </p>
                  </div>
                  <p class="chat-text-area">{{ msg.content }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <h3 v-if="!!userChatTo && userChatTo.name" class="green">
            Write to {{ userChatTo.name }}:
          </h3>
        </div>

        <div class="chat-action">
          <textarea
            v-model="message"
            class="chat-textarea"
            rows="3"
            @keyup.enter.prevent="sendMessage"
          />
          <button class="chat-btn" :disabled="!message" @click="sendMessage">
            <IconAirplane icon-color="#ffffff" width="19" height="17" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from "@vue/reactivity";
import { useStore } from "vuex";
import BaseButton from "@/modules/components/base/BaseButton.vue";
import IconAirplane from "@/modules/components/icons/IconAirplane.vue";

export default {
  components: { BaseButton, IconAirplane },
  props: {
    id: {
      type: Number,
    },
    user: {
      type: Object,
    },
  },
  emits: ["show-all-chats"],
  setup(props, { emit }) {
    const store = useStore();
    const { id, user } = toRefs(props);

    const admin = computed(() => store.getters["auth/user"]);
    const isLoading = computed(() => store.getters["chat/isLoading"]);
    const chat = computed(() => store.getters["chat/chatList"]);
    const chats = computed(() => store.getters["chat/chats"]);
    const chatNextPage = computed(() => store.getters["chat/chatNextPage"]);
    const userChatTo = computed(() => store.getters["chat/userChatTo"]);

    const chatContainer = ref(null);
    const message = ref("");
    const allowedToLoadNext = ref(false);

    const client = new WebSocket(
      "wss://admin.cureline-crm.cureline.com/ws/" +
        localStorage.getItem("authToken") +
        "/" +
        id.value +
        "/" +
        user.value.id
    );

    client.onmessage = function (event) {
      store
        .dispatch("chat/showMessage", JSON.parse(event.data))
        .then(() => scrollToEnd());
    };

    const getTime = (date) => {
      const formatDate = new Date(date);
      let hours = formatDate.getHours();
      let minutes = formatDate.getMinutes();
      let month = formatDate.getMonth();
      month++;
      let day = formatDate.getDate();

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return `${day}.${month} ${hours}:${minutes}`;
    };

    const sendMessage = async () => {
      if (message.value) {
        let msg = {
          content: message.value,
          project_id: id.value,
          user_id: user.value.id || userChatTo.value.id,
          admin_user_id: admin.value.id,

          admin_user: {
            id: admin.value.id,
            avatar: admin.value.avatar,
            name: admin.value.name,
          },
          created_at: new Date().toISOString(),
        };

        message.value = "";
        client.send(JSON.stringify(msg));

        await store.dispatch("chat/sendMessage", msg);
        scrollToEnd();
      }

      if (!chats.value || !chats.value.length) {
        store.commit("chat/START_CHAT", {
          isShown: false,
          user: userChatTo.value,
        });
      }
    };

    const scrollToEnd = () => {
      if (chatContainer.value) {
        chatContainer.value.scrollTop = chatContainer.value.scrollHeight + 100;
      }
    };

    const scrollTop = (ev) => {
      if (ev.srcElement.scrollTop > 2) {
        allowedToLoadNext.value = true;
      }
      if (
        chatNextPage.value &&
        !ev.srcElement.scrollTop &&
        allowedToLoadNext.value
      ) {
        store.dispatch(
          "chat/getChat",
          { pid: id.value, uid: user.value.id, page: chatNextPage.value },
          { root: true }
        );
      }
    };

    const showAllChats = () => {
      store.commit("chat/START_CHAT", { isShown: false, user: null });
      emit("show-all-chats");
    };

    return {
      admin,
      isLoading,
      chat,
      chats,
      chatContainer,
      userChatTo,
      message,
      getTime,
      sendMessage,
      scrollTop,
      showAllChats,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--col-info-bg);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px var(--col-info-bg);
  }
}
.chat {
  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--col-info-bg);
  }

  &-window {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding-top: 20px;

    &.default {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .placeholder {
      max-width: 180px;
      margin: 0 auto;
      opacity: 0.4;
    }
  }

  &-scroller {
    min-height: 360px;
    max-height: 560px;
    margin-bottom: 20px;
    overflow-x: hidden;
    height: calc(100vh - 600px);
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  &-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-avatar {
    min-width: 28px;
    height: 28px;
    flex-basis: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    color: var(--col-info);
    background-color: var(--col-info-bg);
    overflow: hidden;
  }

  &-text {
    flex-grow: 1;
    padding: 5px;

    &-details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-name {
        font-size: 14px;
      }

      &-date {
        font-size: 10px;
      }
    }

    &-area {
      padding: 5px;
      border: 1px solid var(--col-info-bg);
      border-radius: 8px;
      line-height: 1.2;
      background-color: var(--col-info-bg);
    }
  }

  &-admin {
    .chat-text {
      padding-left: 50px;
      &-details {
        &-name {
          padding-left: 10px;
        }
      }

      &-area {
        background-color: var(--col-contrast-text);
      }
    }
  }

  &-user {
    .chat-text {
      padding-right: 50px;
      &-details {
        &-date {
          padding-right: 10px;
        }
      }
    }
  }

  &-action {
    position: relative;
  }

  &-textarea {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 1px solid #c8c8cc;
    border-radius: 14px;
    padding: 5px;
    padding-right: 30px;
    line-height: 1.2;
    color: var(--col-primary-dark);
    resize: none;
    overflow: auto;
  }

  &-btn {
    position: absolute;
    right: 4px;
    bottom: 10px;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 27px;
    height: 27px;
    padding: 4px 3px;
    border: none;
    border-radius: 50%;
    background-color: var(--col-info);

    &:disabled {
      background-color: var(--col-info-bg);
      cursor: unset;
    }
  }
}
</style>
