<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 520 520"
  >
    <path d="m475 125.016v-35.016c0-24.813-20.187-45-45-45h-210.599l-7.859-12.834c-2.727-4.452-7.571-7.166-12.792-7.166h-153.75c-24.813 0-45 20.187-45 45v380c0 24.813 20.187 45 45 45h430c24.813 0 45-20.187 45-45v-279.984c0-24.813-20.187-45-45-45zm-45-50.016c8.271 0 15 6.729 15 15v35h-176.606l-20.075-32.781-10.546-17.219zm60 375c0 8.271-6.729 15-15 15h-430c-8.271 0-15-6.729-15-15v-380c0-8.271 6.729-15 15-15h145.347l7.858 12.831c.001.001.001.002.002.003l28.917 47.219 20.085 32.797c2.727 4.452 7.571 7.166 12.792 7.166h215c8.271 0 15 6.729 15 15v279.984z" />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 24 },
    height: { type: [Number, String], default: 24 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
