<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3348:16574)">
      <path
        d="M22.7194 5.73369C22.2642 5.24483 21.6355 4.95596 20.9201 4.95596H17.3213V4.91152C17.3213 4.35599 17.1046 3.82269 16.736 3.46715C16.3675 3.0894 15.8689 2.86719 15.3269 2.86719H8.82318C8.25952 2.86719 7.76091 3.0894 7.39236 3.46715C7.02382 3.84491 6.80703 4.35599 6.80703 4.91152V4.95596H3.23C2.51459 4.95596 1.8859 5.24483 1.43064 5.73369C0.975381 6.20033 0.671875 6.86696 0.671875 7.57803V18.6441C0.671875 19.3774 0.953702 20.0218 1.43064 20.4884C1.8859 20.9551 2.53627 21.2662 3.23 21.2662H20.9201C21.6355 21.2662 22.2642 20.9773 22.7194 20.4884C23.1747 20.0218 23.4782 19.3552 23.4782 18.6441V7.57803C23.4782 6.84474 23.1964 6.20033 22.7194 5.73369ZM22.3509 18.6441H22.3292C22.3292 19.0441 22.1774 19.3996 21.9173 19.6662C21.6571 19.9329 21.3103 20.0884 20.9201 20.0884H3.23C2.83977 20.0884 2.49291 19.9329 2.23276 19.6662C1.97261 19.3996 1.82086 19.0441 1.82086 18.6441V7.57803C1.82086 7.17806 1.97261 6.82252 2.23276 6.55587C2.49291 6.28922 2.83977 6.13367 3.23 6.13367H7.41404C7.73923 6.13367 7.99938 5.86702 7.99938 5.5337V4.8893C7.99938 4.64487 8.08609 4.42266 8.23785 4.26711C8.3896 4.11156 8.60639 4.02268 8.84486 4.02268H15.3269C15.5653 4.02268 15.7821 4.11156 15.9339 4.26711C16.0856 4.42266 16.1724 4.64487 16.1724 4.8893V5.5337C16.1724 5.86702 16.4325 6.13367 16.7577 6.13367H20.9417C21.332 6.13367 21.6788 6.28922 21.939 6.55587C22.1991 6.82252 22.3509 7.17806 22.3509 7.57803V18.6441Z"
        :fill="iconColor"/>
      <path
        d="M12.0752 7.64478C10.6011 7.64478 9.25695 8.26696 8.30308 9.24469C7.32752 10.2446 6.74219 11.6001 6.74219 13.1111C6.74219 14.6222 7.3492 15.9999 8.30308 16.9776C9.27863 17.9775 10.6011 18.5775 12.0752 18.5775C13.5494 18.5775 14.8935 17.9553 15.8474 16.9776C16.8229 15.9776 17.4083 14.6222 17.4083 13.1111C17.4083 11.6001 16.8012 10.2224 15.8474 9.24469C14.8935 8.26696 13.5494 7.64478 12.0752 7.64478ZM15.0236 16.1554C14.2648 16.9109 13.2242 17.3998 12.0752 17.3998C10.9262 17.3998 9.88564 16.9109 9.12688 16.1554C8.36811 15.3777 7.91285 14.3111 7.91285 13.1334C7.91285 11.9556 8.38979 10.889 9.12688 10.1113C9.88564 9.33357 10.9262 8.86693 12.0752 8.86693C13.2242 8.86693 14.2648 9.35579 15.0236 10.1113C15.7823 10.889 16.2376 11.9556 16.2376 13.1334C16.2593 14.3111 15.7823 15.3777 15.0236 16.1554Z"
        :fill="iconColor"/>
      <path
        d="M19.7947 9.79997C20.3814 9.79997 20.857 9.31249 20.857 8.71114C20.857 8.1098 20.3814 7.62231 19.7947 7.62231C19.208 7.62231 18.7324 8.1098 18.7324 8.71114C18.7324 9.31249 19.208 9.79997 19.7947 9.79997Z"
        :fill="iconColor"/>
    </g>
    <defs>
      <clipPath id="clip0_3348:16574">
        <rect width="22.8063" height="23.3765" fill="white" transform="translate(0.671875 0.378418)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 24 },
    height: { type: [Number, String], default: 24 },
    iconColor: { type: String, default: "#17A2B8" },
  },
};
</script>
