<template>
  <!-- <div class="breadcrumbs-wrapper">
    <p><router-link to="">Project Tracking</router-link> /</p>
  </div> -->

  <div class="tracking-wrapper">
    <div class="project-search">
      <div
        v-if="
          allProjects && allProjects.length && project && project.project_number
        "
        style="margin: 0 10px"
      >
        <h4 class="green">Choose project:</h4>

        <div class="project-id-list">
          <button
            v-for="item in allProjects"
            class="project-id"
            :class="{ active: project.id == item.id }"
            :key="`ALL_${item.id}`"
            @click="showProjectTrackingDetails(item.id)"
          >
            {{ item.project_number }} (
            <span style="font-size: 12px">#{{ item.feasibility_number }}</span>
            )
          </button>
        </div>
      </div>

      <div class="filters-list">
        <div class="filters_select">
          <h4 class="green">Set filters:</h4>

          <p class="grey">Date range or exact date:</p>
          <Datepicker
            v-model="filters.dateFrom"
            :range="true"
            format="MM-dd-yyyy"
            :maxDate="new Date()"
            :enableTimePicker="false"
          />
          <!-- :lowerLimit="today"
                :disabled="!userValues.to_collect || isProposalSent" -->
        </div>

        <div
          v-if="procurementSites && procurementSites.length"
          class="filters_select"
        >
          <p class="grey">TSS:</p>
          <select v-model="filters.tss">
            <option value="">Unselected</option>
            <option
              v-for="tss in procurementSites"
              :key="`ALL_${tss.id}`"
              :value="tss.id"
            >
              <!-- :selected="project.id == tss.id" -->
              {{ tss.title }}
            </option>
          </select>
        </div>

        <div v-if="categories && categories.length" class="filters_select">
          <p class="grey">Pathology:</p>
          <select v-model="filters.pathology">
            <option value="">Unselected</option>
            <option
              v-for="category in categories"
              :key="`ALL_${category.id}`"
              :value="category.id"
            >
              <!-- :selected="project.id == tss.id" -->
              {{ category.title }}
            </option>
          </select>
        </div>

        <div v-if="stages && stages.length" class="filters_select">
          <p class="grey">Stage:</p>
          <select v-model="filters.stage">
            <option value="">Unselected</option>
            <option
              v-for="stage in stages"
              :key="`stage_${stage.type}`"
              :value="stage.type"
            >
              <!-- :selected="project.id == tss.id" -->
              {{ stage.name }}
            </option>
          </select>
        </div>

        <BaseButton
          className="info"
          text="Search"
          style="
            width: calc(50% - 20px);
            padding: 3px 25px;
            margin-left: 10px;
            margin-top: 10px;
          "
          @click="searchProjects"
        ></BaseButton>
      </div>
    </div>

    <hr style="margin-top: 0; margin-bottom: 10px" />

    <h4 class="green" style="padding-left: 10px">Project base information:</h4>

    <div v-if="project" class="tracking-main-data">
      <div class="tracking-main-data-item">
        <IconEducation icon-color="#17A2B8" />
        <span class="tracking-main-data-item-text"
          >#{{ project.feasibility_number }} /
          {{ project.project_number }}</span
        >
      </div>

      <!-- <div class="tracking-main-data-item">
        <IconDocument  icon-color="#17A2B8" />
        <span class="tracking-main-data-item-text">{{
          project.client_company
        }}</span>
      </div> -->

      <div class="tracking-main-data-item">
        <IconCalendar2 icon-color="#17A2B8" />
        <span class="tracking-main-data-item-text">{{
          project.created_at.slice(0, 10)
        }}</span>
      </div>

      <div class="tracking-main-data-item">
        <IconTrade icon-color="#17A2B8" />
        <span class="tracking-main-data-item-text">{{
          project.client_company
        }}</span>
      </div>
    </div>

    <div class="tracking-container">
      <div class="tracking">
        <TrackingColumnInReview title="TSS proposals" :cards="inReview" />
        <TrackingColumnOrdered title="Accepted proposals" :cards="ordered" />
        <TrackingColumnSent title="In transit" :cards="shipped" />
        <TrackingColumnReceived title="Received" :cards="received" />
        <TrackingColumnQC title="QC Complete" :cards="passedQC" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

// import Datepicker from "vue3-datepicker";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import TrackingColumnInReview from "../../components/TrackingColumnInreview.vue";
import TrackingColumnOrdered from "../../components/TrackingColumnOrdered.vue";
import TrackingColumnSent from "../../components/TrackingColumnSent.vue";
import TrackingColumnReceived from "../../components/TrackingColumnReceived.vue";
import TrackingColumnQC from "../../components/TrackingColumnQC.vue";
import BaseButton from "@/modules/components/base/BaseButton";
import IconEducation from "@/modules/components/icons/IconEducation";
// import IconDocument from "@/modules/components/icons/IconDocument";
import IconCalendar2 from "@/modules/components/icons/IconCalendar2";
import IconTrade from "@/modules/components/icons/IconTrade";
import convertDateFormat from "@/modules/helpers/convertDateFormat.js";

export default {
  components: {
    Datepicker,
    TrackingColumnInReview,
    TrackingColumnOrdered,
    TrackingColumnSent,
    TrackingColumnReceived,
    TrackingColumnQC,
    // IconDocument,
    BaseButton,
    IconEducation,
    IconCalendar2,
    IconTrade,
  },
  setup() {
    const store = useStore();
    store.dispatch("projects/loadTrackingProject");

    const filters = ref({
      tss: null,
      pathology: null,
      dateFrom: null,
      stage: null,
    });
    const stages = ref([
      // { name: "Draft", type: "draft" },
      { name: "Feasibility", type: "feasibility" },
      { name: "Active", type: "project" },
      // { name: "Archive", type: "archive" },
    ]);
    const trackingProject = computed(
      () => store.getters["projects/trackingProject"]
    );
    const procurementSites = computed(
      () => store.getters["projects/procurementSites"]
    );
    const categories = computed(
      () => store.getters["projects/projectCategories"]
    );

    const allProjects = computed(
      () => store.getters["projects/filteredTrackingProjects"]
    );
    const project = computed(() => trackingProject.value?.project || null);
    const inReview = computed(() => trackingProject.value?.onReview);
    const ordered = computed(() => trackingProject.value?.accepted);
    const shipped = computed(() => trackingProject.value?.shipped);
    const received = computed(() => trackingProject.value?.received);
    const passedQC = computed(() => trackingProject.value?.passedQC);
    const searchTerm = ref("");

    const searchProjects = () => {
      const dateFrom = filters.value.dateFrom;

      let params = {};

      if (dateFrom && dateFrom.length) {
        let startRange = dateFrom[0];
        let endRange = dateFrom[1] ? dateFrom[1] : dateFrom[0];

        params.start_date = convertDateFormat.getYyyyMmDd(startRange);
        params.end_date = convertDateFormat.getYyyyMmDd(endRange);
      }
      if (filters.value.tss) {
        params.ps_id = filters.value.tss;
      }
      if (filters.value.pathology) {
        params.c = filters.value.pathology;
      }
      if (filters.value.stage) {
        params.s = filters.value.stage;
      }

      store.dispatch("projects/getFilteredTrackingProject", params);
    };

    const showProjectTrackingDetails = (id) => {
      store.dispatch("projects/getTrackingProject", id);
    };

    return {
      filters,
      stages,
      trackingProject,
      procurementSites,
      categories,
      allProjects,
      project,
      inReview,
      ordered,
      shipped,
      received,
      passedQC,
      searchTerm,
      searchProjects,
      showProjectTrackingDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
.project-search {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  margin: 5px 0;

  & > div {
    flex-basis: 50%;
  }

  input {
    width: 300px;
  }
}
.project-id {
  &-list {
    max-height: 160px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 5px 0;
    border: 1px solid var(--col-border);
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  text-align: left;

  &.active {
    border-radius: 4px;
    background-color: var(--col-info-bg);
  }

  &:hover {
    border-bottom: 1px solid var(--col-info-bg);
  }
}
.filters-list {
  border-left: 1px dotted var(--col-border);

  &:nth-child(1) {
    width: 70%;
  }
}
.filters_select {
  width: 50%;
  margin: 0 0 5px;
  padding: 0 10px;

  &:nth-child(1) {
    width: 100%;
  }

  select {
    width: 100%;
  }
}
.tracking {
  &-wrapper {
    position: relative;
    // height: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
  }

  &-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 20px;
    min-height: calc(100vh - 340px);
    max-height: 100vh;
    overflow: hidden;
  }

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  overflow: auto;

  &-main-data {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    padding: 5px 15px 0;

    &-item {
      padding-right: 20px;
      &-text {
        position: relative;
        top: -3px;
        padding-left: 10px;
      }
    }
  }
}
</style>

<style lang="scss">
.filters_select {
  .dp__input_wrap input {
    // width: 400px;
    padding: 2px 35px;
    border: 1px solid var(--col-border);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    background-color: transparent;
    color: var(--col-text-secondary);
    appearance: none;
  }
}
.column {
  min-width: 290px;
  // overflow-y: auto;
  flex-basis: 100%;
  height: 100%;
  border: 1px solid var(--col-secondary-btn);
  padding: 5px;
  border-radius: 14px;
  background-color: var(--col-bg-primary);

  &-wrapper {
    min-height: 100%;
    margin: 5px;
  }

  &-bg {
    height: 100%;
    padding: 5px;
    border: none;
    border-radius: 14px;
    background-color: var(--col-bg-secondary);
  }

  &-title {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 24px;
    font-family: "OpenSans-Semibold";
    text-align: center;
    color: var(--col-info);
  }

  &-scroll {
    max-height: 80vh;
    overflow: auto;
  }

  &-empty {
    height: auto;
  }

  &-card {
    margin: 10px 0;
    border: 1px solid var(--col-secondary-btn);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 1px 2px 10px #00000014, -1px 2px 10px #00000014;

    &-head {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      position: relative;
      padding: 35px 12px 8px;
    }

    &-code {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      line-height: 1.9;
      font-weight: 700;
      // color: var(--col-additional);
      font-family: "SFProDisplay-Medium";
      border-radius: 4px;

      &-prefix {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: auto;
        min-width: 120px;

        padding: 2px 8px;
        color: var(--col-contrast-text);
        background-color: var(--col-info);
        // width: 20px;
        // height: 20px;
        // margin-right: 8px;
        // border-radius: 4px;
        span {
          padding-left: 4px;
        }
      }
    }

    &-date {
      font-size: 10px;
      color: var(--col-primary-dark);
      font-family: "SFProDisplay-Thin";
    }

    &-body {
      padding: 8px 12px 15px;
      color: #000;

      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        h3,
        h4,
        p {
          &:first-of-type {
            flex-basis: 40%;
          }
        }
        &.short {
          h3,
          h4,
          p {
            &:first-of-type {
              flex-basis: 10%;
            }
          }
        }
      }

      &-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.9;
        color: var(--col-primary-dark);
        font-family: "SFProDisplay-Medium";
      }

      &-quantity {
        font-size: 14px;
        line-height: 1.9;
        color: var(--col-attention);
        font-family: "SFProDisplay-Medium";
      }

      &-lineid {
        font-size: 12px;
        line-height: 1.9;
        color: var(--col-secondary);
      }

      &-transfercode {
        font-size: 12px;
        line-height: 1.9;
        font-style: italic;
        color: var(--col-primary-dark);
      }

      &-site {
        font-size: 14px;
        line-height: 1.9;
        font-weight: 700;
        color: var(--col-info);
        font-family: "SFProDisplay-Medium";
      }
    }
  }
}
</style>
