const setCookie = (name,value,days) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
  
const getCookie = (name) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
}
  
const eraseCookie = (name) => {   
    document.cookie = name+'=; Max-Age=-99999999;';  
}

export default { setCookie, getCookie, eraseCookie };