<template>
  <div class="project-base" id="qwe">
    <div class="project-base-body">
      <div style="padding: 20px 30px 0">
        <div class="project-base-body-top">
          <div>
            <div class="project-base-item">
              <IconTrade icon-color="#17A2B8" />
              <p>{{ project.client_company || "-" }}</p>
            </div>
            <div class="project-base-item">
              <IconPerson icon-color="#17A2B8" />
              <p>{{ project.contact_name || "-" }}</p>
            </div>
            <div class="project-base-item">
              <IconEducation icon-color="#17A2B8" />
              <p>{{ project.study_name || "-" }}</p>
            </div>
          </div>

          <div class="project-base-item_center">
            <p class="project-base-heading">{{ type }}</p>
            <h3 class="green">#{{ project.project_number }}</h3>
            <h3
              v-if="
                project.feasibility_number &&
                project.feasibility_number !== project.project_number
              "
              class="green"
            >
              #{{ project.feasibility_number }}
            </h3>
          </div>

          <div class="3">
            <div
              v-if="project.created_at"
              class="project-base-item project-base-item_right"
            >
              <p>{{ project.created_at.slice(0, 10) }}</p>
              <IconCalendar2 icon-color="#17A2B8" />
            </div>
            <div class="project-base-item project-base-item_right">
              <p>{{ project.contact_phone || "-" }}</p>
              <IconTelephone icon-color="#17A2B8" />
            </div>
            <div class="project-base-item project-base-item_right">
              <p>{{ project.contact_email || "-" }}</p>
              <IconMail icon-color="#17A2B8" />
            </div>
          </div>
        </div>

        <hr class="dashed" />

        <div class="project-base-body-bottom">
          <div>
            <h4 class="green">Pathology:</h4>

            <div v-if="chosenCategories && chosenCategories.length">
              <div v-for="category in chosenCategories" :key="category">
                {{ category.title }} /
              </div>
            </div>
          </div>

          <div>
            <h4 class="green">Visibility:</h4>

            <div class="line">
              <div v-if="project.visibility == 'all'">
                <span>Anyone</span>
              </div>
              <div v-else-if="project.visibility == 'procurement-sites'">
                <span>Selected Procurement Sites</span>
              </div>
              <div v-else>
                <span>Tissue bank only</span>
              </div>
            </div>
          </div>

          <div class="icon-button-wrapper">
            <button
              v-if="!project.is_archived"
              class="icon-button"
              @click="goToProposals"
            >
              <IconLogin icon-color="#17A2B8" />
            </button>
            <button
              v-if="project.status !== 'project' && !project.is_archived"
              class="icon-button"
              @click="goToEdit"
            >
              <IconEdit icon-color="#17A2B8" />
            </button>
            <button class="icon-button" @click="printDocument">
              <IconPrinter icon-color="#17A2B8" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="project-base-footer">
      <p class="project-base-footer-text">General Information</p>
    </div>
  </div>
  <PrintedDocument :categories="chosenCategories" />
  <hr />
</template>

<script>
import { computed, toRefs } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import PrintElem from "@/modules/helpers/printDocument.js";
import PrintedDocument from "@/components/print/PrintDocument.vue";

import IconPerson from "@/modules/components/icons/IconPerson";
import IconEducation from "@/modules/components/icons/IconEducation";
import IconMail from "@/modules/components/icons/IconMail";
import IconCalendar2 from "@/modules/components/icons/IconCalendar2";
import IconPrinter from "@/modules/components/icons/IconPrinter";
import IconTelephone from "@/modules/components/icons/IconTelephone";
import IconTrade from "@/modules/components/icons/IconTrade";
import IconLogin from "@/modules/components/icons/IconLogin";
import IconEdit from "@/modules/components/icons/IconEdit";

export default {
  components: {
    PrintedDocument,
    IconEdit,
    IconPerson,
    IconEducation,
    IconMail,
    IconCalendar2,
    IconPrinter,
    IconTelephone,
    IconTrade,
    IconLogin,
  },
  props: {
    project: {
      type: Object,
    },
    type: {
      type: String,
      default: "Project",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { project } = toRefs(props);

    const categories = computed(() => store.getters["projects/categories"]);
    const chosenCategories = computed(() =>
      categories.value.filter((el) => el.isChosen)
    );

    const goToEdit = () => {
      router.push({
        name: "ProjectEdit",
        params: { id: project.value.id },
      });
    };

    const goToProposals = () => {
      store.dispatch("proposals/seenProposal", project.value.id);

      if (project.value.status === "project") {
        router.push({
          name: "ProjectActiveDetails",
          params: { id: project.value.id },
        });
      } else {
        router.push({
          name: "ProjectFeasibilityDetails",
          params: { id: project.value.id },
        });
      }
    };

    const printDocument = () => PrintElem("print");

    return {
      chosenCategories,
      goToEdit,
      goToProposals,
      printDocument,
    };
  },
};
</script>

<style lang="scss" scoped>
.project {
  &-base {
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: var(--col-bg-primary);
    overflow: hidden;

    &-heading {
      margin-bottom: 5px;
      padding: 7px 30px;
      border-radius: 4px;
      background-color: var(--col-info);
      color: #fff;
    }

    &-body-top {
      display: flex;
      justify-content: space-between;

      > div:nth-child(1),
      > div:nth-child(3) {
        flex: 1;
      }
      > div:nth-child(2) {
        text-align: center;
      }
    }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        padding: 7px 10px;
      }
      &_right {
        justify-content: flex-end;
      }
      &_center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &-body-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 0;

      > div {
        flex-basis: 30%;
      }
      > div:nth-child(2) {
        text-align: center;
      }

      .icon-button {
        &-wrapper {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
        }

        padding: 5px 0 10px 10px;
        margin-left: 20px;
        border: none;
        background-color: transparent;
      }
    }

    &-footer {
      //   position: relative;
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: stretch;
      padding: 3px;
      background-color: var(--col-info);
      text-align: center;

      &-text {
        font-size: 16px;
        font-weight: bold;
        color: var(--col-contrast-text);
      }

      .icon-button {
        border: none;
        background-color: transparent;
      }
    }
  }
}
</style>
