<template>
  <svg :width="width" :height="height" :fill="iconColor" viewBox="0 0 21 20">
    <path
      d="M0.293151 5.33261C0.293151 4.18771 0.293151 3.0437 0.293151 1.8997C0.293151 0.736907 1.02318 0.00434911 2.18338 0.00166575C4.02779 -0.00250837 5.87042 -0.00250837 7.71127 0.00166575C8.83844 0.00166575 9.57205 0.744957 9.57383 1.88002C9.578 4.19844 9.578 6.51687 9.57383 8.83529C9.57383 9.96857 8.83577 10.7074 7.70681 10.7136C5.8499 10.719 3.99239 10.719 2.13429 10.7136C1.03478 10.7136 0.299399 9.96409 0.294044 8.85766C0.287797 7.68234 0.293151 6.50792 0.293151 5.33261ZM1.72109 5.35676C1.72109 6.45754 1.72109 7.55831 1.72109 8.65909C1.72109 9.23064 1.77017 9.2852 2.32349 9.2852C4.06319 9.2852 5.80319 9.2852 7.54349 9.2852C8.08342 9.2852 8.14411 9.22706 8.14411 8.7056C8.14411 6.47483 8.13876 4.24317 8.15125 2.0124C8.15125 1.59827 8.02274 1.42295 7.58722 1.42653C5.81717 1.44263 4.04743 1.44263 2.27798 1.42653C1.84157 1.42653 1.70859 1.59916 1.71484 2.01329C1.73358 3.12778 1.72109 4.24317 1.72109 5.35855V5.35676Z"
    />
    <path
      d="M20.2842 14.6645C20.2842 15.8094 20.2842 16.9534 20.2842 18.0974C20.2842 19.2602 19.5524 19.9936 18.3949 19.9963C16.5505 20.0011 14.7079 20.0011 12.867 19.9963C11.739 19.9963 11.0054 19.2521 11.0027 18.118C10.9991 15.7995 10.9991 13.4811 11.0027 11.1627C11.0027 10.0285 11.739 9.2906 12.8688 9.28434C14.7269 9.27957 16.5844 9.27957 18.4413 9.28434C19.5417 9.28434 20.2771 10.0321 20.2825 11.1394C20.2896 12.3165 20.2842 13.491 20.2842 14.6645ZM18.8563 14.6859C18.8563 13.5703 18.8563 12.4549 18.8563 11.3398C18.8563 10.7665 18.8072 10.7137 18.2548 10.7137C16.5145 10.7137 14.7745 10.7137 13.0348 10.7137C12.4931 10.7137 12.4333 10.7709 12.4333 11.2924C12.4333 13.5232 12.4386 15.7548 12.4261 17.9856C12.4261 18.3988 12.5529 18.5759 12.9893 18.5724C14.7593 18.5563 16.5291 18.5563 18.2985 18.5724C18.734 18.5724 18.8688 18.4006 18.8626 17.9856C18.8438 16.8872 18.8563 15.787 18.8563 14.6859V14.6859Z"
    />
    <path
      d="M15.6673 0.00244301C16.5892 0.00244301 17.5102 0.00244301 18.4339 0.00244301C19.5397 0.00959865 20.2813 0.743945 20.2866 1.84681C20.2938 3.24335 20.2938 4.63989 20.2866 6.03643C20.2804 7.10978 19.5325 7.85128 18.4544 7.85575C16.5821 7.86291 14.71 7.86291 12.8382 7.85575C11.7619 7.85575 11.0149 7.1062 11.0078 6.03464C11 4.622 11 3.21025 11.0078 1.7994C11.0158 0.753784 11.7663 0.0104926 12.8177 0.00333698C13.7699 -0.00471311 14.7168 0.00244301 15.6673 0.00244301ZM15.5995 6.4282C16.4919 6.4282 17.3844 6.41568 18.2768 6.43446C18.6918 6.44341 18.8694 6.30656 18.8632 5.87096C18.8453 4.57698 18.8453 3.283 18.8632 1.98903C18.8694 1.55253 18.69 1.42284 18.2768 1.42641C16.5223 1.44073 14.7671 1.44073 13.0113 1.42641C12.5972 1.42641 12.4196 1.55343 12.4259 1.98992C12.4437 3.2833 12.4437 4.57698 12.4259 5.87096C12.4196 6.30835 12.5981 6.44341 13.0122 6.43446C13.8743 6.41479 14.7373 6.4282 15.5995 6.4282Z"
    />
    <path
      d="M4.90781 20.0019C3.9868 20.0019 3.06578 20.0072 2.14119 20.0019C1.03811 19.9938 0.297373 19.2559 0.292018 18.1539C0.285473 16.7717 0.285473 15.3898 0.292018 14.0081C0.29648 12.8829 1.03722 12.1503 2.17332 12.1477C4.01773 12.1435 5.86007 12.1435 7.70032 12.1477C8.83195 12.1477 9.56734 12.8865 9.57448 14.0171C9.58043 15.385 9.58043 16.752 9.57448 18.1181C9.57002 19.2747 8.83641 19.9965 7.66997 20.0019C6.74985 20.0019 5.82705 20.0019 4.90781 20.0019ZM4.91227 18.5707C5.78986 18.5707 6.66715 18.5707 7.54413 18.5707C8.07961 18.5707 8.14476 18.5126 8.14565 17.992C8.14565 16.713 8.13583 15.4339 8.15279 14.1548C8.15815 13.7407 8.02517 13.5645 7.58876 13.5681C5.8199 13.5842 4.05016 13.5815 2.28042 13.5681C1.88684 13.5681 1.71192 13.6933 1.71727 14.1047C1.73274 15.4136 1.73274 16.7225 1.71727 18.0314C1.71281 18.4455 1.88506 18.5797 2.27952 18.5681C3.15681 18.5564 4.0341 18.5707 4.91227 18.5707Z"
    />
    <!-- <clipPath id="clip0_5189_43424">
      <rect
        width="20"
        height="20"
        fill="white"
        transform="translate(0.290039)"
      />
    </clipPath> -->
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 21 },
    height: { type: [Number, String], default: 20 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
