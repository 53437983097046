<template>
  <svg :width="width" :height="height" :fill="iconColor" viewBox="0 0 14 14">
    <path
      d="M13.2794 0.394043C13.7058 0.394043 13.9222 0.732791 13.7853 1.12581C13.4307 2.14518 13.0693 3.16267 12.7098 4.18266C11.6886 7.06983 10.6654 9.95595 9.64044 12.841C9.58019 13.0251 9.47004 13.189 9.32219 13.3145C9.07209 13.5017 8.75946 13.3464 8.58814 12.9752C8.04125 11.8106 7.49561 10.6443 6.95122 9.47601C6.82617 9.20339 6.70862 8.9239 6.56544 8.66001C6.47853 8.49968 6.51354 8.40423 6.63484 8.2857C7.32512 7.60696 8.01061 6.92364 8.69131 6.23575C8.99268 5.93193 8.94141 5.56823 8.58439 5.39043C8.33428 5.26567 8.14671 5.36673 7.97164 5.54327C7.30262 6.21329 6.62547 6.87581 5.96457 7.55456C5.81451 7.70927 5.70134 7.72487 5.51001 7.63441C4.09985 6.96689 2.68657 6.30686 1.27016 5.65432C1.02006 5.53766 0.807471 5.40166 0.827479 5.08662C0.846862 4.78405 1.0732 4.67925 1.3183 4.59253L13.0293 0.47015C13.1269 0.432096 13.2275 0.409015 13.2794 0.394043Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 14 },
    height: { type: [Number, String], default: 14 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
