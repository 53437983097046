<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 24 24"
  >
    <path
      d="M12.0185 0.121582C9.79056 0.121582 7.62219 0.74851 5.74767 1.93455C3.92483 3.08791 2.45466 4.71705 1.49609 6.64577L3.3252 7.55484C4.97776 4.2297 8.3089 2.16414 12.0185 2.16414C17.3683 2.16414 21.7206 6.51648 21.7206 11.8663C21.7206 17.216 17.3683 21.5684 12.0185 21.5684C8.3089 21.5684 4.97776 19.5028 3.3252 16.1777L1.49609 17.0868C2.45466 19.0155 3.92483 20.6446 5.74767 21.798C7.62219 22.984 9.79056 23.6109 12.0185 23.6109C18.4946 23.6109 23.7632 18.3423 23.7632 11.8663C23.7632 5.39021 18.4946 0.121582 12.0185 0.121582Z"
    />
    <path
      d="M7.20982 15.2293L8.65404 16.6735L13.4613 11.8663L8.65404 7.05908L7.20982 8.5033L9.55147 10.845H0.761719V12.8876H9.55147L7.20982 15.2293Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 24 },
    height: { type: [Number, String], default: 24 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
