<template>
  <AddUserModal v-if="showAddUserModal" @close="showAddUserModal = false" />

  <EditCompanyModal
    v-else-if="showEditCompanyModal"
    :company="site"
    @close="showEditCompanyModal = false"
  />

  <EditUserModal
    v-else-if="showEditUserModal"
    :user="choosenUser"
    @close="showEditUserModal = false"
  />

  <div v-else-if="site && Object.keys(site).length" class="site-page">
    <div class="breadcrumbs-wrapper">
      <p>
        <router-link to="/settings">Settings</router-link>
        / {{ site.title }}
      </p>
    </div>

    <div class="site">
      <button v-if="false" @click="deleteSite(site)">Delete this site</button>

      <div class="site-header">
        <h2 class="site-header-title">{{ site.title }}</h2>

        <div class="site-header-activity">
          <div class="site-header-toggler">
            <h4 class="site-header-toggler-title">Active</h4>
            <div>
              <input
                type="checkbox"
                class="toggle-button"
                v-model="isActive"
                @change="setCompanyStatus"
              />
            </div>
          </div>

          <button class="site-header-edit-btn" @click="editCompany">
            <IconPencil />
          </button>
        </div>
      </div>

      <div class="site-note">
        <h4 v-if="site.description_short">{{ site.description_short }}</h4>
        <p v-if="site.description">{{ site.description }}</p>
      </div>

      <div
        class="site-content"
        v-if="site && site.projects && site.projects.length"
      >
        <h3 class="site-content-title">Projects activity:</h3>

        <div class="site-content-body">
          <div class="site-content-card">
            <CardMiniDescription
              v-for="project in site.projects"
              :key="project"
              :cardData="project"
              :is-admin="true"
              @click="
                goToProjectDetails({ id: project.id, status: project.status })
              "
            />
          </div>
        </div>
      </div>
    </div>

    <div class="site-sidebar">
      <div class="site-avatar"><img :src="site.avatar" /></div>
      <div class="user-block">
        <div class="contacts">
          <p class="title-contacts">Contacts:</p>
          <button class="btn btn-add-user" @click="showAddUserModal = true">
            <IconUserAdd />
          </button>
        </div>
        <!--<div class="user-preview text-center" v-for="user in site.users" :key="user">-->
        <div class="user-preview text-center" v-for="user in users" :key="user">
          <div class="user-preview-info">
            <button class="btn btn-edit" @click="initUserEditing(user)">
              <IconPencil width="15" height="15" />
            </button>
            <button class="btn btn-delete" @click="deleteUser(user.id)">
              <IconClose width="12" height="12" />
            </button>
            <h4 class="user-name">{{ user.name }}</h4>
            <p v-if="user.role" class="user-description">Role: "{{ user.role }}"</p>
            <p class="user-description">{{ user.email }}</p>
          </div>
        </div>
        <!--<template v-if="site.users.length == 0"
          ><p class="text-center">No users yet!!!</p></template
        >-->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import EditCompanyModal from "@/components/psites/EditCompanyModal.vue";
import AddUserModal from "@/components/psites/AddUserModal.vue";
import EditUserModal from "@/components/psites/EditUserModal.vue";
import CardMiniDescription from "@/modules/components/base/CardMiniDescription.vue";
import IconUserAdd from "@/modules/components/icons/IconUserAdd";
import IconClose from "@/modules/components/icons/IconClose";
import IconPencil from "@/modules/components/icons/IconPencil";

export default {
  components: {
    EditCompanyModal,
    AddUserModal,
    EditUserModal,
    CardMiniDescription,
    IconUserAdd,
    IconClose,
    IconPencil,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isActive = ref(true);
    const showEditCompanyModal = ref(false);
    const showAddUserModal = ref(false);
    const showEditUserModal = ref(false);

    const site = computed(() => store.getters["site/site"]);
    const users = computed(() => store.getters["user/users"]);
    const choosenUser = ref({});

    onMounted(async () => {
      await store.dispatch("site/loadSite", { id: route.params.id });
      if (!site.value) {
        router.push("/procurement-site-list");
      }
      await store.dispatch("user/fetchUsers", { id: route.params.id });
      isActive.value = !!site.value.is_active;
    });

    const editCompany = () => {
      showEditCompanyModal.value = true;
    };

    const deleteUser = (id) => store.dispatch("user/deleteUser", id);

    const initUserEditing = (user) => {
      choosenUser.value = user;
      showEditUserModal.value = true;
    };

    const setCompanyStatus = () => {
      // let company = site.value;
      let status = isActive.value ? 1 : 0;
      store.dispatch("site/editSite", { is_active: status, id: site.value.id });
    };

    const deleteSite = async (site) => {
      await store.dispatch("site/deleteSite", site);
      router.push("/procurement-site-list");
    };

    const goToProjectDetails = ({ id, status }) => {
      let name = "";

      switch (status) {
        case "draft":
          name = "ProjectEdit";
          break;
        case "feasibility":
          name = "ProjectFeasibility";
          break;
        case "project":
          name = "ProjectActive";
          break;
        case "archive":
          name = "ProjectArchive";
          break;
        default:
          name = "ProjectFeasibility";
      }

      router.push({
        name,
        params: { id: `${id}` },
      });
    };

    return {
      isActive,
      showEditCompanyModal,
      showAddUserModal,
      showEditUserModal,
      site,
      users,
      choosenUser,
      editCompany,
      setCompanyStatus,
      deleteUser,
      initUserEditing,
      deleteSite,
      goToProjectDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
.site {
  flex-basis: 100%;
  min-height: calc(100vh - 190px);
  padding: 23px 38px;
  background-color: var(--col-bg-secondary);

  &-page {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-title {
      flex-basis: 1;
      font-size: 32px;
      line-height: 38px;
      font-weight: 400;
      font-family: "SFProDisplay-Regular";
      color: var(--checkbox-border);
    }

    &-activity {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &-toggler {
      flex-basis: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-title {
        padding-right: 30px;
        font-size: 16px;
        font-family: "OpenSans-Regular";
        color: var(--col-success);
      }
    }

    &-edit-btn {
      display: flex;
      margin-left: 20px;
      padding-left: 10px;
      border: none;
      background-color: transparent;
      transform: rotate(-90deg);
    }
  }

  &-content {
    &-title {
      font-size: 24px;
      color: var(--col-primary);
      font-family: "OpenSans-Semibold";
    }

    &-card {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 30px;
    }
  }

  &-note {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 23px 0;
  }

  &-sidebar {
    min-width: 300px;
    max-width: 300px;
    margin-left: 15px;
    background-color: var(--col-bg-secondary);
  }

  &-avatar {
    margin: 30px 35px 20px;
  }
}

.toggle-button {
  position: relative;
  width: 60px;
  height: 35px;
  border: 4px solid var(--col-success);
  border-radius: 25px;
  outline: none;
  background: var(--col-bg-secondary);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);

  &::after {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--col-success);
    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  &:checked {
    background-color: var(--col-success);

    &::after {
      transform: translateX(calc(100% - 3px));
      background-color: var(--col-bg-secondary);
    }
  }
}

// ------------ //
.site-page {
  display: flex;
}
.content {
  flex: 1;
}
.site-projects {
  display: flex;
  flex-wrap: wrap;
}
.site-project-preview {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 15px;
  margin: 15px;
  max-width: 260px;
}

.user-block {
  padding: 10px;
}
.user-preview {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 15px 0px;
  padding: 24px 16px 16px;
  position: relative;

  &-info {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    overflow: hidden;
  }
}
.btn-add-user {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--col-additional);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    transition: 0.17s all;
  }

  &:hover {
    background: #1437ff;
    svg {
      transform: scale(1.1) rotate(10deg);
    }
  }
}
.contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn {
  &-delete,
  &-edit {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 15px;
    position: absolute;
    right: 4px;
    top: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 5px;

    svg {
      display: inline-block;
      transition: 0.17s all;
    }

    &:hover {
      background-color: var(--col-info-bg);

      svg {
        transform: scale(1.4) rotate(90deg);
      }
    }
  }
  &-edit {
    right: auto;
    left: 4px;
    transform: rotate(-90deg);
  }
}
</style>
