<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.3096 0.866211L22.6426 5.23119L22.6426 24.8662L6.3429 24.8662V17.2164L8.35527 19.3163V22.7796L20.6302 22.7796L20.6302 6.70979L16.8431 6.70979V2.95279L8.35525 2.95279V8.41271L14.3626 14.6813L16.1473 20.4186L10.6504 18.5548L1.88261 9.40579L5.59481 5.53221L6.3429 6.31283V0.866211L18.3096 0.866211ZM13.4335 15.6493L11.5781 17.5868L14.3273 18.518L13.4335 15.6493Z" />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 23 },
    height: { type: [Number, String], default: 24 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
