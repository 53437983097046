<template>
  <DeleteProjectModal
    v-if="showDeleteProjectModal"
    :project-id="project.id"
    project-type="feasibility"
    @close="showDeleteProjectModal = false"
  ></DeleteProjectModal>

  <ArchiveProjectModal
    v-if="showArchiveProjectModal"
    :project-id="project.id"
    project-type="feasibility"
    @close="showArchiveProjectModal = false"
  ></ArchiveProjectModal>

  <div
    v-if="project && Object.keys(project).length"
    class="feasibility-project-page"
  >
    <div class="feasibility-project-main">
      <div class="breadcrumbs-wrapper">
        <p>
          <router-link to="/pending-feasibility"
            >Pending Feasibility List</router-link
          >
          /
          <template v-if="project">#{{ project.project_number }}</template>
        </p>
      </div>

      <div>
        <ProjectGeneralInfo
          v-if="project"
          :project="project"
          type="Pending Feasibility"
        />

        <div class="project-main-section">
          <div class="field-container">
            <h3 class="green center">{{ project.description_short }}</h3>
            <hr />
          </div>

          <div class="project-main-section_basic-values">
            <div class="field-container">
              <div>
                <IconCalendar icon-color="#17a2b8" width="100" height="100" />
              </div>
              <!-- <h3 class="grey">Timeline</h3> -->
              <h3 class="grey">{{ project.timeline }}</h3>
            </div>
            <div class="field-container">
              <div>
                <IconPeople icon-color="#17a2b8" width="100" height="100" />
              </div>
              <!-- <h3 class="grey">#Donors</h3> -->
              <h3 class="grey">#Donors: {{ project.donors_number }}</h3>
            </div>
            <div class="field-container">
              <div>
                <IconTrade icon-color="#17a2b8" width="100" height="100" />
              </div>
              <h3 class="grey">$ {{ project.suggested_price }}</h3>
            </div>
          </div>

          <div class="field-container">
            <h3 class="center">
              <span class="grey">Indication: </span>
              <span class="green"> {{ project.indication }}</span>
            </h3>
          </div>
        </div>

        <div class="new-project-lines">
          <h3 class="green">Number of Specimens/Donors</h3>

          <div class="lines-wrapper">
            <div v-for="line in project.lines" :key="line.id">
              <StatisticLine
                :line="line"
                :project-id="projectId"
                project-type="feasibility"
              />
            </div>
          </div>
        </div>

        <div v-if="project?.note?.content" class="field-container yellow-bg">
          <h3 class="field-container-heading">Notes: Updates Requested</h3>
          <pre>{{ project.note.content }}</pre>
          <hr style="margin: 40px 0 10px" />
        </div>

        <div class="project-secondary-section">
          <div v-if="project.donor_characteristics" class="field-container">
            <h3 class="field-container-heading">Donor Characteristics</h3>
            <pre>
              {{ project.donor_characteristics }}
            </pre>
          </div>

          <div v-if="project.inclusion_criteria" class="field-container">
            <h3 class="field-container-heading">Inclusion Criteria</h3>
            <pre>
              {{ project.inclusion_criteria }}
            </pre>
          </div>

          <div v-if="project.exclusion_criteria" class="field-container">
            <h3 class="field-container-heading">Exclusion Criteria</h3>
            <pre>
              {{ project.exclusion_criteria }}
            </pre>
          </div>

          <div v-if="project.preservation" class="field-container">
            <h3 class="field-container-heading">Preservation/Procurement</h3>
            <pre>
              {{ project.preservation }}
            </pre>
          </div>

          <div v-if="project.clinical_info" class="field-container">
            <h3 class="field-container-heading">Clinical information</h3>
            <pre>
              {{ project.clinical_info }}
            </pre>
          </div>

          <AdditionalFields :is-editable="false" />
        </div>
      </div>
    </div>

    <!--sidebar-->

    <div>
      <div class="sidebar" v-if="project">
        <div v-if="project.public_note" class="field-container">
          <h3 class="green">Public note:</h3>
          <div class="green-border">
            {{ project.public_note }}
          </div>
        </div>

        <div v-if="project.private_note" class="field-container">
          <h3 class="green">Cureline only note:</h3>
          <div class="green-border">
            {{ project.private_note }}
          </div>
        </div>

        <FileUploading />

        <hr class="dashed" />

        <div class="chbx">
          <label>
            <input type="checkbox" v-model="saveToFeasibilitySwitch" />
            <span>Validate as project</span>
          </label>
        </div>

        <div v-if="saveToFeasibilitySwitch">
          <div class="request-number">
            <label>CUR - </label>
            <input
              type="text"
              class="request-number-field"
              v-model="projectNumber"
              @input="toUpperCase"
              required
            />
          </div>

          <BaseButton
            class-name="big typical"
            text="Save as a project"
            :disabled="!projectNumber"
            @click="saveToActive"
          />
        </div>

        <BaseButton
          v-else
          class-name="big typical"
          text="Save"
          @click="saveProject"
        />

        <div class="sidebar-buttons-wrapper">
          <BaseButton
            text="Delete"
            class-name="error"
            @click="showDeleteProjectModal = true"
          />

          <BaseButton
            text="Archive"
            class-name="additional-filled"
            @click="showArchiveProjectModal = true"
          />
        </div>

        <div class="sidebar-admin-data">
          <h6 class="green center">Validated by:</h6>
          <AdminSign />
        </div>
      </div>

      <div
        v-if="project && Object.keys(project).length && project.id"
        class="sidebar"
      >
        <ChatList :id="project.id" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import BaseButton from "@/modules/components/base/BaseButton.vue";
import AdminSign from "@/components/header/AdminSign.vue";
import ProjectGeneralInfo from "@/components/project/ProjectGeneralInfo.vue";
import StatisticLine from "@/components/project/StatisticLine.vue";
import FileUploading from "@/components/project/FileUploading.vue";
import AdditionalFields from "@/components/project/AdditionalFields.vue";
import DeleteProjectModal from "@/components/project/DeleteProjectModal.vue";
import ArchiveProjectModal from "@/components/project/ArchiveProjectModal.vue";
import ChatList from "@/components/chat/ChatList.vue";
import IconPeople from "@/modules/components/icons/IconPeople";
import IconCalendar from "@/modules/components/icons/IconCalendar";
import IconTrade from "@/modules/components/icons/IconTrade";

export default {
  components: {
    BaseButton,
    AdminSign,
    ProjectGeneralInfo,
    StatisticLine,
    FileUploading,
    AdditionalFields,
    DeleteProjectModal,
    ArchiveProjectModal,
    ChatList,
    IconPeople,
    IconCalendar,
    IconTrade,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const showDeleteProjectModal = ref(false);
    const showArchiveProjectModal = ref(false);

    const projectId = computed(() => route.params.id);
    const project = computed(() => store.getters["projects/project"]);
    const lines = computed(() => store.getters["projects/lines"]);
    const projectNumber = ref("");

    onMounted(() => {
      store.dispatch("projects/getProject", { id: projectId.value });
    });

    const addLine = () => {
      store.dispatch("projects/addNewLine", { project_id: project.value.id });
    };

    const removeLine = (id) => {
      store.dispatch("projects/removeLine", id);
    };

    // const visibility = ref("");
    const toUpperCase = () => {
      projectNumber.value = projectNumber.value.toUpperCase();
    };

    const validateProject = () => {
      const p = project.value;
      let payload = {
        isShown: true,
        text: "Files are saved",
        status: "error",
      };
      let errorsText = [];
      if (!p.id) {
        console.log("No project identifier", p);
      }
      if (!p.client_company) {
        errorsText.push("company name");
      }
      if (!p.contact_email) {
        errorsText.push("contact email");
      }
      if (!p.contact_name) {
        errorsText.push("contact name");
      }
      if (!p.contact_phone) {
        errorsText.push("contact phone");
      }
      if (!p.donors_number) {
        errorsText.push("donors number");
      }
      if (!p.description_short) {
        errorsText.push("feasibility title");
      }
      if (!p.indication) {
        errorsText.push("project indication");
      }

      if (errorsText.length) {
        payload.text = "Please do not forget: " + errorsText.join(", ");
        store.commit("admins/SET_NOTIFICATION", payload);

        setTimeout(() => {
          store.commit("admins/CLEAN_NOTIFICATION");
        }, 10000);

        return false;
      }

      return true;
    };

    const saveProject = async () => {
      if (!validateProject()) {
        return;
      }
      const dataForm = project.value;

      const requestData = {
        project_number: dataForm.project_number,
        id: dataForm.id,
        study_name: dataForm.study_name,
        client_company: dataForm.client_company,
        contact_name: dataForm.contact_name,
        contact_phone: dataForm.contact_phone,
        contact_email: dataForm.contact_email,
        indication: dataForm.indication,
        suggested_price: dataForm.suggested_price,
        donors_number: dataForm.donors_number.toString(),
        description_short: dataForm.description_short,
        timeline: dataForm.timeline,
        bio_material: dataForm.bio_material,
        donor_characteristics: dataForm.donor_characteristics,
        inclusion_criteria: dataForm.inclusion_criteria,
        exclusion_criteria: dataForm.exclusion_criteria,
        preservation: dataForm.preservation,
        clinical_info: dataForm.clinical_info,
        public_note: dataForm.public_note,
        private_note: dataForm.private_note,
        visibility: dataForm.visibility,
        status: "feasibility",
      };
      await store.dispatch("projects/saveProject", requestData).then(() => {
        router.push({
          path: "/pending-feasibility",
          query: { p: project.value.id },
        });
      });
    };

    const saveToActive = async () => {
      if (!validateProject()) {
        return;
      }
      const dataForm = project.value;

      const requestData = {
        project_number: projectNumber.value
          ? `CUR-${projectNumber.value}`
          : dataForm.project_number,
        id: dataForm.id,
        study_name: dataForm.study_name,
        client_company: dataForm.client_company,
        contact_name: dataForm.contact_name,
        contact_phone: dataForm.contact_phone,
        contact_email: dataForm.contact_email,
        indication: dataForm.indication,
        suggested_price: dataForm.suggested_price,
        donors_number: dataForm.donors_number.toString(),
        description_short: dataForm.description_short,
        timeline: dataForm.timeline,
        bio_material: dataForm.bio_material,
        donor_characteristics: dataForm.donor_characteristics,
        inclusion_criteria: dataForm.inclusion_criteria,
        exclusion_criteria: dataForm.exclusion_criteria,
        preservation: dataForm.preservation,
        clinical_info: dataForm.clinical_info,
        public_note: dataForm.public_note,
        private_note: dataForm.private_note,
        visibility: dataForm.visibility,
        status: "project",
      };

      await store.dispatch("active/saveToActive", requestData).then(() => {
        router.push({ name: "ActiveList" });
      });
    };

    const saveToFeasibilitySwitch = ref("");

    const switchToDetails = (lineId) => {
      router.push({
        name: `ProjectFeasibilityDetails`,
        path: `/pending-feasibility-details/${projectId.value}#line-${lineId}`,
        params: { id: projectId.value },
      });
    };

    return {
      project,
      projectId,
      lines,
      router,
      projectNumber,
      toUpperCase,
      addLine,
      removeLine,
      saveProject,
      saveToFeasibilitySwitch,
      saveToActive,
      showDeleteProjectModal,
      showArchiveProjectModal,
      switchToDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
// move to modules:
label,
input {
  font-size: 16px;
  line-height: 24px;
  font-family: "OpenSans-Regular", sans-serif;
  color: var(--col-text-secondary);
}
input {
  margin: 7px 0;
  padding: 6px 12px;
}
</style>
