<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 551.132 551.132"
  >
    <path
      d="m525.97 357.643c-46.33-26.637-99.039-41.794-153.635-45.853 11.183 5.004 22.433 9.894 33.024 15.982 12.314 7.087 22.187 17.315 29.448 29.181 25.95 7.207 50.903 17.275 74.023 30.56 4.844 2.792 7.855 8.393 7.855 14.633v80.093h-68.891v34.446h86.114c9.52 0 17.223-7.703 17.223-17.223v-97.316c.001-18.551-9.653-35.605-25.161-44.503z"
    />
    <path
      d="m388.187 357.643c-108.416-62.332-254.608-62.332-363.026 0-15.524 8.931-25.161 25.969-25.161 44.504v97.316c0 9.52 7.703 17.223 17.223 17.223h378.902c9.52 0 17.223-7.703 17.223-17.223v-97.333c0-18.518-9.637-35.556-25.161-44.487zm-9.284 124.597h-344.456v-80.093c0-6.24 3.011-11.841 7.871-14.633 98.022-56.344 230.692-56.344 328.714 0 4.861 2.792 7.871 8.393 7.871 14.616z"
    />
    <path
      d="m335.881 69.701c2.857-.299 5.687-.809 8.578-.809 47.481 0 86.114 38.634 86.114 86.114s-38.634 86.114-86.114 86.114c-2.889 0-5.719-.513-8.576-.812-7.308 11.035-16.254 20.732-26.065 29.549 11.216 3.454 22.79 5.708 34.641 5.708 66.469 0 120.56-54.09 120.56-120.56s-54.09-120.56-120.56-120.56c-11.853 0-23.427 2.254-34.643 5.708 9.811 8.819 18.757 18.513 26.065 29.548z"
    />
    <path
      d="m206.676 275.566c66.469 0 120.56-54.09 120.56-120.56s-54.09-120.56-120.56-120.56-120.56 54.09-120.56 120.56 54.091 120.56 120.56 120.56zm0-206.674c47.481 0 86.114 38.634 86.114 86.114s-38.634 86.114-86.114 86.114-86.114-38.634-86.114-86.114 38.634-86.114 86.114-86.114z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 24 },
    height: { type: [Number, String], default: 24 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
