<template>
  <div class="breadcrumbs-wrapper">
    <p><router-link to="">Quality Control</router-link> /</p>
  </div>

  <div class="track-wrapper">
    <div class="track">
      <div
        v-if="qualityControlList && qualityControlList.length"
        class="track-container"
      >
        <div style="width: 300px; margin-bottom: 20px">
          <div class="search-form">
            <div class="search-field">
              <input
                class="track-search"
                type="text"
                name="project"
                v-model="qcCode"
                @keyup.enter.prevent="makeSearch"
              />
            </div>
            <BaseButton class="info" text="Search" @click="makeSearch" />
          </div>
        </div>

        <div class="track-head">
          <div class="track-head-img">
            <img src="@/modules/assets/images/boxes.png" alt="" />
          </div>
          <div class="track-head-info">
            <div class="track-head-info-left">
              <div>
                <h3 class="track-head-title">
                  {{ qualityControlList[0].lines[0].prs_title }}
                </h3>

                <p>
                  Date Sent:
                  <b style="margin-left: 10px" v-if="qualityControlList[0]">{{
                    qualityControlList[0].lines[0].ps_date_shipping
                  }}</b>
                </p>
                <p>
                  Tracking Number:
                  <b style="margin-left: 10px">{{
                    qualityControlList[0].lines[0].ps_tracking
                  }}</b>
                </p>
              </div>
            </div>
            <!-- <div class="track-head-info-right">
              <BaseButton
                className="info big"
                text="Received"
                @click="confirmChecking"
              ></BaseButton>
            </div> -->
          </div>
        </div>

        <div class="track-body">
          <div
            class="track-project-list"
            v-for="project in qualityControlList"
            :key="project.project_number"
          >
            <h4 class="green">Project# {{ project.project_number }}</h4>
            <QualityControlLine :lines="project.lines" />
          </div>
        </div>
      </div>

      <div v-else class="search-container">
        <!--   <div v-if="trackingNumberList" class="track-hints">
          <div class="track-hint">
            <h3 class="green" style="font-size: 18px">Tracking number list:</h3>
            <div
              v-for="track in trackingNumberList"
              class="track-card"
              :key="track.id"
              @click="
                qcCode = track.tracking;
                makeSearch();
              "
            >
              <h4 class="track-card-title">Code: {{ track.tracking }}</h4>
              <div class="track-card-details">
                <p class="grey"><b>TSS:</b> {{ track.prs_title }}</p>
                <p class="grey"><b>Cases:</b> {{ track.cases_count }}</p>
              </div>
            </div>
          </div>
        </div> -->

        <div class="search-block">
          <div class="opacity-icon">
            <IconSearch width="150" height="150" />
          </div>
          <h2 class="green">Quality Control</h2>
          <p v-if="wrongCode" class="search-alert search-alert_red">
            Your code is wrong. Please enter right code
          </p>
          <p v-else class="search-alert">
            Enter the code and we will show you the options
          </p>
          <div class="search-form">
            <div class="search-field">
              <input
                class="track-search"
                type="text"
                name="project"
                v-model="qcCode"
                @keyup.enter.prevent="makeSearch"
              />
            </div>
            <BaseButton class="info" text="Search" @click="makeSearch" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import QualityControlLine from "../components/QualityControlLine.vue";
import BaseButton from "@/modules/components/base/BaseButton.vue";
import IconSearch from "@/modules/components/icons/IconSearch";

export default {
  components: {
    QualityControlLine,
    BaseButton,
    IconSearch,
  },
  setup() {
    const store = useStore();
    const qcCode = ref("");
    const wrongCode = ref(false);
    const isCheckedAllData = ref(false);

    store.commit("tracking/GET_QUALITY_CONTROL", []);

    // onMounted(() => {
    //   store.dispatch("tracking/getTrackingNumbers");
    // });

    const storedQCCode = computed(() => store.getters["tracking/qcCode"]);

    const qualityControlList = computed(
      () => store.getters["tracking/qualityControlList"]
    );
    const trackingNumberList = computed(
      () => store.getters["tracking/trackingNumberList"]
    );

    if (qcCode.value.length) {
      qcCode.value = storedQCCode.value;
    }
    if (
      qualityControlList.value.length &&
      qualityControlList.value[0].lines[0].length &&
      qualityControlList.value[0].lines[0].ps_shipping_status
    ) {
      isCheckedAllData.value =
        qualityControlList.value[0].lines[0].ps_shipping_status === "arrived";
      // toDo: check each proposal on 'checked' status
    }

    // const confirmChecking = () => {
    //   console.log("confirmChecking");
    //   store.dispatch("tracking/makeShipmentChecked")
    // };

    const makeSearch = async () => {
      store.commit("tracking/SET_QC_CODE", qcCode.value);
      await store.dispatch("tracking/getShippingQC", qcCode.value);
      if (!qualityControlList.value.length) {
        wrongCode.value = true;
        console.log(`No shipments via ${qcCode.value} number`);
      }
    };

    return {
      wrongCode,
      qcCode,
      isCheckedAllData,
      qualityControlList,
      trackingNumberList,
      makeSearch,
      // confirmChecking,
    };
  },
};
</script>

<style lang="scss" scoped>
.track {
  max-width: 1100px;
  margin: 0 auto;

  &-wrapper {
    position: relative;
    min-height: calc(100vh - 190px);
    border-radius: 8px;
    padding: 30px;
    background-color: var(--col-bg-secondary);
  }

  // &-card {
  //   margin: 5px 0;
  //   border: 1px solid var(--col-border);
  //   border-radius: 4px;
  //   cursor: pointer;

  //   &-title {
  //     padding: 5px;
  //     color: #fff;
  //     background-color: var(--col-info);
  //   }

  //   &-details {
  //     padding: 5px;
  //     border-top: 1px solid var(--col-border);
  //   }
  // }

  &-hints {
    max-height: calc(100vh - 245px);
    overflow-y: auto;
    padding: 30px;
  }

  &-search {
    width: 100%;
    max-width: 270px;
    padding: 6px 25px;
    border: 1px solid var(--col-border);
    border-radius: 4px;
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  &-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 24px;
    padding-bottom: 65px;

    &-img {
      width: 150px;
      // height: 110px;
      margin-right: 20px;
      border-radius: 8px;
      // background-color: var(--col-info);
      // opacity: 0.3;
    }

    &-info {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-top: 5px;
      padding-bottom: 15px;
      border-bottom: 1px solid var(--col-info);
      &-right {
        width: 200px;
        display: flex;
        justify-content: flex-end;
      }
    }

    &-title {
      padding-bottom: 6px;
      font-size: 20px;
      font-family: "SFProDisplay-Bold";
      color: var(--col-subtext-primary);
    }

    &-date {
      font-size: 14px;
      font-family: "SFProDisplay-Regular";
      color: var(--col-subtext-primary);
      text-align: center;
    }
  }

  &-body {
    width: 100%;
  }

  &-project {
    &-list {
      h4 {
        margin-top: 15px;
      }
    }
  }

  &-site {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;

    &-address {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-point {
        font-size: 14px;
        font-family: "SFProDisplay-Bold";
        color: var(--col-text-secondary);
      }
    }

    &-user {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &-photo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        margin-right: 10px;
        flex: none;
        border: 5px solid var(--col-bg-attention);
        border-radius: 50%;
        background-color: var(--col-bg-primary);
      }

      &-name {
        font-size: 12px;
        font-family: "SFProDisplay-Regular";
        font-weight: 500;
        color: var(--col-primary-dark);
      }
    }

    &-prefix {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      border-radius: 4px;
      background-color: var(--col-info);
    }
  }

  &-line {
    &:last-child {
      hr {
        display: none;
      }
    }
  }
}
.search {
  &-container {
    min-height: calc(100vh - 245px);
    overflow-y: auto;
  }
  &-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
  }
  &-alert {
    margin: 15px 0;
    &_red {
      color: var(--col-error);
    }
  }
  &-form {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  &-field {
    margin: 2px;
  }
}
.opacity-icon {
  width: 150px;
  margin: 0 auto 20px;
  opacity: 0.15;
}
</style>
