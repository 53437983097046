<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3987_20003)">
      <path d="M2.13695 59.984L17.6071 43.8943C13.6294 39.1659 11.4501 33.2165 11.4501 27.023C11.4501 12.5525 23.2232 0.779297 37.6938 0.779297C52.1643 0.779297 63.9375 12.5525 63.9375 27.023C63.9375 41.4936 52.1643 53.2667 37.6938 53.2667C32.2613 53.2667 27.0845 51.6282 22.6584 48.5178L7.07077 64.7295C6.41925 65.4062 5.54293 65.7793 4.60386 65.7793C3.715 65.7793 2.87178 65.4404 2.23166 64.8243C0.871548 63.5155 0.82819 61.3452 2.13695 59.984ZM37.6938 7.62549C26.9977 7.62549 18.2962 16.327 18.2962 27.023C18.2962 37.7191 26.9977 46.4206 37.6938 46.4206C48.3898 46.4206 57.0913 37.7191 57.0913 27.023C57.0913 16.327 48.3898 7.62549 37.6938 7.62549Z" fill="#E6E6E6"/>
    </g>
    <defs>
      <clipPath id="clip0_3987_20003">
        <rect width="65" height="65" fill="white" transform="matrix(-1 0 0 1 65.0605 0.779297)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 66 },
    height: { type: [Number, String], default: 66 },
    iconColor: { type: String, default: "none" },
  },
};
</script>
