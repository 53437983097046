<template>
  <ShowModalPSites
    v-if="showModalPSites"
    @close="showModalPSites = false"
    :projectVisibility="project.project_visibility"
  />

  <DeleteProjectModal
    v-if="showDeleteProjectModal"
    :project-id="project.id"
    project-type="feasibility"
    @close="showDeleteProjectModal = false"
  ></DeleteProjectModal>

  <ArchiveProjectModal
    v-if="showArchiveProjectModal"
    :project-id="project.id"
    project-type="feasibility"
    @close="showArchiveProjectModal = false"
  ></ArchiveProjectModal>

  <div
    v-if="project && Object.keys(project).length && projectId"
    class="feasibility-project-page"
  >
    <div class="feasibility-project-main">
      <div class="breadcrumbs-wrapper">
        <p>
          <router-link
            :to="{
              name: 'ActiveList',
            }"
            >Active Project List</router-link
          >
          /

          <router-link
            v-if="projectId"
            :to="{
              name: 'ProjectActive',
              params: { id: projectId },
            }"
            >Active Project</router-link
          >
          /
          <span
            v-if="
              project && project.project_number && project.feasibility_number
            "
            >#{{ project.project_number }} (#{{ project.feasibility_number }})
          </span>
        </p>
      </div>

      <div
        v-if="
          project &&
          project.id &&
          lineList &&
          lineList.length &&
          lineList.find((line) => line.proposals.length)
        "
      >
        <div v-for="line in lineList" :key="line.id">
          <div v-if="!!line.proposals && line.proposals.length">
            <Line
              :is-active="true"
              :line="line"
              :project-id="projectId"
              :base-data="projectBaseInfo"
              @accept-proposal="addProposalToBasket"
            />
          </div>
        </div>
      </div>

      <div v-else>
        <NoProposal
          v-if="project && project.feasibility_number"
          :number-type="project.feasibility_number"
        />
      </div>
    </div>

    <StaticSidebar
      :project="project"
      @select="showModalPSites = true"
      @delete="showDeleteProjectModal = true"
      @archive="showArchiveProjectModal = true"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import ShowModalPSites from "@/components/project/ShowModalPSites.vue";
import DeleteProjectModal from "@/components/project/DeleteProjectModal.vue";
import ArchiveProjectModal from "@/components/project/ArchiveProjectModal.vue";
import Line from "@/components/project/Line.vue";
import StaticSidebar from "@/components/project/StaticSidebar.vue";
import NoProposal from "@/modules/components/NoProposal.vue";

export default {
  components: {
    ShowModalPSites,
    DeleteProjectModal,
    ArchiveProjectModal,
    Line,
    StaticSidebar,
    NoProposal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const projectId = computed(() => route.params.id);
    const project = computed(() => store.getters["projects/project"]);

    store.dispatch("projects/getProject", { id: route.params.id });

    const showModalPSites = ref(false);
    const showDeleteProjectModal = ref(false);
    const showArchiveProjectModal = ref(false);
    const lineList = ref([]);
    if (project.value && project.value.lines && project.value.lines.length) {
      lineList.value = project.value.lines;
    }

    const basketList = computed(() => {
      return [];
    });
    // const basketList = ref([]);

    const projectBaseInfo = computed(() => {
      return {
        status: project.value.status,
        created_at: project.value.created_at,
        study_name: project.value.study_name,
        client_company: project.value.client_company,
        contact_name: project.value.contact_name,
        contact_phone: project.value.contact_phone,
        description_short: project.value.description_short,
      };
    });

    const addProposalToBasket = (data) => {
      const lineId = data.line.id;
      const prId = data.proposal.id;

      lineList.value.forEach((el, i) => {
        if (el.id === lineId) {
          el.proposals.forEach((p, j) => {
            if (p.id === prId) {
              if (p.status === "accepted") {
                p.status = "pending";
              } else {
                p.status = "accepted";
                basketList.value.push({
                  lineIndex: i,
                  proposalIndex: j,
                  lineTitle: el.title,
                  lineId: el.id,
                  proposal: p,
                });
                // console.log(basketList.value);
              }
            }
          });
        }
      });
    };

    const selectTSS = () => (showModalPSites.value = true);

    watch(project, (newValue) => {
      lineList.value = newValue.lines;
    });

    return {
      projectId,
      project,
      showModalPSites,
      showDeleteProjectModal,
      showArchiveProjectModal,
      lineList,
      projectBaseInfo,
      basketList,
      selectTSS,
      addProposalToBasket,
    };
  },
};
</script>
