<template>
  <h6 v-if="title">
    {{ title }} <span v-if="!isApproved" class="red">*</span>
  </h6>

  <p v-if="isApproved">{{ formattedTypes }}</p>
  <div v-else class="buttons-block">
    <BaseButton
      v-for="(btn, index) in buttonsList"
      :text="btn.title"
      :className="btn.isChosen ? 'typical' : 'additional-bordered'"
      :key="index"
      @click="selectItem(btn, index)"
    />
  </div>
</template>

<script>
import BaseButton from "./base/BaseButton.vue";
import { toRefs, computed } from "vue";

export default {
  components: { BaseButton },
  props: {
    title: {
      type: String,
      default: "",
      required: false,
    },
    buttonsList: {
      type: Array,
      required: true,
    },
    isApproved: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {
    const { buttonsList } = toRefs(props);
    const filteredTypes = computed(() => {
      return buttonsList.value.filter((item) => item.isChosen === true) || [];
    });
    const formattedTypes = computed(() => {
      return filteredTypes.value.map((item) => item.title).join(" / ");
    });
    const selectItem = (btn, index) => {
      buttonsList.value[index].isChosen = !btn.isChosen;
    };
    return { filteredTypes, formattedTypes, selectItem };
  },
};
</script>

<style lang="scss" scoped>
.buttons-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.btn {
  margin: 4px 2px;
}
h6 {
  padding: 15px 0;
}
p {
  text-align: center;
}
</style>
