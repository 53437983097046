<template>
  <svg
    :width="height"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9764 6.59575H7.97841L6.23443 5.22754H0V17.8234C0 18.8386 0.825277 19.6862 1.8401 19.7127L15.6885 19.7205H15.6896C16.5284 19.7205 17.2675 19.1553 17.4873 18.3457L19.984 9.14704H15.9764V6.59575ZM1.98292 18.5386L1.86308 18.5386V18.5387C1.48891 18.5249 1.17416 18.199 1.17416 17.8234V6.4017H5.82884L7.57282 7.76991H14.8022V9.14708H5.24082L2.88961 17.802C2.77559 18.2217 2.40941 18.5152 1.98292 18.5386V18.5386ZM18.4486 10.3212L16.354 18.0382C16.2728 18.3375 15.9996 18.5464 15.6895 18.5464C15.6894 18.5464 15.6892 18.5464 15.6891 18.5464L3.85594 18.5397C3.92537 18.4049 3.98161 18.2611 4.02275 18.1099L6.13862 10.3212H18.4486Z"
      :fill="iconColor"/>
    <path
      d="M14.4287 2.11463C15.7396 2.11463 16.8473 2.99814 17.1896 4.20068L16.4935 3.65998L15.7733 4.58721L17.8866 6.22876L20 4.58721L19.2796 3.65998L18.42 4.32776C18.1049 2.40907 16.4352 0.94043 14.4287 0.94043C12.1984 0.94043 10.3838 2.75498 10.3838 4.98537H11.5579C11.558 3.40245 12.8458 2.11463 14.4287 2.11463V2.11463Z"
      :fill="iconColor"/>
    <path
      d="M7.33887 17.3628L7.34004 16.1886L14.4288 16.1957L14.4276 17.3699L7.33887 17.3628Z"
      :fill="iconColor"/>
  </svg>

</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 20 },
    height: { type: [Number, String], default: 20 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
