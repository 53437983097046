import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {
    shippingCode: "",
    shippingTrackList: [],
    qcCode: "",
    qualityControlList: [],
    trackingNumberList: [],
  },

  getters: {
    shippingCode: (state) => state.shippingCode,
    shippingTrackList: (state) => state.shippingTrackList,
    qcCode: (state) => state.qcCode,
    qualityControlList: (state) => state.qualityControlList,
    trackingNumberList: (state) => state.trackingNumberList,
  },

  mutations: {
    SET_SHIPPING_CODE(state, payload) {
      state.shippingCode = payload;
    },
    GET_SHIPPING_TRACK(state, payload) {
      state.shippingTrackList = payload;
    },
    SET_QC_CODE(state, payload) {
      state.qcCode = payload;
    },
    GET_QUALITY_CONTROL(state, payload) {
      state.qualityControlList = payload;
    },
    SET_TRACKING_NUMBERS(state, payload) {
      state.trackingNumberList = payload;
    },
  },

  actions: {
    async getShippingInfo({ commit }, shipmentNumber) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .get(`/search-proposal-shipment?tracking=${shipmentNumber}`)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("GET_SHIPPING_TRACK", res.data.data);
          }
        })
        .catch((err) => {
          console.error("Error from getShippingInfo: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async makeShipmentReceive({ commit }, shipmentNumber) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .post(`/receive-proposal-shipment?tracking=${shipmentNumber}`)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("GET_SHIPPING_TRACK", []);
          }
        })
        .catch((err) => {
          console.error("Error from makeShipmentReceive: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async getShippingQC({ commit }, shipmentNumber) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .get(`/search-proposal-qc?tracking=${shipmentNumber}`)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("GET_QUALITY_CONTROL", res.data.data);
          }
        })
        .catch((err) => {
          console.error("Error from getShippingQC: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async makeShipmentChecked({ commit }, shipmentNumber) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .post(`/receive-proposal-shipment?tracking=${shipmentNumber}`)
        .then((res) => {
          if (res.data.status === "Success") {
            console.log("res", res.data);
          }
        })
        .catch((err) => {
          console.error("Error from makeShipmentReceive: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async getTrackingNumbers({ commit }) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios.get("/proposal-quality-control").then((res) => {
        commit("SET_TRACKING_NUMBERS", res.data.data);
      });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async sendQCResponse({ commit }, { id, shipment }) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .put(`/proposal-quality-control/${id}`, shipment)
        .then((res) => {
          commit("SET_TRACKING_NUMBERS", res.data.data);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
  },
};
