<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 68 81"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20.666 57.5132H17.9993C17.263 57.5132 16.666 58.1102 16.666 58.8465C16.666 59.5829 17.263 60.1799 17.9993 60.1799H20.666C21.4023 60.1799 21.9993 59.5829 21.9993 58.8465C21.9993 58.1102 21.4023 57.5132 20.666 57.5132Z" :fill="iconColor"/>
    <path d="M57.9994 57.5132H25.9993C25.263 57.5132 24.666 58.1102 24.666 58.8465C24.666 59.5829 25.263 60.1799 25.9993 60.1799H57.9994C58.7357 60.1799 59.3327 59.5829 59.3327 58.8465C59.3327 58.1102 58.7357 57.5132 57.9994 57.5132Z" :fill="iconColor"/>
    <path d="M20.666 49.5132H17.9993C17.263 49.5132 16.666 50.1102 16.666 50.8465C16.666 51.5828 17.263 52.1798 17.9993 52.1798H20.666C21.4023 52.1798 21.9993 51.5828 21.9993 50.8465C21.9993 50.1102 21.4023 49.5132 20.666 49.5132Z" :fill="iconColor"/>
    <path d="M57.9994 49.5132H25.9993C25.263 49.5132 24.666 50.1102 24.666 50.8465C24.666 51.5828 25.263 52.1798 25.9993 52.1798H57.9994C58.7357 52.1798 59.3327 51.5828 59.3327 50.8465C59.3327 50.1102 58.7357 49.5132 57.9994 49.5132Z" :fill="iconColor"/>
    <path d="M20.666 41.5132H17.9993C17.263 41.5132 16.666 42.1102 16.666 42.8465C16.666 43.5829 17.263 44.1799 17.9993 44.1799H20.666C21.4023 44.1799 21.9993 43.5829 21.9993 42.8465C21.9993 42.1102 21.4023 41.5132 20.666 41.5132Z" :fill="iconColor"/>
    <path d="M57.9994 41.5132H25.9993C25.263 41.5132 24.666 42.1102 24.666 42.8465C24.666 43.5829 25.263 44.1799 25.9993 44.1799H57.9994C58.7357 44.1799 59.3327 43.5829 59.3327 42.8465C59.3327 42.1102 58.7357 41.5132 57.9994 41.5132Z" :fill="iconColor"/>
    <path d="M20.666 33.5132H17.9993C17.263 33.5132 16.666 34.1102 16.666 34.8465C16.666 35.5829 17.263 36.1799 17.9993 36.1799H20.666C21.4023 36.1799 21.9993 35.5829 21.9993 34.8465C21.9993 34.1102 21.4023 33.5132 20.666 33.5132Z" :fill="iconColor"/>
    <path d="M57.9994 33.5132H25.9993C25.263 33.5132 24.666 34.1102 24.666 34.8465C24.666 35.5829 25.263 36.1799 25.9993 36.1799H57.9994C58.7357 36.1799 59.3327 35.5829 59.3327 34.8465C59.3327 34.1102 58.7357 33.5132 57.9994 33.5132Z" :fill="iconColor"/>
    <path d="M20.666 25.5132H17.9993C17.263 25.5132 16.666 26.1102 16.666 26.8465C16.666 27.5829 17.263 28.1799 17.9993 28.1799H20.666C21.4023 28.1799 21.9993 27.5829 21.9993 26.8465C21.9993 26.1102 21.4023 25.5132 20.666 25.5132Z" :fill="iconColor"/>
    <path d="M57.9994 25.5132H25.9993C25.263 25.5132 24.666 26.1102 24.666 26.8465C24.666 27.5829 25.263 28.1799 25.9993 28.1799H57.9994C58.7357 28.1799 59.3327 27.5829 59.3327 26.8465C59.3327 26.1102 58.7357 25.5132 57.9994 25.5132Z" :fill="iconColor"/>
    <path d="M67.3154 20.0957C67.3087 19.9789 67.2859 19.8635 67.2474 19.753C67.2327 19.7104 67.222 19.669 67.2034 19.6277C67.1385 19.4827 67.0482 19.3504 66.9367 19.237L48.27 0.570354C48.1567 0.458854 48.0244 0.368521 47.8794 0.303688C47.838 0.285021 47.7967 0.274354 47.7554 0.259687C47.6439 0.221354 47.5277 0.198021 47.41 0.190354C47.3874 0.194354 47.362 0.179688 47.3327 0.179688H9.99935C9.26302 0.179688 8.66602 0.776688 8.66602 1.51302V5.51302H1.99935C1.26302 5.51302 0.666016 6.11002 0.666016 6.84635V78.8464C0.666016 79.5827 1.26302 80.1797 1.99935 80.1797H57.9994C58.7357 80.1797 59.3327 79.5827 59.3327 78.8464V72.1797H65.9994C66.7357 72.1797 67.3327 71.5827 67.3327 70.8464V20.1797C67.3327 20.1504 67.318 20.125 67.3154 20.0957ZM48.666 4.73169L62.7807 18.8464H48.666V4.73169ZM56.666 77.513H3.33268V8.17969H8.66602V70.8464C8.66602 71.5827 9.26302 72.1797 9.99935 72.1797H56.666V77.513ZM64.666 69.513H11.3327V2.84635H45.9994V20.1797C45.9994 20.916 46.5964 21.513 47.3327 21.513H64.666V69.513Z" :fill="iconColor"/>
    <path d="M25.9993 12.1797H17.9993C17.263 12.1797 16.666 12.7767 16.666 13.513V21.513C16.666 22.2494 17.263 22.8464 17.9993 22.8464H25.9993C26.7357 22.8464 27.3327 22.2494 27.3327 21.513V13.513C27.3327 12.7767 26.7357 12.1797 25.9993 12.1797ZM24.666 20.1797H19.3327V14.8464H24.666V20.1797Z" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 68 },
    height: { type: [Number, String], default: 81 },
    iconColor: { type: String, default: "#E6E6E6" },
  },
};
</script>
