<template>
  <div class="auth-page-wrapper">
    <div class="auth-page">
      <div class="auth-page-header"></div>

      <div class="auth-page-logo">
        <img src="@/modules/assets/cureline-logo-black.svg" alt="logo" />
      </div>

      <hr />

      <h2 class="auth-page-title">Sign in</h2>

      <form class="auth-page-form" @submit.prevent="submitForm">
        <fieldset class="form-group">
          <label for="email-login" class="label">Email</label>
          <input
            type="text"
            class="input"
            placeholder="Email"
            v-model="form.email"
            id="email-login"
          />
        </fieldset>
        <fieldset class="form-group">
          <label for="password-login" class="label">Password</label>
          <input
            type="password"
            class="input"
            placeholder="Password"
            v-model="form.password"
            id="password-login"
          />
        </fieldset>
        <!-- <button class="btn btn-submit" :disabled="isSubmitting">Sign In</button> -->
        <div class="btn-wrp">
          <BaseButton
            class-name="big info "
            text="Sign In"
            @click="submitForm"
          />
        </div>
      </form>

      <div class="auth-page-footer">
        <img src="@/modules/assets/bg/wave.svg" alt="logo" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import BaseButton from "@/modules/components/base/BaseButton.vue";

export default {
  name: "Login",
  components: { BaseButton },
  setup() {
    const store = useStore();
    const router = useRouter();

    const form = ref({
      email: "",
      password: "",
      // email: "super.admin@cureline.com",
      // password: "123456",
    });
    const token = ref(localStorage.getItem("token"));
    const isSubmitting = ref(false);
    const authenticated = computed(() => store.getters["auth/authenticated"]);

    const submitForm = async () => {
      isSubmitting.value = true;
      let result = await store.dispatch("auth/signIn", form.value);
      if (result) {
        isSubmitting.value = false;
        router.push("/project-list");
      }
    };

    return {
      form,
      token,
      isSubmitting,
      authenticated,
      submitForm,
    };
  },
};
</script>

<style scoped lang="scss">
.auth-page {
  &-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--col-bg-secondary);
  }

  z-index: 2;
  max-width: 800px;
  width: 90%;
  padding: 0 120px;

  &-logo {
    width: 160px;
    margin: 30px auto 40px;
  }

  &-title {
    margin: 20px 0;
    font-size: 24px;
    font-weight: normal;
    font-family: "OpenSans-Semibold";
    text-align: center;
    color: var(--col-text-secondary);
  }

  &-form {
    max-width: 400px;
    width: 95%;
    margin: 0 auto;
    padding-bottom: 30px;

    .input {
      margin: 3px 0 0;
      padding: 6px 10px;
    }

    .label {
      font-size: 16px;
      font-weight: normal;
      font-family: "OpenSans-Semibold";
      color: var(--col-text-secondary);
    }
  }

  &-header,
  &-footer {
    position: fixed;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;

    img {
      width: 100%;
    }
  }

  &-header {
    top: 0;
    bottom: auto;
    min-height: 10px;
    background-color: var(--col-info);
  }

  .btn-wrp {
    margin-top: 40px;
    border: 1px solid var(--col-bg-secondary);
    border-radius: 4px;
    overflow: hidden;
  }
}
</style>
