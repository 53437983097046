<template>
  <svg :width="width" :height="height" :fill="iconColor" viewBox="0 0 31 41">
    <path
      d="M30.1384 10.25H21.5282C21.2997 10.25 21.0806 10.16 20.919 9.99969C20.7574 9.83942 20.6667 9.62205 20.6667 9.39539V0.85461C20.6667 0.627954 20.5759 0.41058 20.4143 0.25031C20.2527 0.0900391 20.0336 0 19.8051 0L2.58199 0C1.89764 0.00140691 1.24172 0.271695 0.757814 0.751703C0.273904 1.23171 0.00141835 1.88234 0 2.56117L0 38.4388C0.00141835 39.1177 0.273904 39.7683 0.757814 40.2483C1.24172 40.7283 1.89764 40.9986 2.58199 41H28.418C29.1024 40.9986 29.7583 40.7283 30.2422 40.2483C30.7261 39.7683 30.9986 39.1177 31 38.4388V11.1046C31 10.878 30.9092 10.6606 30.7477 10.5003C30.5861 10.34 30.3669 10.25 30.1384 10.25ZM29.2769 38.4388C29.2769 38.6648 29.1864 38.8815 29.0253 39.0413C28.8643 39.201 28.6458 39.2908 28.418 39.2908H2.58199C2.3542 39.2908 2.13575 39.201 1.97468 39.0413C1.81361 38.8815 1.72312 38.6648 1.72312 38.4388V2.56117C1.72312 2.33522 1.81361 2.11852 1.97468 1.95875C2.13575 1.79898 2.3542 1.70922 2.58199 1.70922H18.9543V9.39539C18.955 10.0751 19.2275 10.7269 19.7121 11.2075C20.1967 11.6882 20.8537 11.9585 21.539 11.9592H29.2876L29.2769 38.4388Z"
    />
    <path
      d="M30.7476 10.5003L20.4143 0.25033C20.3342 0.170966 20.2393 0.10801 20.1347 0.0650587C20.0302 0.022107 19.9181 1.18262e-09 19.805 0C19.6918 -1.18262e-09 19.5798 0.022107 19.4753 0.0650587C19.3707 0.10801 19.2757 0.170966 19.1957 0.25033C19.1157 0.329695 19.0522 0.423914 19.0089 0.527609C18.9656 0.631303 18.9434 0.742443 18.9434 0.854681C18.9434 0.966919 18.9656 1.07806 19.0089 1.18175C19.0522 1.28545 19.1157 1.37967 19.1957 1.45903L29.5237 11.7144C29.6853 11.8746 29.9044 11.9647 30.133 11.9647C30.3615 11.9647 30.5806 11.8746 30.7422 11.7144C30.9038 11.5541 30.9946 11.3367 30.9946 11.11C30.9946 10.8833 30.9038 10.6659 30.7422 10.5057L30.7476 10.5003Z"
    />
    <path
      d="M7.35156 22.3239H8.58888V28.181H7.35156V22.3239ZM7.97425 25.3802H9.42628C9.72611 25.4003 10.0222 25.305 10.2529 25.114C10.3543 25.0149 10.4332 24.8955 10.4841 24.7637C10.535 24.6319 10.5569 24.4908 10.5482 24.3499C10.5575 24.2081 10.5359 24.0661 10.485 23.9333C10.434 23.8006 10.3549 23.6802 10.2529 23.5805C10.021 23.3917 9.7258 23.2966 9.42628 23.3142H7.97425V22.3239H9.72957C10.1082 22.315 10.483 22.4011 10.8193 22.5741C11.1221 22.7347 11.3726 22.9776 11.5413 23.2743C11.7239 23.5996 11.8165 23.967 11.8097 24.3392C11.8173 24.7116 11.7247 25.0792 11.5413 25.4042C11.3696 25.6952 11.1196 25.933 10.8193 26.0911C10.4826 26.2628 10.1081 26.3488 9.72957 26.3413H7.97425V25.3802Z"
    />
    <path
      d="M12.5342 22.3242H13.6078L16.8286 26.9114L15.7925 26.1367H16.3132V22.3242H17.5479V28.1814H16.4743L13.2535 23.5675L14.2895 24.3449H13.7688V28.1814H12.5342V22.3242Z"
    />
    <path
      d="M19.6735 27.9251C19.2491 27.6841 18.9063 27.3237 18.6884 26.8895C18.4451 26.3773 18.3265 25.8155 18.3422 25.2495C18.327 24.6915 18.4447 24.1378 18.6858 23.6334C18.9022 23.1999 19.243 22.8396 19.6654 22.5978C20.0896 22.3815 20.5563 22.2597 21.0328 22.2409C21.5094 22.222 21.9843 22.3066 22.4245 22.4886C22.7691 22.6409 23.0726 22.8717 23.3103 23.1622C23.5381 23.4497 23.6894 23.7893 23.7504 24.1499L23.7638 24.2112H22.5185L22.5051 24.1659C22.4172 23.8963 22.2428 23.6625 22.0085 23.5003C21.7587 23.335 21.4632 23.2513 21.1631 23.2607C20.8673 23.2482 20.5752 23.3288 20.3284 23.4909C20.0816 23.653 19.8927 23.8883 19.7889 24.1632C19.6567 24.5026 19.5938 24.8645 19.6037 25.2282C19.5938 25.5988 19.6605 25.9676 19.7996 26.3117C19.9126 26.5929 20.1068 26.8346 20.3579 27.0066C20.6096 27.1723 20.9067 27.2569 21.2087 27.2489C21.4534 27.2548 21.6961 27.2038 21.9173 27.0998C22.1194 27.007 22.2908 26.8591 22.4115 26.6734C22.5322 26.4877 22.5972 26.2718 22.599 26.0508V25.9683H21.257V25.0498H23.8148V25.726C23.8279 26.2028 23.7199 26.6752 23.5008 27.0998C23.297 27.4764 22.9837 27.7834 22.6017 27.981C22.1411 28.1935 21.6376 28.2989 21.1297 28.2892C20.6218 28.2795 20.1228 28.155 19.6708 27.9251H19.6735Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 31 },
    height: { type: [Number, String], default: 41 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
