import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {
    activeProjectList: [],
  },

  getters: {
    activeProjectList: (state) => {
      return state.activeProjectList;
    },
  },

  mutations: {
    GET_PROJECTS(state, payload) {
      state.activeProjectList = payload;
    },
  },

  actions: {
    async getAllActiveProjects({ commit, dispatch }, page) {
      const newPage = page ? `?page=${page}` : "?page=1";
      await axios
        .get("/active-projects" + newPage)
        .then((response) => {
          if (response.data.status === "Success") {
            commit("GET_PROJECTS", response.data.data.data);
            dispatch("projects/getPagination", response.data.data, {
              root: true,
            });
          }
        })
        .catch((err) => {
          console.error("Error from getAllActiveProjects: ", err);
        });
    },

    async saveToActive(store, data) {
      await axios
        .post(`/project/active/${data.id}`, data)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error("Error saveToActive:: ", error.response);
        });
    },

    async getFilteredProjects({ commit, dispatch }, { params, page }) {
      commit("projects/LOADING_STATE", true, { root: true });

      const newPage = page ? `&page=${page}` : "";
      let query = [];

      for (const p in params) {
        query.push(`${p}=${params[p]}`);
      }

      const q = query.join().replace(/,/g, "&");

      axios
        .get(`/search-projects?${q}${newPage}`)
        .then((response) => {
          if (response.data.status === "Success") {
            commit("GET_PROJECTS", response.data.data.data);
            dispatch("projects/getPagination", response.data.data, {
              root: true,
            });
          }
        })
        .catch((err) => {
          console.error("Error from getAllProjects: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
  },
};
