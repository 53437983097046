<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 19 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6797 0.523438H1.84375C0.972508 0.523438 0.261719 1.23345 0.261719 2.10547V12.6523C0.261719 13.5214 0.969344 14.2344 1.84375 14.2344H16.6797C17.5487 14.2344 18.2617 13.5267 18.2617 12.6523V2.10547C18.2617 1.23641 17.5541 0.523438 16.6797 0.523438ZM16.4612 1.57812L9.29526 8.74413L2.06731 1.57812H16.4612ZM1.31641 12.434V2.31883L6.39575 7.35461L1.31641 12.434ZM2.06218 13.1797L7.14471 8.09715L8.92562 9.86277C9.13178 10.0672 9.46446 10.0665 9.66978 9.86115L11.4062 8.12468L16.4613 13.1797H2.06218ZM17.207 12.4339L12.152 7.37891L17.207 2.32386V12.4339Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 19 },
    height: { type: [Number, String], default: 15 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
