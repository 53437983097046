<template>
  <div class="loader-wrapper">
    <div class="loader">
      <div class="loader-dots">
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-dot"></div>
        <div class="loader-text"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  &-wrapper {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffffa2;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &-dots {
    width: 25vw;
  }

  &-dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;

    &:first-child {
      background-color: var(--col-error);
      animation-delay: 0.5s;
    }

    &:nth-child(2) {
      background-color: var(--col-attention);
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      background-color: var(--col-warning);
      animation-delay: 0.3s;
    }

    &:nth-child(4) {
      background-color: var(--col-success);
      animation-delay: 0.2s;
    }

    &:nth-child(5) {
      background-color: var(--col-info);
      animation-delay: 0.1s;
    }

    &:nth-child(6) {
      background-color: var(--col-additional);
      animation-delay: 0s;
    }

    &-text {
      position: absolute;
      top: 200%;
      left: 0;
      right: 0;
      width: 4rem;
      margin: auto;

      &:after {
        content: "Loading";
        font-weight: bold;
      }
    }
  }

  &-text {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;
    color: var(--col-info);

    &:after {
      content: "Loading";
      font-weight: bold;
    }
  }
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(25vw);
  }
  65% {
    transform: translateX(25vw);
  }
  95% {
    transform: translateX(0);
  }
}
</style>
