import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {
    isLoading: false,
    sites: [],
    dialog: null,
  },

  getters: {
    isLoading: (state) => state.isLoading,
    sites: (state) => state.sites,
    dialog: (state) => state.dialog,
  },

  mutations: {
    SET_SITES(state, payload) {
      state.sites = payload;
    },
    SET_DIALOG_WITH_PS(state, payload) {
      state.dialog = payload;
      // if (payload.length) {
      //   let ind = state.sites.findIndex(
      //     (site) => site.id === payload[0].procurement_site_id
      //   );
      //   state.sites[ind].seen = 1;
      // }
    },

    PUSH_MESSAGE(state, payload) {
      state.dialog.push(payload);
      state.sites.find((site) => {
        if (site.id === payload.tssId) {
          site.updated_at = payload.created_at;
        }
      });
    },
  },

  actions: {
    async showProcurementSites() {
      await axios.get("ps-admin-chat").catch((err) => {
        console.error("Error from showProcurementSites: ", err);
      });
    },

    async showTSS({ commit }) {
      let sites = [];
      await axios
        .get("admin-ps-chat")
        .then((res) => {
          if (res.data.status === "Success") {
            commit("SET_SITES", res.data.data);
            sites = res.data.data;
          }
        })
        .catch((err) => {
          console.error("Error from showTSS: ", err);
        });
      return sites;
    },

    async showAdminChat({ commit }, id) {
      await axios
        .get(`admin-ps-chat/${id}`)
        .then((res) => {
          if (res.data.status === "Success") {
            commit("SET_DIALOG_WITH_PS", res.data.data.data);
          }
        })
        .catch((err) => {
          console.error("Error from showAdminChat: ", err);
        });
    },

    async sendMessage(state, msg) {
      await axios.put("admin-ps-chat", msg).catch((err) => {
        console.error("Error from sendMessage: ", err);
      });
    },

    showMessage({ commit }, msg) {
      commit("PUSH_MESSAGE", msg);
    },
  },
};
