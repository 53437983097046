<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9035 17.64C10.3742 17.64 8.31641 15.5745 8.31641 13.0358C8.31641 10.4971 10.3742 8.43164 12.9035 8.43164C15.4327 8.43164 17.4905 10.4971 17.4905 13.0358C17.4905 15.5745 15.4327 17.64 12.9035 17.64ZM12.9035 9.13998C10.7631 9.13998 9.02211 10.8881 9.02211 13.0358C9.02211 15.1835 10.7631 16.9317 12.9035 16.9317C15.0438 16.9317 16.7848 15.1835 16.7848 13.0358C16.7848 10.8881 15.0438 9.13998 12.9035 9.13998Z"
    />
    <path
      d="M12.9036 15.5153C12.7089 15.5153 12.5508 15.3566 12.5508 15.1611V12.3278C12.5508 12.1323 12.7089 11.9736 12.9036 11.9736C13.0984 11.9736 13.2565 12.1323 13.2565 12.3278V15.1611C13.2565 15.3566 13.0984 15.5153 12.9036 15.5153Z"
    />
    <path
      d="M12.9036 11.266C13.0985 11.266 13.2565 11.1074 13.2565 10.9118C13.2565 10.7162 13.0985 10.5576 12.9036 10.5576C12.7088 10.5576 12.5508 10.7162 12.5508 10.9118C12.5508 11.1074 12.7088 11.266 12.9036 11.266Z"
    />
    <path
      d="M7.25884 15.5156H2.31894C1.34648 15.5156 0.554688 14.7216 0.554688 13.7448V4.53646C0.554688 3.55967 1.34648 2.76562 2.31894 2.76562H3.73034C3.92511 2.76562 4.08319 2.92429 4.08319 3.11979C4.08319 3.31529 3.92511 3.47396 3.73034 3.47396H2.31894C1.73532 3.47396 1.26039 3.95067 1.26039 4.53646V13.7448C1.26039 14.3306 1.73532 14.8073 2.31894 14.8073H7.25884C7.45361 14.8073 7.61169 14.966 7.61169 15.1615C7.61169 15.357 7.45361 15.5156 7.25884 15.5156Z"
    />
    <path
      d="M8.6697 4.89062H4.4355C3.85189 4.89062 3.37695 4.41392 3.37695 3.82812V2.41146C3.37695 2.21596 3.53503 2.05729 3.7298 2.05729H4.82364C4.98807 1.24979 5.70012 0.640625 6.5526 0.640625C7.40509 0.640625 8.11714 1.24979 8.28157 2.05729H9.3754C9.57018 2.05729 9.72825 2.21596 9.72825 2.41146V3.82812C9.72825 4.41392 9.25332 4.89062 8.6697 4.89062ZM4.08265 2.76562V3.82812C4.08265 4.02292 4.24073 4.18229 4.4355 4.18229H8.6697C8.86448 4.18229 9.02255 4.02292 9.02255 3.82812V2.76562H7.964C7.76923 2.76562 7.61115 2.60696 7.61115 2.41146C7.61115 1.82567 7.13622 1.34896 6.5526 1.34896C5.96899 1.34896 5.49405 1.82567 5.49405 2.41146C5.49405 2.60696 5.33598 2.76562 5.1412 2.76562H4.08265Z"
    />
    <path
      d="M10.0817 7.01595H3.02472C2.82995 7.01595 2.67188 6.85728 2.67188 6.66178C2.67188 6.46628 2.82995 6.30762 3.02472 6.30762H10.0817C10.2765 6.30762 10.4346 6.46628 10.4346 6.66178C10.4346 6.85728 10.2765 7.01595 10.0817 7.01595Z"
    />
    <path
      d="M7.96463 9.13997H3.02473C2.82995 9.13997 2.67188 8.98131 2.67188 8.78581C2.67188 8.59031 2.82995 8.43164 3.02473 8.43164H7.96463C8.1594 8.43164 8.31748 8.59031 8.31748 8.78581C8.31748 8.98131 8.1594 9.13997 7.96463 9.13997Z"
    />
    <path
      d="M6.55323 11.266H3.02473C2.82995 11.266 2.67188 11.1073 2.67188 10.9118C2.67188 10.7163 2.82995 10.5576 3.02473 10.5576H6.55323C6.748 10.5576 6.90608 10.7163 6.90608 10.9118C6.90608 11.1073 6.748 11.266 6.55323 11.266Z"
    />
    <path
      d="M12.1991 7.01562C12.0043 7.01562 11.8462 6.85696 11.8462 6.66146V4.53646C11.8462 3.95067 11.3713 3.47396 10.7877 3.47396H9.37629C9.18151 3.47396 9.02344 3.31529 9.02344 3.11979C9.02344 2.92429 9.18151 2.76562 9.37629 2.76562H10.7877C11.7601 2.76562 12.5519 3.55967 12.5519 4.53646V6.66146C12.5519 6.85696 12.3939 7.01562 12.1991 7.01562Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 18 },
    height: { type: [Number, String], default: 18 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
