<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.134 3.4749C15.2858 1.62675 12.8286 0.608887 10.2149 0.608887C7.60115 0.608887 5.14384 1.62675 3.29583 3.4749C1.44755 5.32304 0.429688 7.78022 0.429688 10.394C0.429688 12.5669 1.16007 14.6914 2.4909 16.4013L1.1543 22.044C1.11622 22.2045 1.1724 22.3725 1.29925 22.4781C1.37863 22.5439 1.47705 22.578 1.57641 22.578C1.63595 22.578 1.69602 22.5656 1.75247 22.5406L7.76608 19.8695C8.56364 20.0752 9.38654 20.1793 10.2149 20.1793C12.8286 20.1793 15.2858 19.1614 17.134 17.3131C18.9823 15.4651 20.0001 13.0077 20.0001 10.394C20.0001 7.78022 18.9822 5.32304 17.134 3.4749V3.4749ZM10.2149 19.3113C9.41175 19.3113 8.61446 19.204 7.84492 18.9926C7.74825 18.9659 7.6454 18.9737 7.55382 19.0143L2.20033 21.3921L3.38393 16.3963C3.41517 16.2651 3.38326 16.1268 3.29811 16.0222C2.00804 14.4387 1.29751 12.4398 1.29751 10.394C1.29751 5.47697 5.29778 1.47657 10.2149 1.47657C15.132 1.47657 19.1321 5.47697 19.1321 10.394C19.1321 15.3112 15.132 19.3113 10.2149 19.3113V19.3113Z"
      :fill="iconColor"/>
    <path d="M16.385 10.3336C16.385 6.93099 13.6166 4.1626 10.214 4.1626C6.81136 4.1626 4.04297 6.93099 4.04297 10.3336C4.04297 13.7365 6.81136 16.5048 10.214 16.5048C13.6166 16.5048 16.385 13.7365 16.385 10.3336V10.3336ZM10.214 15.6371C7.28966 15.6371 4.91066 13.258 4.91066 10.3339C4.91066 7.40955 7.28966 5.03042 10.214 5.03042C13.1381 5.03042 15.5172 7.40955 15.5172 10.3339C15.5172 13.258 13.1381 15.6371 10.214 15.6371Z"
      :fill="iconColor"/>
    <path d="M16.9751 13.6029C16.7465 13.5297 16.5024 13.6553 16.4294 13.8835C16.0633 15.0241 15.0432 15.8097 14.2518 16.2678C14.0445 16.3879 13.9735 16.6533 14.0935 16.8606C14.174 16.9995 14.3196 17.0773 14.4695 17.0773C14.5433 17.0773 14.618 17.0584 14.6864 17.0188C15.61 16.4843 16.8051 15.5523 17.2555 14.1486C17.3287 13.9205 17.2032 13.6761 16.9751 13.6029Z"
      :fill="iconColor"/>
    <path d="M13.1507 17.0822C12.9284 16.9922 12.6755 17.0992 12.5855 17.3212L12.5837 17.3258C12.4937 17.5477 12.6005 17.8007 12.8227 17.8909C12.8761 17.9126 12.9313 17.9228 12.9856 17.9228C13.157 17.9228 13.3195 17.8205 13.3879 17.6519L13.3897 17.6474C13.4798 17.4253 13.3728 17.1724 13.1507 17.0822Z"
      :fill="iconColor"/>
    <path d="M10.2017 13.1177C9.99189 13.1177 9.82307 13.1928 9.69582 13.3424C9.56844 13.4925 9.50488 13.6723 9.50488 13.882C9.50488 14.0841 9.56844 14.2623 9.69582 14.4157C9.82321 14.5693 9.99189 14.6462 10.2017 14.6462C10.4112 14.6462 10.591 14.5693 10.741 14.4157C10.8907 14.2623 10.9658 14.0841 10.9658 13.882C10.9658 13.6723 10.8907 13.4925 10.741 13.3424C10.591 13.1928 10.4112 13.1177 10.2017 13.1177Z"
      :fill="iconColor"/>
    <path d="M12.0218 6.85829C11.6021 6.49879 11.0403 6.31885 10.3362 6.31885C9.70679 6.31885 9.20476 6.46125 8.83039 6.74579C8.45561 7.03059 8.26855 7.33028 8.26855 7.64485C8.26855 7.81729 8.32621 7.9503 8.44193 8.04376C8.55765 8.13749 8.68651 8.18429 8.82837 8.18429C8.94007 8.18429 9.03903 8.14621 9.12484 8.06964C9.2108 7.99321 9.28361 7.91155 9.34341 7.82466C9.40308 7.73791 9.51504 7.65625 9.67943 7.57968C9.84356 7.50325 10.0488 7.46504 10.295 7.46504C10.6461 7.46504 10.9259 7.54831 11.135 7.71511C11.344 7.88165 11.4486 8.11671 11.4486 8.42042C11.4486 8.59809 11.4056 8.77039 11.3193 8.93693C11.2333 9.1036 11.1469 9.22951 11.0609 9.31465C10.9747 9.3998 10.8455 9.51646 10.6732 9.66463L10.3473 9.93106C9.89787 10.3312 9.67313 10.9161 9.67313 11.6863C9.67313 11.9233 9.71229 12.0863 9.79086 12.175C9.86971 12.2641 10.0026 12.3085 10.19 12.3085C10.5644 12.3085 10.7519 12.1736 10.7519 11.9039C10.7519 11.5219 10.7816 11.2296 10.8418 11.0272C10.9015 10.8252 11.0589 10.6228 11.3137 10.4205L11.5285 10.2407L11.8228 9.95975C11.8833 9.89995 11.9761 9.79308 12.1008 9.63942C12.2255 9.48602 12.3202 9.34724 12.3844 9.22374C12.4488 9.10011 12.5091 8.94832 12.5657 8.76851C12.6225 8.58883 12.651 8.40527 12.651 8.21794C12.651 7.67113 12.4414 7.21778 12.0218 6.85829V6.85829Z"
      :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 20 },
    height: { type: [Number, String], default: 23 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
