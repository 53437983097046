<template>
  <div
    v-if="!!cardData && Object.values(cardData).length"
    class="card"
    @click="$emit('get-project', cardData.id)"
  >
    <div class="card-header">
      <div class="card-header-name">
        <span v-if="isAdmin && cardData.client_company">
          {{ cardData.client_company }}
        </span>
      </div>
      <div class="card-header-date">
        {{ formatDate(cardData.created_at) }}
      </div>
    </div>

    <hr style="margin-bottom: 10px;"/>

    <div class="card-title">
      <span v-if="cardData.project_number" class="card-title-code">
        {{ cardData.project_number }}
      </span>
      <span v-if="cardData.description_short" class="card-title-name">
        <span style="padding: 0 4px 0 2px;">:</span>
         {{ cardData.description_short }}
      </span>
    </div>

    <div class="card-body">
      <span v-if="cardData.indication">
        {{ cardData.indication }}
      </span>
    </div>

    <hr class="dashed" />

    <div class="card-footer">
      <span v-if="cardData.categories && cardData.categories.length">
        <span v-for="(category, i) in cardData.categories" :key="category.title"
          >{{ category.title
          }}{{ i == cardData.categories.length - 1 ? "" : ", " }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Object,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const formatDate = (createdAt) => {
      const time = new Date(createdAt);
      const day = time.getDate();
      const month = time.toLocaleString("default", { month: "long" });
      const year = time.getFullYear();
      return day + " " + month + " " + year;
    };

    return { formatDate };
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin: 10px;
  padding: 20px;
  border: 1px solid var(--col-border);
  border-radius: 4px;
  max-width: 300px;
  width: 300px;
  cursor: pointer;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
      font-size: 10px;
      color: var(--col-info);
      font-family: "SFProDisplay-Medium";
    }

    &-date {
      font-size: 10px;
      color: var(--col-primary-dark);
      font-family: "SFProDisplay-Thin";
    }
  }

  &-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      white-space: nowrap;
    }

    &-code {
      font-family: "SFProDisplay-Regular";
      overflow: visible;
      color: var(--col-additional);
    }

    &-name {
      font-family: "SFProDisplay-Bold";
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--col-primary-dark);
    }
  }

  &-body {
    max-height: 60px;
    font-size: 12px;
    line-height: 1.5;
    overflow-y: hidden;
    color: var(--col-primary-dark);
  }

  &-footer {
    font-size: 12px;
    color: var(--col-primary-light);
    font-family: "SFProDisplay-Medium";
  }
}
</style>
