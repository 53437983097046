<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 22"
  >
    <path
      d="M0.5 12.125C0.5 11.9592 0.565848 11.8003 0.683058 11.6831C0.800269 11.5658 0.95924 11.5 1.125 11.5H14.875C15.0408 11.5 15.1997 11.5658 15.3169 11.6831C15.4342 11.8003 15.5 11.9592 15.5 12.125C15.5 12.2908 15.4342 12.4497 15.3169 12.5669C15.1997 12.6842 15.0408 12.75 14.875 12.75H1.125C0.95924 12.75 0.800269 12.6842 0.683058 12.5669C0.565848 12.4497 0.5 12.2908 0.5 12.125ZM0.5 8.375C0.5 8.20924 0.565848 8.05027 0.683058 7.93306C0.800269 7.81585 0.95924 7.75 1.125 7.75H14.875C15.0408 7.75 15.1997 7.81585 15.3169 7.93306C15.4342 8.05027 15.5 8.20924 15.5 8.375C15.5 8.54076 15.4342 8.69973 15.3169 8.81694C15.1997 8.93415 15.0408 9 14.875 9H1.125C0.95924 9 0.800269 8.93415 0.683058 8.81694C0.565848 8.69973 0.5 8.54076 0.5 8.375ZM0.5 4.625C0.5 4.45924 0.565848 4.30027 0.683058 4.18306C0.800269 4.06585 0.95924 4 1.125 4H14.875C15.0408 4 15.1997 4.06585 15.3169 4.18306C15.4342 4.30027 15.5 4.45924 15.5 4.625C15.5 4.79076 15.4342 4.94973 15.3169 5.06694C15.1997 5.18415 15.0408 5.25 14.875 5.25H1.125C0.95924 5.25 0.800269 5.18415 0.683058 5.06694C0.565848 4.94973 0.5 4.79076 0.5 4.625ZM0.5 0.875C0.5 0.70924 0.565848 0.550269 0.683058 0.433058C0.800269 0.315848 0.95924 0.25 1.125 0.25H14.875C15.0408 0.25 15.1997 0.315848 15.3169 0.433058C15.4342 0.550269 15.5 0.70924 15.5 0.875C15.5 1.04076 15.4342 1.19973 15.3169 1.31694C15.1997 1.43415 15.0408 1.5 14.875 1.5H1.125C0.95924 1.5 0.800269 1.43415 0.683058 1.31694C0.565848 1.19973 0.5 1.04076 0.5 0.875Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 47 },
    height: { type: [Number, String], default: 47 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
