<template>
  <svg :width="width" :height="height" :fill="iconColor" viewBox="0 0 31 41">
    <path
      d="M30.1384 10.25H21.5282C21.2997 10.25 21.0806 10.16 20.919 9.99969C20.7574 9.83942 20.6667 9.62205 20.6667 9.39539V0.85461C20.6667 0.627954 20.5759 0.41058 20.4143 0.25031C20.2527 0.0900391 20.0336 0 19.8051 0L2.58199 0C1.89764 0.00140691 1.24172 0.271695 0.757814 0.751703C0.273904 1.23171 0.00141835 1.88234 0 2.56117L0 38.4388C0.00141835 39.1177 0.273904 39.7683 0.757814 40.2483C1.24172 40.7283 1.89764 40.9986 2.58199 41H28.418C29.1024 40.9986 29.7583 40.7283 30.2422 40.2483C30.7261 39.7683 30.9986 39.1177 31 38.4388V11.1046C31 10.878 30.9092 10.6606 30.7477 10.5003C30.5861 10.34 30.3669 10.25 30.1384 10.25ZM29.2769 38.4388C29.2769 38.6648 29.1864 38.8815 29.0253 39.0413C28.8643 39.201 28.6458 39.2908 28.418 39.2908H2.58199C2.3542 39.2908 2.13575 39.201 1.97468 39.0413C1.81361 38.8815 1.72312 38.6648 1.72312 38.4388V2.56117C1.72312 2.33522 1.81361 2.11852 1.97468 1.95875C2.13575 1.79898 2.3542 1.70922 2.58199 1.70922H18.9543V9.39539C18.955 10.0751 19.2275 10.7269 19.7121 11.2075C20.1967 11.6882 20.8537 11.9585 21.539 11.9592H29.2876L29.2769 38.4388Z"
    />
    <path
      d="M30.7476 10.5003L20.4143 0.25033C20.3342 0.170966 20.2393 0.10801 20.1347 0.0650587C20.0302 0.022107 19.9181 1.18262e-09 19.805 0C19.6918 -1.18262e-09 19.5798 0.022107 19.4753 0.0650587C19.3707 0.10801 19.2757 0.170966 19.1957 0.25033C19.1157 0.329695 19.0522 0.423914 19.0089 0.527609C18.9656 0.631303 18.9434 0.742443 18.9434 0.854681C18.9434 0.966919 18.9656 1.07806 19.0089 1.18175C19.0522 1.28545 19.1157 1.37967 19.1957 1.45903L29.5237 11.7144C29.6853 11.8746 29.9044 11.9647 30.133 11.9647C30.3615 11.9647 30.5806 11.8746 30.7422 11.7144C30.9038 11.5541 30.9946 11.3367 30.9946 11.11C30.9946 10.8833 30.9038 10.6659 30.7422 10.5057L30.7476 10.5003Z"
    />
    <path
      d="M8.15918 22.3239H9.39381V28.181H8.15918V22.3239ZM8.78455 25.3803H10.2339C10.5345 25.3997 10.8311 25.3044 11.0632 25.114C11.1643 25.0148 11.2427 24.8953 11.2932 24.7635C11.3437 24.6317 11.365 24.4906 11.3558 24.3499C11.3654 24.2084 11.3443 24.0664 11.2938 23.9337C11.2434 23.8009 11.1647 23.6805 11.0632 23.5805C10.8301 23.392 10.5342 23.297 10.2339 23.3143H8.78455V22.3239H10.5372C10.9159 22.3148 11.2907 22.4009 11.6269 22.5741C11.9293 22.7344 12.179 22.9774 12.3462 23.2743C12.5302 23.5991 12.6229 23.9669 12.6146 24.3393C12.6236 24.7118 12.5308 25.0797 12.3462 25.4042C12.176 25.6955 11.9268 25.9335 11.6269 26.0911C11.2903 26.2631 10.9158 26.3491 10.5372 26.3414H8.78455V25.3803Z"
    />
    <path
      d="M13.3447 22.3236H15.602C16.1286 22.3083 16.6507 22.4236 17.1211 22.6591C17.5374 22.8817 17.874 23.2267 18.0846 23.6468C18.3235 24.1385 18.4395 24.68 18.4228 25.2256C18.4386 25.7767 18.3237 26.3239 18.0873 26.823C17.8777 27.2505 17.5416 27.6044 17.1238 27.8373C16.6549 28.0807 16.1309 28.2008 15.602 28.1861H13.3447V22.3236ZM15.4543 27.1717C15.7789 27.1831 16.1006 27.1077 16.3857 26.9534C16.6433 26.8027 16.8455 26.5741 16.9627 26.3011C17.1037 25.964 17.1705 25.601 17.1587 25.2362C17.1691 24.8822 17.1004 24.5303 16.9574 24.2059C16.8364 23.9334 16.6316 23.706 16.3723 23.5563C16.0891 23.4045 15.7707 23.3292 15.449 23.338H14.582V27.1717H15.4543Z"
    />
    <path
      d="M19.2197 22.3242H23.082V23.3359H20.457V24.9093H22.8726V25.8811H20.457V28.1893H19.2197V22.3242Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 31 },
    height: { type: [Number, String], default: 41 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
