<template>
  <div class="dashboard" v-if="dashboard">
    <div class="dashboard__column dashboard__column--highlighted">
      <div class="dashboard__item">
        <div class="dashboard__entry">
          <div class="dashboard__item-title">Numbers of active tss</div>
          <div class="dashboard__item-value">
            {{ ifExist(dashboard.number_active_ps) }}
          </div>
          <IconCity />
        </div>
        <div class="dashboard__entry">
          <div class="dashboard__item-title">TSS users in the system</div>
          <div class="dashboard__item-value">
            {{ ifExist(dashboard.number_users) }}
          </div>
          <IconGroup />
        </div>
        <div class="dashboard__entry">
          <div class="dashboard__item-title">
            New proposals from TSS since last login
          </div>
          <div class="dashboard__item-value">
            {{ ifExist(dashboard.new_proposals_last_login) }}
          </div>
          <IconDocument2 />
        </div>
        <div class="dashboard__entry">
          <div class="dashboard__item-title">Open proposals from TSS total</div>
          <div class="dashboard__item-value">
            {{ ifExist(dashboard.open_proposals) }}
          </div>
          <IconDocument3 />
        </div>
        <div class="dashboard__entry">
          <div class="dashboard__item-title">Stats per TSS</div>
          <div class="dashboard__item-value dashboard__item-value--list">
            <div
              class="dashboard__graph"
              v-for="item in dashboard.tss_statistic"
              :key="item.id"
            >
              <span class="dashboard__graph-title" :title="item.title">{{ ifExist(
                item.title
              )}}</span>
              <span
                class="dashboard__graph-line"
                :style="calculateWidth(item.accepted_proposals_total)"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard__column dashboard__column--default">
      <div class="dashboard__item">
        <div class="dashboard__entries">
          <div class="dashboard__entry dashboard__entry--half">
            <div class="dashboard__item-title">Open Projects</div>
            <div class="dashboard__item-value">
              {{ ifExist(dashboard.active_projects) }}
            </div>
          </div>
          <div class="dashboard__entry dashboard__entry--half">
            <div class="dashboard__item-title">Completed Projects</div>
            <div class="dashboard__item-value">
              {{ ifExist(dashboard.finished_projects) }}
            </div>
          </div>
          <div class="dashboard__entry dashboard__entry--default">
            <div class="dashboard__item-title">Date eldest active project</div>
            <div class="dashboard__item-value">
              {{ ifVal(dashboard.oldest_project.timeline.split("-").join(".")) ? dashboard.oldest_project.timeline.split("-").join(".") : '-' }}
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard__item">
        <div class="dashboard__entries">
          <div class="dashboard__entry dashboard__entry--default">
            <div class="dashboard__item-title">Open feasibilities / all projects</div>
            <div class="dashboard__item-value">
              {{ ifExist(dashboard.open_feasibility) }}<span
                >/{{
                  (ifVal(dashboard.active_projects) ? dashboard.active_projects : 0)
                  + (ifVal(dashboard.finished_projects) ? dashboard.finished_projects : 0)
                }}</span
              >
            </div>
          </div>
          <div class="dashboard__entry dashboard__entry--default">
            <div class="dashboard__item-title">
              Accepted proposals / all projects
            </div>
            <div class="dashboard__item-value">
              {{ ifExist(dashboard.accepted_proposals[0].proposals_count)
              }}<span
                >/{{
                  (ifVal(dashboard.active_projects) ? dashboard.active_projects : 0)
                  + (ifVal(dashboard.finished_projects) ? dashboard.finished_projects : 0)
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard__item">
        <div class="dashboard__entries">
          <div
            class="
              dashboard__entry
              dashboard__entry--half
              dashboard__entry--with-border
            "
          >
            <div class="dashboard__item-title">Draft requests</div>
            <div class="dashboard__item-value">
              {{ ifExist(dashboard.draft_requests) }}
            </div>
          </div>
          <div
            class="
              dashboard__entry
              dashboard__entry--half
              dashboard__entry--with-border
            "
          >
            <div class="dashboard__item-title">No proposals</div>
            <div class="dashboard__item-value">
              {{ ifExist(dashboard.lines_without_proposals) }}
            </div>
          </div>
          <div class="dashboard__entry dashboard__entry--half">
            <div class="dashboard__item-title">Open lines</div>
            <div class="dashboard__item-value">
              {{ ifExist(dashboard.open_proposals) }}
            </div>
          </div>
          <div class="dashboard__entry dashboard__entry--half">
            <div class="dashboard__item-title">Arrived orders</div>
            <div class="dashboard__item-value">
              {{ ifExist(dashboard.cases_arrived_total) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard__column dashboard__column--wide">
      <div class="dashboard__item">
        <div class="dashboard__entry">
          <div class="dashboard__item-title">In transit count</div>
          <div class="dashboard__item-value">
            {{ ifExist(dashboard.orders_in_transit.cases_in_transit_now_count) }}
          </div>
          <IconDocument />
        </div>
        <div class="dashboard__entry">
          <div class="dashboard__item-title">Cases rejected</div>
          <div class="dashboard__item-value">
            {{ ifExist(dashboard.number_active_ps) }}
          </div>
          <IconDocumentRejected />
        </div>
        <div class="dashboard__entry">
          <div class="dashboard__item-title">Accepted ordered sum</div>
          <div class="dashboard__item-value">
            {{ ifVal(dashboard.accepted_proposals[0]) ? ifExist(dashboard.accepted_proposals[0].ordered_sum) : 0 }}
          </div>
          <IconDocumentAccepted />
        </div>
        <div class="dashboard__entry">
          <div class="dashboard__item-title">Orders in transit</div>
          <div class="dashboard__item-value">
            {{ ifExist(dashboard.orders_in_transit.orders_in_transit_count) }}
          </div>
          <IconVehicle />
        </div>
        <div class="dashboard__entry">
          <div class="dashboard__item-title">
            Cases shipped to Cureline Since beginning
          </div>
          <div class="dashboard__item-value">
            {{ ifExist(dashboard.cases_arrived_total) }}
          </div>
          <IconBoxes />
        </div>
        <div class="dashboard__entry">
          <div class="dashboard__item-title">Open QCs to validate</div>
          <div class="dashboard__item-value">
            {{ ifExist(dashboard.open_qc_to_validate) }}
          </div>
          <IconSearch2 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import IconCity from "@/modules/components/icons/IconCity.vue";
import IconGroup from "@/modules/components/icons/IconGroup.vue";
import IconDocument from "@/modules/components/icons/IconDocument.vue";
import IconDocument2 from "@/modules/components/icons/IconDocument2.vue";
import IconDocument3 from "@/modules/components/icons/IconDocument3.vue";
import IconDocumentRejected from "@/modules/components/icons/IconDocumentRejected.vue";
import IconDocumentAccepted from "@/modules/components/icons/IconDocumentAccepted.vue";
import IconVehicle from "@/modules/components/icons/IconVehicle.vue";
import IconBoxes from "@/modules/components/icons/IconBoxes.vue";
import IconSearch2 from "@/modules/components/icons/IconSearch2.vue";

export default {
  name: "Dashboard",
  components: {
    IconCity,
    IconGroup,
    IconDocument,
    IconDocument2,
    IconDocument3,
    IconDocumentRejected,
    IconDocumentAccepted,
    IconVehicle,
    IconBoxes,
    IconSearch2,
  },
  setup() {
    const store = useStore();
    const dashboard = computed(() => {
      const dash = store.getters["auth/dashboard"];
      if (dash && dash.tss_statistic) {
        dash.tss_statistic.forEach((el) => {
          if (el.accepted_proposals_total > maxAccepted.value) {
            maxAccepted.value = el.accepted_proposals_total;
          }
        });
      }

      return dash;
    });
    const maxAccepted = ref(0);

    const calculateWidth = (val) => {
      return `width: ${(val / maxAccepted.value) * 100}%`;
    };

    const ifExist = (val) => {
      return val ? val : '-';
    };

    const ifVal = (val) => {
      return !!val;
    };

    return {
      dashboard,
      calculateWidth,
      ifExist,
      ifVal
    };
  },
};
</script>

<style scoped lang="scss">
.dashboard {
  display: flex;
  min-width: 982px;
  justify-content: space-between;
  color: #5b5b5b;
  margin-bottom: 80px;

  &__column {
    width: 27%;

    &--default {
      .dashboard__item {
        border-top: 4px solid #4aa0b5;
        border-radius: 3px;
        background: #fff;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
        margin-bottom: 13px;
      }

      .dashboard__item-title {
        margin: 0 auto;
      }

      .dashboard__entries {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
      }

      .dashboard__entry {
        &--half {
          width: 50%;
        }

        &:nth-child(2),
        &:nth-child(4) {
          border-left: 1px dashed #ccc;
        }

        &--with-border {
          border-bottom: 1px dashed #ccc;
          padding-bottom: 42px;

          &:nth-child(2) ~ .dashboard__entry--half {
            padding: 23px 0 33px;
          }
        }

        &--default {
          width: 100%;
          border-top: 1px dashed #ccc;
          padding: 11px 0 0;

          &:first-child {
            padding: 0;
            border: 0;

            .dashboard__item-value {
              padding-bottom: 14px;
            }

            + .dashboard__entry--default {
              border-left: 0;
            }
          }

          &:nth-child(2) {
            .dashboard__item-value {
              padding: 3px 0 8px;
            }
          }

          .dashboard__item-value {
            font-size: 24px;
            padding: 6px 0 20px;
          }
        }
      }

      .dashboard__item-title {
        font-size: 16px;
        padding: 3px 0 0;
        text-align: center;
        color: #4aa0b5;
      }

      .dashboard__item-value {
        text-align: center;
        font-size: 62px;
        font-weight: bold;
        padding-top: 18px;
      }
    }

    &--wide {
      width: 44.5%;

      .dashboard__item {
        border-top: 4px solid #4aa0b5;
        border-radius: 3px;
        background: #fff;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
        margin-bottom: 13px;
      }

      .dashboard__entry {
        position: relative;
        padding: 10px 0 16px 27px;
        border-bottom: 1px dashed #ccc;
        margin-bottom: 11px;

        &:last-child {
          padding-bottom: 29px;
          border: 0;
        }

        &:nth-child(1) {
          svg {
            right: 87px;
            position: absolute;
            top: 22px;
          }
        }

        &:nth-child(2) {
          svg {
            right: 84px;
            position: absolute;
            top: 24px;
          }
        }

        &:nth-child(3) {
          svg {
            right: 84px;
            position: absolute;
            top: 24px;
          }
        }

        &:nth-child(4) {
          svg {
            position: absolute;
            right: 69px;
            top: 27px;
          }
        }

        &:nth-child(5) {
          svg {
            position: absolute;
            right: 80px;
            top: 25px;
          }
        }

        &:nth-child(6) {
          svg {
            position: absolute;
            right: 80px;
            top: 30px;
          }
        }
      }

      .dashboard__item-title {
        font-size: 16px;
        padding: 3px 0 0;
        color: #4aa0b5;
      }
      .dashboard__item-value {
        font-size: 60px;
        font-weight: bold;
        line-height: 76px;
      }
    }

    &--highlighted {
      box-shadow: none;
      border: 0;

      .dashboard__entry {
        background: #53b8cf;
        padding: 12px 0 16px 20px;
        font-size: 16px;
        color: #fff;
        position: relative;

        &:nth-child(1) {
          svg {
            position: absolute;
            right: 16px;
            top: 23px;
          }
        }
        &:nth-child(2) {
          background: #51afc5;
          svg {
            position: absolute;
            right: 12px;
            top: 53px;
          }
        }
        &:nth-child(3) {
          background: #4da7bd;
          svg {
            position: absolute;
            right: 18px;
            top: 35px;
          }
        }
        &:nth-child(4) {
          background: #4a9db3;
          svg {
            position: absolute;
            right: 21px;
            top: 37px;
          }
        }
        &:nth-child(5) {
          background: #4290a4;
          svg {
            position: absolute;
          }
        }
      }

      .dashboard__item-title {
        height: 48px;
        width: 180px;
        max-width: 100%;
      }

      .dashboard__item-value {
        font-size: 62px;
      }
    }
  }

  .dashboard__item-value--list {
    height: 100px;
    overflow: auto;
  }

  &__graph {
    display: flex;
    padding-right: 20px;
  }

  &__graph-title {
    font-size: 14px;
    font-family: "SFProDisplay-Bold", serif;
    min-width: 80px;
    max-width: 80px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  &__graph-line {
    background: #fff;
    border-left: 5px solid #fff;
    height: 15px;
    border-radius: 3px;
  }
}
</style>
