<template>
  <div
    v-if="storedProject && Object.keys(storedProject).length"
    class="new-project-page"
  >
    <div class="new-project-main">
      <div class="breadcrumbs-wrapper">
        <div class="back-btn" @click="router.go(-1)">Go Back</div>
      </div>

      <div
        v-if="storedProject && Object.keys(storedProject).length"
        class="new-project"
      >
        <div class="new-project-client">
          <div class="project-heading">
            <h2 class="heading">Edit Feasibility Project:</h2>

            <div class="project-number">
              <h2 class="green" style="opacity: 0.3">
                Project# {{ project.project_number }}
              </h2>
            </div>

            <div class="heading-tagline">! Visible to administrators only</div>
          </div>

          <div class="project-heading_block">
            <label for="study-name">Study Name</label>
            <input
              type="text"
              id="study-name"
              class="highlighted"
              placeholder="Study Name"
              v-model="project.study_name"
            />
          </div>

          <div class="project-heading_block">
            <label for="сlient-сompany"
              >Client Company <span class="red">*</span></label
            >
            <input
              type="text"
              id="сlient-сompany"
              class="highlighted"
              placeholder="Client Company"
              v-model="project.client_company"
              required
            />
          </div>

          <div class="project-heading_block">
            <label for="сontact-name"
              >Contact Name <span class="red">*</span></label
            >
            <input
              type="text"
              id="сontact-name"
              class="highlighted"
              placeholder="Contact Name"
              v-model="project.contact_name"
              required
            />
          </div>

          <div class="project-heading_block">
            <label for="contact-phone"
              >Contact Phone <span class="red">*</span></label
            >
            <input
              type="tel"
              id="contact-phone"
              class="highlighted"
              placeholder="Contact Phone"
              v-model="project.contact_phone"
              required
            />
          </div>

          <div class="project-heading_block">
            <label for="contact-email"
              >Contact Email <span class="red">*</span></label
            >
            <input
              type="email"
              id="contact-email"
              class="highlighted"
              placeholder="Contact Email"
              v-model="project.contact_email"
              required
            />
          </div>
        </div>

        <div class="field-container">
          <label>
            Feasibility Title
            <span class="red" style="font-size: 20px">*</span>
            <span class="heading-small">(Future project name)</span>
          </label>
          <div>
            <input
              type="text"
              class="textarea"
              v-model="project.description_short"
              required
            />
          </div>
        </div>

        <div class="field-container_short_wrapper">
          <div class="field-container field-container_short">
            <label>Timeline</label>
            <div>
              <input type="text" class="textarea" v-model="project.timeline" />
            </div>
          </div>

          <div class="field-container field-container_short">
            <label>
              # Donors <span class="red" style="font-size: 20px">*</span>
            </label>
            <div>
              <input
                type="number"
                class="textarea"
                v-model="project.donors_number"
                required
              />
            </div>
          </div>

          <div class="field-container field-container_short">
            <label>Suggested Price</label>
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <span style="margin-right: 5px; font-size: 20px; color: #828282"
                >$</span
              >
              <input
                type="text"
                class="textarea"
                v-model="project.suggested_price"
              />
            </div>
          </div>
        </div>

        <div class="field-container">
          <label>
            Indication <span class="red" style="font-size: 20px">*</span>
          </label>
          <div>
            <input
              type="text"
              class="textarea"
              v-model="project.indication"
              placeholder="NHL / MM / Serum"
              required
            />
          </div>
        </div>

        <hr style="margin: 10px 0 30px" />

        <div class="new-project-lines">
          <label>Scope of Work (SOW)</label>
          <ProjectLineList
            :lineList="lines"
            :get-line-info="getLineInfo"
            :project-id="storedProject.id"
            @add-line="saveProject"
            @remove-line="saveProject"
          />
        </div>

        <hr style="margin: 40px 0 10px" />

        <div class="new-project-fieldset">
          <div class="field-container">
            <label>Donor Characteristics</label>
            <textarea
              rows="2"
              class="textarea"
              v-model="project.donor_characteristics"
            />
          </div>

          <div class="field-container">
            <label>Inclusion Criteria</label>
            <textarea
              rows="2"
              class="textarea"
              v-model="project.inclusion_criteria"
            />
          </div>

          <div class="field-container">
            <label>Exclusion Criteria</label>
            <textarea
              rows="3"
              class="textarea"
              v-model="project.exclusion_criteria"
            />
          </div>

          <div class="field-container">
            <label>Preservation/Procurement</label>
            <textarea
              rows="2"
              class="textarea"
              v-model="project.preservation"
            />
          </div>

          <div class="field-container">
            <label>Clinical information</label>
            <textarea
              rows="2"
              class="textarea"
              v-model="project.clinical_info"
            />
          </div>

          <AdditionalFields />
        </div>
      </div>
    </div>

    <!--sidebar-->
    <div class="sidebar">
      <ButtonsBlock
        v-if="categories"
        title="Pathology"
        :buttonsList="categories"
        :is-approved="false"
      />

      <div class="field-container">
        <h6>Request public note:</h6>
        <div>
          <textarea rows="1" class="textarea" v-model="project.public_note" />
        </div>
      </div>

      <div class="field-container">
        <h6>Cureline internal note:</h6>
        <div>
          <textarea rows="1" class="textarea" v-model="project.private_note" />
        </div>
      </div>

      <hr class="dashed" />

      <EditVisibility :project="storedProject" @select="$emit('select')" />

      <div class="field-container">
        <hr class="dashed" style="margin-bottom: 10px" />
        <FileUploading />
      </div>

      <BaseButton
        class-name="big typical"
        text="Save as feasibility"
        style="margin-bottom: 20px"
        :disabled="isSavedInfo"
        @click="saveProject"
      />

      <h4 v-if="isSavedInfo" class="green center" style="margin-bottom: 20px">
        ... information is saved
      </h4>

      <BaseButton
        text="Delete this project"
        class-name="error big"
        @click="$emit('delete-project')"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import ProjectLineList from "@/components/base/ProjectLineList.vue";
import EditVisibility from "@/components/project/EditVisibility.vue";
import FileUploading from "@/components/project/FileUploading.vue";
import AdditionalFields from "@/components/project/AdditionalFields.vue";

import BaseButton from "@/modules/components/base/BaseButton.vue";
import ButtonsBlock from "@/modules/components/ButtonsBlock.vue";

export default {
  components: {
    ProjectLineList,
    EditVisibility,
    FileUploading,
    AdditionalFields,
    ButtonsBlock,
    BaseButton,
  },
  emits: ["delete-project", "select"],
  setup() {
    const store = useStore();
    const router = useRouter();

    const project = ref({});
    const isSavedInfo = ref(false);

    const storedProject = computed(() => store.getters["projects/project"]);

    const categories = computed(() => store.getters["projects/categories"]);
    const lines = computed(() => store.getters["projects/lines"]);

    const getLineInfo = ref(1);

    onMounted(() => {
      project.value = storedProject.value;
    });

    // const addLine = () => {
    //   saveProject();
    //   store.dispatch("projects/addNewLine", { project_id: project.value.id });
    // };

    // const editLine = (data) => {
    //   // saveProject();
    //   // store.dispatch("projects/editLine", line);
    // };

    // const removeLine = (id) => {
    //   saveProject(id);
    // };

    const validateProject = () => {
      const p = project.value;
      let payload = {
        isShown: true,
        text: "Files are saved",
        status: "error",
      };
      let errorsText = [];
      if (!p.id) {
        console.log("No project identifier", p);
      }
      if (!p.client_company) {
        errorsText.push("company name");
      }
      if (!p.contact_email) {
        errorsText.push("contact email");
      }
      if (!p.contact_name) {
        errorsText.push("contact name");
      }
      if (!p.contact_phone) {
        errorsText.push("contact phone");
      }
      if (!p.donors_number) {
        errorsText.push("donors number");
      }
      if (!p.description_short) {
        errorsText.push("feasibility title");
      }
      if (!p.indication) {
        errorsText.push("project indication");
      }

      if (errorsText.length) {
        payload.text = "Please do not forget: " + errorsText.join(", ");
        store.commit("admins/SET_NOTIFICATION", payload);

        setTimeout(() => {
          store.commit("admins/CLEAN_NOTIFICATION");
        }, 10000);

        return false;
      }

      return true;
    };

    const prepareData = () => {
      const dataForm = project.value;
      return {
        project_number: dataForm.project_number,
        id: dataForm.id,
        status: dataForm.status,
        study_name: dataForm.study_name,
        client_company: dataForm.client_company,
        contact_name: dataForm.contact_name,
        contact_phone: dataForm.contact_phone,
        contact_email: dataForm.contact_email,
        indication: dataForm.indication,
        suggested_price: dataForm.suggested_price,
        donors_number: dataForm.donors_number
          ? dataForm.donors_number.toString()
          : "",
        description_short: dataForm.description_short,
        timeline: dataForm.timeline,
        bio_material: dataForm.bio_material,
        donor_characteristics: dataForm.donor_characteristics,
        inclusion_criteria: dataForm.inclusion_criteria,
        exclusion_criteria: dataForm.exclusion_criteria,
        preservation: dataForm.preservation,
        clinical_info: dataForm.clinical_info,
        public_note: dataForm.public_note,
        private_note: dataForm.private_note,
        visibility: dataForm.visibility,
        categories:
          categories.value && categories.value.length
            ? categories.value
                .filter((item) => item.isChosen === true)
                .map(({ id }) => id)
            : "",
        project_visibility:
          project.value.project_visibility &&
          project.value.project_visibility.length
            ? project.value.project_visibility.map(({ id }) => id)
            : "",
      };
    };

    const saveProject = async (emitedData) => {
      getLineInfo.value++;

      if (!validateProject()) {
        return;
      }

      if (!project.value.id) {
        return false;
      }

      const requestData = prepareData();

      if (emitedData) {
        if (emitedData.type === "addLine") {
          await store.dispatch("projects/addNewLine", {
            project_id: project.value.id,
          });
        }
        if (emitedData.type === "editLine") {
          await store.dispatch("projects/editLine", emitedData.line);
        }
        if (emitedData.type === "removeItem") {
          await store.dispatch("projects/removeLine", emitedData.line);
        }
      }

      await store.dispatch("projects/saveProject", requestData);

      isSavedInfo.value = true;
      const hideHint = () => (isSavedInfo.value = false);
      setTimeout(hideHint, 3000);

      // if (requestData.status === "feasibility") {
      //   router.push({
      //     path: `/pending-feasibility/${project.value.id}`,
      //     // query: { p: project.value.id },
      //   });
      // } else {
      //   router.push({
      //     path: "/project-list",
      //     query: { p: project.value.id },
      //   });
      // }
    };

    watch(storedProject, (newValue) => {
      project.value = newValue;
    });

    return {
      project,
      storedProject,
      lines,
      categories,
      router,
      getLineInfo,
      saveProject,
      isSavedInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  color: var(--col-additional);
  cursor: pointer;
}
// .field-container {
//   padding: 6px 0;
//   &_short_wrapper {
//     display: flex;
//     justify-content: space-between;
//   }
//   &_short {
//     width: 25%;
//   }
// }
// move to modules:
label,
input {
  font-size: 16px;
  line-height: 24px;
  font-family: "OpenSans-Regular", sans-serif;
  color: var(--col-text-secondary);
}
input {
  margin: 7px 0;
  padding: 6px 12px;
}
.highlighted {
  border: 1px solid var(--col-border);
  border-radius: 4px;
  color: var(--col-contrast-text);
  background-color: var(--col-info);

  &:active,
  &:focus,
  &:focus-visible {
    border-color: var(--col-info);
  }

  &::placeholder {
    color: #ffffff;
  }
}
// .project-heading {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-basis: 100%;

//   &_block {
//     flex-basis: 30%;
//   }
// }
.heading {
  flex-basis: 30%;
  font-size: 26px;
  font-weight: normal;
  font-family: "SFProDisplay-Medium", sans-serif;

  &-small {
    padding-left: 5px;
    font-size: 12px;
    font-weight: 400;
  }
}
.heading-tagline {
  position: relative;
  margin-right: 20px;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--col-contrast-text);
  background-color: var(--col-info);
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 5px 5px 0px 5px;
    border-color: var(--col-info) transparent transparent transparent;
  }
}
.project-number {
  position: relative;
  // flex-basis: 70%;
  // padding-right: 30%;
  &-label {
    position: absolute;
    top: -30px;
    left: 0;
  }
  &-input-wrapper {
    border: 4px solid var(--col-bg-attention);
    border-radius: 6px;

    input {
      margin: 0;
      border-radius: 4px;
      border: 1px solid gray;
    }
  }
}
</style>
