const PrintElem = (elem) => {
  const UA = navigator.userAgent;
  console.log(/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA));
  console.log(/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA));
  console.log(/\b(Linux)\b/i.test(UA));

  console.log("before window.open");
  let w = window.open("", "", "height=900,width=900");
  console.log("after window.open");
  w.document.write("<html><head><title>Print</title>");
  w.document.write(
    "<style>p,h3{padding:0;margin:0}.document{padding: 20px 5px 5px 30px}.document-wrapper{background-color:#fff}.document-head{margin-bottom:30px}.document-head-title{display:flex;justify-content:space-between;align-items:center;margin-bottom:20px}.document-head-title-left{text-align:right}.document-head-client>p{display:flex;justify-content:flex-start;align-items:baseline}.document-head-client>p>b{display:block;min-width:100px;padding-right:10px}.document-body-heading{padding:10px;text-align:center}.document-body-main{display:flex;border-bottom:1px dashed #ced4da;margin-bottom:10px}.document-body-main>div{flex:1;border-top:1px dashed #ced4da;border-right:1px dashed #ced4da}.document-body-main>div:last-child{border-right-width:0}.document-body-main-project>p{padding-right:20px}.document-body-section{margin-bottom:10px}.document-body-line{border-bottom:1px dashed #ced4da}.bold{font-weight:bold}.spaced{padding:10px 20px}.bordered{border-top:1px dashed #ced4da}.green{color:#17a2b8}.grey{color:#6c757d}.red{color:#dc3545}</style>"
  );
  w.document.write(
    "<style>@media print{p,h3{padding:0;margin:0}.document{padding: 20px 5px 5px 30px}.document-wrapper{background-color:#fff}.document-head{margin-bottom:30px}.document-head-title{display:flex;justify-content:space-between;align-items:center;margin-bottom:20px}.document-head-title-left{text-align:right}.document-head-client>p{display:flex;justify-content:flex-start;align-items:baseline}.document-head-client>p>b{display:block;min-width:100px;padding-right:10px}.document-body-heading{padding:10px;text-align:center}.document-body-main{display:flex;border-bottom:1px dashed #ced4da;margin-bottom:10px}.document-body-main>div{flex:1;border-top:1px dashed #ced4da;border-right:1px dashed #ced4da}.document-body-main>div:last-child{border-right-width:0}.document-body-main-project>p{padding-right:20px}.document-body-section{margin-bottom:10px}.document-body-line{border-bottom:1px dashed #ced4da}.bold{font-weight:bold}.spaced{padding:10px 20px}.bordered{border-top:1px dashed #ced4da}.green{color:#17a2b8}.grey{color:#6c757d}.red{color:#dc3545}}</style>"
  );
  w.document.write("</head><body>");
  console.log("document was created");

  // iframe = document.getElementById("iframe-id");

  w.document.write(document.getElementById(elem).innerHTML);
  w.document.write("</body></html>");
  // w.document.close();
  w.focus(); // necessary for IE >= 10*/

  let qw = document.getElementById(elem).innerHTML;
  // var printed = qw.contentWindow.document.execCommand("print", false, null);
  var printed = qw.contentWindow;
  console.log(printed);
  // if (!printed) w.print();

  w.print();
  w.close();
};

export default PrintElem;
