import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    redirect: "/project-list",
    meta: { authOnly: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: { authOnly: false },
    // meta: { guestOnly: true },
  },
  {
    path: "/project-list",
    name: "ProjectList",
    component: () => import("@/views/project/ProjectList.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/project/:id",
    name: "ProjectEdit",
    component: () => import("@/views/project/ProjectEdit.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/project-tracking",
    name: "ProjectTracking",
    component: () => import("@/views/project/ProjectTracking.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/track-shipping",
    name: "TrackShipping",
    component: () => import("@/views/TrackShipping.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/quality-shipping",
    name: "QualityControl",
    component: () => import("@/views/QualityControl.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/answered-leads",
    name: "AnsweredLeads",
    component: () => import("@/views/AnsweredLeads.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/procurement-site/:id",
    name: "TSS",
    component: () => import("@/views/site/TSS.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/pending-feasibility",
    name: "FeasibilityList",
    component: () => import("@/views/FeasibilityList.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/pending-feasibility/:id",
    name: "ProjectFeasibility",
    component: () => import("@/views/project/ProjectFeasibility.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/pending-feasibility-details/:id",
    name: "ProjectFeasibilityDetails",
    component: () => import("@/views/project/ProjectFeasibilityDetails.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/active-projects",
    name: "ActiveList",
    component: () => import("@/views/ActiveList.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/active/:id",
    name: "ProjectActive",
    component: () => import("@/views/project/ProjectActive.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/active-details/:id",
    name: "ProjectActiveDetails",
    component: () => import("@/views/project/ProjectActiveDetails.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/archive-list",
    name: "ArchiveList",
    component: () => import("@/views/ArchiveList.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/archive-details/:id",
    name: "ProjectArchive",
    component: () => import("@/views/project/ProjectArchive.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/user-details/:id",
    name: "AdminDetails",
    component: () => import("@/views/admin/AdminDetails.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/user-account",
    name: "UseAccount",
    component: () => import("@/views/UserAccount.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/messenger",
    name: "Messenger",
    component: () => import("@/views/Messenger.vue"),
    meta: { authOnly: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function isLoggedIn() {
  return localStorage.getItem("authToken");
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    if (!isLoggedIn()) {
      store.commit("auth/SET_AUTHENTICATED", false);
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
    // } else if (to.matched.some((record) => record.meta.guestOnly)) {
    //   next();
    //   if (isLoggedIn) {
    //     next({ path: "/project-list" });
    //   } else {
    //     console.log(5);
    //   }
  } else {
    // store.commit("auth/SET_AUTHENTICATED", false);
    next();
  }
});

export default router;
