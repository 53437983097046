<template>
  <AddSiteModal
    v-if="showAddSiteModal"
    @close="showAddSiteModal = false"
  ></AddSiteModal>
  <div class="breadcrumbs-wrapper">
    <p><router-link class="link" to="">Tissue Source Sites</router-link> /</p>
  </div>

  <div class="sites-container">
    <div class="sites-wrapper">
      <div class="adding-btn-wrapper">
        <button class="adding-btn" @click="showAddSiteModal = true">
          <IconAdd />
          <p class="adding-btn-title">Add a new Tissue Source Site</p>
        </button>
      </div>

      <template v-if="sites">
        <div class="site-preview" v-for="site in sites" :key="site">
          <div class="site-avatar"><img :src="`${site.avatar}`" alt="" /></div>
          <div class="site-preview-info">
            <div class="site-preview-info-inner">
              <h4 class="site-title">{{ site.title }}</h4>
              <p class="site-description">{{ site.description_short }}</p>
            </div>
            <router-link
              :to="{ name: 'TSS', params: { id: `${site.id}` } }"
              class="btn site-preview-link"
              >More</router-link
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import IconAdd from "@/modules/components/icons/IconAdd";
import AddSiteModal from "./AddSiteModal.vue";

export default {
  components: {
    IconAdd,
    AddSiteModal,
  },
  setup() {
    const showAddSiteModal = ref(false);
    const inProcess = ref(false);
    const store = useStore();
    const sites = computed(() => store.getters["site/sites"]);

    onMounted(() => {
      if (sites.value && sites.value.length) {
        return;
      }
      store.dispatch("site/loadSites");
    });
    return {
      showAddSiteModal,
      inProcess,
      sites,
    };
  },
};
</script>

<style lang="scss" scoped>
.sites-container {
  min-height: calc(100vh - 185px);
}

.sites-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin: 0 -15px;
}

.site {
  &-preview {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    // min-width: 200px;
    margin: 0 15px 46px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background: #ffffff;

    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      padding: 16px;

      &-inner {
        flex: 1;
      }
    }

    &-link {
      display: block;
      width: 100%;
      margin-top: 15px;
      color: #ffffff;
      font-size: 16px;
      padding: 3px 15px;
      background: #17a2b8;

      &:hover {
        background: #17889a;
      }
    }
  }

  &-avatar {
    overflow: hidden;
    max-height: 110px;
  }

  &-title {
    font-size: 16px;
    font-family: "OpenSans-Semibold";
    color: var(--col-text-secondary);
  }

  &-description {
    padding-top: 10px;
    font-size: 14px;
    font-family: "OpenSans-Regular";
    color: var(--col-text-secondary);
  }
}
.adding-btn-wrapper {
  max-width: 200px;
}

.btn-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  margin: 0 15px 46px;
  padding: 2rem;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #ffffff;

  svg {
    display: inline-block;
    margin-bottom: 15px;
  }

  &:hover {
    background-color: var(--col-bg-primary);
  }
}
</style>
