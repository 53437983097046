<template>
  <div v-if="user && Object.keys(user).length">
    <div v-if="isSuperAdmin" class="navigation-button-container">
      <BaseButton
        class-name="nav-btn info big"
        :class="{ inactive: !isShownAdminList }"
        text="Manage Cureline Users"
        @click="isShownAdminList = true"
        >Manage Cureline Users</BaseButton
      >
      <div class="navigation-space"></div>
      <BaseButton
        class-name="nav-btn info big"
        :class="{ inactive: isShownAdminList }"
        text="Tissue Source Sites"
        @click="isShownAdminList = false"
      ></BaseButton>
    </div>
    <transition>
      <div class="view-wrapper">
        <div v-if="isSuperAdmin && isShownAdminList">
          <AdminList />
        </div>
        <div v-else>
          <FeedSites />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import BaseButton from "@/modules/components/base/BaseButton.vue";
import FeedSites from "@/components/psites/FeedSites.vue";
import AdminList from "@/components/admin/AdminList.vue";

export default {
  components: { BaseButton, FeedSites, AdminList },
  setup() {
    const store = useStore();
    const isShownAdminList = ref(true);
    const user = computed(() => store.getters["auth/user"]);
    const isSuperAdmin = computed(() => user.value.role === "superadmin");

    return { isShownAdminList, user, isSuperAdmin };
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  &-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > .nav-btn {
      flex-grow: 1;
      &.inactive {
        background-color: var(--col-contrast-text);
        border: 1px solid var(--col-info);
        color: var(--col-info);
      }
    }
  }

  &-space {
    width: 20px;
  }
}
.view-wrapper {
  padding: 30px 0;
}
</style>
