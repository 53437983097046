<template>
  <div
    v-if="isChecked"
    class="custom-checkbox ticked"
    @click="makeChose"
  ></div>
  <div
    v-else
    class="custom-checkbox empty"
    @click="makeChose"
  ></div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  emits: ["set-checkbox-value"],
  setup(props, { emit }) {
    const isChecked = ref(props.checked);

    const makeChose = () => {
      isChecked.value = !isChecked.value;
      emit("set-checkbox-value", isChecked.value);
    };

    return { isChecked, makeChose };
  },
};
</script>