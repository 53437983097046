<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4490:64960)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 20.6382C10 20.6382 17.5 13.5307 17.5 8.13818C17.5 6.14906 16.7098 4.24141 15.3033 2.83488C13.8968 1.42836 11.9891 0.638184 10 0.638184C8.01088 0.638184 6.10322 1.42836 4.6967 2.83488C3.29018 4.24141 2.5 6.14906 2.5 8.13818C2.5 13.5307 10 20.6382 10 20.6382ZM10 11.8882C10.9946 11.8882 11.9484 11.4931 12.6517 10.7898C13.3549 10.0866 13.75 9.13275 13.75 8.13818C13.75 7.14362 13.3549 6.18979 12.6517 5.48653C11.9484 4.78327 10.9946 4.38818 10 4.38818C9.00544 4.38818 8.05161 4.78327 7.34835 5.48653C6.64509 6.18979 6.25 7.14362 6.25 8.13818C6.25 9.13275 6.64509 10.0866 7.34835 10.7898C8.05161 11.4931 9.00544 11.8882 10 11.8882V11.8882Z"
        :fill="iconColor"/>
    </g>
    <defs>
      <clipPath id="clip0_4490:64960">
      <rect width="20" height="20" fill="white" transform="translate(0 0.638184)"/>
    </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 20 },
    height: { type: [Number, String], default: 21 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
