import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {
    adminList: [],
    admin: {},
    adminRoles: [
      { id: 1, value: "admin", title: "Admin" },
      { id: 2, value: "superadmin", title: "Super Admin" },
      { id: 3, value: "underwriter", title: "Underwriter" },
    ],
    isNotificationShown: false,
    notificationText: "",
    notificationStatus: "",
  },
  getters: {
    adminList: (state) => state.adminList,
    admin: (state) => state.admin,
    adminRoles: (state) => state.adminRoles,
    isNotificationShown: (state) => state.isNotificationShown,
    notificationText: (state) => state.notificationText,
    notificationStatus: (state) => state.notificationStatus, // 'success' || 'error'
  },
  mutations: {
    GET_ADMIN(state, payload) {
      state.admin = payload;
    },
    GET_ADMIN_LIST(state, payload) {
      state.adminList = payload;
    },
    SET_ADMIN(state, payload) {
      state.admin = payload;
    },
    PUSH_ADMIN(state, payload) {
      state.adminList.push(payload);
    },
    REMOVE_ADMIN(state, payload) {
      state.adminList.findIndex((admin) => admin.id === payload);
    },
    SET_NOTIFICATION(state, payload) {
      state.isNotificationShown = payload.isShown;
      state.notificationText = payload.text;
      state.notificationStatus = payload.status;
    },
    CLEAN_NOTIFICATION(state) {
      state.isNotificationShown = false;
      state.notificationText = "";
      state.notificationStatus = "";
    },
  },
  actions: {
    async getAdminList({ commit }) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .get("/admin")
        .then((response) => {
          if (response.data.status === "Success") {
            commit("GET_ADMIN_LIST", response.data.data);
          }
        })
        .catch((error) => {
          console.log(456, error);
          // if (response.status === "Error") {
          //   let payload = {
          //     isShown: true,
          //     text: response.message,
          //     status: "error",
          //   };
          //   commit("SET_NOTIFICATION", payload, { root: true });

          //   setTimeout(() => {
          //     commit("CLEAN_NOTIFICATION", {}, { root: true });
          //   }, 3000);
          // }
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async getAdmin({ commit }, { id }) {
      commit("GET_ADMIN", {});
      commit("projects/LOADING_STATE", true, { root: true });
      await axios.get(`/admin/${id}`).then((response) => {
        if (response.data.status === "Success") {
          commit("GET_ADMIN", response.data.data);
        }
      });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async createAdmin({ commit }, data) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios.post("/admin", data).then((response) => {
        commit("SET_ADMIN", response.data.data);
        commit("PUSH_ADMIN", response.data.data);
      });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async editAdmin({ commit }, { id, data }) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios.post(`/admin/${id}`, data).then((response) => {
        commit("SET_ADMIN", response.data.data);
      });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    removeAdmin({ commit }, id) {
      commit("REMOVE_ADMIN", id);
      axios.delete(`/admin/${id}`);
    },
  },
};

// admin list
