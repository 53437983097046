<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 76 85"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M29.2266 57.9185H26.5599C25.8236 57.9185 25.2266 58.5155 25.2266 59.2518C25.2266 59.9881 25.8236 60.5851 26.5599 60.5851H29.2266C29.9629 60.5851 30.5599 59.9881 30.5599 59.2518C30.5599 58.5155 29.9629 57.9185 29.2266 57.9185Z" :fill="iconColor"/>
    <path d="M66.5599 57.9185H34.5599C33.8236 57.9185 33.2266 58.5155 33.2266 59.2518C33.2266 59.9881 33.8236 60.5851 34.5599 60.5851H66.5599C67.2962 60.5851 67.8932 59.9881 67.8932 59.2518C67.8932 58.5155 67.2962 57.9185 66.5599 57.9185Z" :fill="iconColor"/>
    <path d="M29.2266 49.9185H26.5599C25.8236 49.9185 25.2266 50.5155 25.2266 51.2518C25.2266 51.9881 25.8236 52.5851 26.5599 52.5851H29.2266C29.9629 52.5851 30.5599 51.9881 30.5599 51.2518C30.5599 50.5155 29.9629 49.9185 29.2266 49.9185Z" :fill="iconColor"/>
    <path d="M66.5599 49.9185H34.5599C33.8236 49.9185 33.2266 50.5155 33.2266 51.2518C33.2266 51.9881 33.8236 52.5851 34.5599 52.5851H66.5599C67.2962 52.5851 67.8932 51.9881 67.8932 51.2518C67.8932 50.5155 67.2962 49.9185 66.5599 49.9185Z" :fill="iconColor"/>
    <path d="M29.2266 41.9185H26.5599C25.8236 41.9185 25.2266 42.5155 25.2266 43.2518C25.2266 43.9881 25.8236 44.5851 26.5599 44.5851H29.2266C29.9629 44.5851 30.5599 43.9881 30.5599 43.2518C30.5599 42.5155 29.9629 41.9185 29.2266 41.9185Z" :fill="iconColor"/>
    <path d="M66.5599 41.9185H34.5599C33.8236 41.9185 33.2266 42.5155 33.2266 43.2518C33.2266 43.9881 33.8236 44.5851 34.5599 44.5851H66.5599C67.2962 44.5851 67.8932 43.9881 67.8932 43.2518C67.8932 42.5155 67.2962 41.9185 66.5599 41.9185Z" :fill="iconColor"/>
    <path d="M29.2266 33.9185H26.5599C25.8236 33.9185 25.2266 34.5155 25.2266 35.2518C25.2266 35.9881 25.8236 36.5851 26.5599 36.5851H29.2266C29.9629 36.5851 30.5599 35.9881 30.5599 35.2518C30.5599 34.5155 29.9629 33.9185 29.2266 33.9185Z" :fill="iconColor"/>
    <path d="M66.5599 33.9185H34.5599C33.8236 33.9185 33.2266 34.5155 33.2266 35.2518C33.2266 35.9881 33.8236 36.5851 34.5599 36.5851H66.5599C67.2962 36.5851 67.8932 35.9881 67.8932 35.2518C67.8932 34.5155 67.2962 33.9185 66.5599 33.9185Z" :fill="iconColor"/>
    <path d="M29.2266 25.9185H26.5599C25.8236 25.9185 25.2266 26.5155 25.2266 27.2518C25.2266 27.9881 25.8236 28.5851 26.5599 28.5851H29.2266C29.9629 28.5851 30.5599 27.9881 30.5599 27.2518C30.5599 26.5155 29.9629 25.9185 29.2266 25.9185Z" :fill="iconColor"/>
    <path d="M66.5599 25.9185H34.5599C33.8236 25.9185 33.2266 26.5155 33.2266 27.2518C33.2266 27.9881 33.8236 28.5851 34.5599 28.5851H66.5599C67.2962 28.5851 67.8932 27.9881 67.8932 27.2518C67.8932 26.5155 67.2962 25.9185 66.5599 25.9185Z" :fill="iconColor"/>
    <path d="M75.8759 20.501C75.8692 20.3841 75.8464 20.2688 75.8079 20.1583C75.7932 20.1156 75.7826 20.0743 75.7639 20.033C75.6991 19.888 75.6087 19.7556 75.4972 19.6423L56.8306 0.975628C56.7172 0.864128 56.5849 0.773794 56.4399 0.708961C56.3986 0.690294 56.3572 0.679628 56.3159 0.664961C56.2044 0.626628 56.0882 0.603294 55.9706 0.595628C55.9479 0.599628 55.9226 0.584961 55.8932 0.584961H18.5599C17.8236 0.584961 17.2266 1.18196 17.2266 1.91829V5.91829H10.5599C9.82356 5.91829 9.22656 6.51529 9.22656 7.25163V79.2516C9.22656 79.988 9.82356 80.585 10.5599 80.585H66.5599C67.2962 80.585 67.8932 79.988 67.8932 79.2516V72.585H74.5599C75.2962 72.585 75.8932 71.988 75.8932 71.2516V20.585C75.8932 20.5556 75.8786 20.5303 75.8759 20.501ZM57.2266 5.13696L71.3412 19.2516H57.2266V5.13696ZM65.2266 77.9183H11.8932V8.58496H17.2266V71.2516C17.2266 71.988 17.8236 72.585 18.5599 72.585H65.2266V77.9183ZM73.2266 69.9183H19.8932V3.25163H54.5599V20.585C54.5599 21.3213 55.1569 21.9183 55.8932 21.9183H73.2266V69.9183Z" :fill="iconColor"/>
    <path d="M34.5599 12.585H26.5599C25.8236 12.585 25.2266 13.182 25.2266 13.9183V21.9183C25.2266 22.6546 25.8236 23.2516 26.5599 23.2516H34.5599C35.2962 23.2516 35.8932 22.6546 35.8932 21.9183V13.9183C35.8932 13.182 35.2962 12.585 34.5599 12.585ZM33.2266 20.585H27.8932V15.2516H33.2266V20.585Z" :fill="iconColor"/>
    <circle cx="17.2259" cy="67.0365" r="15.6185" fill="#fff" stroke="#E6E6E6" stroke-width="3"/>
    <rect x="4.41797" y="76.6431" width="31.7014" height="3.72306" transform="rotate(-45 4.41797 76.6431)" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 76 },
    height: { type: [Number, String], default: 85 },
    iconColor: { type: String, default: "#E6E6E6" },
  },
};
</script>
