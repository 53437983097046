<template>
  <BaseModal @close="$emit('close')">
    <template v-slot:header>
      <h2>Tissue Source Site</h2>
    </template>

    <template v-slot:body>
      <hr style="margin: 0 0 10px" />

      <div class="form">
        <form @submit.prevent="addSiteSubmit">
          <div class="upload-photo-container">
            <div class="upload-photo-bg">
              <p class="upload-photo-text">
                <span
                  v-if="fileName"
                  style="font-weight: 900; font-size: 18px"
                  >{{ fileName }}</span
                >
                <span v-else>Upload Logo</span>
              </p>
            </div>
            <input
              type="file"
              class="upload-photo-input"
              accept="image/*"
              @change="uploadImage"
            />
          </div>
          <fieldset class="form-group">
            <label>Site</label>
            <input type="text" v-model="formSiteAdd.title" />
            <span
              v-if="showValidation && !formSiteAdd.title.length"
              class="field-validation"
            >
              Please fill in Site field
            </span>
          </fieldset>
          <fieldset class="form-group">
            <label>Notes</label>
            <input type="text" v-model="formSiteAdd.description_short" />
            <span
              v-if="showValidation && !formSiteAdd.description_short.length"
              class="field-validation"
            >
              Please fill in Notes field
            </span>
          </fieldset>
          <fieldset class="form-group">
            <label>Description</label>
            <textarea type="text" v-model="formSiteAdd.description" />
            <span
              v-if="showValidation && !formSiteAdd.description.length"
              class="field-validation"
            >
              Please fill in Description field
            </span>
          </fieldset>
          <!--<fieldset class="form-group">
            <input type="text" placeholder="City" v-model="formSiteAdd.city" />
          </fieldset>
          <fieldset class="form-group">
            <input
              type="text"
              placeholder="Country"
              v-model="formSiteAdd.country"
            />
          </fieldset>-->
        </form>
      </div>
    </template>

    <template v-slot:footer>
      <div class="btn-wrapper">
        <BaseButton
          className="typical big"
          text="Add Site"
          @click="addSiteSubmit"
        ></BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import BaseModal from "@/components/BaseModal";
import BaseButton from "@/modules/components/base/BaseButton.vue";

export default {
  components: { BaseModal, BaseButton },
  emits: ["close"],
  setup(propos, { emit }) {
    const store = useStore();

    const initSitePhoto = ref({});
    const fileName = ref("");
    let newFormData = new FormData();
    let showValidation = ref(false);

    const uploadImage = (ev) => {
      let formData = new FormData();
      formData.append("avatar", ev.target.files[0]);
      fileName.value = ev.target.files[0].name;
      initSitePhoto.value = ev.target.files[0];

      newFormData.append("avatar", ev.target.files[0]);
    };

    let formSiteAdd = ref({
      title: "",
      description: "",
      description_short: "",
      // city: "Kharkiv",
      // country: "Ukraine",
    });

    const addSiteSubmit = async () => {
      showValidation.value = false;
      if (
        !formSiteAdd.value.title.length ||
        !formSiteAdd.value.description.length ||
        !formSiteAdd.value.description_short.length
      ) {
        showValidation.value = true;
        return;
      }

      newFormData.append("title", formSiteAdd.value.title);
      newFormData.append("description", formSiteAdd.value.description);
      newFormData.append(
        "description_short",
        formSiteAdd.value.description_short
      );

      await store.dispatch("site/createSite", newFormData);
      emit("close");
    };

    return {
      addSiteSubmit,
      formSiteAdd,
      initSitePhoto,
      fileName,
      uploadImage,
      showValidation,
    };
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 26px;
  font-weight: 400;
}
input,
textarea {
  width: 100%;
  margin: 0;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--col-border);
  font-weight: 400;
}
label {
  font-size: 16px;
  line-height: 2;
  font-family: "OpenSans-Regular";
  color: var(--col-text-secondary);
}
.btn-wrapper {
  display: flex;
  justify-content: flex-end;
}
.upload-photo {
  &-container {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  &-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100px;
    border: none;
    border-radius: 4px;
    background: var(--col-text-secondary)
      url("../../modules/assets/bg/Pedro-lastra.jpg") no-repeat 50% 50%;
    background-size: cover;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.55);
    }
  }

  &-text {
    position: relative;
    z-index: 5;
    font-family: "OpenSans-Regular";
    font-size: 16px;
    line-height: 24px;
    color: var(--col-contrast-text);
  }

  &-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
}
.form-group {
  position: relative;
}
.field-validation {
  position: absolute;
  left: 0;
  bottom: -15px;
  font-size: 12px;
  color: #dc3545;
}
</style>
