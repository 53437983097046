<template>
  <div class="breadcrumbs-wrapper">
    <p>Pending Feasibility</p>
  </div>

  <transition>
    <div class="main-body">
      <div class="filters-wrapper">
        <div>
          <div class="filters_search">
            <label>Search:<input v-model="searchTerm" /></label>
          </div>
          <div class="filters_icon"><IconFilter height="18" /></div>
          <div
            class="filters_select"
            v-if="categoryOptions && categoryOptions.length"
          >
            <label>Select Category: </label>
            <select v-model="selectedCategory">
              <option value="">All projects</option>
              <option
                v-for="category in categoryOptions"
                :value="category.id"
                :key="category.id"
              >
                {{ category.title }}
              </option>
            </select>
          </div>
        </div>
        <div class="view-toggler" v-if="viewTogglerEnabled">
          <IconShow1 @click="tableView = false" />
          <IconShow2 @click="tableView = true" />
        </div>
      </div>

      <div v-if="tableView">
        <Table
          project-type="feasibility"
          :columns="table.columns"
          :rows="table.rows"
          :pagination="pagination"
          :sortable="table.sortable"
          @do-sort="doSort"
          @paginate="paginate"
        ></Table>
      </div>

      <div v-else class="archive-card">
        <template v-if="!!feasibilityProjects && feasibilityProjects.length">
          <CardMiniDescription
            v-for="project in feasibilityProjects"
            :key="project"
            :cardData="project"
            :is-admin="true"
            @click="goToFeasibilityDetails(project.id)"
          />
        </template>
        <!-- <div v-else>
        <h2>No pending feasibility is now</h2>
      </div> -->
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Table from "@/components/table/table.vue";
import CardMiniDescription from "@/modules/components/base/CardMiniDescription.vue";
import IconShow1 from "@/modules/components/icons/IconShow1.vue";
import IconShow2 from "@/modules/components/icons/IconShow2.vue";
import IconFilter from "@/modules/components/icons/IconFilter.vue";

export default {
  components: {
    Table,
    CardMiniDescription,
    IconShow1,
    IconShow2,
    IconFilter,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const tableView = ref(true);
    //ToDo: remove view toggler after 09.12.2021 if not needed
    const viewTogglerEnabled = ref(false);
    const params = ref({
      t: "f", // r, f, a, arc, reop
      page: 1,
      // s: "bus",
      // so: "created_at",
      // sw: "desc",
      // c: 2, //category.id
    });

    store.dispatch("feasibility/getAllFeasibilityProjects");
    // if (!feasibilityProjects.value || !feasibilityProjects.value.length) {
    // }
    const categoryOptions = computed(
      () => store.getters["projects/projectCategories"]
    );
    const pagination = computed(() => store.getters["projects/pagination"]);
    const feasibilityProjects = computed(
      () => store.getters["feasibility/feasibilityProjectList"]
    );
    const selectedCategory = ref("");
    const searchTerm = ref("");

    const table = reactive({
      isLoading: false,
      isReSearch: true,
      columns: [
        {
          label: "Project#",
          field: "project_number",
          width: "10%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Client",
          field: "client_company",
          width: "15%",
          sortable: true,
        },
        {
          label: "Request Title",
          field: "description_short",
          width: "18%",
          sortable: false,
        },
        {
          label: "Creation Date",
          field: "created_at",
          width: "12%",
          sortable: true,
        },
        {
          label: "Timeline",
          field: "timeline",
          width: "10%",
          sortable: false,
        },
        {
          label: "Indication",
          field: "indication",
          width: "15%",
          sortable: false,
        },
        {
          label: "Pathology",
          field: "pathology",
          width: "20%",
          sortable: false,
        },
        {
          label: "Status",
          field: "notify",
          width: "5%",
          sortable: true,
        },
      ],
      rows: [],
      totalRecordCount: 2,
      sortable: {
        order: "id",
        sort: "asc",
      },
    });

    table.rows = computed(() => {
      if (!feasibilityProjects.value) {
        return [];
      }
      return feasibilityProjects.value.map((project) => {
        let p = {};
        p.client_company = project.client_company ? project.client_company : "";
        p.project_number = project.project_number;
        p.description_short = project.description_short
          ? project.description_short
          : "";
        let creationDate = project.created_at;
        // creationDate;
        p.created_at = creationDate.slice(0, 10);
        p.timeline = project.timeline;
        p.id = project.id;
        const pathologies = project.categories.map((c) => c.title);
        p.pathology = pathologies.join(", ");
        p.indication = project.indication ? project.indication : "";
        p.notify = {
          hasMsg: !!project.new_messages,
          hasProposal: !!project.new_proposals,
        };
        return p;
      });
    });

    const getFilteredProjects = () => {
      store.dispatch("feasibility/getFilteredProjects", {
        params: params.value,
      });
    };

    const doSort = (order, sort) => {
      params.value.sw = sort;
      params.value.so = order;
      getFilteredProjects();
    };

    const paginate = (to) => {
      if (params.value.c || params.value.s) {
        params.value = { ...params.value, ...{ page: to } };
        getFilteredProjects();
      } else {
        store.dispatch("feasibility/getAllFeasibilityProjects", to);
      }
    };

    watch(searchTerm, (newTerm) => {
      params.value.s = newTerm;
      if (!newTerm.length) {
        delete params.value.s;
      } else {
        params.value.page = 1;
      }
      getFilteredProjects();
    });

    watch(selectedCategory, (newCategory) => {
      params.value.c = newCategory;
      if (!newCategory) {
        delete params.value.c;
      } else {
        params.value.page = 1;
      }
      getFilteredProjects();
    });

    const goToFeasibilityDetails = (id) => {
      router.push({
        name: "ProjectFeasibility",
        params: { id: `${id}` },
      });
    };

    return {
      table,
      tableView,
      pagination,
      searchTerm,
      categoryOptions,
      selectedCategory,
      feasibilityProjects,
      doSort,
      goToFeasibilityDetails,
      viewTogglerEnabled,
      paginate,
    };
  },
};
</script>
