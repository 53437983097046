<template>
  <div class="shipping-line">
    <div class="shipping-line-head">
      <div class="shipping-line-row">
        <h6 class="shipping-line-cell green">Line</h6>
        <h6 class="shipping-line-cell green">Indications</h6>
        <h6 class="shipping-line-cell green">Cases#</h6>
        <h6 class="shipping-line-cell green">Pass QC</h6>
        <h6 class="shipping-line-cell green">Defect</h6>
      </div>
    </div>
    <div class="shipping-line-body">
      <div v-for="(row, ind) in lineList" :key="row.plp_id">
        <div v-if="row.qcForm" class="shipping-line-row">
          <div class="shipping-line-cell">{{ row.pl_description }}</div>
          <div class="shipping-line-cell">
            {{ row.pl_title }}
          </div>
          <div class="shipping-line-cell">{{ row.ps_cases_count }}</div>
          <div class="shipping-line-cell">
            <input
              type="number"
              v-model="row.qcForm.passQC"
              class="shipping-line-input"
              :key="`passQC${row.plp_id}`"
              :max="row.ps_cases_count"
              @input="countSamples('pass', row.qcForm.passQC, ind)"
              :disabled="row.qcForm.isDisabled"
            />
          </div>
          <div class="shipping-line-cell">
            <input
              type="number"
              v-model="row.qcForm.defected"
              class="shipping-line-input"
              :key="`defected${row.plp_id}`"
              :max="row.ps_cases_count"
              @input="countSamples('defect', row.qcForm.defected, ind)"
              :disabled="row.qcForm.isDisabled"
            />
          </div>
        </div>

        <div class="shipping-line-row shipping-line-row_end">
          <div class="shipping-line-cell_area">
            <div class="shipping-line-cell_big">
              <p>What is the problem?</p>
              <input
                type="text"
                v-model="row.qcForm.comment"
                class="shipping-line-textfield"
                :disabled="row.qcForm.isDisabled"
              />
            </div>
            <BaseButton
              class-name="info"
              :text="`${row.qcForm.isDisabled ? 'Saved' : 'Save'}`"
              :disabled="row.qcForm.isDisabled"
              @click="saveLine(ind)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs, watch } from "vue";
import { useStore } from "vuex";
import BaseButton from "@/modules/components/base/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    lines: {
      type: Array,
    },
  },
  setup(props) {
    const store = useStore();
    const { lines } = toRefs(props);
    const lineList = computed(() => lines.value);

    const setLineList = () => {
      lineList.value.map((line) => {
        line.qcForm = {
          id: line.pqc_id,
          ordered: line.ps_cases_count,
          passQC: line.pqc_number_ok || line.ps_cases_count,
          defected: line.pqc_number_not_ok || 0,
          comment: line.pqc_comment || "",
          isDisabled: !!(line.pqc_number_ok || line.pqc_number_not_ok),
        };
      });
    };

    setLineList();

    const countSamples = (type, count, ind) => {
      let line = lineList.value[ind].qcForm;
      let ordered = lineList.value[ind].ps_cases_count;
      if (count > ordered) {
        if (type === "pass") {
          line.passQC = ordered;
        } else if (type === "defect") {
          line.defected = ordered;
        }
      }
      if (type === "pass") {
        line.defected = ordered - line.passQC;
      } else if (type === "defect") {
        line.passQC = ordered - line.defected;
      }
    };

    const saveLine = (ind) => {
      let line = lineList.value[ind].qcForm;
      lineList.value[ind].qcForm.isDisabled = true;
      let shipment = {
        received: true,
        number_ok: line.passQC || line.ordered,
        number_not_ok: line.defected || 0,
        comment: line.comment,
      };
      store.dispatch("tracking/sendQCResponse", { id: line.id, shipment });
    };

    watch(lineList, () => {
      setLineList();
    });

    return {
      lineList,
      countSamples,
      saveLine,
    };
  },
};
</script>

<style lang="scss" scoped>
input,
textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--col-border);
  font-weight: 400;
  text-align: center;
}
.shipping {
  &-line {
    margin-top: 10px;
    // border-left: 8px solid var(--col-info);
    // border-top-left-radius: 8px;
    // border-bottom-left-radius: 8px;
    overflow: hidden;

    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--col-bg-attention);
    }

    &-body {
      .shipping-line-row {
        background-color: var(--col-bg-primary);
      }
      .shipping-line---col-subtext-primary {
        color: var(--col-subtext-primary);
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 3px 0;
      padding: 0 15px;

      &_bg-light {
        display: flex;
        justify-content: flex-end;
        background-color: #fff;
      }

      &_end.shipping-line-row {
        justify-content: flex-end;
        background-color: #fff;
        padding-right: 0;
      }

      > .shipping-line-cell {
        padding: 7px 15px;

        &.radio-wrp {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }

        &:nth-child(1) {
          flex-basis: 50%;
        }
        &:nth-child(2) {
          flex-basis: 170px;
          text-align: center;
        }
        &:nth-child(3) {
          flex-basis: 90px;
          text-align: center;
        }
        &:nth-child(4) {
          flex-basis: 120px;
          text-align: center;
        }
        &:nth-child(5) {
          flex-basis: 120px;
          text-align: center;
        }
        &:nth-child(6) {
          flex-basis: 120px;
          text-align: center;
        }
      }
    }

    &-input {
      width: 100%;
      margin: 0;
    }
    &-textfield {
      text-align: left;
      padding-left: 10px;
    }

    &-cell_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      padding-right: 20px;
      background-color: var(--col-bg-primary);
    }

    &-cell_big {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      padding: 7px 15px;
      background-color: var(--col-bg-primary);

      p {
        min-width: 160px;
        font-weight: bold;
        color: var(--col-info);
      }
    }
  }
}
</style>
<style lang="scss">
.custom-checkbox:not(.ticked) {
  border-color: var(--col-warning);
}
.shipping-line-row {
  .btn {
    padding: 2px 25px;
  }
}
</style>
