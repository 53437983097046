<template>
  <div class="breadcrumbs-wrapper">
    <p><router-link to="">Clients Requests</router-link> /</p>
  </div>
  <div class="request-list cards-wrapper">
    <div class="cards-bg">
      <BaseButton
        class="typical big"
        style="margin: 20px 0"
        text="+ Create New Request"
        @click="initProject"
        :disabled="isInitProjectDisabled"
      ></BaseButton>

      <div class="filters-wrapper">
        <div>
          <div class="filters_search">
            <label>Search:<input v-model="searchTerm" /></label>
          </div>

          <div class="filters_icon"><IconFilter height="18" /></div>

          <div
            class="filters_select"
            v-if="categoryOptions && categoryOptions.length"
          >
            <label>Select Category: </label>
            <select v-model="selectedCategory">
              <option value="">All projects</option>
              <option
                v-for="category in categoryOptions"
                :value="category.id"
                :key="category.id"
              >
                {{ category.title }}
              </option>
            </select>
          </div>
        </div>

        <div class="view-toggler" v-if="viewTogglerEnabled">
          <IconShow1 @click="tableView = false" />
          <IconShow2 @click="tableView = true" />
        </div>
      </div>

      <div v-if="tableView">
        <Table
          project-type="draft"
          :columns="table.columns"
          :rows="table.rows"
          :pagination="pagination"
          :sortable="table.sortable"
          @do-sort="doSort"
          @paginate="paginate"
        ></Table>
      </div>

      <div v-else class="cards">
        <div class="adding-btn-wrapper">
          <button
            class="adding-btn"
            @click="initProject"
            :disabled="isInitProjectDisabled"
          >
            <IconAdd />
            <p class="adding-btn-title">New request</p>
          </button>
        </div>

        <CardMiniDescription
          v-for="project in projects"
          :key="project.id + 'plist'"
          :cardData="project"
          :is-admin="true"
          @click="goToProjectDetails(project.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Table from "@/components/table/table.vue";
import CardMiniDescription from "@/modules/components/base/CardMiniDescription.vue";
import BaseButton from "@/modules/components/base/BaseButton.vue";
import IconShow1 from "@/modules/components/icons/IconShow1.vue";
import IconShow2 from "@/modules/components/icons/IconShow2.vue";
import IconAdd from "@/modules/components/icons/IconAdd.vue";
import IconFilter from "@/modules/components/icons/IconFilter.vue";

export default {
  name: "ProjectList",
  components: {
    Table,
    BaseButton,
    CardMiniDescription,
    IconShow1,
    IconShow2,
    IconAdd,
    IconFilter,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isInitProjectDisabled = ref(false);
    const tableView = ref(true);
    //ToDo: remove view toggler after 09.12.2021 if not needed
    const viewTogglerEnabled = ref(false);
    const params = ref({
      t: "r", // r, f, a, arc, reop
      page: 1,
      // s: "bus",
      // so: "created_at",
      // sw: "desc",
      // c: 2, //category.id
    });
    store.dispatch("projects/getAllProjects");
    //  if (!projects.value || !projects.value.length) {
    //     }
    const categoryOptions = computed(
      () => store.getters["projects/projectCategories"]
    );
    const pagination = computed(() => store.getters["projects/pagination"]);
    const projects = computed(() => store.getters["projects/projects"]);
    const selectedCategory = ref("");
    const searchTerm = ref("");

    const table = reactive({
      isLoading: false,
      isReSearch: true,
      columns: [
        {
          label: "Project#",
          field: "project_number",
          width: "10%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Client",
          field: "client_company",
          width: "15%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Request Title",
          field: "description_short",
          width: "18%",
          sortable: false,
        },
        {
          label: "Creation Date",
          field: "created_at",
          width: "12%",
          sortable: true,
        },
        {
          label: "Timeline",
          field: "timeline",
          width: "10%",
          sortable: false,
        },
        {
          label: "Indication",
          field: "indication",
          width: "15%",
          sortable: false,
        },
        {
          label: "Pathology",
          field: "pathology",
          width: "20%",
          sortable: false,
        },
      ],
      rows: [],
      totalRecordCount: 5,
      sortable: {
        order: "id",
        sort: "asc",
      },
    });

    table.rows = computed(() => {
      if (!projects.value || !projects.value.length) {
        return [];
      }

      return projects.value.map((project) => {
        let p = {};
        p.client_company = project.client_company ? project.client_company : "";
        // p.number = project.project_number ? project.project_number : 0;
        p.project_number = `#${project.project_number}`;
        p.description_short = project.description_short
          ? project.description_short
          : "";
        let creationDate = project.created_at;
        // creationDate;
        p.created_at = creationDate.slice(0, 10);
        p.timeline = project.timeline;
        p.id = project.id;
        const pathologies = project.categories.map((c) => c.title);
        p.pathology = pathologies.join(", ");
        p.indication = project.indication ? project.indication : "";
        return p;
      });
    });

    const initProject = async () => {
      isInitProjectDisabled.value = true;
      await store.dispatch("projects/createProject");
      const projectId = store.getters["projects/project"].id;

      router.push({
        name: "ProjectEdit",
        params: { id: projectId },
      });
      isInitProjectDisabled.value = false;
    };

    const getFilteredProjects = () => {
      store.dispatch("projects/getFilteredProjects", { params: params.value });
    };

    const doSort = (order, sort) => {
      params.value.sw = sort;
      params.value.so = order;
      getFilteredProjects();
    };

    const paginate = (to) => {
      if (params.value.c || params.value.s) {
        params.value = { ...params.value, ...{ page: to } };
        getFilteredProjects();
      } else {
        store.dispatch("projects/getAllProjects", to);
      }
    };

    watch(searchTerm, (newTerm) => {
      params.value.s = newTerm;
      if (!newTerm.length) {
        delete params.value.s;
      } else {
        params.value.page = 1;
      }
      getFilteredProjects();
    });

    watch(selectedCategory, (newCategory) => {
      params.value.c = newCategory;
      if (!newCategory) {
        delete params.value.c;
      } else {
        params.value.page = 1;
      }
      getFilteredProjects();
    });

    const goToProjectDetails = (id) => {
      router.push({ name: "ProjectEdit", params: { id } });
    };

    return {
      table,
      tableView,
      pagination,
      searchTerm,
      categoryOptions,
      selectedCategory,
      projects,
      isInitProjectDisabled,
      initProject,
      goToProjectDetails,
      doSort,
      viewTogglerEnabled,
      paginate,
    };
  },
};
</script>

<style lang="scss" scoped>
.cards {
  &-wrapper {
    min-height: 100%;
  }

  &-bg {
    min-height: calc(100vh - 170px);
    padding: 23px 38px;
    background-color: var(--col-bg-secondary);
  }

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  & > div {
    flex-basis: 100%;
    margin: 5px 7px 20px;
    max-width: 300px;
  }
}
.card {
  padding-top: 3px;
}
</style>
