<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 45 35"
  >
    <path
      d="M36.7023 11.7617C34.6799 3.70523 26.5096 -1.18632 18.4532 0.836031C12.1573 2.4165 7.58573 7.85795 7.11471 14.332C2.64436 15.0692 -0.381993 19.2907 0.355217 23.7611C1.01056 27.7353 4.45419 30.6458 8.48197 30.6298H15.3183V27.8953H8.48197C5.46152 27.8953 3.01292 25.4467 3.01292 22.4262C3.01292 19.4058 5.46152 16.9572 8.48197 16.9572C9.23713 16.9572 9.84923 16.3451 9.84923 15.5899C9.8424 8.79384 15.3462 3.27899 22.1423 3.27224C28.0253 3.26634 33.0895 7.42538 34.2275 13.1972C34.3399 13.7735 34.8076 14.2138 35.3897 14.291C39.1276 14.8233 41.7262 18.285 41.194 22.0228C40.716 25.3792 37.8503 27.8784 34.46 27.8953H28.9909V30.6298H34.46C39.7458 30.6138 44.0178 26.3158 44.0017 21.03C43.9884 16.63 40.9767 12.8058 36.7023 11.7617Z"
    />
    <path
      d="M21.1839 17.3537L15.7148 22.8227L17.6427 24.7506L20.7874 21.6195V34.7316H23.5219V21.6195L26.6529 24.7506L28.5808 22.8227L23.1117 17.3537C22.5784 16.8235 21.7172 16.8235 21.1839 17.3537Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 45 },
    height: { type: [Number, String], default: 35 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
