<template>
  <div v-if="project && Object.keys(project).length">
    <div class="sidebar">
      <div v-if="project.public_note" class="field-container">
        <h3 class="green">Public note:</h3>
        <div class="green-border">
          {{ project.public_note }}
        </div>
      </div>

      <div v-if="project.private_note" class="field-container">
        <h3 class="green">Cureline only note:</h3>
        <div class="green-border">
          {{ project.private_note }}
        </div>
      </div>

      <hr
        v-if="project.public_note || project.private_note"
        class="dashed"
        style="margin-bottom: 10px"
      />

      <EditVisibility :project="project" @select="$emit('select')" />
      <hr class="dashed" />
      <FileUploading />
      <hr class="dashed" style="margin: 0" />

      <!-- <div v-if="basketList.length && false" class="basket">
      <hr class="dashed" />

      <div v-for="(bItem, ind) in basketList" :key="ind">
        {{ bItem }}
      </div>
    </div> -->

      <div class="sidebar-buttons-wrapper">
        <BaseButton text="Delete" class-name="error" @click="$emit('delete')" />

        <BaseButton
          text="Archive"
          class-name="additional-filled"
          @click="$emit('archive')"
        />
      </div>

      <div class="sidebar-admin-data">
        <h6 class="green center">Validated by:</h6>
        <AdminSign />
      </div>
    </div>

    <div class="sidebar">
      <ChatList :id="project.id" />
    </div>
  </div>
</template>

<script>
import EditVisibility from "@/components/project/EditVisibility.vue";
import FileUploading from "@/components/project/FileUploading.vue";
import BaseButton from "@/modules/components/base/BaseButton.vue";
import ChatList from "@/components/chat/ChatList.vue";
import AdminSign from "@/components/header/AdminSign.vue";

export default {
  components: {
    EditVisibility,
    FileUploading,
    BaseButton,
    ChatList,
    AdminSign,
  },
  emits: ["select", "delete", "archive"],
  props: {
    project: {
      type: Object,
    },
  },
  setup() {},
};
</script>
