<template>
  <button class="btn" :class="[className]" :disabled="disabled">
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    className: {
      type: String,
      default: "",
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin mybutton($color_1, $color_2) {
  background-color: $color_1;
  // border-color: var(--col-error);
  color: $color_2;

  &:hover {
    opacity: 0.7;
    background-color: $color_1;
    border-color: $color_1;
    color: $color_2;
  }

  // &:active {
  //   animation: active 2s ease 0 1 normal forwards;
  // }
  &:active {
    background-color: $color_1;
    border-color: $color_1;
    color: $color_2;
    box-shadow: none;
  }
  &:focus,
  &:active:focus {
    background-color: $color_1;
    border-color: $color_1;
    color: $color_2;
    outline: 5px auto $color_1;
    // animation: active 2s ease ;

  }
  &:visited {
    background-color: $color_1;
    border-color: $color_1;
  }
}
.btn {
  margin: 2px;
  padding: 4px 8px;
  border: 2px solid transparent;
  border-radius: 4px;
  font-size: 14px;
  font-family: "SFProDisplay-Regular", serif;

  // @apply inline-block px-3 py-1 m-1 text-base rounded;

  &.big {
    display: block;
    width: 100%;
    margin: 0;
    padding: 3px 0;
    font-size: 20px;
    line-height: 30px;
  }
  /* red */
  &.error {
    @include mybutton(var(--col-error), var(--col-contrast-text));
    // color: var(--col-contrast-text);
    // background-color: var(--col-error);
    // border-color: var(--col-error);
  }
  /* yellow */
  &.warning {
    @include mybutton(var(--col-warning), var(--col-subtext-primary));
    // color: var(--col-subtext-primary);
    // background-color: var(--col-warning);
    // border-color: var(--col-warning);
  }
  /* green */
  &.success {
    @include mybutton(var(--col-success), var(--col-contrast-text));
    // color: var(--col-contrast-text);
    // background-color: var(--col-success);
    // border-color: var(--col-success);
  }
  /* turquoise */
  &.info {
    @include mybutton(var(--col-info), var(--col-contrast-text));
    // color: var(--col-contrast-text);
    // background-color: var(--col-info);
    // border-color: var(--col-info);
    &_empty {
      color: var(--col-info);
      background-color: transparent;
      border-color: var(--col-info);
    }
  }
  /* violet */
  &.indifferent {
    @include mybutton(var(--col-indifferent), var(--col-contrast-text));
  }
  /* blue */
  &.typical {
    @include mybutton(var(--col-additional), var(--col-contrast-text));
  }
  /* grey-empty */
  &.additional-bordered {
    color: var(--col-additional);
    border-color: var(--col-additional);
    &:hover {
      opacity: 0.6;
      background-color: var(--col-additional);
      border-color: var(--col-contrast-text);
      color: var(--col-contrast-text);
    }
  }
  /* grey-filled */
  &.additional-filled {
    // @include mybutton(var(--col-additional), var(--col-contrast-text));
    @include mybutton(var(--col-subtext-secondary), var(--col-contrast-text));
    // color: var(--col-contrast-text);
    // background-color: var(--col-subtext-secondary);
    // border-color: var(--col-subtext-secondary);
  }
  &.light-grey {
    @include mybutton(var(--col-secondary-btn), var(--col-primary));
    // color: var(--col-primary);
    // background-color: var(--col-secondary-btn);
    // border-color: var(--col-secondary-btn);
  }
  /* grey filled */
  &:disabled, &:disabled:hover  {
    opacity: 1;
    color: #333;
    background-color: #e9ecef;
    border-color: #e9ecef;
    cursor: unset;
  }
}

@keyframes active {
  0% {
    background: var(--col-additional-light);
  }
  5% {
    background: linear-gradient(
      to right,
      var(--col-additional) 5%,
      var(--col-additional-light) 10%
    );
  }
  10% {
    background: linear-gradient(
      to right,
      var(--col-additional) 10%,
      var(--col-additional-light) 10%
    );
  }
  15% {
    background: linear-gradient(
      to right,
      var(--col-additional) 15%,
      var(--col-additional-light) 10%
    );
  }
  20% {
    background: linear-gradient(
      to right,
      var(--col-additional) 20%,
      var(--col-additional-light) 10%
    );
  }
  25% {
    background: linear-gradient(
      to right,
      var(--col-additional) 25%,
      var(--col-additional-light) 10%
    );
  }
  30% {
    background: linear-gradient(
      to right,
      var(--col-additional) 30%,
      var(--col-additional-light) 10%
    );
  }
  35% {
    background: linear-gradient(
      to right,
      var(--col-additional) 35%,
      var(--col-additional-light) 10%
    );
  }
  40% {
    background: linear-gradient(
      to right,
      var(--col-additional) 40%,
      var(--col-additional-light) 10%
    );
  }
  45% {
    background: linear-gradient(
      to right,
      var(--col-additional) 45%,
      var(--col-additional-light) 10%
    );
  }
  50% {
    background: linear-gradient(
      to right,
      var(--col-additional) 50%,
      var(--col-additional-light) 10%
    );
  }
  55% {
    background: linear-gradient(
      to right,
      var(--col-additional) 55%,
      var(--col-additional-light) 10%
    );
  }
  60% {
    background: linear-gradient(
      to right,
      var(--col-additional) 60%,
      var(--col-additional-light) 10%
    );
  }
  65% {
    background: linear-gradient(
      to right,
      var(--col-additional) 65%,
      var(--col-additional-light) 10%
    );
  }
  70% {
    background: linear-gradient(
      to right,
      var(--col-additional) 70%,
      var(--col-additional-light) 10%
    );
  }
  75% {
    background: linear-gradient(
      to right,
      var(--col-additional) 75%,
      var(--col-additional-light) 10%
    );
  }
  80% {
    background: linear-gradient(
      to right,
      var(--col-additional) 80%,
      var(--col-additional-light) 10%
    );
  }
  85% {
    background: linear-gradient(
      to right,
      var(--col-additional) 85%,
      var(--col-additional-light) 10%
    );
  }
  90% {
    background: linear-gradient(
      to right,
      var(--col-additional) 90%,
      var(--col-additional-light) 10%
    );
  }
  95% {
    background: linear-gradient(
      to right,
      var(--col-additional) 95%,
      var(--col-additional-light) 10%
    );
  }
  100% {
    background: var(--col-additional);
  }
}
</style>
