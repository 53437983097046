<template>
  <div class="wrapper">
    <IconUpsetFace />
    <h2 class="green">There is no proposal</h2>
    <h4 class="green">on project #{{ numberType }}</h4>
    <div>
      <BaseButton class-name="info" text="Come back" @click="router.go(-1)" />
    </div>
  </div>
</template>

<script>
import BaseButton from "@/modules/components/base/BaseButton.vue";
import { useRouter } from "vue-router";
import IconUpsetFace from "@/modules/components/icons/IconUpsetFace";

export default {
  components: { BaseButton, IconUpsetFace },
  props: {
    numberType: {
      type: String,
      default: "",
    },
  },
  setup() {
    const router = useRouter();

    return { router };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}
</style>
