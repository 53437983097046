<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 127 127"
  >
    <path
      d="M123.905 3.07555C120.768 -0.0616484 115.682 -0.0616395 112.544 3.07556L63.2231 52.3969L13.9018 3.07561C10.7646 -0.0615879 5.67819 -0.0615891 2.54098 3.07561C-0.596217 6.21281 -0.596209 11.2992 2.54099 14.4364L51.8623 63.7577L2.5404 113.08C-0.596798 116.217 -0.596799 121.303 2.5404 124.44C5.6776 127.578 10.764 127.578 13.9012 124.44L63.2231 75.1185L112.545 124.44C115.682 127.578 120.769 127.578 123.906 124.44C127.043 121.303 127.043 116.217 123.906 113.08L74.5839 63.7577L123.905 14.4364C127.042 11.2992 127.042 6.21275 123.905 3.07555Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 127 },
    height: { type: [Number, String], default: 127 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
