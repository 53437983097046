<template>
  <BaseModal size="big" @close="saveProcurementSitesVisibility">
    <template v-slot:header>
      <h2>Select Tissue Source Sites</h2>
    </template>

    <template v-slot:body>
      <hr style="margin-bottom: 10px" />
      <div
        v-if="procurementSites && procurementSites.length"
        class="form add-psites-modal"
      >
        <template
          v-for="site of procurementSites"
          :key="site.id + 'svisibility'"
        >
          <label class="btn" :class="{ chosen: isChosen(site.id) }">
            TSS: {{ site.title }}
            <div class="icon-checked-wrapper">
              <IconChecked
                v-if="isChosen(site.id)"
                icon-color="#fff"
                width="21"
                height="21"
              />
              <IconClose v-else icon-color="#eaf5f7" width="21" height="21" />
            </div>
            <input
              type="checkbox"
              :value="site.id"
              v-model="siteVisibilityId"
              @click="selectProcurementSite(site)"
            />
          </label>
        </template>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import BaseModal from "@/components/BaseModal.vue";
import IconChecked from "@/modules/components/icons/IconChecked";
import IconClose from "@/modules/components/icons/IconClose";

export default {
  components: { BaseModal, IconChecked, IconClose },
  props: {
    // visibilitySites: {
    //   type: Array,
    // },
    // projectVisibility: {
    //   type: Array,
    // },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const project = computed(() => store.getters["projects/project"]);
    const procurementSites = computed(
      () => store.getters["projects/procurementSites"]
    );
    const siteVisibilityId = ref([]);

    // const projectVisibility = ref(props.projectVisibility);
    const projectVisibility = ref([]);
    projectVisibility.value = project.value.project_visibility;

    const selectProcurementSite = (site) => {
      if (isChosen(site.id)) {
        projectVisibility.value.splice(
          projectVisibility.value.map(({ id }) => id).indexOf(site.id),
          1
        );
      } else {
        if (!projectVisibility.value) {
          projectVisibility.value = [];
        }
        projectVisibility.value.push(site);
      }
    };

    const isChosen = (pid) => {
      if (projectVisibility.value && projectVisibility.value.length) {
        return projectVisibility.value.map(({ id }) => id).includes(pid);
      } else {
        return false;
      }
    };

    const saveProcurementSitesVisibility = () => {
      let data = {
        id: project.value.id,
        visibility: "procurement-sites",
        project_visibility: projectVisibility.value,
      };
      if (data.project_visibility && data.project_visibility.length) {
        store.dispatch("projects/saveVisibility", data);
      } else {
        let payload = {
          isShown: true,
          text: "Visibility was saved for Tissue Bank only",
          status: "success",
        };
        store.commit("admins/SET_NOTIFICATION", payload);

        setTimeout(() => {
          store.commit("admins/CLEAN_NOTIFICATION");
        }, 10000);

        store.dispatch("projects/saveVisibility", {
          id: data.id,
          visibility: "tissue-bank",
        });
      }
      emit("close");
    };

    return {
      project,
      procurementSites,
      siteVisibilityId,
      projectVisibility,
      isChosen,
      selectProcurementSite,
      saveProcurementSitesVisibility,
    };
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 26px;
  font-weight: 400;
  color: var(--col-info);
}
.add-psites-modal {
  margin: 0 -5px;
}
input,
textarea {
  width: 0;
  height: 0;
}
label {
  position: relative;
  font-size: 16px;
  line-height: 2;
  font-family: "OpenSans-Regular", sans-serif;
  color: var(--col-info);
  border: 1px solid var(--col-info);
  margin: 5px;
  padding-right: 30px;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;

  // &:active {
  //   background-color: $color_1;
  //   border-color: $color_1;
  //   color: $color_2;
  //   box-shadow: none;
  // }
  // &:focus,
  // &:active:focus {
  //   background-color: $color_1;
  //   border-color: $color_1;
  //   color: $color_2;
  //   outline: 5px auto $color_1;
  // }

  &:hover {
    background-color: var(--col-info-bg);
  }

  &.chosen {
    color: var(--col-contrast-text);
    background-color: var(--col-info);
    border-color: var(--col-info);

    &:hover {
      background-color: var(--col-info-bg_d);
    }
  }
}
.icon-checked-wrapper {
  position: absolute;
  top: 10px;
  right: 12px;
}
.btn-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
