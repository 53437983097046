<template>
  <svg 
    :width="width"
    :height="height"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.875 4.4707C1.70924 4.4707 1.55027 4.53655 1.43306 4.65376C1.31585 4.77097 1.25 4.92994 1.25 5.0957V7.5957H7.5C7.66576 7.5957 7.82473 7.66155 7.94194 7.77876C8.05915 7.89597 8.125 8.05494 8.125 8.2207C8.125 8.53695 8.225 9.0257 8.5075 9.4182C8.76625 9.7782 9.20375 10.0957 10 10.0957C10.7963 10.0957 11.2338 9.7782 11.4925 9.4182C11.775 9.0257 11.875 8.53695 11.875 8.2207C11.875 8.05494 11.9408 7.89597 12.0581 7.77876C12.1753 7.66155 12.3342 7.5957 12.5 7.5957H18.75V5.0957C18.75 4.92994 18.6842 4.77097 18.5669 4.65376C18.4497 4.53655 18.2908 4.4707 18.125 4.4707H1.875V4.4707ZM18.75 8.8457H13.0613C12.9716 9.31437 12.7828 9.75847 12.5075 10.1482C12.0162 10.8307 11.2037 11.3457 10 11.3457C8.79625 11.3457 7.98375 10.8307 7.4925 10.1482C7.21723 9.75845 7.02843 9.31436 6.93875 8.8457H1.25V16.3457C1.25 16.5115 1.31585 16.6704 1.43306 16.7876C1.55027 16.9049 1.70924 16.9707 1.875 16.9707H18.125C18.2908 16.9707 18.4497 16.9049 18.5669 16.7876C18.6842 16.6704 18.75 16.5115 18.75 16.3457V8.8457ZM0 5.0957C0 4.59842 0.197544 4.12151 0.549175 3.76988C0.900805 3.41825 1.37772 3.2207 1.875 3.2207H18.125C18.6223 3.2207 19.0992 3.41825 19.4508 3.76988C19.8025 4.12151 20 4.59842 20 5.0957V16.3457C20 16.843 19.8025 17.3199 19.4508 17.6715C19.0992 18.0232 18.6223 18.2207 18.125 18.2207H1.875C1.37772 18.2207 0.900805 18.0232 0.549175 17.6715C0.197544 17.3199 0 16.843 0 16.3457L0 5.0957Z"
      :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 20 },
    height: { type: [Number, String], default: 21 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
