<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 31 41"
  >
    <path
      d="M30.1384 10.25H21.5282C21.2997 10.25 21.0806 10.16 20.919 9.99969C20.7574 9.83942 20.6667 9.62205 20.6667 9.39539V0.85461C20.6667 0.627954 20.5759 0.41058 20.4143 0.25031C20.2527 0.0900391 20.0336 0 19.8051 0L2.58199 0C1.89764 0.00140691 1.24172 0.271695 0.757814 0.751703C0.273904 1.23171 0.00141835 1.88234 0 2.56117L0 38.4388C0.00141835 39.1177 0.273904 39.7683 0.757814 40.2483C1.24172 40.7283 1.89764 40.9986 2.58199 41H28.418C29.1024 40.9986 29.7583 40.7283 30.2422 40.2483C30.7261 39.7683 30.9986 39.1177 31 38.4388V11.1046C31 10.878 30.9092 10.6606 30.7477 10.5003C30.5861 10.34 30.3669 10.25 30.1384 10.25ZM29.2769 38.4388C29.2769 38.6648 29.1864 38.8815 29.0253 39.0413C28.8643 39.201 28.6458 39.2908 28.418 39.2908H2.58199C2.3542 39.2908 2.13575 39.201 1.97468 39.0413C1.81361 38.8815 1.72312 38.6648 1.72312 38.4388V2.56117C1.72312 2.33522 1.81361 2.11852 1.97468 1.95875C2.13575 1.79898 2.3542 1.70922 2.58199 1.70922H18.9543V9.39539C18.955 10.0751 19.2275 10.7269 19.7121 11.2075C20.1967 11.6882 20.8537 11.9585 21.539 11.9592H29.2876L29.2769 38.4388Z"
    />
    <path
      d="M30.7476 10.5003L20.4143 0.25033C20.3342 0.170966 20.2393 0.10801 20.1347 0.0650587C20.0302 0.022107 19.9181 1.18262e-09 19.805 0C19.6918 -1.18262e-09 19.5798 0.022107 19.4753 0.0650587C19.3707 0.10801 19.2757 0.170966 19.1957 0.25033C19.1157 0.329695 19.0522 0.423914 19.0089 0.527609C18.9656 0.631303 18.9434 0.742443 18.9434 0.854681C18.9434 0.966919 18.9656 1.07806 19.0089 1.18175C19.0522 1.28545 19.1157 1.37967 19.1957 1.45903L29.5237 11.7144C29.6853 11.8746 29.9044 11.9647 30.133 11.9647C30.3615 11.9647 30.5806 11.8746 30.7422 11.7144C30.9038 11.5541 30.9946 11.3367 30.9946 11.11C30.9946 10.8833 30.9038 10.6659 30.7422 10.5057L30.7476 10.5003Z"
    />
    <path
      d="M9.51465 27.388L12.5234 23.3599V23.3359H9.58443V22.3242H13.9969V23.1229L11.0043 27.1564V27.1803H14.0801V28.192H9.51733L9.51465 27.388Z"
    />
    <path d="M14.9365 22.3242H16.1738V28.1814H14.9365V22.3242Z" />
    <path
      d="M17.1587 22.3239H18.396V28.181H17.1587V22.3239ZM17.7814 25.3803H19.2307C19.5313 25.3997 19.828 25.3044 20.0601 25.114C20.1615 25.015 20.2403 24.8955 20.2912 24.7637C20.3421 24.6319 20.364 24.4908 20.3553 24.3499C20.3646 24.2082 20.3431 24.0661 20.2921 23.9333C20.2412 23.8006 20.162 23.6803 20.0601 23.5805C19.8269 23.392 19.531 23.297 19.2307 23.3143H17.7814V22.3239H19.534C19.9127 22.3148 20.2876 22.4009 20.6237 22.5741C20.9291 22.7332 21.1817 22.9763 21.3511 23.2743C21.5351 23.5991 21.6278 23.9669 21.6195 24.3393C21.6285 24.7118 21.5357 25.0797 21.3511 25.4042C21.1787 25.6966 20.9266 25.9347 20.6237 26.0911C20.2871 26.2631 19.9126 26.3491 19.534 26.3414H17.7814V25.3803Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 31 },
    height: { type: [Number, String], default: 41 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
