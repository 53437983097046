// from "2021-07-23T12:04:04.000000Z" to "2021-07-23"
const getYyyyMmDd = (dateString) => {
  return dateString.toISOString().slice(0, 10);
};

// from "31-12-2021" to "2021-12-31T12:04:04.000000Z"
const getDateFromString = (dateString) => {
  return new Date(dateString);
};

const formatCurrentDate = () => {
  const today = new Date();
  const weekday = today.toLocaleString("default", { weekday: "long" });
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "long" });
  const year = today.getFullYear();
  const thisDate = weekday + " " + month + " " + day + " " + year;

  return thisDate;
};

export default { getYyyyMmDd, getDateFromString, formatCurrentDate };
