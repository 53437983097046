<template>
  <svg :width="width" :height="height" :fill="iconColor" viewBox="0 0 257 256">
    <path
      d="M128.28 255.509C111.498 255.497 94.8825 252.175 79.3862 245.732C63.8898 239.289 49.8167 229.852 37.9731 217.962C26.1294 206.072 16.7479 191.962 10.3656 176.441C3.98343 160.919 0.726023 144.291 0.779956 127.509C0.812699 110.73 4.15425 94.1219 10.6132 78.6357C17.0722 63.1496 26.5217 49.0893 38.4211 37.2595C50.3205 25.4298 64.4361 16.0629 79.9599 9.69495C95.4837 3.32703 112.111 0.0831541 128.89 0.14893C162.693 0.244276 195.076 13.7474 218.934 37.6946C242.791 61.6417 256.172 94.0761 256.14 127.879C256.145 144.66 252.84 161.278 246.413 176.78C239.987 192.282 230.565 206.365 218.688 218.22C206.811 230.076 192.712 239.472 177.199 245.87C161.685 252.269 145.061 255.544 128.28 255.509ZM236.28 127.759C236.322 113.602 233.566 99.5768 228.17 86.4888C222.773 73.4008 214.844 61.5085 204.836 51.4954C194.829 41.4822 182.941 33.5457 169.856 28.1422C156.771 22.7387 142.747 19.9746 128.59 20.0089C114.393 19.9361 100.322 22.6776 87.1904 28.075C74.0589 33.4723 62.1272 41.4185 52.0849 51.4543C42.0425 61.4902 34.0886 73.4166 28.6828 86.5446C23.2769 99.6725 20.5264 113.742 20.59 127.939C20.5465 142.096 23.3018 156.121 28.6977 169.209C34.0936 182.297 42.0235 194.189 52.0314 204.202C62.0394 214.215 73.9281 222.15 87.0136 227.552C100.099 232.954 114.123 235.716 128.28 235.679C142.479 235.761 156.553 233.028 169.689 227.638C182.826 222.248 194.764 214.308 204.813 204.276C214.862 194.245 222.823 182.321 228.236 169.194C233.649 156.067 236.407 141.998 236.35 127.799L236.28 127.759Z"
    />
    <path
      d="M133.28 143.059C154.06 144.059 172.111 153.349 183.861 173.499C184.616 174.786 185.284 176.122 185.861 177.499C187.701 181.959 186.431 185.889 182.651 187.669C178.871 189.449 175.001 188.049 172.831 183.599C168.77 174.972 162.061 167.867 153.68 163.319C131.05 150.919 99.9605 159.419 89.3505 183.009C87.0605 188.109 82.9604 189.699 78.9604 187.639C75.1704 185.639 74.2005 181.769 76.3005 176.909C85.0205 156.689 107.28 142.949 133.28 143.059Z"
    />
    <path
      d="M163.42 108.079C161.466 108.084 159.531 107.704 157.724 106.959C155.918 106.214 154.276 105.12 152.894 103.74C151.511 102.359 150.415 100.719 149.668 98.9133C148.921 97.1079 148.537 95.1729 148.54 93.219C148.61 89.2775 150.218 85.5196 153.021 82.7471C155.823 79.9746 159.598 78.407 163.54 78.3789C167.489 78.4237 171.26 80.0238 174.037 82.832C176.813 85.6402 178.37 89.4299 178.37 93.3789C178.364 95.3286 177.971 97.2578 177.214 99.0547C176.457 100.852 175.352 102.481 173.962 103.848C172.571 105.215 170.924 106.293 169.115 107.019C167.305 107.745 165.369 108.105 163.42 108.079Z"
    />
    <path
      d="M95.75 108.079C91.8053 108.089 88.0177 106.533 85.22 103.752C82.4222 100.971 80.8433 97.1933 80.8301 93.2487C80.8977 89.3026 82.5033 85.539 85.3052 82.7595C88.107 79.9801 91.8836 78.4046 95.8301 78.3687C99.7707 78.4003 103.539 79.9887 106.313 82.7874C109.088 85.586 110.643 89.368 110.64 93.3087C110.651 95.2615 110.272 97.1969 109.526 99.0016C108.78 100.806 107.682 102.444 106.296 103.819C104.909 105.195 103.262 106.28 101.452 107.011C99.641 107.742 97.7026 108.105 95.75 108.079Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 257 },
    height: { type: [Number, String], default: 256 },
    iconColor: { type: String, default: "#F1F1F2" },
  },
};
</script>
