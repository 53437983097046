<template>
  <div v-if="user && Object.keys(user).length" class="person-menu-heading">
    <div class="person-menu-heading-photo">
      <img v-if="user.avatar" :src="`${user.avatar}`" alt="" />
    </div>
    <div class="person-menu-heading-description">
      <p v-if="user.name" class="person-menu-heading-text">{{ user.name }}</p>
      <p
        v-if="user.role"
        class="person-menu-heading-text person-menu-heading-text_role"
      >
        {{ user.role }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();

    const user = computed(() => store.getters["auth/user"]);

    return { user };
  },
};
</script>

<style lang="scss" scoped>
// .person-menu {
//   &-heading {
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     &-photo {
//       border-radius: 50%;
//       border: 1px solid var(--col-info);
//       background-color: var(--col-bg-primary);

//       img {
//         width: 40px;
//         height: 40px;
//         min-width: 40px;
//       }
//     }

//     &-description {
//       padding: 5px;
//     }

//     &-text {
//       font-family: "SFProDisplay-Regular", serif;
//       font-weight: 400;
//       font-size: 14px;
//       color: var(--col-primary-dark);

//       &:first-of-type {
//         font-family: "SFProDisplay-Medium", serif;
//         font-weight: 500;
//       }

//       &_role {
//         &::first-letter {
//           text-transform: uppercase;
//         }
//       }
//     }
//   }
// }
</style>
