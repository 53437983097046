import Cookies from "@/modules/helpers/cookies";

const cookieName = "cureline_notification";

export default {
  namespaced: true,

  state: {
    notifications: Cookies.getCookie(cookieName)
      ? JSON.parse(Cookies.getCookie(cookieName))
      : [],
  },

  getters: {
    notifications(state) {
      return state.notifications;
    },
  },

  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
  },

  actions: {
    removeNotification({ commit }, index) {
      let current = Cookies.getCookie(cookieName)
        ? JSON.parse(Cookies.getCookie(cookieName))
        : [];
      current.splice(index, 1);
      Cookies.setCookie(cookieName, JSON.stringify(current), 1);
      commit("notifications/SET_NOTIFICATIONS", current, { root: true });
    },
    setNotifications({ commit }, notifications) {
      Cookies.setCookie(cookieName, JSON.stringify(notifications), 1);
      commit("notifications/SET_NOTIFICATIONS", notifications, { root: true });
    },
    addNotifications({ commit }, notifications) {
      const current = Cookies.getCookie(cookieName)
        ? JSON.parse(Cookies.getCookie(cookieName))
        : [];
      const newNotifications = [...notifications, ...current];
      Cookies.setCookie(cookieName, JSON.stringify(newNotifications), 1);
      commit("notifications/SET_NOTIFICATIONS", newNotifications, {
        root: true,
      });
    },
    readNotificationsCookies({ commit }, notifications) {
      const current = Cookies.getCookie(cookieName)
        ? JSON.parse(Cookies.getCookie(cookieName))
        : [];
      if (
        JSON.stringify(current) !== JSON.stringify(notifications.value) &&
        JSON.stringify(current).length >
          JSON.stringify(notifications.value).length
      ) {
        commit("notifications/SET_NOTIFICATIONS", current, { root: true });
      }
    },
  },
};
