<template>
  <div
    v-if="storedProject && Object.keys(storedProject).length"
    class="new-project-page"
  >
    <div class="new-project-main">
      <div class="breadcrumbs-wrapper">
        <p>
          <router-link to="/project-list">Clients Requests</router-link> / New
          Request
        </p>
      </div>

      <div class="new-project">
        <div class="new-project-client">
          <div class="project-heading">
            <h2 class="heading">New Request:</h2>

            <h2 class="green" style="opacity: 0.3">
              Request# {{ storedProject.project_number }}
            </h2>

            <div class="heading-tagline">! Visible to administrators only</div>
          </div>

          <div class="project-heading_block">
            <label for="study-name">Study Name</label>
            <input
              type="text"
              id="study-name"
              class="highlighted"
              placeholder="Study Name"
              v-model="project.study_name"
            />
          </div>

          <div class="project-heading_block">
            <label for="сlient-сompany"
              >Client Company <span class="red">*</span></label
            >
            <input
              type="text"
              id="сlient-сompany"
              class="highlighted"
              placeholder="Client Company"
              v-model="project.client_company"
              required
            />
            <div class="project__field-validation">
              <span v-if="!validationObj.client_company.validation"
                >Please fill in Client Company field</span
              >
            </div>
          </div>

          <div class="project-heading_block">
            <label for="сontact-name"
              >Contact Name <span class="red">*</span></label
            >
            <input
              type="text"
              id="сontact-name"
              class="highlighted"
              placeholder="Contact Name"
              v-model="project.contact_name"
              required
            />
            <div class="project__field-validation">
              <span v-if="!validationObj.contact_name.validation"
                >Please fill in Contact Name field</span
              >
            </div>
          </div>

          <div class="project-heading_block">
            <label for="contact-phone"
              >Contact Phone <span class="red">*</span></label
            >
            <input
              type="tel"
              id="contact-phone"
              class="highlighted"
              placeholder="Contact Phone"
              v-model="project.contact_phone"
              required
            />
            <div class="project__field-validation">
              <span v-if="!validationObj.contact_phone.validation"
                >Please fill in Contact Phone field</span
              >
            </div>
          </div>

          <div class="project-heading_block">
            <label for="contact-email"
              >Contact Email <span class="red">*</span></label
            >
            <input
              type="email"
              id="contact-email"
              class="highlighted"
              placeholder="Contact Email"
              v-model="project.contact_email"
              required
            />
            <div class="project__field-validation">
              <span v-if="!validationObj.contact_email.validation"
                >Please fill in Contact Email field</span
              >
            </div>
          </div>
        </div>

        <div class="field-container">
          <label
            >Feasibility Title <span class="red">*</span>
            <span class="heading-small">(Future project name)</span>
          </label>
          <input
            type="text"
            class="textarea"
            v-model="project.description_short"
            required
          />
          <div class="project__field-validation">
            <span v-if="!validationObj.description_short.validation"
              >Please fill in Feasibility Title field</span
            >
          </div>
        </div>

        <div class="field-container_short_wrapper">
          <div class="field-container field-container_short">
            <label> Timeline </label>
            <input type="text" class="textarea" v-model="project.timeline" />
          </div>

          <div class="field-container field-container_short">
            <label
              ># Donors <span class="red" style="font-size: 20px">*</span>
            </label>
            <input
              type="text"
              class="textarea"
              placeholder="Ex: 3"
              v-model="project.donors_number"
              required
            />
            <div class="project__field-validation">
              <span v-if="!validationObj.donors_number.validation"
                >Please fill in # Donors field</span
              >
            </div>
          </div>

          <div class="field-container field-container_short">
            <label>Suggested Price</label>
            <input
              type="text"
              class="textarea"
              v-model="project.suggested_price"
              placeholder="$"
            />
          </div>
        </div>

        <div class="field-container">
          <label
            >Indication <span class="red" style="font-size: 20px">*</span>
          </label>
          <input
            type="text"
            class="textarea"
            v-model="project.indication"
            placeholder="NHL / MM / Serum"
            required
          />
          <div class="project__field-validation">
            <span v-if="!validationObj.indication.validation"
              >Please fill in Indication field</span
            >
          </div>
        </div>

        <hr style="margin: 10px 0 30px" />

        <div class="new-project-lines">
          <h3 class="green">Scope of Work (SOW)</h3>
          <ProjectLineList
            :lineList="lines"
            :get-line-info="getLineInfo"
            :project-id="storedProject.id"
            @add-line="saveProject"
            @remove-line="saveProject"
          />
        </div>

        <hr style="margin: 40px 0 10px" />

        <div class="new-project-fieldset">
          <div class="field-container">
            <label class="green">Donor Characteristics</label>
            <textarea
              rows="2"
              class="textarea"
              v-model="project.donor_characteristics"
            />
          </div>

          <div class="field-container">
            <label class="green">Inclusion Criteria</label>
            <textarea
              rows="2"
              class="textarea"
              v-model="project.inclusion_criteria"
            />
          </div>

          <div class="field-container">
            <label class="green">Exclusion Criteria</label>
            <textarea
              rows="3"
              class="textarea"
              v-model="project.exclusion_criteria"
            />
          </div>

          <div class="field-container">
            <label class="green">Preservation/Procurement</label>
            <textarea
              rows="2"
              class="textarea"
              v-model="project.preservation"
            />
          </div>

          <div class="field-container">
            <label class="green">Clinical information</label>
            <textarea
              rows="2"
              class="textarea"
              v-model="project.clinical_info"
            />
          </div>

          <AdditionalFields />
        </div>
      </div>
    </div>

    <!--sidebar-->

    <div class="sidebar">
      <ButtonsBlock
        v-if="categories"
        title="Pathology"
        :buttonsList="categories"
        :is-approved="false"
      />
      <div class="project__field-validation">
        <span v-if="!categoriesValidation">Please fill in Pathology field</span>
      </div>

      <div class="field-container">
        <h6>Request public note:</h6>
        <div>
          <textarea rows="1" class="textarea" v-model="project.public_note" />
        </div>
      </div>

      <div class="field-container">
        <h6>Cureline internal note:</h6>
        <div>
          <textarea rows="1" class="textarea" v-model="project.private_note" />
        </div>
      </div>

      <hr class="dashed" />

      <EditVisibility :project="storedProject" @select="$emit('select')" />

      <div class="field-container">
        <hr class="dashed" style="margin-bottom: 10px" />
        <FileUploading />
      </div>

      <hr class="dashed" style="margin-bottom: 10px" />

      <div class="chbx">
        <label>
          <input type="checkbox" v-model="isFeasibility" />
          <span>Ready for feasibility</span>
        </label>
      </div>

      <BaseButton
        class-name="big typical"
        :text="`${isFeasibility ? 'Save as feasibility' : 'Save as draft'}`"
        style="margin-bottom: 20px"
        @click="saveProject"
      />

      <h4 v-if="isSavedInfo" class="green center" style="margin-bottom: 20px">
        ... information is saved
      </h4>

      <BaseButton
        text="Delete this project"
        class-name="error big"
        @click="$emit('delete-project')"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import ProjectLineList from "@/components/base/ProjectLineList.vue";
import EditVisibility from "@/components/project/EditVisibility.vue";
import FileUploading from "@/components/project/FileUploading.vue";
import AdditionalFields from "@/components/project/AdditionalFields.vue";

import BaseButton from "@/modules/components/base/BaseButton.vue";
import ButtonsBlock from "@/modules/components/ButtonsBlock.vue";

export default {
  components: {
    ProjectLineList,
    EditVisibility,
    FileUploading,
    AdditionalFields,
    ButtonsBlock,
    BaseButton,
  },
  emits: ["delete-project", "select"],
  setup() {
    const store = useStore();
    const router = useRouter();

    const project = ref({});
    const isFeasibility = ref(false);
    const isSavedInfo = ref(false);
    const getLineInfo = ref(1);

    const storedProject = computed(() => store.getters["projects/project"]);
    const categories = computed(() => store.getters["projects/categories"]);
    const lines = computed(() => store.getters["projects/lines"]);

    const categoriesValidation = ref(true);
    const validationObj = ref({
      client_company: { type: "text", validation: true },
      contact_name: { type: "text", validation: true },
      contact_phone: { type: "text", validation: true },
      contact_email: { type: "text", validation: true },
      description_short: { type: "text", validation: true },
      donors_number: { type: "text", validation: true },
      indication: { type: "text", validation: true },
    });

    onMounted(async () => {
      project.value = storedProject.value;
    });
    // const addLine = () => {
    //   saveProject();
    //   store.dispatch("projects/addNewLine", { project_id: project.value.id });
    // };

    // const editLine = (data) => {
    //   // saveProject();
    //   // store.dispatch("projects/editLine", line);
    // };

    // const removeLine = (id) => {
    //   saveProject(id);
    // };

    const validateProject = () => {
      if (isFeasibility.value === false) {
        return true;
      }
      const p = project.value;
      let payload = {
        isShown: true,
        text: "Files are saved",
        status: "error",
      };
      let errorsText = [];

      if (!p.id) {
        console.log("No project identifier", p);
      }
      if (!p.client_company) {
        errorsText.push("company name");
      }
      if (!p.contact_email) {
        errorsText.push("contact email");
      }
      if (!p.contact_name) {
        errorsText.push("contact name");
      }
      if (!p.contact_phone) {
        errorsText.push("contact phone");
      }
      if (!p.donors_number) {
        errorsText.push("donors number");
      }
      if (!p.description_short) {
        errorsText.push("feasibility title");
      }
      if (!p.indication) {
        errorsText.push("project indication");
      }

      if (errorsText.length) {
        payload.text = "Please do not forget: " + errorsText.join(", ");
        store.commit("admins/SET_NOTIFICATION", payload);

        setTimeout(() => {
          store.commit("admins/CLEAN_NOTIFICATION");
        }, 10000);

        return false;
      }

      if (!categories.value || !categories.value.length) {
        let payload = {
          isShown: true,
          text: "Pathology type is empty! Please select at least one category",
          status: "error",
        };
        store.commit("admins/SET_NOTIFICATION", payload);

        setTimeout(() => {
          store.commit("admins/CLEAN_NOTIFICATION");
        }, 10000);

        return false;
      }

      return true;
    };

    const prepareData = () => {
      const dataForm = project.value;
      return {
        project_number: dataForm.project_number,
        id: dataForm.id,
        status: dataForm.status,
        study_name: dataForm.study_name,
        client_company: dataForm.client_company,
        contact_name: dataForm.contact_name,
        contact_phone: dataForm.contact_phone,
        contact_email: dataForm.contact_email,
        indication: dataForm.indication,
        suggested_price: dataForm.suggested_price,
        donors_number: dataForm.donors_number,
        description_short: dataForm.description_short,
        timeline: dataForm.timeline,
        bio_material: dataForm.bio_material,
        donor_characteristics: dataForm.donor_characteristics,
        inclusion_criteria: dataForm.inclusion_criteria,
        exclusion_criteria: dataForm.exclusion_criteria,
        preservation: dataForm.preservation,
        clinical_info: dataForm.clinical_info,
        public_note: dataForm.public_note,
        private_note: dataForm.private_note,
        visibility: dataForm.visibility,
        categories:
          categories.value && categories.value.length
            ? categories.value
                .filter((item) => item.isChosen === true)
                .map(({ id }) => id)
            : "",
        project_visibility:
          project.value.project_visibility &&
          project.value.project_visibility.length
            ? project.value.project_visibility.map(({ id }) => id)
            : "",
      };
    };

    const saveProject = async (emitedData) => {
      categoriesValidation.value = true;
      if (
        isFeasibility.value &&
        !formValidation(validationObj, project.value)
      ) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      if (!categoriesValidate()) {
        categoriesValidation.value = false;
        return;
      }

      if (!project.value.id) {
        return false;
      }
      if (isFeasibility.value && !validateProject()) {
        return;
      }

      const requestData = prepareData();

      if (isFeasibility.value) {
        requestData.status = "feasibility";
      }

      getLineInfo.value++;

      if (emitedData) {
        if (emitedData.type === "addLine") {
          await store.dispatch("projects/addNewLine", {
            project_id: project.value.id,
          });
        }
        if (emitedData.type === "editLine") {
          await store.dispatch("projects/editLine", emitedData.line);
        }
        if (emitedData.type === "removeItem") {
          await store.dispatch("projects/removeLine", emitedData.line);
        }
      }

      await store.dispatch("projects/saveProject", requestData);
      showHint();
    };

    const categoriesValidate = () => {
      return !!categories.value
        .filter((item) => item.isChosen === true)
        .map(({ id }) => id).length;
    };

    const formValidation = (obj, computedValue) => {
      let list = Object.entries(obj.value);
      let validation = true;
      list.forEach((el) => {
        const item = obj.value[el[0]];
        item.validation = computedValue[el[0]] && !!computedValue[el[0]].length;
        if (!computedValue[el[0]] || !computedValue[el[0]].length) {
          validation = false;
        }
      });
      return validation;
    };

    const showHint = () => {
      isSavedInfo.value = true;
      const hideHint = () => (isSavedInfo.value = false);
      setTimeout(hideHint, 3000);
    };

    watch(storedProject, (newValue) => {
      project.value = newValue;
    });

    return {
      storedProject,
      project,
      lines,
      categories,
      router,
      validateProject,
      getLineInfo,
      saveProject,
      isSavedInfo,
      isFeasibility,
      validationObj,
      categoriesValidation,
    };
  },
};
</script>

<style lang="scss" scoped>
label,
input {
  font-size: 16px;
  line-height: 24px;
  font-family: "OpenSans-Regular", sans-serif;
  color: var(--col-text-secondary);
}
input {
  margin: 7px 0;
  padding: 6px 12px;
}
.highlighted {
  border: 1px solid var(--col-border);
  border-radius: 4px;
  color: var(--col-contrast-text);
  background-color: var(--col-info);

  &:active,
  &:focus,
  &:focus-visible {
    border-color: var(--col-info);
  }

  &::placeholder {
    color: #ffffff;
  }
}
.project-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;

  &_block {
    flex-basis: 30%;
  }
}
.project__field-validation {
  position: relative;

  span {
    position: absolute;
    font-size: 12px;
    color: #dc3545;
    bottom: -8px;
  }
}
.buttons-block ~ .project__field-validation span {
  bottom: -13px;
}
.heading {
  flex-basis: 30%;
  font-size: 26px;
  font-weight: normal;
  font-family: "SFProDisplay-Medium", sans-serif;

  &-small {
    padding-left: 5px;
    font-size: 12px;
    font-weight: 400;
  }
}
.heading-tagline {
  position: relative;
  margin-right: 20px;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--col-contrast-text);
  background-color: var(--col-info);
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 5px 5px 0px 5px;
    border-color: var(--col-info) transparent transparent transparent;
  }
}
.project-number {
  position: relative;
  &-label {
    position: absolute;
    top: -30px;
    left: 0;
  }
  &-input-wrapper {
    border: 4px solid var(--col-bg-attention);
    border-radius: 6px;

    input {
      margin: 0;
      border-radius: 4px;
      border: 1px solid gray;
    }
  }
}
</style>
