<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 119 121" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <path d="M75.3669 78.4872C75.3669 77.3833 74.4784 76.4873 73.3837 76.4873C72.2889 76.4873 71.4004 77.3833 71.4004 78.4872V82.4873C71.4004 83.5912 72.2889 84.4872 73.3837 84.4872C74.4784 84.4872 75.3669 83.5912 75.3669 82.4873V78.4872Z" fill="white"/>
      <path d="M73.3836 100.487C72.2889 100.487 71.4004 101.383 71.4004 102.487V110.487C71.4004 111.591 72.2889 112.487 73.3836 112.487C74.4784 112.487 75.3669 111.591 75.3669 110.487V102.487C75.3671 101.383 74.4786 100.487 73.3836 100.487Z" fill="white"/>
      <path d="M83.3005 54.4872C83.3005 53.3833 82.412 52.4873 81.3172 52.4873C80.2225 52.4873 79.334 53.3833 79.334 54.4872V58.4873C79.334 59.5912 80.2225 60.4872 81.3172 60.4872C82.412 60.4872 83.3005 59.5912 83.3005 58.4873V54.4872Z" fill="white"/>
      <path d="M75.3669 66.4872C75.3669 65.3833 74.4784 64.4873 73.3837 64.4873C72.2889 64.4873 71.4004 65.3833 71.4004 66.4872V70.4873C71.4004 71.5912 72.2889 72.4872 73.3837 72.4872C74.4784 72.4872 75.3669 71.5912 75.3669 70.4873V66.4872Z" fill="white"/>
      <path d="M75.3671 90.4872C75.3671 89.3833 74.4786 88.4873 73.3839 88.4873C72.2892 88.4873 71.4004 89.3833 71.4004 90.4872V94.4873C71.4004 95.5912 72.2889 96.4872 73.3836 96.4872C74.4784 96.4872 75.3669 95.5912 75.3669 94.4873V90.4872H75.3671Z" fill="white"/>
      <path d="M83.3005 66.4872C83.3005 65.3833 82.412 64.4873 81.3172 64.4873C80.2225 64.4873 79.334 65.3833 79.334 66.4872V70.4873C79.334 71.5912 80.2225 72.4872 81.3172 72.4872C82.412 72.4872 83.3005 71.5912 83.3005 70.4873V66.4872Z" fill="white"/>
      <path d="M67.4333 54.4872C67.4333 53.3833 66.5448 52.4873 65.4501 52.4873C64.3554 52.4873 63.4668 53.3833 63.4668 54.4872V58.4873C63.4668 59.5912 64.3554 60.4872 65.4501 60.4872C66.5448 60.4872 67.4333 59.5912 67.4333 58.4873V54.4872Z" fill="white"/>
      <path d="M75.3669 54.4872C75.3669 53.3833 74.4784 52.4873 73.3837 52.4873C72.2889 52.4873 71.4004 53.3833 71.4004 54.4872V58.4873C71.4004 59.5912 72.2889 60.4872 73.3837 60.4872C74.4784 60.4872 75.3669 59.5912 75.3669 58.4873V54.4872Z" fill="white"/>
      <path d="M83.3005 34.4873V30.4872C83.3005 29.3833 82.412 28.4873 81.3172 28.4873C80.2225 28.4873 79.334 29.3833 79.334 30.4872V34.4873C79.334 35.5912 80.2225 36.4872 81.3172 36.4872C82.412 36.4872 83.3005 35.5912 83.3005 34.4873Z" fill="white"/>
      <path d="M67.4333 66.4872C67.4333 65.3833 66.5448 64.4873 65.4501 64.4873C64.3554 64.4873 63.4668 65.3833 63.4668 66.4872V70.4873C63.4668 71.5912 64.3554 72.4872 65.4501 72.4872C66.5448 72.4872 67.4333 71.5912 67.4333 70.4873V66.4872Z" fill="white"/>
      <path d="M91.2341 30.4872C91.2341 29.3833 90.3455 28.4873 89.2508 28.4873C88.1561 28.4873 87.2676 29.3833 87.2676 30.4872V34.4873C87.2676 35.5912 88.1561 36.4872 89.2508 36.4872C90.3455 36.4872 91.2341 35.5912 91.2341 34.4873V30.4872Z" fill="white"/>
      <path d="M65.4501 76.4873C64.3554 76.4873 63.4668 77.3833 63.4668 78.4872V82.4873C63.4668 83.5912 64.3554 84.4872 65.4501 84.4872C66.5448 84.4872 67.4333 83.5912 67.4333 82.4873V78.4872C67.4333 77.3833 66.5448 76.4873 65.4501 76.4873Z" fill="white"/>
      <path d="M99.1659 90.4872C99.1659 89.3833 98.2774 88.4873 97.1827 88.4873C96.088 88.4873 95.1992 89.3833 95.1992 90.4872V94.4873C95.1992 95.5912 96.0878 96.4872 97.1825 96.4872C98.2772 96.4872 99.1657 95.5912 99.1657 94.4873V90.4872H99.1659Z" fill="white"/>
      <path d="M97.1825 100.487C96.0878 100.487 95.1992 101.383 95.1992 102.487V110.487C95.1992 111.591 96.0878 112.487 97.1825 112.487C98.2772 112.487 99.1657 111.591 99.1657 110.487V102.487C99.166 101.383 98.2774 100.487 97.1825 100.487Z" fill="white"/>
      <path d="M105.116 76.4873C104.021 76.4873 103.133 77.3833 103.133 78.4872V82.4873C103.133 83.5912 104.021 84.4872 105.116 84.4872C106.211 84.4872 107.099 83.5912 107.099 82.4873V78.4872C107.099 77.3833 106.211 76.4873 105.116 76.4873Z" fill="white"/>
      <path d="M73.3836 36.4873C74.4784 36.4873 75.3669 35.5913 75.3669 34.4874V26.0494L95.2004 21.0493V54.4873C95.2004 55.3212 95.714 56.0672 96.4875 56.3614L111.067 61.8734V82.4874C111.067 83.5913 111.956 84.4873 113.05 84.4873C114.145 84.4873 115.034 83.5913 115.034 82.4874V60.4873C115.034 59.6534 114.52 58.9074 113.746 58.6132L99.1669 53.1012V14.4872C99.1669 13.3833 98.2783 12.4873 97.1836 12.4873C96.0889 12.4873 95.2004 13.3833 95.2004 14.4872V16.9252L72.9018 22.5471C72.0193 22.7691 71.4004 23.5693 71.4004 24.4871V34.4871C71.4004 35.5913 72.2889 36.4873 73.3836 36.4873Z" fill="white"/>
      <path d="M107.099 66.4872C107.099 65.3833 106.211 64.4873 105.116 64.4873C104.021 64.4873 103.133 65.3833 103.133 66.4872V70.4873C103.133 71.5912 104.021 72.4872 105.116 72.4872C106.211 72.4872 107.099 71.5912 107.099 70.4873V66.4872Z" fill="white"/>
      <path d="M99.1677 66.4872C99.1677 65.3833 98.2791 64.4873 97.1844 64.4873C96.0897 64.4873 95.2012 65.3833 95.2012 66.4872V70.4873C95.2012 71.5912 96.0897 72.4872 97.1844 72.4872C98.2791 72.4872 99.1677 71.5912 99.1677 70.4873V66.4872Z" fill="white"/>
      <path d="M83.3005 90.4872C83.3005 89.3833 82.412 88.4873 81.3172 88.4873C80.2225 88.4873 79.334 89.3833 79.334 90.4872V94.4873C79.334 95.5912 80.2225 96.4872 81.3172 96.4872C82.412 96.4872 83.3005 95.5912 83.3005 94.4873V90.4872Z" fill="white"/>
      <path d="M99.1677 78.4872C99.1677 77.3833 98.2791 76.4873 97.1844 76.4873C96.0897 76.4873 95.2012 77.3833 95.2012 78.4872V82.4873C95.2012 83.5912 96.0897 84.4872 97.1844 84.4872C98.2791 84.4872 99.1677 83.5912 99.1677 82.4873V78.4872Z" fill="white"/>
      <path d="M81.3172 100.487C80.2225 100.487 79.334 101.383 79.334 102.487V110.487C79.334 111.591 80.2225 112.487 81.3172 112.487C82.412 112.487 83.3005 111.591 83.3005 110.487V102.487C83.3005 101.383 82.412 100.487 81.3172 100.487Z" fill="white"/>
      <path d="M83.3005 78.4872C83.3005 77.3833 82.412 76.4873 81.3172 76.4873C80.2225 76.4873 79.334 77.3833 79.334 78.4872V82.4873C79.334 83.5912 80.2225 84.4872 81.3172 84.4872C82.412 84.4872 83.3005 83.5912 83.3005 82.4873V78.4872Z" fill="white"/>
      <path d="M89.25 112.487C90.3447 112.487 91.2332 111.591 91.2332 110.487V46.4872C91.2332 45.5692 90.6124 44.7692 89.7318 44.5473L57.9985 36.5471C57.4095 36.3971 56.7767 36.5331 56.2967 36.9111C55.8147 37.2892 55.5332 37.8711 55.5332 38.4871V82.487C55.5332 83.5909 56.4218 84.4869 57.5165 84.4869C58.6112 84.4869 59.4997 83.5909 59.4997 82.487V41.0492L87.2665 48.0493V110.487C87.2667 111.591 88.1553 112.487 89.25 112.487Z" fill="white"/>
      <path d="M43.6325 42.4872C43.6325 41.3833 42.744 40.4873 41.6493 40.4873C40.5546 40.4873 39.666 41.3833 39.666 42.4872V46.4873C39.666 47.5912 40.5546 48.4872 41.6493 48.4872C42.744 48.4872 43.6325 47.5912 43.6325 46.4873V42.4872Z" fill="white"/>
      <path d="M43.6325 30.4872C43.6325 29.3833 42.744 28.4873 41.6493 28.4873C40.5546 28.4873 39.666 29.3833 39.666 30.4872V34.4873C39.666 35.5912 40.5546 36.4872 41.6493 36.4872C42.744 36.4872 43.6325 35.5912 43.6325 34.4873V30.4872Z" fill="white"/>
      <path d="M27.7675 90.4872C27.7675 89.3833 26.879 88.4873 25.7843 88.4873C24.6896 88.4873 23.8008 89.3833 23.8008 90.4872V94.4873C23.8008 95.5912 24.6893 96.4872 25.784 96.4872C26.8787 96.4872 27.7673 95.5912 27.7673 94.4873V90.4872H27.7675Z" fill="white"/>
      <path d="M9.91708 72.4872C11.0118 72.4872 11.9003 71.5911 11.9003 70.4872V60.4871H31.7336V110.487C31.7336 111.591 32.6221 112.487 33.7168 112.487C34.8116 112.487 35.7001 111.591 35.7001 110.487V58.4872C35.7001 57.3833 34.8116 56.4873 33.7168 56.4873H9.91685C8.82214 56.4873 7.93359 57.3833 7.93359 58.4872V70.4872C7.93359 71.5911 8.82214 72.4872 9.91708 72.4872Z" fill="white"/>
      <path d="M31.7324 42.4872V46.4873C31.7324 47.5912 32.621 48.4872 33.7157 48.4872C34.8104 48.4872 35.6989 47.5912 35.6989 46.4873V42.4872C35.6989 41.3833 34.8104 40.4873 33.7157 40.4873C32.621 40.4873 31.7324 41.3833 31.7324 42.4872Z" fill="white"/>
      <path d="M43.6325 18.4872C43.6325 17.3833 42.744 16.4873 41.6493 16.4873C40.5546 16.4873 39.666 17.3833 39.666 18.4872V22.4873C39.666 23.5912 40.5546 24.4872 41.6493 24.4872C42.744 24.4872 43.6325 23.5912 43.6325 22.4873V18.4872Z" fill="white"/>
      <path d="M25.784 100.487C24.6893 100.487 23.8008 101.383 23.8008 102.487V110.487C23.8008 111.591 24.6893 112.487 25.784 112.487C26.8787 112.487 27.7673 111.591 27.7673 110.487V102.487C27.7675 101.383 26.879 100.487 25.784 100.487Z" fill="white"/>
      <path d="M117.017 116.487H113.05V104.203C116.461 103.309 119 100.203 119 96.4872C119 92.0751 115.442 88.4873 111.067 88.4873C106.692 88.4873 103.133 92.0754 103.133 96.4872C103.133 100.203 105.672 103.309 109.083 104.203V116.487H59.5V108.285C64.0199 107.355 67.4333 103.317 67.4333 98.4874C67.4333 92.9735 62.9847 88.4873 57.5165 88.4873C52.0486 88.4873 47.5998 92.9732 47.5998 98.4874C47.5998 103.317 51.0131 107.355 55.533 108.285V116.487H11.9V96.2854C16.4199 95.3554 19.8333 91.3174 19.8333 86.4874C19.8333 80.9735 15.3847 76.4873 9.91651 76.4873C4.44855 76.4875 0 80.9735 0 86.4874C0 91.3174 3.41335 95.3554 7.93326 96.2854V116.487H1.98326C0.888549 116.487 0 117.383 0 118.487C0 119.591 0.888549 120.487 1.98326 120.487H117.017C118.111 120.487 119 119.591 119 118.488C119 117.384 118.111 116.487 117.017 116.487ZM3.96674 86.4874C3.96674 83.1794 6.63634 80.4874 9.91674 80.4874C13.1971 80.4874 15.8667 83.1794 15.8667 86.4874C15.8667 89.7954 13.1971 92.4874 9.91674 92.4874C6.63634 92.4874 3.96674 89.7954 3.96674 86.4874ZM51.5667 98.4874C51.5667 95.1794 54.2363 92.4874 57.5167 92.4874C60.7971 92.4874 63.4667 95.1794 63.4667 98.4874C63.4667 101.795 60.7971 104.487 57.5167 104.487C54.2363 104.487 51.5667 101.795 51.5667 98.4874ZM107.1 96.4875C107.1 94.2815 108.879 92.4874 111.067 92.4874C113.255 92.4874 115.033 94.2813 115.033 96.4875C115.033 98.6934 113.255 100.488 111.067 100.488C108.879 100.488 107.1 98.6934 107.1 96.4875Z" fill="white"/>
      <path d="M15.8672 102.487V110.487C15.8672 111.591 16.7557 112.487 17.8504 112.487C18.9452 112.487 19.8337 111.591 19.8337 110.487V102.487C19.8337 101.383 18.9452 100.487 17.8504 100.487C16.7557 100.487 15.8672 101.383 15.8672 102.487Z" fill="white"/>
      <path d="M27.7673 66.4872C27.7673 65.3833 26.8787 64.4873 25.784 64.4873C24.6893 64.4873 23.8008 65.3833 23.8008 66.4872V70.4873C23.8008 71.5912 24.6893 72.4872 25.784 72.4872C26.8787 72.4872 27.7673 71.5912 27.7673 70.4873V66.4872Z" fill="white"/>
      <path d="M27.7673 78.4872C27.7673 77.3833 26.8787 76.4873 25.784 76.4873C24.6893 76.4873 23.8008 77.3833 23.8008 78.4872V82.4873C23.8008 83.5912 24.6893 84.4872 25.784 84.4872C26.8787 84.4872 27.7673 83.5912 27.7673 82.4873V78.4872Z" fill="white"/>
      <path d="M15.8672 66.4872V70.4873C15.8672 71.5912 16.7557 72.4872 17.8504 72.4872C18.9452 72.4872 19.8337 71.5912 19.8337 70.4873V66.4872C19.8337 65.3833 18.9452 64.4873 17.8504 64.4873C16.7557 64.4873 15.8672 65.3833 15.8672 66.4872Z" fill="white"/>
      <path d="M43.6325 78.4872C43.6325 77.3833 42.744 76.4873 41.6493 76.4873C40.5546 76.4873 39.666 77.3833 39.666 78.4872V82.4873C39.666 83.5912 40.5546 84.4872 41.6493 84.4872C42.744 84.4872 43.6325 83.5912 43.6325 82.4873V78.4872Z" fill="white"/>
      <path d="M51.5661 54.4872C51.5661 53.3833 50.6776 52.4873 49.5829 52.4873C48.4882 52.4873 47.5996 53.3833 47.5996 54.4872V58.4873C47.5996 59.5912 48.4882 60.4872 49.5829 60.4872C50.6776 60.4872 51.5661 59.5912 51.5661 58.4873V54.4872Z" fill="white"/>
      <path d="M51.5661 42.4872C51.5661 41.3833 50.6776 40.4873 49.5829 40.4873C48.4882 40.4873 47.5996 41.3833 47.5996 42.4872V46.4873C47.5996 47.5912 48.4882 48.4872 49.5829 48.4872C50.6776 48.4872 51.5661 47.5912 51.5661 46.4873V42.4872Z" fill="white"/>
      <path d="M51.5661 66.4872C51.5661 65.3833 50.6776 64.4873 49.5829 64.4873C48.4882 64.4873 47.5996 65.3833 47.5996 66.4872V70.4873C47.5996 71.5912 48.4882 72.4872 49.5829 72.4872C50.6776 72.4872 51.5661 71.5912 51.5661 70.4873V66.4872Z" fill="white"/>
      <path d="M49.5829 76.4873C48.4882 76.4873 47.5996 77.3833 47.5996 78.4872V82.4873C47.5996 83.5912 48.4882 84.4872 49.5829 84.4872C50.6776 84.4872 51.5661 83.5912 51.5661 82.4873V78.4872C51.5663 77.3833 50.6778 76.4873 49.5829 76.4873Z" fill="white"/>
      <path d="M51.5661 30.4872C51.5661 29.3833 50.6776 28.4873 49.5829 28.4873C48.4882 28.4873 47.5996 29.3833 47.5996 30.4872V34.4873C47.5996 35.5912 48.4882 36.4872 49.5829 36.4872C50.6776 36.4872 51.5661 35.5912 51.5661 34.4873V30.4872Z" fill="white"/>
      <path d="M43.6325 90.4872C43.6325 89.3833 42.744 88.4873 41.6493 88.4873C40.5546 88.4873 39.666 89.3833 39.666 90.4872V94.4873C39.666 95.5912 40.5546 96.4872 41.6493 96.4872C42.744 96.4872 43.6325 95.5912 43.6325 94.4873V90.4872Z" fill="white"/>
      <path d="M25.7838 52.4874C26.8785 52.4874 27.7671 51.5914 27.7671 50.4875V35.7232L34.6035 32.2772C35.2759 31.9371 35.7003 31.2453 35.7003 30.4873V13.7234L45.6171 8.72348L55.5338 13.7234V30.4873C55.5338 31.5912 56.4224 32.4872 57.5171 32.4872C58.6118 32.4872 59.5005 31.5912 59.5005 30.4873V12.4873C59.5005 11.7293 59.0761 11.0372 58.4038 10.6974L47.6005 5.25121V2.48723C47.6005 1.38332 46.712 0.487305 45.6173 0.487305C44.5226 0.487305 43.634 1.38332 43.634 2.48723V5.25121L32.8308 10.6971C32.1584 11.0372 31.734 11.7291 31.734 12.4871V29.251L24.8976 32.697C24.2252 33.0371 23.8008 33.7289 23.8008 34.4869V50.487C23.8005 51.5914 24.6891 52.4874 25.7838 52.4874Z" fill="white"/>
      <path d="M43.6325 66.4872C43.6325 65.3833 42.744 64.4873 41.6493 64.4873C40.5546 64.4873 39.666 65.3833 39.666 66.4872V70.4873C39.666 71.5912 40.5546 72.4872 41.6493 72.4872C42.744 72.4872 43.6325 71.5912 43.6325 70.4873V66.4872Z" fill="white"/>
      <path d="M41.6493 100.487C40.5546 100.487 39.666 101.383 39.666 102.487V110.487C39.666 111.591 40.5546 112.487 41.6493 112.487C42.744 112.487 43.6325 111.591 43.6325 110.487V102.487C43.6325 101.383 42.744 100.487 41.6493 100.487Z" fill="white"/>
      <path d="M51.5661 18.4872C51.5661 17.3833 50.6776 16.4873 49.5829 16.4873C48.4882 16.4873 47.5996 17.3833 47.5996 18.4872V22.4873C47.5996 23.5912 48.4882 24.4872 49.5829 24.4872C50.6776 24.4872 51.5661 23.5912 51.5661 22.4873V18.4872Z" fill="white"/>
      <path d="M43.6325 54.4872C43.6325 53.3833 42.744 52.4873 41.6493 52.4873C40.5546 52.4873 39.666 53.3833 39.666 54.4872V58.4873C39.666 59.5912 40.5546 60.4872 41.6493 60.4872C42.744 60.4872 43.6325 59.5912 43.6325 58.4873V54.4872Z" fill="white"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 119 },
    height: { type: [Number, String], default: 121 },
    iconColor: { type: String, default: "none" },
  },
};
</script>
