<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 12 12"
  >
    <path
      d="M10.325 2.27148H8.39136V1.94922C8.39136 1.41613 7.95765 0.982422 7.42456 0.982422H5.49097C4.95788 0.982422 4.52417 1.41613 4.52417 1.94922V2.27148H2.59058C2.05748 2.27148 1.62378 2.70519 1.62378 3.23828V3.88281C1.62378 4.06079 1.76807 4.20508 1.94604 4.20508H2.29373L2.86063 11.1004C2.8607 11.1013 2.86079 11.1022 2.86087 11.1032C2.90646 11.6044 3.3204 11.9824 3.82369 11.9824H9.09181C9.59513 11.9824 10.009 11.6044 10.0546 11.1032C10.0547 11.1022 10.0548 11.1013 10.0549 11.1004L10.6218 4.20508H10.9695C11.1475 4.20508 11.2917 4.06079 11.2917 3.88281V3.23828C11.2917 2.70519 10.858 2.27148 10.325 2.27148ZM5.1687 1.94922C5.1687 1.77152 5.31327 1.62695 5.49097 1.62695H7.42456C7.60226 1.62695 7.74683 1.77152 7.74683 1.94922V2.27148H5.1687V1.94922ZM9.41266 11.0459C9.397 11.2124 9.25922 11.3379 9.09181 11.3379H3.82369C3.65629 11.3379 3.51853 11.2124 3.50285 11.0459L2.94043 4.20508H9.9751L9.41266 11.0459ZM10.6472 3.56055H10.325H2.59058H2.26831V3.23828C2.26831 3.06058 2.41288 2.91602 2.59058 2.91602H4.84644H8.06909H10.325C10.5026 2.91602 10.6472 3.06058 10.6472 3.23828V3.56055Z"
    />
    <path
      d="M6.45776 4.84961C6.27979 4.84961 6.1355 4.9939 6.1355 5.17188V9.72656C6.1355 9.90454 6.27979 10.0488 6.45776 10.0488C6.63574 10.0488 6.78003 9.90454 6.78003 9.72656V5.17188C6.78003 4.9939 6.63574 4.84961 6.45776 4.84961Z"
    />
    <path
      d="M8.39136 4.84961C8.21338 4.84961 8.06909 4.9939 8.06909 5.17188V9.72656C8.06909 9.90454 8.21338 10.0488 8.39136 10.0488C8.56933 10.0488 8.71362 9.90454 8.71362 9.72656V5.17188C8.71362 4.9939 8.56933 4.84961 8.39136 4.84961Z"
    />
    <path
      d="M4.52417 4.84961C4.34619 4.84961 4.2019 4.9939 4.2019 5.17188V9.72656C4.2019 9.90454 4.34619 10.0488 4.52417 10.0488C4.70215 10.0488 4.84644 9.90454 4.84644 9.72656V5.17188C4.84644 4.9939 4.70215 4.84961 4.52417 4.84961Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 12 },
    height: { type: [Number, String], default: 12 },
    iconColor: { type: String, default: "#FF0000" },
  },
};
</script>
