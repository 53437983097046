<template>
  <div class="shipping-line">
    <!-- <div class="shipping-line-title">Project# {{ pid }}</div> -->
    <div class="shipping-line-head">
      <div class="shipping-line-row">
        <h6 class="shipping-line-cell green">Line Title</h6>
        <h6 class="shipping-line-cell green">Ordered#</h6>
        <h6 class="shipping-line-cell green">Got Cases#</h6>
        <h6 class="shipping-line-cell green">From User</h6>
        <!-- <h6 class="shipping-line-cell green">Pass QC</h6> -->
        <!-- <h6 class="shipping-line-cell green">Defect</h6> -->
      </div>
    </div>
    <div class="shipping-line-body">
      <div
        v-for="row in lineList"
        class="shipping-line-row"
        :key="row.plp_id"
        @click="switchToProposalDetails(row.plp_id)"
      >
        <!-- {{ row.plp_id }} -->
        <div class="shipping-line-row">
          <div class="shipping-line-cell">{{ row.pl_title }}</div>
          <div class="shipping-line-cell">
            {{ row.plp_ordered }}
          </div>
          <div class="shipping-line-cell">{{ row.ps_cases_count }}</div>
          <div class="shipping-line-cell">{{ row.u_name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  props: {
    lines: {
      type: Array,
    },
    pid: {
      type: Number,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { lines, pid } = toRefs(props);
    const lineList = computed(() => lines.value);

    const switchToProposalDetails = (lineId) => {
      store.dispatch("projects/getProject", { id: pid.value });
      // router.push({
      //   name: `ProjectFeasibilityDetails`,
      //   path: `/pending-feasibility-details/1#line-${lineId}`,
      //   params: { id: 1 },
      // });

      let routeData = router.resolve({
        name: `ProjectActiveDetails`,
        path: `/active-details/${pid.value}#line-${lineId}`,
        params: { id: pid.value },
      });
      window.open(routeData.href, "_blank");
    };

    return { lineList, switchToProposalDetails };
  },
};
</script>

<style lang="scss" scoped>
.shipping {
  &-line {
    margin-top: 10px;
    border-left: 8px solid var(--col-info);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;

    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--col-bg-attention);
    }

    &-body {
      .shipping-line-row {
        background-color: var(--col-bg-primary);
        cursor: pointer;
      }
      .shipping-line---col-subtext-primary {
        color: var(--col-subtext-primary);
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin: 3px 0;

      > .shipping-line-cell {
        padding: 7px 15px;

        // text-align: center;
        &:nth-child(1) {
          flex-basis: 50%;
        }
        &:nth-child(2) {
          // flex-basis: 20%;
        }
        &:nth-child(3) {
          flex-basis: 15%;
        }
      }
    }

    &-cell {
      &:nth-child(1) {
        flex-basis: 50%;
      }
      &:nth-child(2) {
        flex-basis: 15%;
        text-align: center;
      }
      &:nth-child(3) {
        flex-basis: 15%;
        text-align: center;
      }
      &:nth-child(4) {
        flex-basis: 30%;
      }
    }
  }
}
</style>
<style lang="scss">
.custom-checkbox:not(.ticked) {
  border-color: var(--col-warning);
}
</style>
