<template>
  <div class="feasibility-project-page">
    <!-- <div class="breadcrumbs-wrapper">
      <p>
        <router-link to="">Messenger</router-link>
      </p>
    </div> -->
    <GlobalMessenger />
  </div>
</template>

<script>
import GlobalMessenger from "@/components/chat/GlobalMessenger.vue";

export default {
  components: { GlobalMessenger },
};
</script>
