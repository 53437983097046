<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5544 2.12589C18.4746 -0.910547 13.3541 1.32063 13.2925 5.51082C13.2684 7.19224 14.0974 8.74309 15.5098 9.6582C15.8044 9.84912 15.7146 9.777 17.472 12.8813C17.5034 12.9368 17.5402 12.9861 17.5808 13.03L15.0169 15.5C14.0865 14.5763 12.542 14.7475 11.8369 15.859L8.41478 13.8222C8.40969 12.6945 7.4902 11.778 6.36101 11.778C4.56852 11.778 3.64622 13.9219 4.85507 15.2261L2.616 18.6092C1.31133 18.236 0 19.2199 0 20.5846C0 21.7167 0.920898 22.6382 2.05325 22.6382C4.0284 22.6382 4.85963 20.1066 3.2721 18.933L5.43555 15.6646C6.50191 16.2073 7.82676 15.7156 8.27229 14.5822L11.5604 16.5393C11.2949 17.8106 12.2714 19.0106 13.571 19.0106C15.0653 19.0106 16.0613 17.4546 15.4379 16.1017C18.5738 13.0805 18.3811 13.2707 18.4122 13.2279C18.5761 13.169 18.7231 13.0537 18.8205 12.8813C20.577 9.7784 20.4879 9.84912 20.7836 9.65768C22.1716 8.75766 23 7.234 23 5.58207C23 4.27302 22.4867 3.04539 21.5544 2.12589V2.12589ZM3.38142 20.5846C3.38142 21.3168 2.78551 21.9128 2.05325 21.9128C1.32098 21.9128 0.725594 21.3168 0.725594 20.5846C0.725594 19.8521 1.32098 19.2564 2.05325 19.2564C2.78551 19.2564 3.38142 19.8521 3.38142 20.5846ZM5.27411 14.5935C4.66064 13.7236 5.28358 12.5035 6.36101 12.5035C7.09695 12.5035 7.68919 13.1035 7.68919 13.8317C7.68919 15.1195 6.02094 15.6563 5.27411 14.5935V14.5935ZM13.571 18.2851C12.6934 18.2851 12.1794 17.459 12.2437 16.9455C12.2516 15.7807 13.6606 15.1939 14.4941 16.0038C15.3449 16.8245 14.767 18.2851 13.571 18.2851V18.2851ZM20.4135 9.08685C20.1796 9.23864 19.9846 9.44447 19.8499 9.68259C18.1025 12.7694 18.2263 12.5943 18.1463 12.5943C18.0661 12.5943 18.1928 12.7743 16.4426 9.68259C16.3079 9.44447 16.1134 9.23864 15.8794 9.0872C14.6647 8.30002 13.9518 6.96693 13.9726 5.52083C14.0251 1.879 18.461 0.0317593 21.077 2.6102C22.9777 4.48499 22.6531 7.63479 20.4135 9.08685V9.08685Z"
      :fill="iconColor"/>
    <path
      d="M20.8645 3.57093C19.7246 2.02639 17.6024 1.77984 16.1405 2.85885C14.6725 3.94224 14.3019 6.05637 15.4282 7.58284C16.6262 9.20617 18.9141 9.39849 20.3777 8.1133C20.5283 7.98117 20.5432 7.752 20.4111 7.60144C20.2789 7.45088 20.0497 7.43597 19.899 7.5681C18.7458 8.58007 16.9508 8.42425 16.012 7.15204C15.128 5.95389 15.4186 4.29336 16.5713 3.44248C17.7233 2.5923 19.3884 2.79287 20.2807 4.00172C20.8564 4.78189 20.9408 5.77017 20.5913 6.60631C20.5141 6.79109 20.6013 7.00359 20.786 7.08098C20.9708 7.15801 21.1835 7.07097 21.2607 6.88602C21.6934 5.85089 21.6139 4.58641 20.8645 3.57093Z"
      :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 23 },
    height: { type: [Number, String], default: 23 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
