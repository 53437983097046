<template>
  <svg :width="width" :height="height" :fill="iconColor" viewBox="0 0 20 20">
    <path
      d="M8.38838 19.9986C6.35824 19.9986 4.3281 19.9986 2.29796 19.9986C0.902631 19.9986 0 19.1022 0 17.7148C0 13.6562 0 9.5975 0 5.53882C0 4.17245 0.904193 3.27061 2.2722 3.26905C5.04412 3.26905 7.81604 3.26127 10.5872 3.2815C10.8338 3.30261 11.0633 3.41572 11.2298 3.59819C11.3235 3.7048 11.3079 4.05417 11.204 4.15299C11.0183 4.31912 10.7803 4.41544 10.531 4.42533C7.8379 4.44323 5.14328 4.43701 2.451 4.43701C1.48981 4.43701 1.17202 4.75604 1.17202 5.7209V17.6261C1.17202 18.4821 1.52182 18.8322 2.37448 18.8322H14.4366C15.2604 18.8322 15.6149 18.4735 15.6157 17.6433C15.6157 14.9587 15.6157 12.2745 15.6157 9.59049C15.6062 9.4607 15.6107 9.33028 15.6289 9.20143C15.6969 8.90263 15.8921 8.719 16.206 8.72133C16.5199 8.72367 16.7112 8.9073 16.7768 9.20766C16.7928 9.3234 16.7968 9.44048 16.7885 9.55703C16.7885 12.2545 16.7885 14.952 16.7885 17.6495C16.7885 19.1225 15.9108 19.9994 14.439 20.001L8.38838 19.9986Z"
    />
    <path
      d="M17.5676 0.000976562C17.8604 0.149597 18.1704 0.272539 18.4414 0.454619C18.653 0.597015 18.806 0.821893 19.0012 0.993079C20.3583 2.18127 20.2865 2.93449 19.0184 4.08066C18.3258 4.70315 17.691 5.39412 17.0273 6.05319C14.9972 8.0763 12.9629 10.0994 10.9244 12.1225C10.7189 12.3126 10.4677 12.4466 10.1951 12.5116C9.45175 12.6921 8.69514 12.8228 7.94477 12.9714C7.19439 13.1201 6.8969 12.8291 7.04448 12.093C7.20064 11.332 7.33338 10.5671 7.51297 9.81151C7.56949 9.56325 7.69038 9.33412 7.86356 9.14699C10.7229 6.27884 13.5888 3.41744 16.4612 0.562779C16.7578 0.259125 17.1468 0.0616239 17.5676 0.000976562V0.000976562ZM8.32737 11.6984C8.76931 11.609 9.13318 11.5063 9.50251 11.4697C9.90776 11.43 10.1873 11.2285 10.4621 10.953C12.6063 8.80332 14.7554 6.65883 16.9094 4.51952C17.0156 4.41369 17.1171 4.3032 17.1757 4.24251L15.8358 2.88002C15.8077 2.89322 15.7815 2.90996 15.7577 2.92982C13.4407 5.23617 11.1243 7.54458 8.80836 9.85508C8.73389 9.92497 8.6799 10.0137 8.65219 10.1119C8.54444 10.6106 8.44605 11.1164 8.32737 11.6984ZM18.0877 3.33989C18.1501 3.28153 18.1977 3.23873 18.2438 3.1936C18.9067 2.54465 18.9067 2.54465 18.2501 1.89025C17.5028 1.14559 17.5028 1.14481 16.7798 1.91126C16.7619 1.93485 16.748 1.96111 16.7384 1.98907L18.0877 3.33989Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 20 },
    height: { type: [Number, String], default: 20 },
    iconColor: { type: String, default: "#BDBDBD" },
  },
};
</script>
