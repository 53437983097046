<template>
  <svg
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5 21C16.5 21 18 21 18 19.5C18 18 16.5 13.5 9 13.5C1.5 13.5 0 18 0 19.5C0 21 1.5 21 1.5 21H16.5ZM1.5075 19.584V19.581V19.584ZM1.533 19.5H16.467C16.474 19.4992 16.481 19.4982 16.488 19.497L16.5 19.494C16.4985 19.125 16.269 18.015 15.252 16.998C14.274 16.02 12.4335 15 9 15C5.565 15 3.726 16.02 2.748 16.998C1.731 18.015 1.503 19.125 1.5 19.494C1.51098 19.4961 1.52198 19.4981 1.533 19.5ZM16.494 19.584V19.581V19.584ZM9 10.5C9.79565 10.5 10.5587 10.1839 11.1213 9.62132C11.6839 9.05871 12 8.29565 12 7.5C12 6.70435 11.6839 5.94129 11.1213 5.37868C10.5587 4.81607 9.79565 4.5 9 4.5C8.20435 4.5 7.44129 4.81607 6.87868 5.37868C6.31607 5.94129 6 6.70435 6 7.5C6 8.29565 6.31607 9.05871 6.87868 9.62132C7.44129 10.1839 8.20435 10.5 9 10.5ZM13.5 7.5C13.5 8.69347 13.0259 9.83807 12.182 10.682C11.3381 11.5259 10.1935 12 9 12C7.80653 12 6.66193 11.5259 5.81802 10.682C4.97411 9.83807 4.5 8.69347 4.5 7.5C4.5 6.30653 4.97411 5.16193 5.81802 4.31802C6.66193 3.47411 7.80653 3 9 3C10.1935 3 11.3381 3.47411 12.182 4.31802C13.0259 5.16193 13.5 6.30653 13.5 7.5ZM20.25 7.5C20.4489 7.5 20.6397 7.57902 20.7803 7.71967C20.921 7.86032 21 8.05109 21 8.25V11.25C21 11.4489 20.921 11.6397 20.7803 11.7803C20.6397 11.921 20.4489 12 20.25 12H17.25C17.0511 12 16.8603 11.921 16.7197 11.7803C16.579 11.6397 16.5 11.4489 16.5 11.25C16.5 11.0511 16.579 10.8603 16.7197 10.7197C16.8603 10.579 17.0511 10.5 17.25 10.5H19.5V8.25C19.5 8.05109 19.579 7.86032 19.7197 7.71967C19.8603 7.57902 20.0511 7.5 20.25 7.5Z"
      fill="#ffffff"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5 11.25C19.5 11.0511 19.579 10.8603 19.7197 10.7197C19.8603 10.579 20.0511 10.5 20.25 10.5H23.25C23.4489 10.5 23.6397 10.579 23.7803 10.7197C23.921 10.8603 24 11.0511 24 11.25C24 11.4489 23.921 11.6397 23.7803 11.7803C23.6397 11.921 23.4489 12 23.25 12H21V14.25C21 14.4489 20.921 14.6397 20.7803 14.7803C20.6397 14.921 20.4489 15 20.25 15C20.0511 15 19.8603 14.921 19.7197 14.7803C19.579 14.6397 19.5 14.4489 19.5 14.25V11.25Z"
      fill="#ffffff"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 17 },
    height: { type: [Number, String], default: 17 },
    iconColor: { type: String, default: "#ffffff" },
  },
};
</script>
