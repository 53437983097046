<template>
  <DeleteProjectModal
    v-if="isShownDeleteProjectModal"
    :project-id="storedProject.id"
    project-type="draft"
    @close="isShownDeleteProjectModal = false"
  ></DeleteProjectModal>

  <ShowModalPSites
    v-if="showModalPSites"
    @close="showModalPSites = false"
    :projectVisibility="storedProject.project_visibility"
  />

  <div v-if="storedProject && Object.keys(storedProject).length">
    <ProjectEditDraft
      v-if="storedProject.status === 'draft'"
      @delete-project="showDeleteProjectModal"
      @select="showModalPSites = true"
    />
    <ProjectEditFeasibility
      v-else-if="storedProject.status === 'feasibility'"
      @delete-project="showDeleteProjectModal"
      @select="showModalPSites = true"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import ProjectEditDraft from "@/components/project/ProjectEditDraft.vue";
import ProjectEditFeasibility from "@/components/project/ProjectEditFeasibility.vue";
import ShowModalPSites from "@/components/project/ShowModalPSites.vue";
import DeleteProjectModal from "@/components/project/DeleteProjectModal.vue";

export default {
  components: {
    ProjectEditDraft,
    ProjectEditFeasibility,
    ShowModalPSites,
    DeleteProjectModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    store
      .dispatch("projects/getProject", { id: route.params.id })
      .then((res) => {
        if (!res) router.replace({ name: "ProjectList" });
      });

    const showModalPSites = ref(false);
    const isShownDeleteProjectModal = ref(false);
    const showDeleteProjectModal = () =>
      (isShownDeleteProjectModal.value = true);

    const storedProject = computed(() => store.getters["projects/project"]);
    const procurementSites = computed(
      () => store.getters["projects/procurementSites"]
    );

    return {
      storedProject,
      showModalPSites,
      isShownDeleteProjectModal,
      showDeleteProjectModal,
      procurementSites,
    };
  },
};
</script>
