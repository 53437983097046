<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    enable-background="new 0 0 515.556 515.556"
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 18 18"
  >
    <path
      d="M10.4714 6.8291L16.7118 8.06868C17.2147 8.17493 17.5547 8.59994 17.5547 9.10285V16.3279C17.5547 16.9158 17.0801 17.3904 16.4922 17.3904H10.1172C10.3155 17.3904 10.4714 17.2345 10.4714 17.0362V16.682H16.4922C16.6834 16.682 16.8464 16.5262 16.8464 16.3279V9.10285C16.8464 8.93993 16.733 8.79119 16.5701 8.75577L10.4714 7.5516V6.8291Z"
    />
    <path
      d="M14.3685 10.3066C14.5668 10.3066 14.7227 10.4625 14.7227 10.6608C14.7227 10.8591 14.5668 11.015 14.3685 11.015H12.9518C12.7535 11.015 12.5977 10.8591 12.5977 10.6608C12.5977 10.4625 12.7535 10.3066 12.9518 10.3066H14.3685Z"
    />
    <path
      d="M14.3685 12.4316C14.5668 12.4316 14.7227 12.5875 14.7227 12.7858C14.7227 12.9841 14.5668 13.14 14.3685 13.14H12.9518C12.7535 13.14 12.5977 12.9841 12.5977 12.7858C12.5977 12.5875 12.7535 12.4316 12.9518 12.4316H14.3685Z"
    />
    <path
      d="M14.3685 14.5566C14.5668 14.5566 14.7227 14.7125 14.7227 14.9108C14.7227 15.1091 14.5668 15.265 14.3685 15.265H12.9518C12.7535 15.265 12.5977 15.1091 12.5977 14.9108C12.5977 14.7125 12.7535 14.5566 12.9518 14.5566H14.3685Z"
    />
    <path
      d="M10.47 17.0353C10.47 17.2337 10.3142 17.3895 10.1159 17.3895C9.91755 17.3895 9.76172 17.2337 9.76172 17.0353V16.6812V7.11871C9.76172 7.01246 9.8113 6.91329 9.88922 6.84246C9.97422 6.77871 10.0805 6.75038 10.1867 6.77163L10.47 6.82829V7.55079V16.6812V17.0353Z"
    />
    <path
      d="M9.76431 16.6816V17.0358C9.76431 17.2342 9.92015 17.39 10.1185 17.39H7.28516C7.48349 17.39 7.63932 17.2342 7.63932 17.0358V16.6816H9.76431Z"
    />
    <path
      d="M7.99349 3.93164C8.19182 3.93164 8.34766 4.08748 8.34766 4.28581C8.34766 4.48415 8.19182 4.63998 7.99349 4.63998H6.57682C6.37849 4.63998 6.22266 4.48415 6.22266 4.28581C6.22266 4.08748 6.37849 3.93164 6.57682 3.93164H7.99349Z"
    />
    <path
      d="M8.34766 6.41081C8.34766 6.60915 8.19182 6.76498 7.99349 6.76498H6.57682C6.37849 6.76498 6.22266 6.60915 6.22266 6.41081C6.22266 6.21248 6.37849 6.05664 6.57682 6.05664H7.99349C8.19182 6.05664 8.34766 6.21248 8.34766 6.41081Z"
    />
    <path
      d="M7.99349 8.18164C8.19182 8.18164 8.34766 8.33747 8.34766 8.5358C8.34766 8.73413 8.19182 8.88996 7.99349 8.88996H6.57682C6.37849 8.88996 6.22266 8.73413 6.22266 8.5358C6.22266 8.33747 6.37849 8.18164 6.57682 8.18164H7.99349Z"
    />
    <path
      d="M7.99349 10.3066C8.19182 10.3066 8.34766 10.4625 8.34766 10.6608C8.34766 10.8591 8.19182 11.015 7.99349 11.015H6.57682C6.37849 11.015 6.22266 10.8591 6.22266 10.6608C6.22266 10.4625 6.37849 10.3066 6.57682 10.3066H7.99349Z"
    />
    <path
      d="M4.80469 10.6608C4.80469 10.8591 4.64885 11.015 4.45052 11.015H3.03385C2.83552 11.015 2.67969 10.8591 2.67969 10.6608C2.67969 10.4625 2.83552 10.3066 3.03385 10.3066H4.45052C4.64885 10.3066 4.80469 10.4625 4.80469 10.6608Z"
    />
    <path
      d="M4.45052 3.93164C4.64885 3.93164 4.80469 4.08748 4.80469 4.28581C4.80469 4.48415 4.64885 4.63998 4.45052 4.63998H3.03385C2.83552 4.63998 2.67969 4.48415 2.67969 4.28581C2.67969 4.08748 2.83552 3.93164 3.03385 3.93164H4.45052Z"
    />
    <path
      d="M4.45052 6.05664C4.64885 6.05664 4.80469 6.21248 4.80469 6.41081C4.80469 6.60915 4.64885 6.76498 4.45052 6.76498H3.03385C2.83552 6.76498 2.67969 6.60915 2.67969 6.41081C2.67969 6.21248 2.83552 6.05664 3.03385 6.05664H4.45052Z"
    />
    <path
      d="M4.45052 8.18164C4.64885 8.18164 4.80469 8.33747 4.80469 8.5358C4.80469 8.73413 4.64885 8.88996 4.45052 8.88996H3.03385C2.83552 8.88996 2.67969 8.73413 2.67969 8.5358C2.67969 8.33747 2.83552 8.18164 3.03385 8.18164H4.45052Z"
    />
    <path
      d="M6.92839 13.4941C6.92839 13.2958 6.76547 13.14 6.57422 13.14H4.44922C4.25089 13.14 4.09505 13.2958 4.09505 13.4941V16.6816H3.38672V13.4941C3.38672 12.9062 3.8613 12.4316 4.44922 12.4316H6.57422C7.16214 12.4316 7.63672 12.9062 7.63672 13.4941V16.6816H6.92839V13.4941Z"
    />
    <path
      d="M4.09505 16.6816H6.92839H7.63672V17.0358C7.63672 17.2342 7.48089 17.39 7.28255 17.39H3.74089C3.54255 17.39 3.38672 17.2342 3.38672 17.0358V16.6816H4.09505Z"
    />
    <path
      d="M1.79427 0.531369L9.57885 1.71429C10.0959 1.79929 10.4714 2.23845 10.4714 2.76262V6.82845L10.188 6.77179C10.0818 6.75054 9.97552 6.77887 9.89052 6.84262C9.8126 6.91345 9.76302 7.01262 9.76302 7.11887V2.76262C9.76302 2.58554 9.63552 2.43679 9.46552 2.40845L1.68094 1.23262C1.65969 1.22554 1.63844 1.22554 1.61719 1.22554C1.53219 1.22554 1.45427 1.25387 1.39052 1.31054C1.30552 1.38137 1.26302 1.47345 1.26302 1.5797V16.3272C1.26302 16.5255 1.42594 16.6814 1.61719 16.6814H3.38802V17.0355C3.38802 17.2339 3.54385 17.3897 3.74219 17.3897H1.61719C1.02927 17.3897 0.554688 16.9151 0.554688 16.3272V1.5797C0.554688 1.26804 0.689271 0.970535 0.930104 0.772202C1.17094 0.566785 1.4826 0.481785 1.79427 0.531369Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 18 },
    height: { type: [Number, String], default: 18 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
