import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {
    archiveProjectList: [],
  },

  getters: {
    archiveProjectList: (state) => {
      return state.archiveProjectList;
    },
  },

  mutations: {
    GET_PROJECTS(state, payload) {
      state.archiveProjectList = payload;
    },
    MAKE_PROJECT_ARCHIVE(state, payload) {
      console.log(payload);
      // state.archiveProjectList = payload;
    },
  },

  actions: {
    async getAllArchivedProjects({ commit, dispatch }, page) {
      const newPage = page ? `?page=${page}` : "?page=1";
      await axios
        .get("/archived-projects" + newPage)
        .then((response) => {
          if (response.data.status === "Success") {
            commit("GET_PROJECTS", response.data.data.data);
            dispatch("projects/getPagination", response.data.data, {
              root: true,
            });
          }
        })
        .catch((err) => {
          console.error("Error from getAllArchivedProjects: ", err);
        });
    },
    // async getArchiveProject({ commit }, { id }) {
    //   await axios
    //     .get("project/archive/" + id)
    //     .then((response) => {
    //       commit("GET_ARCHIVE_PROJECT", response.data.data);
    //     })
    //     .catch((err) => {
    //       console.error("Error from getArchiveProject: ", err);
    //     });
    // },
    async makeArchive({ commit }, id) {
      await axios.post("project/archive/" + id).then(() => {
        commit("MAKE_PROJECT_ARCHIVE", id);
      });
    },

    async getFilteredProjects({ commit, dispatch }, { params, page }) {
      commit("projects/LOADING_STATE", true, { root: true });

      const newPage = page ? `&page=${page}` : "";
      let query = [];

      for (const p in params) {
        query.push(`${p}=${params[p]}`);
      }

      const q = query.join().replace(/,/g, "&");

      await axios
        .get(`/search-projects?${q}${newPage}`)
        .then((response) => {
          if (response.data.status === "Success") {
            commit("GET_PROJECTS", response.data.data.data);
            dispatch("projects/getPagination", response.data.data, {
              root: true,
            });
          }
        })
        .catch((err) => {
          console.error("Error from getAllProjects: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
  },
};
