<template>
  <div class="line">
    <button class="close-btn" @click="removeLine" />
    <div class="line-content">
      <form @submit.prevent="editLine">
        <div class="line-header">
          <input
            type="text"
            class="line-name-input"
            v-model="formLineEdit.title"
            placeholder="Indication - Ex: NHL"
            required
            :class="{
              error:
                (!formLineEdit.title && showErr) ||
                (formLineEdit.title && !formLineEdit.title.length && showErr),
            }"
          />
          <div class="line-quantity">
            Up to:
            <input
              type="text"
              class="line-quantity-input"
              v-model="formLineEdit.cases_count"
              placeholder="Ex: 20"
              required
              :class="{ error: !formLineEdit.cases_count && showErr }"
            />
            Cases
          </div>
        </div>

        <div>
          <textarea
            cols="30"
            rows="2"
            class="line-textarea"
            v-model="formLineEdit.description"
            placeholder="HBS Description - Ex: Plasma 5ml ...."
          >
          </textarea>
        </div>
        <div class="text-right">
          <BaseButton
            class-name="btn typical"
            text="Save line"
            @click="editLine"
          />
        </div>
      </form>
    </div>

    <hr />
  </div>
</template>

<script>
import { ref, toRefs, watch } from "vue";
import BaseButton from "@/modules/components/base/BaseButton.vue";
import { useStore } from "vuex";

export default {
  components: { BaseButton },
  props: {
    item: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    getLineInfo: {
      type: Number,
      default: 1,
    },
  },
  // emits: ["remove-line"],
  setup(props) {
    const store = useStore();
    const { item, getLineInfo } = toRefs(props);

    const formLineEdit = ref({});
    const showErr = ref(false);
    formLineEdit.value = item.value;

    const validateLine = (data) => {
      showErr.value = false;
      let payload = {
        isShown: true,
        text: "Please fill in required fields",
        status: "error",
      };

      if (!data.title || !data.cases_count) {
        showErr.value = true;
        store.commit("admins/SET_NOTIFICATION", payload);

        setTimeout(() => {
          cleanNotification();
        }, 6000);

        return false;
      }

      return true;
    };

    const cleanNotification = () => store.commit("admins/CLEAN_NOTIFICATION");

    const editLine = async () => {
      let data = formLineEdit.value;
      data.id = item.value.id;

      if (!validateLine(data)) {
        return;
      }

      await store.dispatch("projects/editLine", data);
      // emit("edit-line", {
      //   type: "editLine",
      //   line: {
      //     id: formLineEdit.id.value,
      //     title: formLineEdit.title.value,
      //     cases_count: formLineEdit.cases_count.value,
      //     description: formLineEdit.description.value,
      //   },
      // });
    };

    const removeLine = async () => {
      await store.dispatch("projects/removeLine", item.value.id);
      // emit("remove-line", { type: "removeLine", data: id });
    };

    watch(getLineInfo, (newValue) => {
      if (newValue) {
        editLine();
      }
    });

    return { formLineEdit, editLine, removeLine, showErr };
  },
};
</script>

<style lang="scss" scoped>
.text-right {
  text-align: right;
}
.typical {
  color: var(--col-contrast-text);
  background-color: var(--col-additional);
  border-color: var(--col-additional);

  &:hover {
    background-color: #0d4a8c;
  }
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  border: 1px solid var(--col-secondary);
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    border-color: var(--col-primary-dark);

    &:after,
    &:before {
      background-color: var(--col-primary-dark);
    }
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 6px;
    width: 1px;
    height: 10px;
    padding: 0;
    margin: 0;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
    background-color: var(--col-secondary);
  }

  &:before {
    transform: rotate(135deg);
  }
}

.line {
  position: relative;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid var(--col-border);
  border-radius: 8px;
  background-color: var(--col-bg-attention);

  &-content {
    padding-right: 28px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;

    .error {
      border-color: #dc3545;
    }
  }

  &-name-input,
  &-quantity-input,
  &-textarea {
    padding: 3px 5px;
    border: 1px solid var(--col-border);
    border-radius: 4px;
    color: var(--col-text-primary);
  }

  &-name-input {
    max-width: 40%;
    padding: 3px 5px;
  }

  &-quantity {
    padding-right: 20px;

    input {
      width: 80px;
      margin: 0 10px;
      text-align: center;

      &::placeholder {
        text-align: left;
      }
    }
  }

  &-textarea {
    width: 100%;
  }

  hr {
    margin-bottom: 10px;
  }

  &:last-of-type {
    hr {
      display: none;
    }
  }
}
</style>
